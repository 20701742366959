import { ADD_WITHDRAWAL_CREDIT, DELETE_WITHDRAWAL_CREDIT, EDIT_WITHDRAWAL_CREDIT, GET_GATEWAY_PAYMENT_STATMENT, GET_GATEWAY_PAYOUT_STATMENT, GET_WITHDRAWAL_CREDIT, READ_METRIWS } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const readWithdrawalCredit = (payload) => {
  return apiInstance.post(READ_METRIWS,payload);
};
export const getWithdrawalCredit = (payload) => {
  return apiInstance.post(GET_WITHDRAWAL_CREDIT,payload);
};
export const editWithdrawalCredit = (payload) => {
  return apiInstance.post(EDIT_WITHDRAWAL_CREDIT,payload);
};
export const addWithdrawalCredit = (payload) => {
  return apiInstance.post(ADD_WITHDRAWAL_CREDIT,payload);
};
export const deleteWithdrawalCredit = (payload) => {
  return apiInstance.post(DELETE_WITHDRAWAL_CREDIT,payload);
};