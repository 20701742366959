import React, { Fragment, useEffect } from "react";
import CheckBox from "../../../components/Custom/Elements/CheckBox";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  Merchant_Permission_Get,
  Update_Merchant_Permission,
  useMerchantPermission,
} from "../../../redux/slices/merchantSlice";
import { showToaster } from "../../../constant";
import { Button, Card, CardHeader, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const initialData = [
  {
    id: "dashboard",
    names: ["Dashboard"],
    parents: ["Report", "Statement", "Balance Sheet"],
    name: "Dashboard",
    value: "dashboard",
    label: "Dashboard",
  },
  {
    id: "deposit",
    name: "Deposit",
    names: ["Deposit"],
    parents: ["Report", "Statement", "Balance Sheet", "Claim UTR"],
    value: "deposit",
    label: "Deposit",
  },
  {
    id: "withdrawal",
    names: ["Withdrawal"],
    parents: ["Report", "Statement", "Balance Sheet"],
    name: "Withdrawal",
    value: "withdrawal",
    label: "Withdrawal",
  },
  {
    id: "claimutr",
    names: ["Claim UTR", "Deposit"],
    blockChild: ["Deposit"],
    name: "Claim UTR",
    value: "claimutr",
    label: "Claim UTR",
  },
  {
    id: "bulkupload",
    names: ["Bulk Upload", "Withdrawal"],
    blockChild: ["Withdrawal"],
    name: "Bulk Upload",
    value: "bulkupload",
    label: "Bulk Upload",
  },
  {
    id: "statement",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Statement",
    value: "statement",
    label: "Statement",
  },
  {
    id: "balancesheet",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Balance Sheet",
    value: "balancesheet",
    label: "Balance Sheet",
  },
  {
    id: "fundtransfer",
    names: ["Internal Fund Transfer"],
    name: "Internal Fund Transfer",
    value: "fundtransfer",
    label: "Internal Fund Transfer",
  },
  {
    id: "report",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Report",
    value: "report",
    label: "Report",
  },
];

const MerchantPermission = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const merchantPermission = useMerchantPermission();

  const [data, setData] = useState(initialData);
  const role = useRole("Merchant");

  const onSubmit = async () => {
    const values = getValues();
    const fileUpload = new FormData();

    try {
      const permissions = data
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.name);
      fileUpload.append("innerLogo", values?.innerLogo);
      fileUpload.append("outerLogo", values?.outerLogo);
      fileUpload.append("merchantId", id);
      fileUpload.append("permission", JSON.stringify(permissions));
      const res = await dispatch(
        Update_Merchant_Permission(fileUpload)
      ).unwrap();
      get_Mer_Permission();
      responseToaster(res);
    } catch (err) {}
  };

  const { watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {},
  });

  const get_Mer_Permission = async () => {
    try {
      await dispatch(Merchant_Permission_Get({ merchantId: id })).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role.includes("merchant-permission")) {
      get_Mer_Permission();
    }
  }, []);

  useEffect(() => {
    const newData = initialData?.map((item) => ({
      ...item,
      checked: merchantPermission?.includes(item?.name),
    }));
    setData(newData);
  }, [merchantPermission]);

  if (!role.includes("merchant-permission")) {
    return;
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="main-title mb-2">
          <b>Select Access</b>
        </h4>
      </CardHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-2">
          <div className="d-flex gap-1 flex-wrap">
            {data?.map((val, i, array) => {
              return (
                <Fragment key={i} className="">
                  <CheckBox
                    className="accent-chatlook-darkblue"
                    label={val?.label}
                    id={val?.id}
                    name={val?.name}
                    value={val?.value}
                    checked={!!val?.checked}
                    disabled={!role.includes("merchant-permission-update")}
                    onChange={(e) => {
                      const allNames = array?.map((item) => item?.name);
                      if (e.target.checked) {
                        if (val?.names?.length > 1) {
                          showToaster("All required permissions selected");
                        }
                        const newData = data?.map((item) => ({
                          ...item,
                          checked:
                            val?.names?.includes(item?.name) &&
                            allNames?.includes(item?.name)
                              ? true
                              : !!item?.checked,
                        }));
                        setData([...newData]);
                      } else {
                        if (val?.parents?.length) {
                          const index =
                            data
                              ?.filter((item) => item?.checked)
                              ?.findIndex((item) =>
                                val?.parents?.includes(item?.name)
                              ) || -1;
                          if (index > -1) {
                            // showToaster(`${val?.parents?.join(', ')} - Parents should unchecked first`,'Warning')
                            return;
                          }
                        }
                        const newData = data?.map((item) => ({
                          ...item,
                          checked:
                            val?.names?.includes(item?.name) &&
                            !val?.blockChild?.includes(item?.name)
                              ? false
                              : !!item?.checked,
                        }));
                        setData([...newData]);
                      }
                    }}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className="d-flex gap-1 mt-1 justify-content-end p-1">
          <Button type="submit" disabled={!role.includes("merchant-permission-update")} color="primary">
            Update
          </Button>
        </div>
      </form>

    </Card>
  );
};

export default MerchantPermission;
