import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Dashboard_Deposit_Chart_Bank,
  Dashboard_Withdrawal_Chart_Bank,
  Get_Available_Balance,
  Get_Bank_Sync,
  Get_Dashboard_Deposit_Chart,
  Get_Dashboard_Deposit_Chart_Last4,
  Get_Dashboard_Withdrawal_Chart,
  Get_Dashboard_Withdrawal_Chart_Last4,
  Get_MM_Dashboard_Data,
  Get_MM_Dashboard_Summary,
  Get_Mobile_Sync,
  Get_Payment_data,
  Get_Payout_Summary,
  Get_Pg_Summary,
  Get_Transaction_Summary,
  Payout_CR_Data,
  Payout_Summary,
} from "../services/dashboardServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  availableBalanceLoading: false,
  availableBalanceState: {},
  depositChartIsLoading: false,
  withdrawalChartIsLoading: false,
  depositChart: {},
  withdrawalChart: {},
  transactionSummaryState: [],
  payoutSummaryState: [],
  pgSummaryState: {},
  isLoading: true,
  payoutLoading: true,
  pgSummaryIsLoading: true,
  getPayoutCRLoading: true,
  getPayoutCRState: {},
  mmDashboardDataState: [],
  mmDashboardDataStateLoading: true,
  mmDashboardSummaryState: [],
  payoutSummarys: [],
  bankSyncGetLoading: true,
  summeryPayoutGetLoading: true,
  bankSyncGetState: [],
  mobileSyncGetLoading: true,
  mobileSyncGetState: {},
  trackingChart: {},
  getPaymentDataState: {},
  getDepoChartBankState: {},
  getDepoChartBankLoading: true,
  getWithChartBankState: {},
  getWithChartBankLoading: true,
};
export const availableBalancegGet = createAsyncThunk(
  "/available-balance",
  async (payload) => {
    return await Get_Available_Balance(payload);
  }
);
export const dashboardDepositChartGet = createAsyncThunk(
  "/dashboard-deposit-chart",
  async (payload) => {
    return await Get_Dashboard_Deposit_Chart(payload);
  }
);
export const dashboardDepositChartLast4Get = createAsyncThunk(
  "/dashboardDepositChartLast4Get",
  async (payload) => {
    return await Get_Dashboard_Deposit_Chart_Last4(payload);
  }
);
export const dashboardWithdrawalChartGet = createAsyncThunk(
  "/dashboardWithdrawalChartGet",
  async (payload) => {
    return await Get_Dashboard_Withdrawal_Chart(payload);
  }
);
export const dashboardWithdrawalChartLast4Get = createAsyncThunk(
  "/dashboardWithdrawalChartLast4Get",
  async (payload) => {
    return await Get_Dashboard_Withdrawal_Chart_Last4(payload);
  }
);
export const Transaction_Summary_Get = createAsyncThunk(
  "/transaction-summary",
  async (payload) => {
    return await Get_Transaction_Summary(payload);
  }
);
export const Payout_Summary_Get = createAsyncThunk(
  "/payout-summary",
  async (payload) => {
    return await Get_Payout_Summary(payload);
  }
);
export const Pg_Summary_Get = createAsyncThunk(
  "/pg-summary",
  async (payload) => {
    return await Get_Pg_Summary(payload);
  }
);
export const Get_Payout_CR_Data = createAsyncThunk(
  "/payout-cr-summary",
  async (payload) => {
    return await Payout_CR_Data(payload);
  }
);
export const MM_Dashboard_Data = createAsyncThunk(
  "/mm_dashboard_data",
  async (payload) => {
    return await Get_MM_Dashboard_Data(payload);
  }
);
export const MM_Dashboard_Summary = createAsyncThunk(
  "/mm_dashboard_summary",
  async (payload) => {
    return await Get_MM_Dashboard_Summary(payload);
  }
);
export const Summary_Payout = createAsyncThunk(
  "/summary_payout",
  async (payload) => {
    return await Payout_Summary(payload);
  }
);
export const Bank_Sync_Get = createAsyncThunk(
  "/bank_sync_get",
  async (payload) => {
    return await Get_Bank_Sync(payload);
  }
);
export const Mobile_Sync_Get = createAsyncThunk(
  "/mobile_sync_get",
  async (payload) => {
    return await Get_Mobile_Sync(payload);
  }
);

export const Payment_Data_Get = createAsyncThunk(
  "/Get_Payment_data",
  async (payload) => {
    return await Get_Payment_data(payload);
  }
);
export const dashboardDepositChartBank = createAsyncThunk(
  "/Dashboard_Deposit_Chart_Bank",
  async (payload) => {
    return await Dashboard_Deposit_Chart_Bank(payload);
  }
);
export const dashboardWithdrawalChartBank = createAsyncThunk(
  "/Dashboard_Withdrawal_Chart_Bank",
  async (payload) => {
    return await Dashboard_Withdrawal_Chart_Bank(payload);
  }
);

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(availableBalancegGet.fulfilled, (state, action) => {
      state.availableBalanceLoading = false;
      state.availableBalanceState = action.payload;
    });

    builder.addCase(availableBalancegGet.pending, (state, action) => {
      state.availableBalanceLoading = true;
    });
    builder.addCase(availableBalancegGet.rejected, (state, action) => {
      state.availableBalanceLoading = false;
    });

    builder.addCase(dashboardDepositChartGet.fulfilled, (state, action) => {
      state.depositChartIsLoading = false;
      state.depositChart = action.payload;
    });
    builder.addCase(dashboardDepositChartGet.pending, (state, action) => {
      state.depositChartIsLoading = true;
    });
    builder.addCase(dashboardDepositChartGet.rejected, (state, action) => {
      state.depositChartIsLoading = false;
    });

    builder.addCase(
      dashboardDepositChartLast4Get.fulfilled,
      (state, action) => {
        state.depositChartIsLoading = false;
        state.depositChart = action.payload;
      }
    );
    builder.addCase(dashboardDepositChartLast4Get.pending, (state, action) => {
      state.depositChartIsLoading = true;
    });
    builder.addCase(dashboardDepositChartLast4Get.rejected, (state, action) => {
      state.depositChartIsLoading = false;
    });

    builder.addCase(dashboardWithdrawalChartGet.fulfilled, (state, action) => {
      state.withdrawalChartIsLoading = false;
      state.withdrawalChart = action.payload;
    });
    builder.addCase(dashboardWithdrawalChartGet.pending, (state, action) => {
      state.withdrawalChartIsLoading = true;
    });
    builder.addCase(dashboardWithdrawalChartGet.rejected, (state, action) => {
      state.withdrawalChartIsLoading = false;
    });

    builder.addCase(
      dashboardWithdrawalChartLast4Get.fulfilled,
      (state, action) => {
        state.withdrawalChartIsLoading = false;
        state.withdrawalChart = action.payload;
      }
    );
    builder.addCase(
      dashboardWithdrawalChartLast4Get.pending,
      (state, action) => {
        state.withdrawalChartIsLoading = true;
      }
    );
    builder.addCase(
      dashboardWithdrawalChartLast4Get.rejected,
      (state, action) => {
        state.withdrawalChartIsLoading = false;
      }
    );

    builder.addCase(Transaction_Summary_Get.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionSummaryState = action.payload;
    });
    builder.addCase(Transaction_Summary_Get.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Transaction_Summary_Get.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(Payout_Summary_Get.fulfilled, (state, action) => {
      state.payoutLoading = false;
      state.payoutSummaryState = action.payload;
    });
    builder.addCase(Payout_Summary_Get.pending, (state, action) => {
      state.payoutLoading = true;
    });
    builder.addCase(Payout_Summary_Get.rejected, (state, action) => {
      state.payoutLoading = false;
    });
    builder.addCase(Pg_Summary_Get.fulfilled, (state, action) => {
      state.pgSummaryIsLoading = false;
      state.pgSummaryState = action.payload;

      // const pgData = action.payload
      // pgData?.data?.filter((val, i) => {
      // });
    });
    builder.addCase(Pg_Summary_Get.pending, (state, action) => {
      state.pgSummaryIsLoading = true;
    });
    builder.addCase(Pg_Summary_Get.rejected, (state, action) => {
      state.pgSummaryIsLoading = false;
    });
    builder.addCase(Get_Payout_CR_Data.fulfilled, (state, action) => {
      state.getPayoutCRLoading = false;
      state.getPayoutCRState = action.payload;
    });
    builder.addCase(Get_Payout_CR_Data.pending, (state, action) => {
      state.getPayoutCRLoading = true;
    });
    builder.addCase(Get_Payout_CR_Data.rejected, (state, action) => {
      state.getPayoutCRLoading = false;
    });
    builder.addCase(MM_Dashboard_Data.pending, (state, action) => {
      state.mmDashboardDataStateLoading = true;
    });
    builder.addCase(MM_Dashboard_Data.fulfilled, (state, action) => {
      state.mmDashboardDataState = action.payload;
      state.mmDashboardDataStateLoading = false;
    });
    builder.addCase(MM_Dashboard_Data.rejected, (state, action) => {
      state.mmDashboardDataStateLoading = false;
    });

    builder.addCase(MM_Dashboard_Summary.fulfilled, (state, action) => {
      state.mmDashboardSummaryState = action.payload;
    });

    builder.addCase(Summary_Payout.fulfilled, (state, action) => {
      state.payoutSummarys = action.payload;
      state.summeryPayoutGetLoading = false;
    });
    builder.addCase(Summary_Payout.pending, (state, action) => {
      state.summeryPayoutGetLoading = true;
    });
    builder.addCase(Summary_Payout.rejected, (state, action) => {
      state.summeryPayoutGetLoading = false;
    });
    builder.addCase(Bank_Sync_Get.fulfilled, (state, action) => {
      state.bankSyncGetLoading = false;
      state.bankSyncGetState = action.payload;
    });
    builder.addCase(Bank_Sync_Get.pending, (state, action) => {
      state.bankSyncGetLoading = true;
    });
    builder.addCase(Bank_Sync_Get.rejected, (state, action) => {
      state.bankSyncGetLoading = false;
    });
    builder.addCase(Mobile_Sync_Get.fulfilled, (state, action) => {
      state.mobileSyncGetLoading = false;
      state.mobileSyncGetState = action.payload;
    });
    builder.addCase(Mobile_Sync_Get.pending, (state, action) => {
      state.mobileSyncGetLoading = true;
    });
    builder.addCase(Mobile_Sync_Get.rejected, (state, action) => {
      state.mobileSyncGetLoading = false;
    });

    builder.addCase(Payment_Data_Get.fulfilled, (state, action) => {
      state.getPaymentDataState = action.payload;
    });
    builder.addCase(dashboardDepositChartBank.fulfilled, (state, action) => {
      state.getDepoChartBankLoading = false;
      state.getDepoChartBankState = action.payload;
    });
    builder.addCase(dashboardDepositChartBank.pending, (state, action) => {
      state.getDepoChartBankLoading = true;
    });
    builder.addCase(dashboardDepositChartBank.rejected, (state, action) => {
      state.getDepoChartBankLoading = false;
    });
    builder.addCase(dashboardWithdrawalChartBank.fulfilled, (state, action) => {
      state.getWithChartBankLoading = false;
      state.getWithChartBankState = action.payload;
    });
    builder.addCase(dashboardWithdrawalChartBank.pending, (state, action) => {
      state.getWithChartBankLoading = true;
    });
    builder.addCase(dashboardWithdrawalChartBank.rejected, (state, action) => {
      state.getWithChartBankLoading = false;
    });
  },
});

export default dashboardSlice.reducer;
export const { clearDashboard } = dashboardSlice.actions;

export const selectGetDepoChartBankState = (state) => {
  return state.dashboard.getDepoChartBankState;
};
export const useGetDepoChartBankState = () => {
  const getDepoChartBankState = useSelector(selectGetDepoChartBankState);
  return useMemo(() => getDepoChartBankState, [getDepoChartBankState]);
};
export const selectGetWithChartBankState = (state) => {
  return state.dashboard.getWithChartBankState;
};
export const useGetWithChartBankState = () => {
  const getWithChartBankState = useSelector(selectGetWithChartBankState);
  return useMemo(() => getWithChartBankState, [getWithChartBankState]);
};
