import { Fragment } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiListItem from "@mui/material/ListItem";

import clsx from "clsx";
import { toast } from "react-hot-toast";

import { Link, useLocation, useNavigate } from "react-router-dom";
import themeConfig from "../../../../configs/themeConfig";
import {
  getLocalData,
  hexToRGBA,
  isNavLinkActive,
  prepareImageName,
} from "../../../../helperFunctions";
import { baseUrlImg } from "../../../../redux/api/baseUrl";
import { matchResellerPwddata } from "../../../../redux/slices/trackingSlice";
import { useRole, useUser } from "../../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";

const ListItem = styled(MuiListItem)(({ theme }) => {
  return {
    width: "auto",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.active, &.active:hover": {
      backgroundColor: `var(--chatlook-darkblue--)`,
    },
    "&.active .MuiTypography-root, &.active .MuiListItemIcon-root": {
      color: "#fff",
    },
    "&.active .MuiTypography-root": {
      fontWeight: 500,
    },
    "&:focus-visible": {
      outline: 0,
      backgroundColor: `var(--chatlook-darkblue--)`,
    },
  };
});

const HorizontalNavLink = (props) => {
  const { item, hasParent } = props;
  const role = useRole("Role Manager");
  const router = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const dispatch = useDispatch();

  const { menuTextTruncate } = themeConfig;
  const Wrapper = !hasParent ? List : Fragment;

  const isNavLinkActive = () => {
    if (
      item?.name === "Merchant" ||
      item?.name === "SS Deposit" ||
      item?.name === "Bank Transaction"
    ) {
      const items = item?.route?.split("/")?.[1];
      const routes = router?.pathname?.split("/")?.[1];
      return items === routes;
    } else {
      return router?.pathname === item?.route;
    }
  };

  return (
    <>
      <Wrapper
        {...(!hasParent
          ? {
              component: "div",
              sx: { py: 2.75 },
            }
          : {})}
      >
        <ListItem
          component={Link}
          className={clsx({ active: isNavLinkActive(router?.pathname) })}
          target={item.openInNewTab ? "_blank" : undefined}
          to={
            item?.route === undefined
              ? "/"
             /*  : item?.route === "/resellerList"
              ? undefined */
              : `${item?.route}`
          }
          onClick={async (e) => {
           /*  try {
              if (role.includes("match-sub-user-pwd")) {
                if (item?.route === "/resellerList") {
                  const password = prompt("Please enter your password");
                  if (password !== null) {
                    const token = getLocalData("token");

                    const res = await dispatch(
                      matchResellerPwddata({
                        email_id: token?.emailId,
                        password,
                      })
                    ).unwrap();
                    if (res?.status === true) {
                      navigate("/resellerList");
                    } else {
                      toast.error(res?.message, {
                        position: "top-right",
                      });
                    }
                  }
                }
              }
            } catch (err) {} */
            if (isNavLinkActive()) {
              if (
                (router?.pathname?.split("/")?.[1] !== "merchant" &&
                  router?.pathname?.split("/")?.[1] !== "ss-transaction") ||
                router?.pathname === "/merchant" ||
                router?.pathname === "/ss-transaction" ||
                router?.pathname === "/bank-transaction"
              ) {
                window.location.reload(false);
              }
            }
          }}
          sx={{
            cursor: "pointer",
            ...(!hasParent
              ? {
                  "&.active, &.active:hover": {
                    boxShadow: (theme) =>
                      `0px 2px 6px ${hexToRGBA(
                        theme.palette.primary.main,
                        0.48
                      )}`,
                    background: (theme) =>
                      `linear-gradient(72.47deg, ${
                        theme.palette.primary.main
                      } 22.16%, ${hexToRGBA(
                        theme.palette.primary.main,
                        0.7
                      )} 76.47%)`,
                    "&:focus-visible": {
                      background: (theme) =>
                        `linear-gradient(72.47deg, ${
                          theme.palette.primary.dark
                        } 22.16%, ${hexToRGBA(
                          theme.palette.primary.dark,
                          0.7
                        )} 76.47%)`,
                    },
                    "& .MuiTypography-root, & .MuiListItemIcon-root": {
                      color: "common.white",
                    },
                  },
                }
              : {
                  mx: 2,
                  width: `100%`,
                  "&.active, &.active:hover": {
                    "&:focus-visible": {
                      backgroundColor: (theme) =>
                        hexToRGBA(theme.palette.primary.main, 0.24),
                    },
                  },
                }),
          }}
        >
          <Box
            sx={{
              gap: 2,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginBottom: "0",
                display: "flex",
                alignItems: "center",
                ...(menuTextTruncate ? { overflow: "hidden" } : {}),
              }}
            >
              <ListItemIcon
                sx={{ mr: 2, color: "text.secondary", minWidth: "20px" }}
              >
                {item?.icon_class ? (
                  <img
                    className="subitem-icon"
                    src={`${baseUrlImg}${
                      isNavLinkActive()
                        ? prepareImageName(item?.icon_class)
                        : item?.icon_class
                    }`}
                  />
                ) : (
                  <div className="dot"></div>
                )}
              </ListItemIcon>
              <Typography
                className="mb-0 bold"
                overflow="visible"
                sx={{ color: "text.primary", fontSize: 14 }}
              >
                {item?.name}
              </Typography>
            </Box>
            {item.badgeContent ? (
              <Chip
                size="small"
                label={item.badgeContent}
                color={item.badgeColor || "primary"}
                sx={{
                  height: 22,
                  minWidth: 22,
                  "& .MuiChip-label": { px: 1.5, textTransform: "capitalize" },
                }}
              />
            ) : null}
          </Box>
        </ListItem>
      </Wrapper>
    </>
  );
};

export default HorizontalNavLink;
