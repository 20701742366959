// React Imports
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./Login.scss";

// Formik validation
import * as Yup from "yup";
import EyeOff from "../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../assets/images/pages/eye-on.svg";

// Reactstrap Imports
import { CardTitle, Form, Label, Input, Button } from "reactstrap";

// Illustrations Imports
import illustrationsLight from "../../../assets/images/pages/login-v3.svg";

// Styles
import "../../../scss/react/pages/page-authentication.scss";
import {
  factorAuth,
  login,
  setIsLoading,
  setQr,
  useAuth,
  useIsLoading,
} from "../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import {
  prepareSvgUrl,
  responseToaster,
  verifyingUser,
} from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { yupResolver } from "@hookform/resolvers/yup";
import LogginUser from "../LogginUser";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const { auth } = useAuth();
  const isLoading = useIsLoading();
  const [loginConfirmModal, setLoginConfirmModal] = useState(false);
  const initialValues = {
    emailId: "",
    password: "",
  };

  const validationSchema = Yup.object({
    emailId: Yup.string().required("Please Enter Your Email or Phone number"),
    password: Yup.string().required("Please Enter Your Password"),
  });

  const onSubmit = async (values) => {
    try {
      dispatch(setIsLoading(true));

      const response = await dispatch(login(values)).unwrap();

      if (response?.is_set_secret_key === false) {
        /*   const domain = window.location.hostname;
          const response = await dispatch(factorAuth(domain)).unwrap();
          dispatch(setQr(response?.qr)); */
        verifyingUser(dispatch, false);
      }
      if (response?.status) {
        responseToaster(response);
      }
      // navigate(response?.firstRoute);
      reset();
      dispatch(setIsLoading(false));
    } catch (err) {
      dispatch(setIsLoading(false));
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const source = illustrationsLight;
  const logo = process.env.REACT_APP_OUTER_LOGO;

  return (
    <>
      {auth?.logged_in === true ? (
        <LogginUser open={auth?.logged_in === true} />
      ) : (
        <div className="login-wrapper">
          {/* <img src={LoginOne} alt="" className="img-flud LoginOne" />
    <img src={LoginTwo} alt="" className="img-flud LoginTwo" />
    <img src={LoginThree} alt="" className="img-flud LoginThree" /> */}
          <div className="login-inner">
            <div className="subindex">
              <div className="text-center loghead">
                {logo ? (
                  <img
                    src={prepareSvgUrl(logo)}
                    alt=""
                    width={300}
                    className="img-fluid mb-1"
                  />
                ) : null}

                <CardTitle tag="h2" className=" loghead">
                  Log in to your account
                </CardTitle>
                {/* <p className="">Welcome back! Please enter your details.</p> */}
              </div>
              <Form
                className="auth-login-form mt-2 d-flex flex-column gap-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <ControlledInput
                  type="text"
                  className="w-100"
                  name="emailId"
                  label="Username"
                  placeholder="Enter your username (Registered Email ID)"
                  control={control}
                  errors={errors}
                />
                <ControlledInput
                  type={isShow ? "text" : "password"}
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  rightIcon={
                    <img
                      src={isShow ? EyeOn : EyeOff}
                      alt="Eye"
                      onClick={() => setIsShow(!isShow)}
                    />
                  }
                  control={control}
                  errors={errors}
                />
                <div className="form-check mb-1">
                  <Input type="checkbox" id="remember-me" />
                  <Label className="form-check-label" for="remember-me">
                    Remember Me
                  </Label>
                </div>
                <Button
                  disabled={isLoading}
                  className="btn-login"
                  block
                  type="submit"
                >
                  Sign in
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
