export const searchDataOptions = [
  { value: "withdrawalId", label: "Withdrawal Id" },
  { value: "acHolderName", label: "Account Holder Name" },
  { value: "bankAc", label: "Account No" },
  { value: "bankRrn", label: "Bank RRN" },
  { value: "customerId", label: "Customer Id" },
  { value: "manualPayBatchId", label: "Manual pay batch id" },
  { value: "merchantRefId", label: "Merchant Ref Id" },
  { value: "pgRefId", label: "PG Ref Id" },
  { value: "withdrawalAmt", label: "Payment Amount" },
  { value: "processBy", label: "Process By" },
  { value: "tempBankRrn", label: "Temp Bank RRN" },
];

export const statusDataOptions = [
  { value: "Success", label: "Success" },
  { value: "Processing", label: "Processing" },
  { value: "Initialized", label: "Initialized" },
  { value: "Pending", label: "Pending" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Failed", label: "Failed" },
  { value: "LOWBAL", label: "LOWBAL" },
  { value: "OnHold", label: "OnHold" },
  { value: "Recheck", label: "Recheck" },
  { value: "FROZEN", label: "Frozen" },
  { value: "NotFound", label: "NotFound" },
];

export const payoutTypeOptions = [
  { value: "IMPS", label: "IMPS" },
  { value: "RTGS", label: "RTGS" },
  { value: "Manual", label: "Manual" },
  { value: "Success To Failed", label: "Success To Failed" },
];
