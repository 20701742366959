import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { GetOptionTracking, GetPeningPaymenData, Tracking, MatchReseller } from "../services/trackingServices";

const initialState = {
  trackingState: {},
  trackingIsLoading: false,
  pendingpaymentLoading:false,
  pendingpaymentState: [],
  trackingOptionState: [],
};

export const getTracking = createAsyncThunk("/getTracking", async (payload) => {
  return await Tracking(payload);
});
export const getOptionTracking = createAsyncThunk(
  "/getOptionTracking",
  async (payload) => {
    return await GetOptionTracking(payload);
  }
);
export const getpendingpaymentdata = createAsyncThunk(
  "/getpendingpaymentdata",
  async (payload) => {
    return await GetPeningPaymenData(payload);
  }
);
export const matchResellerPwddata = createAsyncThunk(
  "/matchResellerPwd",
  async (payload) => {
    return await MatchReseller(payload);
  }
);

const trackingSlice = createSlice({
  name: "trackingSlice",
  initialState,
  reducers: {
    clearTracking: () => initialState,
    setTracking: (state, { payload }) => {
      state.trackingState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTracking.pending, (state, action) => {
      state.trackingIsLoading = true;
    });
    builder.addCase(getTracking.fulfilled, (state, action) => {
      state.trackingIsLoading = false;
      state.trackingState = action.payload;
    });
    builder.addCase(getTracking.rejected, (state, action) => {
      state.trackingIsLoading = false;
    });
    builder.addCase(getpendingpaymentdata.pending, (state, action) => {
      state.pendingpaymentLoading = true;
    });
    builder.addCase(getpendingpaymentdata.fulfilled, (state, action) => {
      state.pendingpaymentLoading = false;
      state.pendingpaymentState = action.payload;
    });
    builder.addCase(getpendingpaymentdata.rejected, (state, action) => {
      state.pendingpaymentLoading = false;
    });
    builder.addCase(getOptionTracking.fulfilled, (state, action) => {
      state.trackingOptionState = action.payload;
    });
  },
});

export default trackingSlice.reducer;
export const { clearTransaction, setTracking } = trackingSlice.actions;

export const selectTracking = (state) => {
  return state.tracking.trackingState;
};
export const useTracking = () => {
  const trackingState = useSelector(selectTracking);
  return useMemo(() => trackingState, [trackingState]);
};

export const selectPendingpaymentState = (state) => {
  return state.tracking.pendingpaymentState;
};
export const usePendingpaymentState = () => {
  const pendingpaymentState = useSelector(selectPendingpaymentState);
  return useMemo(() => pendingpaymentState, [pendingpaymentState]);
};

export const selectTrackingOptionState = (state) => {
  return state.tracking.trackingOptionState;
};
export const useTrackingOptionState = () => {
  const trackingOptionState = useSelector(selectTrackingOptionState);
  return useMemo(() => trackingOptionState, [trackingOptionState]);
};
