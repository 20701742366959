import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import { isParsable } from "../../../../helperFunctions";

const PayinReqResLogsModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);
  const [parsedData3, setParsedData3] = useState(null);

  useEffect(() => {
    const jsonData = new URLSearchParams(data?.requestBody);
    const json = {};
    jsonData?.forEach((value, key) => {
      json[key] = value;
    });
    setParsedData1(json);

    try {
      const parsedSendNotifyContent = isParsable(data?.responseBody)
        ? JSON.parse(data?.responseBody || null)
        : (data?.responseBody || null);
      setParsedData3(parsedSendNotifyContent);
    } catch (error) {}
  }, [data]);
  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="modal-xl"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Payin Req Res Logs</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100">
          <div className="row">
            <div className="col-lg-6">
              <Card>
                <CardBody>
                  <h5>URL</h5>
                  <p>
                    <span className="badge badge-success">
                      {data?.requestMethod}
                    </span>
                    : {data?.requestUrl}
                  </p>
                  <h5>Request Body</h5>
                  <div className="webevent-sec">
                    <pre>{JSON.stringify(parsedData1, null, 1)}</pre>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-6">
              <Card>
                <CardBody>
                  <h5>Status Code </h5>
                  <p className="badge badge-success">
                    {data?.responseHttpCode}
                  </p>
                  <h5>Response Body</h5>
                  <div className="webevent-sec mb-1 req_text_wrap">
                    <pre>{JSON.stringify(parsedData3, null, 1)}</pre>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default PayinReqResLogsModal;
