import React, { useState, useRef, useEffect } from "react";
import "./OTPInput.scss";

const OTPInput = ({ numInputs, onOtpChange, isValid, triggerVibrate }) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    setOtp(new Array(numInputs).fill(""));
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [numInputs]);

  useEffect(() => {
    if (!isValid && triggerVibrate) {
      inputRefs.current.forEach((input) => {
        input.classList.add("vibrate");
        setTimeout(() => input.classList.remove("vibrate"), 300);
      });
    }
  }, [isValid, triggerVibrate]);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onOtpChange(newOtp.join(""));

    // Move to the next input field if there's a value
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
      } else if (index > 0) {
        inputRefs.current[index - 1].focus();
        newOtp[index - 1] = "";
      }
      setOtp(newOtp);
      onOtpChange(newOtp.join(""));
    }
  };

  return (
    <div className={`d-flex gap-2 justify-content-center`}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="tel"
          maxLength={1}
          autoFocus
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className={`d-flex justify-content-center align-items-center rounded border text-center ${isValid ? "border-secondary" : "border-danger"
            }`} style={{ width: '2.5rem', height: '2.5rem', fontSize: '1.125rem' }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
