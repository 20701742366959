import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetUtrReConiliation } from "../services/utrReconciliationServices";

const initialState = {};
export const UtrReconciliationGet = createAsyncThunk(
  "/UtrReconciliationGet",
  async (payload) => {
    return await GetUtrReConiliation(payload);
  }
);

const utrReconciliationSlice = createSlice({
  name: "utrReconciliationSlice",
  initialState,
  reducers: {
    clearUtrReconciliation: () => initialState,
  },
});

export default utrReconciliationSlice.reducer;
export const { clearUtrReconciliation } = utrReconciliationSlice.actions;
