import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  customerManagementGet,
  useCustomerManagement,
} from "../../../redux/slices/customerManagementSlice";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import {
  DROPDOWN_ALL_VALUE,
  DROPDOWN_SEARCH_VALUE,
  FILTER,
} from "../../../constant";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useRole } from "../../../redux/slices/authSlice";

const searchDataOptions = [
  { value: "hardwareId", label: "Hardware ID" },
  { value: "customerId", label: "Customer ID" },
];

const CustomerManagement = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const cutomerManagementItems = useCustomerManagement();

  const initialValues = {
    filter: DROPDOWN_SEARCH_VALUE,
    search: "",
    merchantId: DROPDOWN_ALL_VALUE,
    levelId: DROPDOWN_ALL_VALUE,
  };

  const [filter, setFilter] = useState({ ...FILTER, isToast: false });
  const [filterColumns, setFilterColumns] = useState([]);
  const role = useRole("Customer Management");

  const columns = [
    {
      title: "Customer ID",
      name: "customer_id",
      Cell: (data) => {
        return (
          <>
            <span>{data?.customer_id || ""}</span>
          </>
        );
      },
    },
    {
      title: "Merchant Name",
      name: "merchant_name",
      Cell: (data) => {
        return (
          <>
            <span>{data?.merchant_details?.merchant_name || ""}</span>
          </>
        );
      },
    },
    {
      title: "Register Date",
      name: "registerDate",
    },
    {
      title: "Total deposit Amount",
      name: "deposit_sum",
      Cell: (data) => {
        return (
          <>
            <span>{data?.success_payment_amount || ""}</span>
          </>
        );
      },
    },
    {
      title: "Total Deposit Count",
      name: "deposit_sum",
      Cell: (data) => {
        return (
          <>
            <span>{data?.deposit_sum || ""}</span>
          </>
        );
      },
    },
    {
      title: "Hardware ID",
      name: "browser_id",
      Cell: (data) => {
        return (
          <>
            <span>{data?.browser_id || ""}</span>
          </>
        );
      },
    },
    {
      title: "Is Block",
      name: "is_auto_block",
      Cell: (data) => {
        return (
          <>
            <span>{data?.is_auto_block ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      title: "Level",
      name: "customer_level",
      Cell: (data) => {
        return (
          <>
            <span>{data?.customer_level?.toString() || ""}</span>
          </>
        );
      },
    },
  ];

  const onGet = async () => {
    setIsLoading(true);
    try {
      const {
        isFilter,
        isToast,
        page_no,
        limit,
        search,
        filter: dropdown,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
            filter_data: {
              [dropdown]: search,
              ...rest,
            },
            page_no,
            limit,
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(customerManagementGet(payload)).unwrap();
      if (isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareMerchantOptions = () => {
    return cutomerManagementItems?.merchant?.map((item) => ({
      value: item?.merchant_id,
      label: item?.merchant_details?.merchant_name,
    }));
  };

  const prepareLevelOptions = () => {
    return cutomerManagementItems?.level?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("customerManagement-list")) {
      onGet();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("customerManagement-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Customer Management</b>
                    </h4>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledDropDown
                    id="filter"
                    name="filter"
                    label="Filter"
                    isDefaultOption={DROPDOWN_SEARCH_VALUE}
                    options={searchDataOptions}
                    control={control}
                  />
                  <ControlledInput
                    placeholder="Enter Search Value"
                    name="search"
                    label="Search"
                    control={control}
                  />
                  <ControlledDropDown
                    name="merchantId"
                    label="Merchant ID"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareMerchantOptions()}
                    control={control}
                  />

                  <ControlledDropDown
                    name="levelId"
                    label="Level ID"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareLevelOptions()}
                    control={control}
                  />

                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...FILTER, isFilter: false, isToast: false });
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={cutomerManagementItems?.data || []}
                isData={!!cutomerManagementItems?.data?.length}
                filterColumns={filterColumns}
                count={cutomerManagementItems?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                    isToast: false,
                  });
                }}
              >
                {cutomerManagementItems?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomerManagement;
