import React from "react";
import { Button, Card, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Active_Merchant_PG } from "../../../redux/slices/payinPayoutSlice";
import { useEffect } from "react";
import { Table, TableRow } from "../../../components/Table";
import {
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
  showToaster,
} from "../../../constant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRole } from "../../../redux/slices/authSlice";

const ActiveMerchantPG = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [filter, setFilter] = useState(TRA_FILTER);
  const role = useRole("Active Merchant Pg");

  const dispatch = useDispatch();
  const { getActiveMerchantPGState } = useSelector(
    (state) => state.payinPayout
  );

  const columns = [
    {
      title: "Merchant Name",
      name: "merchantName",
    },
    {
      title: "Label",
      name: "pgLabel",
      Cell: (data) => (
        <>{`${data?.pgLabel} ${data?.pgName ? `(${data.pgName})` : ""}`}</>
      ),
    },

    /*  {
       title: "Action",
       name: "Action",
       Cell: (val) => {
         return (
           <CopyToClipboard text={` ${val?.merchantName} »»»» ${val?.pgLabel}`}>
             <Button
               className="submitTransbutton"
               onClick={() => {
                 showToaster("Copied");
               }}
             >
               Copy
             </Button>
           </CopyToClipboard>
         );
       },
     }, */
  ];

  const ongetActiveMerchantPG = async (id) => {
    try {
      setIsLoading(true);
      const payload = {
        ...filter,
      };
      await dispatch(Active_Merchant_PG(payload)).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("activeMerchantPg-list")) {
      ongetActiveMerchantPG();
    }
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        ongetActiveMerchantPG();
      }
    }, 5000);
    return () => clearInterval(IntervalClick);
  }, [refresh, filter]);

  const prepareCopyContent = (data) => {
    let temp = [];
    if (data && data?.length > 0) {
      temp = data?.map?.((dt, index) => {
        let content = "";

        if (dt?.merchantName || dt?.pgLabel) {
          content += `${dt?.merchantName} »»»» ${dt?.pgLabel
            ?.split?.(",")
            .join?.(",  ")}\n`;
        }

        return `${content}\n`;
      });
    }

    const copyContent = temp?.join?.("");
    return copyContent;
  };

  if (!role.includes("activeMerchantPg-list")) {
    return;
  }

  return (
    <>
      {role.includes("activeMerchantPg-list") ? (
        <>
          <div
            className={`payoutmanual-main-div ${isLoading && refresh ? "opacity-25" : ""
              }`}
          >
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Active Merchant PG</b>
                    </h4>
                  </div>
                  <div className="d-flex gap-1">
                    <CopyToClipboard
                      text={prepareCopyContent(getActiveMerchantPGState?.data)}
                    >
                      <Button
                        className={"filterbtn"}
                        disabled={
                          !getActiveMerchantPGState?.data?.length ||
                          (isLoading && !refresh)
                        }
                        onClick={(e) => {
                          showToaster("Copied");
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <Button
                      type="button"
                      className={`${refresh ? "" : "filterbtn"} gap`}
                      color={refresh ? "primary" : ""}
                      onClick={() => setRefresh(!refresh)}
                      title="Auto refresh"
                    >
                      {refresh ? (
                        <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                      ) : (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      )}
                      Auto Refresh
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={isLoading && !refresh}
                data={getActiveMerchantPGState?.data || []}
                isData={!!getActiveMerchantPGState?.data?.length}
                count={getActiveMerchantPGState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {getActiveMerchantPGState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      filterColumns={filterColumns}
                      item={item}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ActiveMerchantPG;
