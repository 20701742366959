import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBankMeta } from "../services/bankMetaServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  data: {},
};
export const bankMetaGet = createAsyncThunk(
  "/bankMetaGet",
  async (payload) => {
    return await getBankMeta(payload);
  }
);

const bankMetaSlice = createSlice({
  name: "bankMetaSlice",
  initialState,
  reducers: {
    clearBankMeta: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(bankMetaGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default bankMetaSlice.reducer;
export const { clearBankMeta } = bankMetaSlice.actions;

export const selectBankMeta = (state) => {
  return state.bankMeta.data;
};
export const useBankMeta = () => {
  const data = useSelector(selectBankMeta);
  return useMemo(() => data, [data]);
};