import React from 'react'
import { Button, Modal } from "reactstrap";
import CopyToClipboard from 'react-copy-to-clipboard';

const PasswordSet = ({ isOpen, onHide, data }) => {

    const onClose = () => {
        onHide()
    }

    return (
        <Modal className="update-manual-bank-modal" isOpen={isOpen} 
        centered={true}>
            <div className="modal-header">
                <h3 className="modal-title mt-0">Password successfully set!</h3>
            </div>
            <div className="modal-body py-4">
                <p className="mb-50">
                    <span>Email ID :- </span>
                    <span className="bold">{data?.emailId}</span>
                </p>
                <p>
                    <span>Password :- </span>
                    <span className="bold">{data?.password}</span>
                </p>
            </div>
            <div className="modal-footer d-flex align-items-center justify-content-end">
                <CopyToClipboard text={`Email :${data?.emailId} \nPassword :${data?.password}`}>
                    <Button className="submitbutton" onClick={() => onClose()}>
                        Copy & Close
                    </Button>
                </CopyToClipboard>
            </div>
        </Modal>
    )
}

export default PasswordSet