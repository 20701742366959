import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Report, getReportMerchantList } from "../services/reportServices";
const initialState = {
  reportState: [],
  merchantList: [],
  isLoading: true,
};

export const getReports = createAsyncThunk(
  "/support/GetGeneratedReport",
  async (payload) => {
    return await Report(payload);
  }
);

export const reportMerchantListGet = createAsyncThunk(
  "/support/reportMerchantListGet",
  async (payload) => {
    return await getReportMerchantList(payload);
  }
);

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    clearReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reportState = action.payload;
    });
    builder.addCase(getReports.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(reportMerchantListGet.fulfilled, (state, action) => {
      state.merchantList = action.payload?.data || [];
    });
  },
});
export default reportSlice.reducer;
export const { clearReport } = reportSlice.actions;

export const selectReport = (state) => {
  return state.report.reportState;
};
export const useReport = () => {
  const reportState = useSelector(selectReport);
  return useMemo(() => ({ reportState }), [reportState]);
};

export const selectReportMerchantList = (state) => {
  return state.report.merchantList;
};
export const useReportMerchantList = () => {
  const merchantList = useSelector(selectReportMerchantList);
  return useMemo(() => merchantList, [merchantList]);
};
