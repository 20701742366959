import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { FILTER } from "../../../constant";
import { Table, TableRow } from "../../../components/Table";

const MetriwesParseModal = (props) => {
  const { data, onClose } = props;

  const [filter, setFilter] = useState(FILTER);
  const [tableData, setTableData] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);

  useEffect(() => {
    const { limit, page_no } = filter;

    setTableData(
      data?.parsedData?.length
        ? data?.parsedData?.slice(
            limit * (page_no - 1),
            limit * (page_no - 1) + limit
          )
        : []
    );
  }, [data, filter]);

  useEffect(() => {
    if(data?.heading){
        const newColumns = data?.heading?.map((item) => item?.name);
        setFilterColumns(newColumns);
    }
  }, [data]);

  const isNullorObject = (value) => {
    if (value === null) {
      return "null";
    } else if (typeof value === "object" || typeof value === "function") {
      return "object";
    } else {
      return "not null or object";
    }
  };

  const onHide = () => { 
    onClose()
    setTableData([])
    setFilter(FILTER)
   }

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isNullorObject(data) !== "null"}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Parse Data</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Table
          columns={data?.heading || []}
          data={tableData || []}
          // isLoading={isTableLoading}
          isData={!!tableData?.length}
          isExpandable={false}
          filterColumns={filterColumns}
          count={data?.parsedData?.length}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        >
          {tableData?.map((items) => {
            return (
              <TableRow
                columns={data?.heading || []}
                filterColumns={filterColumns}
                item={items}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MetriwesParseModal;
