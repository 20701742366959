import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddMerchantGoogleAnalyticModal from "./AddGoogleAnalyticModal";
import {
  useMerchantGoogleAnalytic,
  merchantGoogleAnalyticGet,
  merchantGoogleAnalyticStatus,
  setMerchantGoogleAnalytic,
} from "../../../redux/slices/merchantGoogleAnalyticSlice";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import MerchantGoogleAnalyticModal from "../../../components/Custom/Modals/MerchantGoogleAnalyticModal/MerchantGoogleAnalyticModal";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantGoogleAnalytic = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [viewData, setViewData] = useState({});
  const [isViewsendDataModal, setIsViewsendDataModal] = useState(false);
  const role = useRole("Google Code");

  const data = useMerchantGoogleAnalytic();
  const dispatch = useDispatch();

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.pk);
      const res = await dispatch(
        merchantGoogleAnalyticStatus(payload)
      ).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.merchant_id === payload?.pk
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], status: payload?.value };
        dispatch(setMerchantGoogleAnalytic({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };
  const handleModal = (data) => {
    setViewData(data);
    setIsViewsendDataModal(!isViewsendDataModal);
  };

  const columns = [
    {
      title: "Merchant Name",
      name: "merchant_id",
      Cell: (data) => {
        return <span>{data?.merchant_details?.merchant_name || "-"}</span>;
      },
    },
    // {
    //   title: "Code",
    //   name: "code",
    //   Cell: (data) => {
    //     return <pre className="googleCode">{data?.code || "-"}</pre>;
    //   },
    // },
    {
      title: "Status",
      name: "status",
      Cell: (data) => {
        return (
          <ToggleSwitch
            isLoading={statusLoading === data?.merchant_id}
            disabled={statusLoading}
            value={data?.status ? 1 : 0}
            onChange={async (value, e) => {
              if (role.includes("update-google-code")) {
                e.preventDefault();
                e.stopPropagation();
                try {
                  const payload = {
                    value,
                    pk: data?.merchant_id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }
            }}
          />
        );
      },
    },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <>
            <div className="button-space d-flex">
              <Button
                color="primary"
                className="btn-smallsize"
                style={{ backgroundColor: "var(--chatlook-darkblue--)" }}
                onClick={() => handleModal(data)}
              >
                Details
              </Button>

              {role.includes("update-google-code") ? (
                <Button
                  title="Edit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => {
                    setIsOpenModal(data);
                  }}
                >
                  Edit
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...payload } = filter;
      const res = await dispatch(merchantGoogleAnalyticGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("googleCode-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("googleCode-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Merchant Google Analytic</b>
            </h4>
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ gap: "10px" }}
            >
              {role.includes("add-google-code") ? (
                <Button
                  title="Add MerchantGoogleAnalytic"
                  color="primary"
                  onClick={() => setIsOpenModal(true)}
                >
                  Add Merchant Google Analytic
                </Button>
              ) : null}
            </div>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>

      <AddMerchantGoogleAnalyticModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
      />
      <MerchantGoogleAnalyticModal
        data={viewData}
        isViewsendDataModal={isViewsendDataModal}
        onCloseModal={() => handleModal()}
      />
    </div>
  );
};

export default MerchantGoogleAnalytic;
