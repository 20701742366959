import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const Image = ({ onClick,...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`cursor-pointer ${onClick ? 'themecolor' : ''}`}
    onClick={onClick}
    {...rest}
  >
    <path d="m15 18-6-6 6-6" />
  </svg>
);
const BackIcon = (props) => {
  const {className = '', onClick, isButton = true } = props;
  const navigate = useNavigate();

  const onLocalClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      {isButton ? (
        <Button
          className={`${className} filterbtn d-flex align-items-center justify-content-center`}
          onClick={onLocalClick}
        >
          <Image />
        </Button>
      ) : (
        <Image className={`${className}`} onClick={onLocalClick} />
      )}
    </>
  );
};

export default BackIcon;
