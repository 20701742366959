import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Form } from "reactstrap";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { Table, TableCell, TableRow } from "../../../components/Table";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";
import AddToSettlementModal from "./AddToSettlementModal";
import { useDispatch } from "react-redux";
import {
  resellerSettlementBalanceGet,
  resellerSettlementGet,
  useResellerSettlementBalanceData,
  useResellerSettlementData,
} from "../../../redux/slices/resellerSlice";
import { DROPDOWN_ALL_VALUE, LIMIT } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const releaseColumns = [
  {
    title: "Name",
    name: "full_name",
    Cell: (item) => {
      return <span>{item?.reseller_details?.full_name}</span>;
    },
  },
  {
    title: "Date",
    name: "settlement_at",
    Cell: (item) => {
      return <span>{item?.settlement_at?.slice(0, 10)}</span>;
    },
  },
  {
    title: "Transaction ID",
    name: "transaction_id",
  },
  {
    title: "Ref ID",
    name: "reference_id",
  },
  {
    title: "Amount",
    name: "release_amt",
    Cell: (item) => {
      return (
        <>
          {item?.value ? (
            <span>
              {seperator(item?.release_amt, false)} {item?.sub_type}{" "}
              <span>
                <sub>(at {item?.value} INR)</sub>{" "}
              </span>
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    title: "Method",
    name: "method",
    Cell: (item) => {
      return (
        <span>{`${item?.type}${item?.sub_type ? "/" + item?.sub_type : ""
          }`}</span>
      );
    },
  },
  {
    title: "Settlement Amount (INR)",
    name: "settlement_amt",
    Cell: (item) => {
      return <span>{seperator(item?.settlement_amt)}</span>;
    },
  },
];

const balanceColumns = [
  {
    title: "Name",
    name: "full_name",
  },
  {
    title: "Deposit Commission",
    name: "total_payin_commission",
    Cell: (item) => {
      return <span>{seperator(item?.total_payin_commission)}</span>;
    },
  },
  {
    title: "Withdrawal Commission",
    name: "total_payout_commission",
    Cell: (item) => {
      return <span>{seperator(item?.total_payout_commission)}</span>;
    },
  },
  {
    title: "Total Settlement",
    name: "settlement_amount",
    Cell: (item) => {
      return <span>{seperator(item?.settlement_amount)}</span>;
    },
  },
  {
    title: "Pending Commission",
    name: "pending_commission",
    Cell: (item) => {
      return <span>{seperator(item?.pending_commission)}</span>;
    },
  },
];

const initialValues = {
  resellerId: DROPDOWN_ALL_VALUE,
  page_no: 1,
  limit: LIMIT,
  isFilter: false,
  isCall: true,
  startDate: null,
  endDate: null,
  isToast: false,
};
const ResellerSettlement = () => {
  const [filterReleaseColumns, setFilterReleaseColumns] = useState([]);
  const [filterBalanceColumns, setFilterBalanceColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [addSettlement, setAddSettlement] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [isBalance, setIsBalance] = useState(true);
  const role = useRole("Reseller User");

  const dispatch = useDispatch();
  const releaseData = useResellerSettlementData();
  const balanceData = useResellerSettlementBalanceData();

  const onSubmit = (e) => {
    e.preventDefault();
    setFilter({
      ...filter,
      isCall: true,
      isFilter: true,
      isToast: true,
    });
  };

  const onGetReleaseData = async () => {
    try {
      setIsLoading(true);
      const {
        page_no,
        limit,
        startDate,
        endDate,
        isCall,
        isFilter,
        isToast,
        ...rest
      } = filter;
      const payload = {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(startDate, "start"),
          endDate: dateFormatter(endDate, "end", startDate),
          ...rest,
        },
      };
      const res = await dispatch(resellerSettlementGet(payload)).unwrap();
      if (isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetBalanceData = async () => {
    try {
      setIsBalanceLoading(true);
      const {
        page_no,
        limit,
        startDate,
        endDate,
        isCall,
        isFilter,
        isToast,
        ...rest
      } = filter;
      const payload = {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(startDate, "start"),
          endDate: dateFormatter(endDate, "end", startDate),
          ...rest,
        },
      };
      const res = await dispatch(
        resellerSettlementBalanceGet(payload)
      ).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
      setIsBalanceLoading(false);
    } catch (err) {
      setIsBalanceLoading(false);
    }
  };

  const prepareResellerOptions = () => {
    const options = balanceData?.reseller?.map((item) => ({
      value: item?.reseller_id,
      label: item?.full_name || "-",
    }));
    return options;
  };

  const onGetData = () => {
    if (!filter?.isCall) {
      return;
    }
    if (role?.includes("reseller-list")) {
      if (isBalance) {
        onGetBalanceData();
      } else {
        onGetReleaseData();
      }
    }
  };

  useEffect(() => {
    onGetData();
  }, [filter, isBalance]);

  useEffect(() => {
    const newReleaseColumns = releaseColumns?.map((item) => item?.name);
    setFilterReleaseColumns(newReleaseColumns);
    const newBalanceColumns = balanceColumns?.map((item) => item?.name);
    setFilterBalanceColumns(newBalanceColumns);
  }, []);

  const getAvailableData = () => {
    let total = 0;
    releaseData?.data?.forEach((item) => {
      total += +item?.settlement_amt || 0;
    });

    return (
      <>
        <tr className="lasttablerow">
          <TableCell>{"Total"}</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>{seperator(total)}</TableCell>
        </tr>
      </>
    );
  };

  if (!role.includes("reseller-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="gap">
            <BackIcon />
            <Button
              className={isBalance ? "primary" : "filterbtn"}
              color={isBalance ? "primary" : ""}
              onClick={() => {
                setFilter({ ...initialValues, isToast: false });
                setIsBalance(true);
              }}
            >
              Balance
            </Button>
            <Button
              className={!isBalance ? "primary" : "filterbtn"}
              color={!isBalance ? "primary" : ""}
              onClick={() => {
                setFilter({ ...initialValues, isToast: false });
                setIsBalance(false);
              }}
            >
              Released Settlement
            </Button>

            {role?.includes("add-reseller-settlement") ? <Button
              className="filterbtn"
              color="primary"
              onClick={() => setAddSettlement(true)}
            >
              New Settlement
            </Button> : null}
          </div>
        </CardHeader>
        <Form onSubmit={onSubmit}>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div className="d-flex gap align-items-center">
                {/* <BackIcon isButton={false} /> */}
                <h4 className="main-title">
                  <b>{isBalance ? "Balance" : "Released Settlement"}</b>
                </h4>
              </div>
              <div className="d-flex align-items-end button-space">
                <div
                  className="d-flex my-xl-auto right-content align-items-center button-space "
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="mb-0">Reseller ID</p>
                  <DropDown
                    name="resellerId"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareResellerOptions()}
                    value={filter?.resellerId}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        resellerId: e.value,
                        isCall: false,
                      })
                    }
                  />
                  {!isBalance ? (
                    <div className="d-flex flex-column select-div">
                      <DatePicker
                        placeholder="Select Date"
                        maxDate={new Date()}
                        selectsRange
                        startDate={filter?.startDate}
                        endDate={filter?.endDate}
                        onChange={(selectedDate) =>
                          setFilter({
                            ...filter,
                            startDate: selectedDate?.[0],
                            endDate: selectedDate?.[1],
                            isCall: false,
                          })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      setFilter({
                        ...initialValues,
                        isCall: true,
                        isFilter: false,
                      });
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </CardHeader>
        </Form>
        {isBalance ? (
          <Table
            columns={balanceColumns}
            filterColumns={filterBalanceColumns}
            isLoading={isBalanceLoading}
            isData={!!balanceData?.data?.length}
            isExpandable={false}
          >
            {balanceData?.data?.map((item) => {
              return (
                <TableRow
                  columns={balanceColumns}
                  filterColumns={filterBalanceColumns}
                  item={item}
                  isExpandable={false}
                ></TableRow>
              );
            })}
          </Table>
        ) : (
          <Table
            columns={releaseColumns}
            filterColumns={filterReleaseColumns}
            isLoading={isLoading}
            isData={!!releaseData?.data?.length}
            isExpandable={false}
          >
            {releaseData?.data?.map((item) => {
              return (
                <TableRow
                  columns={releaseColumns}
                  filterColumns={filterReleaseColumns}
                  item={item}
                  isExpandable={false}
                ></TableRow>
              );
            })}
            {getAvailableData()}
          </Table>
        )}
      </Card>

      <AddToSettlementModal
        isOpen={addSettlement}
        options={prepareResellerOptions}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setAddSettlement(false)}
      />
    </>
  );
};

export default ResellerSettlement;
