import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  Form,
  Dropdown,
} from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch } from "react-redux";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import { responseToaster, seperator } from "../../../helperFunctions";
import { UtrReconciliationGet } from "../../../redux/slices/utrReconciliationSlice";
import { getStatusStyle } from "../../../theme";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useRole } from "../../../redux/slices/authSlice";

const UtrReconcilation = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const role = useRole("UTR Reconciliation");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAt || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Customer ID : </span>
                  {data?.custId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "DEPOSIT DETAILS",
      name: "txnId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.txnId ? (
                <div className="order-singal-div">
                  <p>
                    <span> ID : </span>
                    {data?.txnId}
                  </p>
                </div>
              ) : null}
              {data?.pgType ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Type : </span>
                    {data?.pgType}
                  </p>
                </div>
              ) : null}

              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span>PG Lable : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ORDER DETAILS",
      name: "order id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Order ID : </span>
                  {data?.merchantOrderId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UTR : </span>
                  {data?.utr || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Method : </span>
                  {data?.paymentMethod || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT",
      name: "custName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.merchantDatas?.merchantName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>PG ID : </span>
                  {data?.pgOrderId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "paymentAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Amount : </span>
                  {`${seperator(data?.paymentAmt)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Pg Fees : </span>
                  {`${seperator(data?.pgFees)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Setteled : </span>
                  {`${seperator(data?.payableAmt)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "paymentSts",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.paymentSts);
        return (
          <>
            <div>
              <span className={`${bgClassName}`}>{data?.paymentSts}</span>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const initialValues = {
    UTR: "",
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = await dispatch(UtrReconciliationGet(values)).unwrap();
      setData(res?.data || []);
      responseToaster(res);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  if (!role.includes("utrReconciliation-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>UTR Reconciliation</b>
            </h4>
            <div className="d-flex" style={{ gap: "10px" }}>
              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                  title="Column Filter"
                >
                  <IoFilterOutline fill="#7367F0" />
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Form className="banktransaction-main-div" onSubmit={handleSubmit(onSubmit)}>
            <div
              className="d-flex mt-1 right-content align-items-end button-space "
              style={{ flexWrap: "wrap" }}
            >
              <>
                <ControlledInput
                  name="UTR"
                  placeholder="Enter UTR"
                  label="Search"
                  control={control}
                />

                <Button type="submit" color="primary">
                  Search
                </Button>
                <Button
                  type="button"
                  color="danger "
                  onClick={() => {
                    reset();
                    setData([]);
                  }}
                >
                  Clear
                </Button>
              </>
            </div>
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data}
          isData={!!data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.map((item) => {
            return (
              <TableRow
                tdClassName="position-relative btncell-height"
                item={item}
                columns={columns}
                isExpandable={false}
                filterColumns={filterColumns}
              />
            );
          })}
        </Table>
      </Card>
    </>
  );
};

export default UtrReconcilation;
