import React, { useEffect, useMemo, useState } from "react";
import { Table, TableRow } from "../../../components/Table";
import { seperator } from "../../../helperFunctions";

const RiskManagementDetails = (props) => {
  const { item } = props;
  const [filterColumns, setFilterColumns] = useState([]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "transaction id",
        name: "transaction_id",
      },
      {
        title: "payment amount",
        name: "payment_amount",
        Cell: (data) => <span>{seperator(data?.payment_amount)}</span>,
      },
      {
        title: "payment status",
        name: "payment_status",
      },
      {
        title: "date",
        name: "created_at_ist",
      },
    ],
    []
  );

  return (
    <>
      <Table
        columns={columns}
        data={item?.transactions || []}
        isData={!!item?.transactions?.length}
        filterColumns={filterColumns}
        isExpandable={false}
      >
        {item?.transactions?.map((item) => {
          return (
            <TableRow
              columns={columns}
              item={item}
              filterColumns={filterColumns}
              isExpandable={false}
            />
          );
        })}
      </Table>
    </>
  );
};

export default RiskManagementDetails;
