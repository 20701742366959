import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import {
  getpendingpaymentdata,
  usePendingpaymentState,
} from "../../../redux/slices/trackingSlice";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { DROPDOWN_ALL_VALUE } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const initialValues = {
  bankId: DROPDOWN_ALL_VALUE,
  date: [new Date(), new Date()],
  page_no: 1,
  limit: 100,
  isFilter: false,
};

const UpiPendingTxn = () => {
  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const { pendingpaymentLoading } = useSelector((state) => state.tracking);
  const dispatch = useDispatch();
  const pendingpaymentState = usePendingpaymentState();
  const role = useRole("UPI Transactions");

  const onPendingpayment = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        date,
        filter: dropdown,
        ...rest
      } = filter;
      const payload = {
        filter_data: {
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          ...rest,
        },
        page_no,
        limit,
      };
      const res = await dispatch(getpendingpaymentdata(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const getOptions = () => {
    const options = pendingpaymentState?.bankList
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.meta_id,
        label: `${val?.pg_label} - ${val?.account_number} - ${val?.bank_name}`,
      }));
    return options;
  };

  const onSubmit = () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const columns = [
    {
      title: "UPI TRANSCATION",
      name: "BANK",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${
                data?.isOpen ? "cell-order-white" : "cell-order"
              } upi-position`}
            >
              <div className="order-singal-div">
                <p>
                  <span>Name : </span>
                  {data?.pg_label || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> A/C No : </span>
                  {data?.account_number || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "STATUS",
      className: "px-0",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${
                data?.isOpen ? "cell-order-white" : "cell-order"
              } d-flex flex-column `}
            >
              <span className="px-1">Success :-</span>
              <b className="border-b-1"></b>
              <span className="px-1">Pending :-</span>
            </div>
          </>
        );
      },
    },
    {
      title: "PHONEPE ",
      name: "total_PhonePe_txn",
      className: "px-0",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <div className="order-singal-div border-b-1">
              <p className="themecolor">
                <span> {data?.success_PhonePe_txn || "0"} ~ </span>
                {`${onFixed(data?.success_PhonePe_percentage || 0)}%`}
              </p>
            </div>
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.pending_PhonePe_txn || "0"} ~ </span>
                {`${onFixed(data?.pending_PhonePe_percentage || 0)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "PAYTM",
      name: "total_PayTM_txn",
      className: "px-0",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <div className="order-singal-div border-b-1">
              <p className="themecolor">
                <span> {data?.success_PayTM_txn || "0"} ~ </span>
                {`${onFixed(data?.success_PayTM_percentage || 0)}%`}
              </p>
            </div>
            <div className="d-flex gap order-singal-div">
              <p className="themecolor  mb-0">
                <span> {data?.pending_PayTM_txn || "0"} ~ </span>
                {`${onFixed(data?.pending_PayTM_percentage || 0)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "GOOGLEPAY",
      name: "total_GooglePay_txn",
      className: "px-0",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <div className="order-singal-div border-b-1">
              <p className="themecolor">
                <span> {data?.success_GooglePay_txn || ""} ~ </span>
                {`${onFixed(data?.success_GooglePay_percentage || 0)}%`}
              </p>
            </div>
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.pending_GooglePay_txn || ""} ~ </span>
                {`${onFixed(data?.pending_GooglePay_percentage || 0)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "OTHER",
      name: "success_other_txn",
      className: "px-0",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <div className="order-singal-div border-b-1">
              <p className="themecolor">
                <span> {data?.success_other_txn || ""} ~ </span>
                {`${onFixed(+data?.success_other_percentage || 0)}%`}
              </p>
            </div>
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.pending_other_txn || ""} ~ </span>
                {`${onFixed(+data?.pending_other_percentage || 0)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "TOTAL TXN",
      name: "total_pending",
      className: "px-0",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <div className="order-singal-div border-b-1 ">
              <p className="themecolor">
                <span>
                  {" "}
                  {`${seperator(data?.total_success_count, false)}`}{" "}
                </span>
              </p>
            </div>
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {`${seperator(data?.total_pending, false)}`} </span>
              </p>
            </div>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("upi-list")) {
      onPendingpayment();
    }
  }, [filter]);

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("upi-list") ? (
        <>
          <div className="payoutmanual-main-div">
            <Card>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CardHeader>
                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div>
                      <h4 className="main-title">
                        <b>UPI Transactions</b>
                      </h4>
                    </div>
                    <div className="d-flex align-items-end button-space">
                      <div
                        className="d-flex my-xl-auto right-content align-items-center button-space "
                        style={{ flexWrap: "wrap" }}
                      >
                        <p className="mb-0">Bank</p>
                        <ControlledDropDown
                          id="bankId"
                          name="bankId"
                          isDefaultOption={DROPDOWN_ALL_VALUE}
                          options={getOptions()}
                          control={control}
                        />
                        <div className="d-flex flex-column select-div">
                          <ControlledDatePicker
                            name="date"
                            maxDate={new Date()}
                            placeholder="Select Date"
                            selectsRange
                            control={control}
                          />
                        </div>
                        <Button type="submit" color="primary">
                          Apply
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            setFilter({
                              ...initialValues,
                            });
                            reset();
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Form>
              <Table
                columns={columns}
                isLoading={pendingpaymentLoading}
                data={pendingpaymentState?.data || []}
                isData={!!pendingpaymentState?.data?.length}
                filterColumns={filterColumns}
                isExpandable={false}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
              >
                {pendingpaymentState?.data?.map((item) => {
                  return (
                    <TableRow
                      tdClassName="position-relative py-50"
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};
export default UpiPendingTxn;
