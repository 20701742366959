import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { getReports, useReport } from "../../../redux/slices/ReportSlice";
import { useEffect } from "react";
import "./Report.scss";
import { Table, TableRow } from "../../../components/Table";
import { getStatusStyle } from "../../../theme";
import { DROPDOWN_ALL_VALUE, FILTER, TRA_FILTER, TRA_PAGINATION_OPTIONS } from "../../../constant";
import { Box, Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { styled } from "@mui/material/styles";
import ReportModal from "./ReportModal";
import { seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { useForm } from "react-hook-form";

const Report = () => {
  const { isLoading } = useSelector((state) => state.report);
  const { reportState } = useReport();
  const dispatch = useDispatch();
  const role = useRole("Report");

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: theme.palette.mode === "light" ? "#76bdff" : "#308fe8",
    },
  }));

  const [filter, setFilter] = useState(FILTER);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialValues = {
    emailId: DROPDOWN_ALL_VALUE,
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const downloadFileFromURL = (url, data) => {
    if (url) {
      const fileURL = `${process.env.REACT_APP_BASE_URL}api${url?.split?.('/api')?.[1]}`;
      const a = document.createElement("a");
      a.href = fileURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  const columns = [
    {
      title: "DATE",
      name: "Date",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAt || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAt || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Expire : </span>
                  {data?.expiredAt || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "EMAIL ID",
      name: "emailId",
    },
    {
      title: "FILE INFO",
      name: "fileinfo",
      isOpen: false,
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span> File Name : </span>
                  {data?.fileName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Download ID : </span>
                  {data?.downId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "status",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.status);
        return (
          <>
            <div className="ver-center">
              <span className={`${bgClassName}`}>{data?.status}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "REPORT DETAILS",
      name: "Type",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span> Type : </span>
                  {data?.Type || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Record : </span>
                  {`${seperator(data?.count, false)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PROGRESS",
      name: "progress",
      Cell: (data) => {
        const todata = (+data?.progress / +data?.count) * 100 || 0;
        const progressPer = todata > 100 ? 100 : todata;

        return (
          <>
            <div className="position-relative">
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progressPer}
                />
              </Box>
              <div className="progress-position text-center">
                <span>{progressPer.toFixed(2)}%</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "DOWNLOAD",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                {data?.expiredSts ? (
                  <Button color="danger" className="btn-smallsize">
                    Expired
                  </Button>
                ) : data?.down_url && role?.includes("download-report") ? (
                  <Button
                    type="button"
                    color="btn btn-primary border-0"
                    className="btn-smallsize"
                    onClick={() => downloadFileFromURL(data?.down_url, data)}
                  >
                    Download
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const GetReport = async () => {
    try {
      const { isFilter, page_no, limit, emailId } = filter;
      const payload = {
        filter_data: {
          emailId: emailId,
        },
        page_no,
        limit,
      };
      await dispatch(getReports(payload)).unwrap();
    } catch (err) {}
  };

  const prepareEmaiIdOptions = () => {
    const options = reportState?.emailIds
      ?.slice()
      ?.sort((a, b) => a?.email_id?.localeCompare(b?.email_id))
      ?.map((val) => {
        const emailOption = {
          value: val?.email_id,
          label: val?.email_id,
        };
        return emailOption;
      });
    return options;
  };

  useEffect(() => {
    if (role.includes("report-list")) {
      GetReport();
    }
  }, [filter]);

  if (!role.includes("report-list")) {
    return;
  }

  return (
    <div className="report-main-div">
      <Card>
        <CardHeader className="flex-column  align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <h4 className="main-title">
                <b>Report</b>
              </h4>
            </div>

            <div className="d-flex inputgap">
              {role.includes("quick-report") ? (
                <Button
                  className="btn btn-primary h-100"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  Quick Report
                </Button>
              ) : null}

              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  <IoFilterOutline fill="#7367F0" />
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Form
            className="transaction-main-div"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="d-flex align-items-end mt-1 button-space flex-wrap">
              <ControlledDropDown
                label="Email"
                name="emailId"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={prepareEmaiIdOptions()}
                control={control}
              />
              <Button type="submit" color="primary">
                Apply
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  reset();
                  setFilter({ ...TRA_FILTER });
                }}
              >
                Clear
              </Button>
            </div>
          </Form>
        </CardBody>
        <Table
          columns={columns}
          isLoading={isLoading}
          data={reportState?.data || []}
          isData={!!reportState?.data?.length}
          filterColumns={filterColumns}
          count={reportState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
        >
          {reportState?.data?.map((item) => {
            return (
              <TableRow
                tdClassName="position-relative"
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <ReportModal
        isOpen={isModalOpen}
        onHide={() => setIsModalOpen(!isModalOpen)}
      />
    </div>
  );
};

export default Report;
