import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Table, TableRow } from "../../../components/Table";
import { Button, Card, CardHeader, Form } from "reactstrap";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import { useFormik } from "formik";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import {
  mailReaderGet,
  mailReaderStatusUpdate,
  setMailReader,
  useMailReaderData,
} from "../../../redux/slices/mailReaderSlice";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { useRole } from "../../../redux/slices/authSlice";

const searchOptions = [
  {
    label: "Bank ID",
    value: "av_bank_id",
  },
  {
    label: "Mail Sender",
    value: "mail_sender",
  },
  {
    label: "Mail From",
    value: "mail_from",
  },
  {
    label: "Prvoider",
    value: "provider",
  },
  {
    label: "Username",
    value: "username",
  },
];
const MailReader = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const role = useRole("Mail Reader");
  const dispatch = useDispatch();

  const data = useMailReaderData();

  const columns = [
    {
      title: "BANK",
      name: "av_bank_id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Bank ID : </span>
                  {data?.av_bank_id || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Label : </span>
                  {data?.bank_details?.account_holder_name || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> A/C : </span>
                  {data?.bank_details?.account_number || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UPI : </span>
                  {data?.bank_details?.upi_id || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> IFSC : </span>
                  {data?.bank_details?.ifsc_code || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Bank : </span>
                  {data?.bank_details?.bank_name || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "USERNAME",
      name: "username",
    },
    {
      title: "SENDER",
      name: "mail_sender",
    },
    {
      title: "FROM",
      name: "mail_from",
    },
    {
      title: "PROVIDER",
      name: "provider",
    },
    {
      title: "STATUS",
      name: "is_active",
      isOpen: role.includes("update-mail-reader-status"),
      Cell: (data) => {
        return (
          <div>
            {role.includes("update-mail-reader-status") ? <ToggleSwitch
              isLoading={statusLoading === data?.av_bank_id}
              value={data?.is_active ? 1 : 0}
              className={`${statusLoading ? "disabled" : ""
                }`}
              onChange={async (value) => {
                const payload = {
                  pk: data?.av_bank_id,
                  value,
                };
                await onUpdateStatus(payload);
              }}
            /> : null}
          </div>
        );
      },
    },
    {
      title: "DATE",
      name: "date",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.created_at_ist || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updated_at_ist || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.pk);
      const res = await dispatch(mailReaderStatusUpdate(payload)).unwrap();

      const index = data?.data?.findIndex(
        (item) => item?.av_bank_id === payload?.pk
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], is_active: payload?.value };
        dispatch(setMailReader({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const initialValues = {
    filter: "",
    search: "",
    startDate: null,
    endDate: null,
  };

  const onSubmit = async (values) => {
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  const { values, handleSubmit, setFieldValue, setValues, resetForm } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const {
        isFilter,
        startDate,
        filter: dropdown,
        search,
        endDate,
        page_no,
        limit,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
          page_no,
          limit,
          filter_data: {
            [dropdown]: search,
            startDate: dateFormatter(startDate, "start"),
            endDate: dateFormatter(endDate, "end", startDate),
            ...rest,
          },
        }
        : {
          page_no,
          limit,
        };
      const res = await dispatch(mailReaderGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("mailReader-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("mailReader-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Mail Reader</b>
            </h4>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Button
                type="button"
                className="filterbtn"
                onClick={() => {
                  setShowDiv(!showDiv);
                }}
                title="Apply Filter"
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
            </div>
          </div>

          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
            onSubmit={handleSubmit}
          >
            {showDiv && (
              <>
                <DropDown
                  label="Filter"
                  options={searchOptions}
                  value={values?.filter}
                  onChange={(selectedOption) =>
                    setFieldValue("filter", selectedOption.value)
                  }
                />
                <InputField
                  placeholder="Enter Search Value"
                  label="Search"
                  value={values?.search}
                  onChange={(e) => {
                    setFieldValue("search", e.target.value);
                  }}
                />
                <DatePicker
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  startDate={values?.startDate}
                  endDate={values?.endDate}
                  selectsRange
                  onChange={(selectedDate) =>
                    setValues({
                      ...values,
                      startDate: selectedDate?.[0],
                      endDate: selectedDate?.[1],
                    })
                  }
                />
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger "
                  onClick={() => {
                    resetForm();
                    setFilter({ ...FILTER });
                  }}
                >
                  Clear
                </Button>
              </>
            )}
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
    </div>
  );
};

export default MailReader;
