import TablePagination from "@mui/material/TablePagination";
import * as React from "react";
import { PAGINATION_OPTIONS } from "../../constant";

const Pagination = (props) => {
  const {
    className,
    style,
    page_no = 1,
    limit,
    count = 0,
    handlePageChange,
    handleRowChange,
    paginationOptions = PAGINATION_OPTIONS,
  } = props;

  return (
    <TablePagination
      className={`${className} table-pagi-overflow`}
      style={{ ...style }}
      showFirstButton
      showLastButton
      siblingcount={0}
      boundarycount={2}
      component="div"
      count={count}
      rowsPerPageOptions={
        Array.isArray(paginationOptions)
          ? paginationOptions
          : PAGINATION_OPTIONS
      }
      page={page_no - 1 || 0}
      onPageChange={(_, page_no) => {
        handlePageChange(+page_no + 1);
      }}
      rowsPerPage={limit}
      onRowsPerPageChange={(e) => handleRowChange(e.target.value)}
    />
  );
};

export default Pagination;
