import React, { useState } from "react";
import { Update_Temputr_Transaction } from "../../../../redux/slices/transactionSlice";
import { Button, Input, Label, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useFormik } from "formik";

const UpdateTempUTRModal = (props) => {
  const { isUpdateUtrId, isUpdateUtr, onCloseIsTempUtr, onGet } = props;

  const { resetForm } = useFormik({});

  const onClose = () => {
    onCloseIsTempUtr();
    resetForm();
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  const Temputr_Transaction = async () => {
    try {
      setIsButtonLoading(true);
      let payload = { ...values, txnId: isUpdateUtrId };
      let response = await dispatch(
        Update_Temputr_Transaction(payload)
      ).unwrap();
      responseToaster(response);
      onClose();
      onGet();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={isUpdateUtr} toggle={onClose} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Set Temp UTR!</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <div className="select-div">
            <Label>Temp UTR</Label>
            <Input
              type="number"
              placeholder="Enter Temp UTR"
              onChange={(selectedOption) => {
                setValues({
                  ...values,
                  ["tempUtr"]: selectedOption.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onClose}>
            Cancel
          </Button>
          <CustomeButton
          className="submitbutton"
            onClick={Temputr_Transaction}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateTempUTRModal;
