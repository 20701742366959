import { useEffect, useLayoutEffect, useState } from "react";
import HorizontalLayout from "./Layouts/HorizontalLayout/HorizontalLayout";
import VerticalLayout from "./Layouts/VerticalLayout/VerticalLayout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  checkAuth,
  clearAuth,
  factorAuth,
  resetAuth,
  setHasResetPwd,
  setQr,
  setUser,
  useAuth,
  useSidebar,
  useUser,
} from "../redux/slices/authSlice";
import Header from "./Layouts/VerticalLayout/Header/Header";
import {
  getLocalData,
  prepareIsHorizontalNavBar,
  setStyles,
  verifyingUser,
} from "../helperFunctions";
import Loader from "./Custom/Loader";
import ScrollToTop from "./Layouts/ScrollTotop/ScrollToTop";
import LoginAgainModal from "./Custom/Modals/LoginAgainModal/LoginAgainModal";
import AuthVerifyOtp from "../pages/Auth/AuthVerifyOtp";
import TwoFactorAuthModal from "../pages/Auth/TwoFactorAuthModal";
import PasswordSet from "../pages/Auth/PasswordSet";
import { useDispatch } from "react-redux";
import { showToaster } from "../constant";
import LogginUser from "../pages/Auth/LogginUser";

const color = process.env.REACT_APP_COLOR;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebar = useSidebar();
  const { auth } = useAuth();
  const dispatch = useDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [isVerify, setIsVerify] = useState(auth?.verifyAuth);
  const [qrCode, setQrCode] = useState(auth?.qr);
  const [resetPwdModal, setResetPwdModal] = useState(auth?.hasResetPwd);

  const checkAuthStatus = async () => {
    try {
      const response = await dispatch(checkAuth()).unwrap();
      if (response?.is_required_2fa === 0) {
        verifyingUser(dispatch, null);
        dispatch(setUser({ ...auth?.user, ...response }));
        navigate(response?.firstRoute);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const showQr = async () => {
      const domain = window.location.hostname;
      const response = await dispatch(factorAuth(domain)).unwrap();
      dispatch(setQr(response?.qr));
      verifyingUser(dispatch, false);
    };
    if (auth?.verifyAuth === false) {
      showQr();
    }
    setIsVerify(auth?.verifyAuth);
    if (auth?.verifyAuth === true || auth?.verifyAuth === false) {
      checkAuthStatus();
      // return;
    }
    // eslint-disable-next-line
  }, [auth?.verifyAuth]);

  useEffect(() => {
    setQrCode(auth?.qr);
    // eslint-disable-next-line
  }, [auth?.qr]);

  useEffect(() => {
    setResetPwdModal(auth?.hasResetPwd);
    // eslint-disable-next-line
  }, [auth?.hasResetPwd]);

  useEffect(() => {
    let newData = [];
    const prepareData = (data) => {
      Object.entries(data || {})?.forEach(([key, val]) => {
        if (Array.isArray(val)) {
          newData = [...newData, ...val];
        } else if (val instanceof Object) {
          prepareData(val);
        }
      });
    };
    prepareData(sidebar);
    const currentRoute = location?.pathname?.split("/")?.at(-1);
    const currentPage = newData?.find((item) =>
      item?.route?.includes(currentRoute)
    );
    if (currentPage) {
      window.document.title =
        currentPage?.group === "PG"
          ? "PG " + currentPage?.name
          : currentPage?.name;
    }
  }, [navigate, sidebar]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-darkblue--", color);
    }
    // setTimeout(() => {
    setIsLoading(false);
    // }, 3000);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="w-100 vh-100  d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      </>
    );
  }
  return (
    <>
      {auth?.logged_in === true ? (
        <LogginUser open={auth?.logged_in === true} />
      ) : Object.keys?.(auth?.hasResetPwd || {})?.length ? (
        <PasswordSet
          isOpen={resetPwdModal?.password}
          onHide={() => {
            try {
              localStorage.removeItem("token");
              dispatch(setUser({}));
              dispatch(resetAuth({}));
              setResetPwdModal(false);
              sessionStorage.clear();
              localStorage.clear();
              dispatch(setHasResetPwd(false));
              // dispatch(clearAuth());
              showToaster("Copied");
              navigate("/login");
            } catch (error) {
              console.log("error", error);
            }
          }}
          data={auth?.hasResetPwd}
        />
      ) : (
        <>
          {isVerify === true ? (
            <AuthVerifyOtp
              isOpen={isVerify}
              onHide={() => {
                setIsVerify(false);
              }}
            />
          ) : isVerify === false ? (
            <TwoFactorAuthModal
              isOpen={qrCode}
              onHide={() => {
                setQrCode("");
              }}
              svgString={qrCode}
            />
          ) : (
            <>
              <div className="position-relative">
                {prepareIsHorizontalNavBar(windowWidth) ? (
                  <HorizontalLayout />
                ) : (
                  <VerticalLayout
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                )}
                <div className="page-main">
                  {!prepareIsHorizontalNavBar(windowWidth) ? (
                    <Header
                      setIsSidebarOpen={setIsSidebarOpen}
                      isSidebarOpen={isSidebarOpen}
                    />
                  ) : null}
                  <div
                    className="jumps-prevent"
                    style={{
                      paddingTop:
                        windowWidth >= 1231
                          ? "64px"
                          : prepareIsHorizontalNavBar(windowWidth)
                          ? "120px"
                          : "10px",
                    }}
                  ></div>
                  <div
                    className="main-content app-content mt-0"
                    style={{ paddingBottom: "35px" }}
                  >
                    <div className="side-app">
                      <div className="main-container">
                        <Outlet />
                        <ScrollToTop />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginAgainModal />
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
