// MUI Imports
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { hexToRGBA } from "../../../helperFunctions";
import Navigation from "./navigation";
import {
  setSidebar,
  useSidebar,
  useUser,
} from "../../../redux/slices/authSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const HorizontalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
  overflow: "clip",
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

const HorizontalLayout = (props) => {
  const sidebar = useSidebar();
  const { user } = useUser();

  const dispatch = useDispatch();

  const getMenu = () => {
    const newData = [];
    Object.entries(sidebar || {})?.forEach(([key, val]) => {
      if (Array.isArray(val)) {
        const obj = {
          name: key,
          icon_class: `${key?.toLowerCase()?.replace(" ", "")}.svg`,
          is_child: true,
          child: val,
          hasParent: false,
        };
        newData.push(obj);
      } else {
        Object.entries(val || {})?.forEach(([subKey, subVal]) => {
          const obj = {
            name: subKey,
            icon_class: `${subKey?.toLowerCase()?.replace(" ", "")}.svg`,
            is_child: true,
            child: subVal,
            hasParent: false,
          };
          newData.push(obj);
        });
      }
    });

    return newData;
  };

  useEffect(() => {
    dispatch(setSidebar(user?.SiderBar || {}));
  }, [user]);

  return (
    <HorizontalLayoutWrapper className="layout-wrapper horizontal-layout">
      <MainContentWrapper
        className="layout-content-wrapper"
        style={{ minHeight: "unset" }}
        sx={{
          maxHeight: "100vh",
        }}
      >
        <AppBar
          color="default"
          elevation={0}
          className="layout-navbar-and-nav-container"
          sx={{
            alignItems: "center",
            color: "text.primary",
            justifyContent: "center",
            transition: "border-bottom 0.2s ease-in-out",
            backdropFilter: "blur(6px)",
            backgroundColor: (theme) =>
              hexToRGBA(theme.palette.background.paper, 0.95),
            boxShadow:
              "0 2px 6px rgba(47, 43, 61,.14),0 0 transparent,0 0 transparent",
            marginLeft: "15px",
            marginRight: "20px",
            borderRadius: "3px",
            width: "unset",
            right: "unset",
            zIndex: "111",
            position: "fixed",
            top: "0",
            right: "0",
            left: "0",
          }}
        >
          <Box className="layout-horizontal-nav" sx={{ width: "100%" }}>
            <div className="px-1 d-flex justify-content-between align-items-start">
              <Navigation {...props} horizontalNavItems={getMenu()} />
            </div>
          </Box>
        </AppBar>
      </MainContentWrapper>
    </HorizontalLayoutWrapper>
  );
};

export default HorizontalLayout;
