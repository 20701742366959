import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { Customer_Block } from "../../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const BlockCustomerModal = (props) => {
  const { blockCustomer, isBlockCustomerId, onCloseBlockCustomerModal, onGet } =
    props;

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const Block_customer = async () => {
    try {
      setIsButtonLoading(true);
      let Payload = {
        txnId: isBlockCustomerId,
      };
      let response = await dispatch(Customer_Block(Payload)).unwrap();
      responseToaster(response);
      if (!!response) {
        onCloseBlockCustomerModal();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal
      isOpen={blockCustomer}
      toggle={onCloseBlockCustomerModal}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Block Customer</h3>
        <i
          onClick={onCloseBlockCustomerModal}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to block customer?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onCloseBlockCustomerModal}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={Block_customer}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default BlockCustomerModal;
