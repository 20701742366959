import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { activeUpiIds } from "../services/activeUpiIdsServices";

const initialState = {
  activeUpiIdsState: [],
  isLoading: true,
};
export const getActiveUpiIds = createAsyncThunk(
  "/activeUpiIds",
  async () => {
    return await activeUpiIds();
  }
);

const activeUpiIdsSlice = createSlice({
  name: "activeUpiIdsSlice",
  initialState,
  reducers: {
    clearActiveUpiIds: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveUpiIds.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getActiveUpiIds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.activeUpiIdsState = action.payload;
    });
    builder.addCase(getActiveUpiIds.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default activeUpiIdsSlice.reducer;
export const { clearActiveUpiIds } = activeUpiIdsSlice.actions;

export const selectActiveUpiIds = (state) => {
  return state.activeUpiIds.activeUpiIdsState;
};

export const useActiveUpiIds = () => {
  const activeUpiIdsState = useSelector(selectActiveUpiIds);
  return useMemo(() => ({ activeUpiIdsState }), [activeUpiIdsState]);
};
