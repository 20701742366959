import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "reactstrap";
import {
  merchant_white_listIps_status_update,
  setWhitelistIps,
  useViewMerchantWhitelistIpsState,
  view_Merchant_White_list_Ips,
} from "../../../../redux/slices/merchantSlice";
import { Table, TableRow } from "../../../Table";
import ToggleSwitch from "../../Forms/ToggleSwitch/ToggleSwitch";
import AddMerchantWhitelistIp from "../AddMerchantWhitelistIp/AddMerchantWhitelistIp";
import { FILTER } from "../../../../constant";

const MerchantWhitelistIp = (props) => {
  const [addMerchantWhitelistIp, setAddMerchantWhitelistIp] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const onCloseAddMerchantWhitelistIp = () => {
    setAddMerchantWhitelistIp(false);
  };
  const { id, onCloseMerchantWhiteIp, merchantWhiteIp } = props;
  const { viewMerchantWhitelistIpsStateLoading } = useSelector(
    (state) => state.merchant
  );

  const [filterColumns, setFilterColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [filter, setFilter] = useState({
    ...FILTER
  });

  const dispatch = useDispatch();
  const viewMerchantWhitelistIpsState = useViewMerchantWhitelistIpsState();

  useEffect(() => {
    const { limit, page_no } = filter;

    setTableData(
      viewMerchantWhitelistIpsState?.data?.length
        ? viewMerchantWhitelistIpsState?.data?.slice(
          limit * (page_no - 1),
          limit * (page_no - 1) + limit
        )
        : []
    );
  }, [viewMerchantWhitelistIpsState, filter]);

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.id);
      await dispatch(merchant_white_listIps_status_update(payload)).unwrap();
      const index = viewMerchantWhitelistIpsState?.data?.findIndex(
        (item) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...viewMerchantWhitelistIpsState?.data];
        newData[index] = { ...newData[index], isActive: payload?.isActive };
        dispatch(
          setWhitelistIps({
            ...viewMerchantWhitelistIpsState?.data,
            data: newData,
          })
        );
      }
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "MERCHANT ID",
      name: "merchantId",
    },
    {
      title: "IS ACTIVE",
      name: "isActive",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusLoading === data?.id}
              value={data?.isActive ? 1 : 0}
              className={`${
                statusLoading ? "disabled" : ""
              }`}
              onChange={async (value, e) => {
                e.preventDefault();
                e.stopPropagation();
                try {
                  const payload = {
                    isActive: value,
                    merchantId: data?.merchantId,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) { }
              }}
            />
          </>
        );
      },
    },
    {
      title: "MERCHANT IP",
      name: "merchantIp",
    },
    {
      title: "TYPE",
      name: "type",
    },
    {
      title: "CREATED AT",
      name: "createdAt",
    },
    {
      title: "UPDATED AT",
      name: "updatedAt",
    },
  ]);
  const Merchant_White_list_Ips = async (payload) => {
    try {
      await dispatch(
        view_Merchant_White_list_Ips({ ...payload, merchantId: id })
      ).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (merchantWhiteIp) {
      Merchant_White_list_Ips(filter);
    }
  }, [filter, merchantWhiteIp]);

  return (
    <>
      <Modal
        className="add-manual-payout-modal modal-xl"
        isOpen={merchantWhiteIp}
        toggle={onCloseMerchantWhiteIp}
        centered={true}
      >
        <div className="modal-header d-flex justify-content-between">
          <h3 className="modal-title mt-0">Merchant Whitelist Ip</h3>
          <div className="button-space d-flex align-items-center">
            <Button
              className="submitbutton"
              onClick={() => setAddMerchantWhitelistIp(true)}
            >
              Add
            </Button>
            <i
              onClick={() => {
                onCloseMerchantWhiteIp();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
        </div>
        <div className="modal-body">
          <Table
            columns={columns}
            isLoading={viewMerchantWhitelistIpsStateLoading}
            data={tableData || []}
            isData={!!tableData?.length}
            filterColumns={filterColumns}
            count={tableData?.length || 0}
            pagination={filter}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
          >
            {tableData?.map((item) => {
              return (
                <TableRow
                  columns={columns}
                  item={item}
                  isExpandable={false}
                  filterColumns={filterColumns}
                />
              );
            })}
          </Table>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button
              className="graybutton"
              onClick={() => onCloseMerchantWhiteIp()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <AddMerchantWhitelistIp
        merchant_White_list_Ips={() => {
          Merchant_White_list_Ips(filter);
        }}
        addMerchantWhitelistIp={addMerchantWhitelistIp}
        onCloseAddMerchantWhitelistIp={onCloseAddMerchantWhitelistIp}
      />
    </>
  );
};

export default MerchantWhitelistIp;
