import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { MerchantPayInPaymentLevelUpdate } from "../../../redux/slices/merchantSlice";
import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantPayinLevels = (props) => {
  const { data, onGet } = props;
  const dispatch = useDispatch();
  const role = useRole("Merchant");
  const [isLoading, setIsLoading] = useState(false);

  const staticDataTwo = [
    { value: "is_level1", label: "Level 1" },
    { value: "is_level2", label: "Level 2" },
    { value: "is_level3", label: "Level 3" },
    { value: "is_level4", label: "Level 4" },
  ];

  const [checked, setChecked] = useState({});

  const updateLevel = async (levelKey, status) => {
    try {
      setIsLoading(true);
      const payload = {
        levelKey,
        status,
        merchantId: data?.merchantId,
        pgName: data?.pgName,
        id: data?.id,
        pgId: data?.pgId,
      };
      const res = await dispatch(
        MerchantPayInPaymentLevelUpdate(payload)
      ).unwrap();
      responseToaster(res);

      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setChecked({
      ...checked,
      is_level1: data?.level_1 || 0,
      is_level2: data?.level_2 || 0,
      is_level3: data?.level_3 || 0,
      is_level4: data?.level_4 || 0,
    });
  }, [data]);

  const prepareChecked = (value) => {
    // if (value === "all") {
    //   const values = Object.entries(checked || {})?.map(([key, item]) => item);
    //   return !values?.includes(0);
    // } else {
    return !!checked?.[value];
    // }
  };
  return (
    <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
      <div className="order-singal-div">
        <div className="button-space checkbox-icon">
          {staticDataTwo?.map((level) => {
            return (
              <div className="d-flex align-items-center gap mb-50">
                <Input
                  type="checkbox"
                  checked={prepareChecked(level?.value)}
                  disabled={!role.includes("merchant-deposit-update")}
                  onChange={() => {
                    updateLevel(
                      level.value,
                      checked?.[level.value] === 0 ? 1 : 0
                    );
                    setChecked({
                      ...checked,
                      [level.value]: checked?.[level.value] === 0 ? 1 : 0,
                    });
                  }}
                />
                {level?.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MerchantPayinLevels;
