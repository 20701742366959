import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { lastUpiRate } from "../services/lastUpiRateServices";

const initialState = {
  lastUpiRateState: {},
  isLoading: true,
};
export const getLastUpiRate = createAsyncThunk(
  "/lastUpiRate",
  async (payload) => {
    return await lastUpiRate(payload);
  }
);

const lastUpiRateSlice = createSlice({
  name: "lastUpiRateSlice",
  initialState,
  reducers: {
    clearLastUpiRate: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getLastUpiRate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getLastUpiRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.lastUpiRateState = action.payload;
    });
    builder.addCase(getLastUpiRate.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default lastUpiRateSlice.reducer;
export const { clearLastUpiRate } = lastUpiRateSlice.actions;

export const selectLastUpiRate = (state) => {
  return state.lastUpiRate.lastUpiRateState;
};

export const useLastUpdateRate = () => {
  const lastUpiRateState = useSelector(selectLastUpiRate);
  return useMemo(() => ({ lastUpiRateState }), [lastUpiRateState]);
};
