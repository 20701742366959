export const defaultValues = {
  enabledPayments: {
    IS_IMPS: 0,
    IS_Paytym: 0,
    is_QR: 0,
    is_UTR: 0,
    is_googlepay: 0,
    is_phonepay: 0,
    all: 0,
  },
  mobile: {
    auto_mobile_from: 0,
    auto_mobile_status: 0,
    auto_mobile_to: 0,
    imps_mobile_from: 0,
    imps_mobile_status: 0,
    imps_mobile_to: 0,
    qr_mobile_from: 0,
    qr_mobile_status: 0,
    qr_mobile_to: 0,
    utr_mobile_from: 0,
    utr_mobile_status: 0,
    utr_mobile_to: 0,
  },
  desktop: {
    imps_desktop_from: 0,
    imps_desktop_status: 0,
    imps_desktop_to: 0,
    qr_desktop_from: 0,
    qr_desktop_status: 0,
    qr_desktop_to: 0,
    utr_desktop_from: 0,
    utr_desktop_status: 0,
    utr_desktop_to: 0,
  },
};

export const paymentsCheckKeys = {
  IS_IMPS: "is IMPS",
  IS_Paytym: "is Paytm",
  is_googlepay: "is Google Pay",
  is_phonepay: "is Phone Pay",
  is_QR: "is QR",
  is_UTR: "is UTR",
  all: "select all",
};

export const mobileCheckKeys = {
  auto_mobile_status: "AUTO",
  qr_mobile_status: "QR",
  utr_mobile_status: "UTR",
  imps_mobile_status: "IMPS",
};

export const desktopCheckKeys = {
  qr_desktop_status: "QR",
  utr_desktop_status: "UTR",
  imps_desktop_status: "IMPS",
};

export const mobileInputKeys = {
  auto_mobile_status: ["auto_mobile_from", "auto_mobile_to"],
  imps_mobile_status: ["imps_mobile_from", "imps_mobile_to"],
  qr_mobile_status: ["qr_mobile_from", "qr_mobile_to"],
  utr_mobile_status: ["utr_mobile_from", "utr_mobile_to"],
};

export const desktopInputKeys = {
  imps_desktop_status: ["imps_desktop_from", "imps_desktop_to"],
  qr_desktop_status: ["qr_desktop_from", "qr_desktop_to"],
  utr_desktop_status: ["utr_desktop_from", "utr_desktop_to"],
};

export const mobileToEP = {
  imps_mobile_status: ["IS_IMPS"],
  qr_mobile_status: ["is_QR"],
  utr_mobile_status: ["is_UTR"],
};

export const desktopToEP = {
  imps_desktop_status: ["IS_IMPS"],
  qr_desktop_status: ["is_QR"],
  utr_desktop_status: ["is_UTR"],
};

export const EPToMD = {
  IS_IMPS: ["imps_mobile_status", "imps_desktop_status"],
  is_QR: ["qr_mobile_status", "qr_desktop_status"],
  is_UTR: ["utr_mobile_status", "utr_desktop_status"],
};

// export const EPToMD = {
//   IS_IMPS: [["imps_mobile_status","imps_mobile_from","imps_mobile_to"], ["imps_desktop_status", "imps_desktop_from", "imps_desktop_to"]],
//   is_QR: [["qr_mobile_status", "qr_mobile_from", "qr_mobile_to"], ["qr_desktop_status", "qr_desktop_from", "qr_desktop_to"]],
//   is_UTR: [["utr_mobile_status", "utr_mobile_from", "utr_mobile_to"], ["utr_desktop_status", "utr_desktop_from", "utr_desktop_to"]],
// }

export const stateCity = [
  {
    state: "Andhra Pradesh",
    cities: [
      "Visakhapatnam",
      "Vijayawada",
      "Guntur",
      "Tirupati",
      "Nellore",
      "Kurnool",
      "Kakinada",
      "Rajahmundry",
      "Kadapa",
      "Anantapur",
    ],
  },
  {
    state: "Arunachal Pradesh",
    cities: [
      "Itanagar",
      "Naharlagun",
      "Pasighat",
      "Roing",
      "Tawang",
      "Ziro",
      "Bomdila",
      "Along",
      "Tezu",
      "Daporijo",
    ],
  },
  {
    state: "Assam",
    cities: [
      "Guwahati",
      "Silchar",
      "Dibrugarh",
      "Jorhat",
      "Tezpur",
      "Nagaon",
      "Tinsukia",
      "Bongaigaon",
      "Dhubri",
      "Goalpara",
    ],
  },
  {
    state: "Bihar",
    cities: [
      "Patna",
      "Gaya",
      "Bhagalpur",
      "Muzaffarpur",
      "Purnia",
      "Darbhanga",
      "Arrah",
      "Begusarai",
      "Katihar",
      "Chhapra",
    ],
  },
  {
    state: "Chhattisgarh",
    cities: [
      "Raipur",
      "Bhilai",
      "Bilaspur",
      "Korba",
      "Raigarh",
      "Jagdalpur",
      "Ambikapur",
      "Durg",
      "Rajnandgaon",
      "Dhamtari",
    ],
  },
  {
    state: "Goa",
    cities: [
      "Panaji",
      "Margao",
      "Vasco da Gama",
      "Mapusa",
      "Ponda",
      "Bicholim",
      "Curchorem",
      "Sanquelim",
      "Canacona",
      "Valpoi",
    ],
  },
  {
    state: "Gujarat",
    cities: [
      "Ahmedabad",
      "Surat",
      "Vadodara",
      "Rajkot",
      "Bhavnagar",
      "Jamnagar",
      "Junagadh",
      "Gandhinagar",
      "Anand",
      "Navsari",
    ],
  },
  {
    state: "Haryana",
    cities: [
      "Faridabad",
      "Gurgaon",
      "Hisar",
      "Rohtak",
      "Panipat",
      "Karnal",
      "Sonipat",
      "Yamunanagar",
      "Ambala",
      "Bhiwani",
    ],
  },
  {
    state: "Himachal Pradesh",
    cities: [
      "Shimla",
      "Solan",
      "Dharamshala",
      "Kullu",
      "Mandi",
      "Palampur",
      "Nahan",
      "Sirmaur",
      "Una",
      "Bilaspur",
    ],
  },
  {
    state: "Jharkhand",
    cities: [
      "Ranchi",
      "Jamshedpur",
      "Dhanbad",
      "Bokaro",
      "Deoghar",
      "Hazaribagh",
      "Giridih",
      "Ramgarh",
      "Rajmahal",
      "Sahebganj",
    ],
  },
  {
    state: "Karnataka",
    cities: [
      "Bangalore",
      "Mysore",
      "Hubli",
      "Mangalore",
      "Belgaum",
      "Gulbarga",
      "Davanagere",
      "Bellary",
      "Bijapur",
      "Shimoga",
    ],
  },
  {
    state: "Kerala",
    cities: [
      "Thiruvananthapuram",
      "Kochi",
      "Kozhikode",
      "Kollam",
      "Thrissur",
      "Palakkad",
      "Alappuzha",
      "Kannur",
      "Kottayam",
      "Manjeri",
    ],
  },
  {
    state: "Madhya Pradesh",
    cities: [
      "Indore",
      "Bhopal",
      "Jabalpur",
      "Gwalior",
      "Ujjain",
      "Sagar",
      "Dewas",
      "Satna",
      "Ratlam",
      "Rewa",
    ],
  },
  {
    state: "Maharashtra",
    cities: [
      "Mumbai",
      "Pune",
      "Nagpur",
      "Thane",
      "Nashik",
      "Kalyan-Dombivali",
      "Vasai-Virar",
      "Aurangabad",
      "Navi Mumbai",
      "Solapur",
    ],
  },
  {
    state: "Manipur",
    cities: [
      "Imphal",
      "Thoubal",
      "Bishnupur",
      "Churachandpur",
      "Kakching",
      "Senapati",
      "Ukhrul",
      "Jiribam",
      "Tamenglong",
      "Kangpokpi",
    ],
  },
  {
    state: "Meghalaya",
    cities: [
      "Shillong",
      "Tura",
      "Nongstoin",
      "Williamnagar",
      "Baghmara",
      "Jowai",
      "Resubelpara",
      "Nongpoh",
      "Amlarem",
      "Mairang",
    ],
  },
  {
    state: "Mizoram",
    cities: [
      "Aizawl",
      "Lunglei",
      "Saiha",
      "Champhai",
      "Kolasib",
      "Serchhip",
      "Lawngtlai",
      "Saitual",
      "Thenzawl",
      "Bairabi",
    ],
  },
  {
    state: "Nagaland",
    cities: [
      "Kohima",
      "Dimapur",
      "Mokokchung",
      "Tuensang",
      "Wokha",
      "Zunheboto",
      "Phek",
      "Mon",
      "Longleng",
      "Kiphire",
    ],
  },
  {
    state: "Odisha",
    cities: [
      "Bhubaneswar",
      "Cuttack",
      "Rourkela",
      "Berhampur",
      "Sambalpur",
      "Puri",
      "Balasore",
      "Bhadrak",
      "Baripada",
      "Jharsuguda",
    ],
  },
  {
    state: "Punjab",
    cities: [
      "Ludhiana",
      "Amritsar",
      "Jalandhar",
      "Patiala",
      "Bathinda",
      "Hoshiarpur",
      "Mohali",
      "Batala",
      "Pathankot",
      "Moga",
    ],
  },
  {
    state: "Rajasthan",
    cities: [
      "Jaipur",
      "Jodhpur",
      "Kota",
      "Bikaner",
      "Ajmer",
      "Udaipur",
      "Bhilwara",
      "Alwar",
      "Sikar",
      "Pali",
    ],
  },
  {
    state: "Sikkim",
    cities: [
      "Gangtok",
      "Namchi",
      "Mangan",
      "Gyalshing",
      "Ravangla",
      "Rangpo",
      "Singtam",
      "Naya Bazar",
      "Jorethang",
      "Soreng",
    ],
  },
  {
    state: "Tamil Nadu",
    cities: [
      "Chennai",
      "Coimbatore",
      "Madurai",
      "Tiruchirappalli",
      "Salem",
      "Tirunelveli",
      "Tiruppur",
      "Vellore",
      "Thoothukudi",
      "Thanjavur",
    ],
  },
  {
    state: "Telangana",
    cities: [
      "Hyderabad",
      "Warangal",
      "Nizamabad",
      "Khammam",
      "Karimnagar",
      "Ramagundam",
      "Mahbubnagar",
      "Nalgonda",
      "Adilabad",
      "Suryapet",
    ],
  },
  {
    state: "Tripura",
    cities: [
      "Agartala",
      "Dharmanagar",
      "Udaipur",
      "Kailasahar",
      "Belonia",
      "Ambassa",
      "Khowai",
      "Kamalpur",
      "Sonamura",
      "Santirbazar",
    ],
  },
  {
    state: "Uttar Pradesh",
    cities: [
      "Lucknow",
      "Kanpur",
      "Ghaziabad",
      "Agra",
      "Varanasi",
      "Meerut",
      "Allahabad",
      "Bareilly",
      "Aligarh",
      "Moradabad",
    ],
  },
  {
    state: "Uttarakhand",
    cities: [
      "Dehradun",
      "Haridwar",
      "Roorkee",
      "Haldwani",
      "Rudrapur",
      "Kashipur",
      "Rishikesh",
      "Pauri",
      "Kotdwar",
      "Ramnagar",
    ],
  },
  {
    state: "West Bengal",
    cities: [
      "Kolkata",
      "Asansol",
      "Siliguri",
      "Durgapur",
      "Bardhaman",
      "Malda",
      "Baharampur",
      "Habra",
      "Kharagpur",
      "Shantipur",
    ],
  },
];

export const AccountStatusOptions = [
  { value: "Approved", label: "Approved" },
  { value: "Hold", label: "Hold" },
  { value: "New", label: "New" },
  { value: "Suspended", label: "Suspended" },
];

export const trueFalseOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
