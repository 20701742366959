import { Table, TableCell, TableRow } from "../../../components/Table";
import { Button, Card, CardHeader, Form } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dateFormatter,
  getLocalData,
  onFixed,
  responseToaster,
  seperator,
  setLocalData,
} from "../../../helperFunctions";
import {
  PgPayin_Deposit_Summary_Get,
  PgPayin_Withdrawal_Summary_Get,
  usePgPayinDepositSummary,
  usePgPayinWithdrawalSummary,
} from "../../../redux/slices/pgPayInSlice";
import "./PgPayin.scss";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useForm } from "react-hook-form";
import {
  BANK_SUMMARY_DEPOSIT_LOCAL,
  BANK_SUMMARY_WITHDRAWAL_LOCAL,
  DROPDOWN_ALL_VALUE,
} from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const initialValues = {
  merchant_id: DROPDOWN_ALL_VALUE,
  date: [new Date(), new Date()],
  isLast24: false,
};

const PgPayIn = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [isWithdrawalLoading, setIsWithdrawalLoading] = useState(false);
  const [isDepositFilterOpen, setIsDepositFilterOpen] = useState(
    getLocalData(BANK_SUMMARY_DEPOSIT_LOCAL, false)
  );
  const [isWithdrawalFilterOpen, setIsWithdrawalFilterOpen] = useState(
    BANK_SUMMARY_WITHDRAWAL_LOCAL,
    false
  );
  const role = useRole("Bank Summary");
  const [depositFilter, setDepositFilter] = useState(initialValues);
  const [withdrawalFilter, setWithdrawalFilter] = useState(initialValues);

  const dispatch = useDispatch();
  const depositSummary = usePgPayinDepositSummary();
  const withdrawalSummary = usePgPayinWithdrawalSummary();

  const getPercentage = (status, total) => {
    status = status ? status : 0;
    total = total ? total : 0;
    if (status > 0) {
      return " (" + onFixed((status * 100) / total) + "%)";
    }
    return "";
  };

  const columns = [
    {
      title: "#",
      name: "",
      Cell: (data) => {
        return (
          <>
            <div className="">
              <span
                className={`${checkIsHigherFailedRatio(
                  data?.Success,
                  data?.total_txn,
                  data?.Pending
                )}`}
              ></span>
            </div>
          </>
        );
      },
    },
    {
      title: "LABEL",
      name: "label",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  {data.label} ({data.pg_name}) ({data.meta_id})
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PG",
      name: "pg_name",
    },
    {
      title: "PROCESSING",
      name: "Processing",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  {seperator(data?.Processing, false)}
                  {getPercentage(data?.Processing, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PENDING",
      name: "Pending",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  {seperator(data?.Pending, false)}
                  {getPercentage(data?.Pending, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "SUCCESS",
      name: "Success",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  {seperator(data?.Success, false)}
                  {getPercentage(data?.Success, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "FAILED",
      name: "Failed",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  {seperator(data?.Failed, false)}
                  {getPercentage(data?.Failed, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CURRENT MIN AMOUNT",
      name: "current_min_amt",
      Cell: (data) => {
        return <>{seperator(data?.current_min_amt)}</>;
      },
    },
    {
      title: "CURRENT MAX AMOUNT",
      name: "current_max_amt",
      Cell: (data) => {
        return <>{seperator(data?.current_max_amt)}</>;
      },
    },
    {
      title: "LAST TRANSACTION AT",
      name: "last_success_txn_date",
    },
    {
      title: "TOTAL",
      name: "total_txn",
      Cell: (data) => {
        return <>{seperator(data?.total_txn, false)}</>;
      },
    },
  ];

  const payInPgOption = () => {
    const options = depositSummary?.merchantList
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.merchant_id,
        label: val?.merchant_name,
      }));
    return options;
  };

  const onDepositSubmit = () => {
    const values = getValuesDeposit();
    setDepositFilter({
      ...depositFilter,
      ...values,
      isFilter: true,
    });
  };

  const onWithdrawalSubmit = () => {
    const values = getValuesWithdrawal();
    setWithdrawalFilter({
      ...withdrawalFilter,
      ...values,
      isFilter: true,
    });
  };

  const onGetDepositData = async () => {
    try {
      setIsDepositLoading(true);
      const { isFilter, isLast24, date, ...rest } = depositFilter;
      const last24 = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      const payload = {
        startDate: dateFormatter(
          isLast24 ? last24 : date?.[0],
          isLast24 ? "default" : "start"
        ),
        endDate: dateFormatter(
          isLast24 ? new Date() : date?.[1],
          isLast24 ? "default" : "end",
          date?.[0]
        ),
        ...rest,
      };
      const res = await dispatch(PgPayin_Deposit_Summary_Get(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsDepositLoading(false);
    } catch (err) {
      setIsDepositLoading(false);
    }
  };

  const onGetWithdrawalData = async () => {
    try {
      setIsWithdrawalLoading(true);
      const { isFilter, isLast24, date, ...rest } = withdrawalFilter;
      const last24 = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      const payload = {
        startDate: dateFormatter(
          isLast24 ? last24 : date?.[0],
          isLast24 ? "default" : "start"
        ),
        endDate: dateFormatter(
          isLast24 ? new Date() : date?.[1],
          isLast24 ? "default" : "end",
          date?.[0]
        ),
        ...rest,
      };
      const res = await dispatch(
        PgPayin_Withdrawal_Summary_Get(payload)
      ).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsWithdrawalLoading(false);
    } catch (err) {
      setIsWithdrawalLoading(false);
    }
  };

  const checkIsHigherFailedRatio = (failed, total, pending) => {
    failed = failed ? failed : 0;
    total = total ? total : 0;
    let isFailedDetect = false;
    let isPendingDetect = false;

    if ((failed * 100) / total < 25) {
      isFailedDetect = true;
    }
    if (!isFailedDetect) {
      if ((pending * 100) / total > 20) {
        isPendingDetect = true;
      }
    }

    if (isFailedDetect) {
      return "mo-red-light";
    }
    if (isPendingDetect) {
      return "mo-orange-light";
    }
  };

  const toggleIsDepositFilterOpen = () => {
    setIsDepositFilterOpen(!isDepositFilterOpen);
    setLocalData(BANK_SUMMARY_DEPOSIT_LOCAL, !isDepositFilterOpen);
  };

  const toggleIsWithdrawalFilterOpen = () => {
    setIsWithdrawalFilterOpen(!isWithdrawalFilterOpen);
    setLocalData(BANK_SUMMARY_WITHDRAWAL_LOCAL, !isWithdrawalFilterOpen);
  };

  const prepareTotalData = () => {
    let totalPending = 0;
    let totalFailed = 0;
    let totalProcessing = 0;
    let totalSuccess = 0;
    let totalMin = 0;
    let totalMax = 0;
    let totalTxn = 0;

    depositSummary?.data?.forEach((item, index) => {
      totalPending =
        totalPending + (item?.Pending ? parseInt(item?.Pending) : 0);
      totalFailed = totalFailed + (item?.Failed ? parseInt(item?.Failed) : 0);
      totalProcessing =
        totalProcessing + (item?.Processing ? parseInt(item?.Processing) : 0);
      totalSuccess =
        totalSuccess + (item?.Success ? parseInt(item?.Success) : 0);
      totalMin =
        totalMin +
        (item?.current_min_amt ? parseInt(item?.current_min_amt) : 0);
      totalMax =
        totalMax +
        (item?.current_max_amt ? parseInt(item?.current_max_amt) : 0);

      totalTxn = totalTxn + (item?.total_txn ? parseInt(item?.total_txn) : 0);
    });

    return (
      <>
        <tr className="lasttablerow">
          <TableCell>{"-"}</TableCell>
          <TableCell>{"Total"}</TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>{`${seperator(totalProcessing, false)}`}</TableCell>
          <TableCell>{`${seperator(totalPending, false)}`}</TableCell>
          <TableCell>{`${seperator(totalSuccess, false)}`}</TableCell>
          <TableCell>{`${seperator(totalFailed, false)}`}</TableCell>
          <TableCell>{`${seperator(totalMin)}`}</TableCell>
          <TableCell>{`${seperator(totalMax)}`}</TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>{`${seperator(totalTxn, false)}`}</TableCell>
        </tr>
      </>
    );
  };

  const prepareWithdrawalTotalData = () => {
    let totalPending = 0;
    let totalFailed = 0;
    let totalProcessing = 0;
    let totalSuccess = 0;
    let totalMin = 0;
    let totalMax = 0;
    let totalTxn = 0;

    withdrawalSummary?.data?.forEach((item, index) => {
      totalPending =
        totalPending + (item?.Pending ? parseInt(item?.Pending) : 0);
      totalFailed = totalFailed + (item?.Failed ? parseInt(item?.Failed) : 0);
      totalProcessing =
        totalProcessing + (item?.Processing ? parseInt(item?.Processing) : 0);
      totalSuccess =
        totalSuccess + (item?.Success ? parseInt(item?.Success) : 0);
      totalMin =
        totalMin +
        (item?.current_min_amt ? parseInt(item?.current_min_amt) : 0);
      totalMax =
        totalMax +
        (item?.current_max_amt ? parseInt(item?.current_max_amt) : 0);

      totalTxn = totalTxn + (item?.total_txn ? parseInt(item?.total_txn) : 0);
    });

    return (
      <>
        <tr className="lasttablerow">
          <TableCell>{"-"}</TableCell>
          <TableCell>{"Total"}</TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>{`${seperator(totalProcessing, false)}`}</TableCell>
          <TableCell>{`${seperator(totalPending, false)}`}</TableCell>
          <TableCell>{`${seperator(totalSuccess, false)}`}</TableCell>
          <TableCell>{`${seperator(totalFailed, false)}`}</TableCell>
          <TableCell>{`${seperator(totalMin)}`}</TableCell>
          <TableCell>{`${seperator(totalMax)}`}</TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>{`${seperator(totalTxn, false)}`}</TableCell>
        </tr>
      </>
    );
  };

  useEffect(() => {
    if (role.includes("deposit-bankSummary-list")) {
      onGetDepositData();
    }
  }, [depositFilter]);

  useEffect(() => {
    if (role.includes("withdrawal-bankSummary-list")) {
      onGetWithdrawalData();
    }
  }, [withdrawalFilter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const {
    control: controlDeposit,
    handleSubmit: handleSubmitDeposit,
    getValues: getValuesDeposit,
    reset: resetDeposit,
  } = useForm({
    defaultValues: initialValues,
  });

  const {
    control: controlWithdrawal,
    handleSubmit: handleSubmitWithdrawal,
    getValues: getValuesWithdrawal,
    reset: resetWithdrawal,
  } = useForm({
    defaultValues: initialValues,
  });
  if (!role.includes("bankSummary-list")) {
    return;
  }
  return (
    <div className="bank-summary">
      {role.includes("deposit-bankSummary-list") ? <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Deposit Bank Summary</b>
            </h4>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Button
                type="button"
                className="filterbtn"
                onClick={() => {
                  toggleIsDepositFilterOpen();
                }}
                title="Apply Filter"
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
            </div>
          </div>

          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
            onSubmit={handleSubmitDeposit(onDepositSubmit)}
          >
            {isDepositFilterOpen && (
              <>
                <ControlledDropDown
                  name="merchant_id"
                  label="Client"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={payInPgOption()}
                  control={controlDeposit}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={controlDeposit}
                />
                <Button
                  type="button"
                  className={depositFilter?.isLast24 ? "" : "filterbtn"}
                  color={depositFilter?.isLast24 ? "primary" : ""}
                  onClick={() =>
                    setDepositFilter({
                      ...depositFilter,
                      isLast24: !depositFilter?.isLast24,
                    })
                  }
                >
                  {depositFilter?.isLast24 ? "Set Default" : "Set Last 24 Hour"}
                </Button>
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    setDepositFilter(initialValues);
                    resetDeposit();
                  }}
                >
                  Clear
                </Button>
              </>
            )}
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isDepositLoading}
          data={depositSummary?.data}
          isData={!!depositSummary?.data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {depositSummary?.data?.map((val, index) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
          {prepareTotalData()}
        </Table>
      </Card> : null}
      {role.includes("withdrawal-bankSummary-list") ? <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Withdrawal Bank Summary</b>
            </h4>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Button
                type="button"
                className="filterbtn"
                onClick={() => {
                  toggleIsWithdrawalFilterOpen();
                }}
                title="Apply Filter"
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
            </div>
          </div>

          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
            onSubmit={handleSubmitWithdrawal(onWithdrawalSubmit)}
          >
            {isWithdrawalFilterOpen && (
              <>
                <ControlledDropDown
                  name="merchant_id"
                  label="Client"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={payInPgOption()}
                  control={controlWithdrawal}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={controlWithdrawal}
                />
                <Button
                  type="button"
                  className={withdrawalFilter?.isLast24 ? "" : "filterbtn"}
                  color={withdrawalFilter?.isLast24 ? "primary" : ""}
                  onClick={() =>
                    setWithdrawalFilter({
                      ...withdrawalFilter,
                      isLast24: !withdrawalFilter?.isLast24,
                    })
                  }
                >
                  {withdrawalFilter?.isLast24
                    ? "Set Default"
                    : "Set Last 24 Hour"}
                </Button>
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    setWithdrawalFilter(initialValues);
                    resetWithdrawal();
                  }}
                >
                  Clear
                </Button>
              </>
            )}
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isWithdrawalLoading}
          data={withdrawalSummary?.data}
          isData={!!withdrawalSummary?.data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {withdrawalSummary?.data?.map((val, index) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
          {prepareWithdrawalTotalData()}
        </Table>
      </Card> : null}
    </div>
  );
};

export default PgPayIn;
