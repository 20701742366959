import React, { useState, useEffect } from "react";
import {
  Upi_Bank_Success_Get,
  useUpiBankSuccessGetState,
} from "../../../redux/slices/upiBankSuccessSlice";
import { Button, Card, CardHeader, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import { Table, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  onFixed,
  responseToaster,
} from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const initialValues = {
  bankId: "ALL",
  isCall: true,
  startDate: new Date(),
  endDate: new Date(),
  page_no: 1,
  limit: 10,
  isFilter: false,
};

const UpiBankSuccess = () => {
  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const { upiBankSuccessGetLoading } = useSelector(
    (state) => state.upiBankSuccess
  );

  const dispatch = useDispatch();
  const upiBankSuccessGetState = useUpiBankSuccessGetState();
  const role = useRole("Upi Bank Success");

  const onUpiBankSucces = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        startDate,
        endDate,
        isCall,
        filter: dropdown,
        ...rest
      } = filter;
      const payload = {
        filter_data: {
          startDate: dateFormatter(startDate, "start"),
          endDate: dateFormatter(endDate, "end", startDate),
          ...rest,
        },
        page_no,
        limit,
      };
      const res = await dispatch(Upi_Bank_Success_Get(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const getOptions = () => {
    const options = upiBankSuccessGetState?.bankList
      ?.slice()
      ?.sort((a, b) =>
        a?.bank_details?.account_holder_name?.localeCompare(
          b?.bank_details?.account_holder_name
        )
      )
      ?.map((val) => ({
        value: val?.account_number,
        label: `${val?.bank_details?.account_holder_name} - ${val?.account_number}`,
      }));
    return options?.length
      ? [{ value: "ALL", label: "All" }, ...options]
      : [{ value: "ALL", label: "All" }];
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFilter({
      ...filter,
      isFilter: true,
      isCall: true,
    });
  };

  const columns = [
    {
      title: "BANK DETAILS",
      name: "BANK",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.bank_details?.account_holder_name || "-"}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> A/C No : </span>
                  {data?.account_number || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PHONEPE",
      name: "total_PhonePe_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
          >
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.total_PhonePe_txn || "0"} ~ </span>
                {`${onFixed(data?.PhonePe_percentage)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "PAYTM",
      name: "total_PayTM_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
          >
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.total_PayTM_txn || "0"} ~ </span>
                {`${onFixed(data?.PayTM_percentage)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "GOOGLEPAY",
      name: "total_GooglePay_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
          >
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.total_GooglePay_txn || "0"} ~ </span>
                {`${onFixed(data?.GooglePay_percentage)}%`}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "TOTAL TXN",
      name: "total_pending",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
          >
            <div className="order-singal-div">
              <p className="themecolor">
                <span> {data?.total_count || "-"} </span>
              </p>
            </div>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (filter?.isCall && role.includes("upiBankSuccess-list")) {
      onUpiBankSucces();
    }
  }, [filter]);

  return (
    <>
      {role.includes("upiBankSuccess-list") ? (
        <>
          <div className="payoutmanual-main-div">
            <Card>
              <Form onSubmit={onSubmit}>
                <CardHeader>
                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div>
                      <h4 className="main-title">
                        <b>UPI Bank Success</b>
                      </h4>
                    </div>
                    <div className="d-flex align-items-end button-space">
                      <div
                        className="d-flex my-xl-auto right-content align-items-center button-space "
                        style={{ flexWrap: "wrap" }}
                      >
                        <p className="mb-0">Bank</p>
                        <DropDown
                          id="bankId"
                          name="Bank Id"
                          options={getOptions()}
                          value={filter?.bankId}
                          onChange={(selectedOption) =>
                            setFilter({
                              ...filter,
                              bankId: selectedOption.value,
                              isCall: false,
                            })
                          }
                        />
                        <div className="d-flex flex-column select-div">
                          <DatePicker
                            maxDate={new Date()}
                            placeholder="Select Date"
                            selectsRange
                            startDate={filter?.startDate}
                            endDate={filter?.endDate}
                            onChange={(selectedDate) => {
                              setFilter({
                                ...filter,
                                startDate: selectedDate?.[0],
                                endDate: selectedDate?.[1],
                                isCall: false,
                              });
                            }}
                          />
                        </div>
                        <Button type="submit" color="primary">
                          Apply
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            setFilter({
                              ...initialValues,
                              isCall: true,
                            });
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Form>
              <Table
                columns={columns}
                isLoading={upiBankSuccessGetLoading}
                data={upiBankSuccessGetState?.data || []}
                isData={!!upiBankSuccessGetState?.data?.length}
                filterColumns={filterColumns}
                count={upiBankSuccessGetState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
              >
                {upiBankSuccessGetState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default UpiBankSuccess;
