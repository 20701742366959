import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import {
  updateFedConfigBankStatus,
  fedConfigBankGet,
  setFedConfigBank,
  useFedConfigBankData,
  updateFedConfigBankClaimCheck,
} from "../../../redux/slices/fedConfigBankSlice";
import { Table, TableRow } from "../../../components/Table";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { DROPDOWN_ALL_VALUE, FILTER } from "../../../constant";
import { responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import { useRole } from "../../../redux/slices/authSlice";

const FedConfigBank = () => {
  const dispatch = useDispatch();
  const role = useRole("Fed Config Bank");

  const [filter, setFilter] = useState({ ...FILTER, isToast: true });

  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState();

  const data = useFedConfigBankData();

  const statusOption = [
    { value: 1, label: "Active", activeClassName: "green" },
    { value: 0, label: "Deactive", activeClassName: "red" },
  ];

  const claimCheckOption = [
    { value: 1, label: "Yes", activeClassName: "green" },
    { value: 0, label: "No", activeClassName: "red" },
  ];

  const claimCheckOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const initialValues = {
    is_claim_check: DROPDOWN_ALL_VALUE,
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const columns = [
    {
      title: "android Id",
      name: "androidId",
    },
    {
      title: "remitter Name",
      name: "remitterName",
    },
    {
      title: "remitter Account Number",
      name: "remitterAccountNumber",
    },
    {
      title: "is Claim Check",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={
                statusLoading?.id === data?.remitterAccountNumber &&
                statusLoading?.type === "claim"
              }
              value={+data?.isClaimCheck === 1 ? 1 : 0}
              disabled={!!statusLoading?.loading}
              reverse
              options={claimCheckOption}
              onChange={async (value) => {
                if (role.includes("fed-bank-config-update")) {
                  try {
                    const payload = {
                      remitterAccountNumber: data?.remitterAccountNumber,
                      is_claim_check: value,
                    };
                    await updateClaimCheck(payload);
                  } catch (err) {}
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: "is Activated",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={
                statusLoading?.id === data?.remitterAccountNumber &&
                statusLoading?.type === "status"
              }
              value={data?.isActivated ? 1 : 0}
              disabled={!!statusLoading?.loading}
              reverse
              options={statusOption}
              onChange={async (value) => {
                try {
                  if (role.includes("fed-bank-config-update")) {
                    const payload = {
                      remitterAccountNumber: data?.remitterAccountNumber,
                      isActivated: value,
                    };
                    await updateStatus(payload);
                  }
                } catch (err) {}
              }}
            />
          </>
        );
      },
    },
  ];

  const updateClaimCheck = async (payload) => {
    try {
      setStatusLoading({
        loading: true,
        id: payload?.remitterAccountNumber,
        type: "claim",
      });
      const res = await dispatch(
        updateFedConfigBankClaimCheck(payload)
      ).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.remitterAccountNumber === payload?.remitterAccountNumber
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = {
          ...newData[index],
          isClaimCheck: payload?.is_claim_check,
        };
        dispatch(setFedConfigBank({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading({});
    } catch (err) {
      setStatusLoading({});
    }
  };

  const updateStatus = async (payload) => {
    try {
      setStatusLoading({
        loading: true,
        id: payload?.remitterAccountNumber,
        type: "status",
      });
      const res = await dispatch(updateFedConfigBankStatus(payload)).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.remitterAccountNumber === payload?.remitterAccountNumber
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = {
          ...newData[index],
          isActivated: payload?.isActivated,
        };
        dispatch(setFedConfigBank({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading({});
    } catch (err) {
      setStatusLoading({});
    }
  };

  const getFedConfigBanks = async () => {
    try {
      setIsLoading(true);
      const { page_no, limit, isToast, isFilter, ...rest } = filter;
      const payload = isFilter
        ? {
            page_no,
            limit,
            filter_data: {
              ...rest,
            },
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(fedConfigBankGet(payload)).unwrap();
      // if (isToast) {
      //   responseToaster(res);
      // }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isToast: true,
      isFilter: true,
    });
  };

  useEffect(() => {
    if (role.includes("fedConfigBank-list")) {
      getFedConfigBanks();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("fedConfigBank-list")) {
    return;
  }

  return (
    <div className="support-log-main-div">
      <Card>
        <CardHeader className="flex-column  align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <h4 className="main-title">
                <b>FED Config Bank</b>
              </h4>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form
            className="transaction-main-div"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div className="d-flex align-items-end mt-1 button-space flex-wrap">
              <ControlledDropDown
                name="is_claim_check"
                label="Claim"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={claimCheckOptions}
                control={control}
              />
              <Button type="submit" color="primary">
                Apply
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isToast: false });
                }}
              >
                Clear
              </Button>
            </div> */}
          </Form>
        </CardBody>
        <Table
          columns={columns}
          isLoading={isLoading}
          data={data?.data || []}
          isData={!!data?.data?.length}
          filterColumns={filterColumns}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
          count={data?.total_item || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
              isToast: false,
            });
          }}
        >
          {data?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
    </div>
  );
};

export default FedConfigBank;
