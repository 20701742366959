export const statusStyles = {
  Success: "bg-success fa-solid fa-check",
  Failed: "bg-failed fa-solid fa-xmark",
  Initialized: "bg-initialized fa fa-exclamation",
  Processing: "bg-processing fa fa-refresh",
  Expired: "bg-expired",
  Pending: "bg-pending",
  RECHECK: "bg-recheck",
  Open: "bg-open",
  LowBal: "bg-lowbal",
  Frozen: "bg-frozen",
  "Not Attempted": "bg-not-attempted",
};

export const statusTextStyles = {
  Success: "bg-textsuccess",
  Failed: "bg-textfailed",
  Initialized: "bg-textinitialized",
  Processing: "bg-textprocessing",
  Expired: "bg-textexpired",
  Pending: "bg-textpending",
  Open: "bg-textopen",
  RECHECK: "bg-textrecheck",
  LowBal: "bg-textlowbal",
  Frozen: "bg-textfrozen",
  "Not Attempted": "bg-textnot-attempted",
  NotFound: "bg-textnot-attempted",
};

export const statusBgStyles = {
  Success: "status-btn-div bg-success",
  Failed: "status-btn-div bg-failed",
  Initialized: "status-btn-div bg-initialized",
  Processing: "status-btn-div bg-processing",
  Expired: "status-btn-div bg-expired",
  Pending: "status-btn-div bg-pending",
  SUCCESS: "status-btn-div bg-success",
  FAILED: "status-btn-div bg-failed",
  INITIALIZED: "status-btn-div bg-initialized",
  PROCESSING: "status-btn-div bg-processing",
  EXPIRED: "status-btn-div bg-expired",
  PENDING: "status-btn-div bg-pending",
  Open: "status-btn-div bg-open",
  RECHECK: "status-btn-div bg-recheck",
  LowBal: "status-btn-div bg-lowbal",
  Frozen: "status-btn-div bg-frozen",
  "Not Attempted": "status-btn-div bg-not-attempted",
  NotFound: "status-btn-div bg-notFound ",
};

export const getStatusStyle = (status) => {
  const className =
    status === true
      ? statusStyles["Success"]
      : status === false
      ? statusStyles["Failed"]
      : statusStyles[status];
  const bgClassName =
    status === true
      ? statusBgStyles["Success"]
      : status === false
      ? statusBgStyles["Failed"]
      : statusBgStyles[status];
  const textClassName =
    status === true
      ? statusTextStyles["Success"]
      : status === false
      ? statusTextStyles["Failed"]
      : statusTextStyles[status];
  return { className, bgClassName, textClassName };
};
