import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Add_Bank_Transaction,
  Available_Bank,
  Bank_Transaction,
  Bank_Transaction_Details,
  Mark_As_Used,
  Merge_UTR,
  Update_Mobile_Number,
  Update_Payment,
  Update_Transaction,
} from "../services/bankTransactionServices";

const initialState = {
  bankTransactionState: [],
  availableBankState: [],
  bankTransactionStateisLoading: true,
};
export const Bank_Transaction_Get = createAsyncThunk(
  "/banktransaction",
  async (payload) => {
    return await Bank_Transaction(payload);
  }
);

export const Bank_Transaction_Details_Get = createAsyncThunk(
  "/banktransaction-details",
  async (payload) => {
    return await Bank_Transaction_Details(payload);
  }
);

export const Bank_Available = createAsyncThunk(
  "/available_bank",
  async (payload) => {
    return await Available_Bank(payload);
  }
);
export const Bank_Transaction_Add = createAsyncThunk(
  "/add_bank_transaction",
  async (payload) => {
    return await Add_Bank_Transaction(payload);
  }
);

export const UTR_Merge = createAsyncThunk("/mergeutr", async (payload) => {
  return await Merge_UTR(payload);
});

export const Used_Mark = createAsyncThunk("/used-mark", async (payload) => {
  return await Mark_As_Used(payload);
});

export const Payment_Update = createAsyncThunk(
  "/update-payment",
  async (payload) => {
    return await Update_Payment(payload);
  }
);

export const Transaction_Update = createAsyncThunk(
  "/update-transaction",
  async (payload) => {
    return await Update_Transaction(payload);
  }
);

export const Mobile_Number_Update = createAsyncThunk(
  "/update-mobile-number",
  async (payload) => {
    return await Update_Mobile_Number(payload);
  }
);

const bankTransactionSlice = createSlice({
  name: "bankTransactionSlice",
  initialState,
  reducers: {
    clearBankTransaction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(Bank_Transaction_Get.fulfilled, (state, action) => {
      state.bankTransactionStateisLoading = false;
      state.bankTransactionState = action.payload;
    });
    builder.addCase(Bank_Transaction_Get.pending, (state, action) => {
      state.bankTransactionStateisLoading = true;
    });
    builder.addCase(Bank_Transaction_Get.rejected, (state, action) => {
      state.bankTransactionStateisLoading = false;
    });
    builder.addCase(Bank_Available.fulfilled, (state, action) => {
      state.availableBankState = action.payload;
    });
  },
});
export default bankTransactionSlice.reducer;
export const { clearBankTransaction } = bankTransactionSlice.actions;

export const selectTransaction = (state) => {
  return state.bankTransaction.transactionState;
};

export const useTransaction = () => {
  const transactionState = useSelector(selectTransaction);
  return useMemo(() => ({ transactionState }), [transactionState]);
};
