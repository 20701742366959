import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import {
  addPayoutWhitelistClient,
  useWhitelistState,
} from "../../../../redux/slices/whitelistClientSlice";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";

const AddWhiteListClient = (props) => {
  const { onCloseaddWhitelistModal, addWhitelistModal, onGet } = props;

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const whitelistState = useWhitelistState();

  const dispatch = useDispatch();

  const initialValues = {};
  const addclientOption = () => {
    const options = whitelistState?.merchant
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.merchant_id,
        label: val?.merchant_name,
      }));
    return options;
  };

  const onSubmit = async () => {
    const values = getValues();
    let payload = {
      ...values,
    };
    try {
      setIsButtonLoading(true);
      const res = await dispatch(addPayoutWhitelistClient(payload)).unwrap();
      responseToaster(res);
      if (!!res) {
        onGet();
        onClose();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    reset();
    onCloseaddWhitelistModal();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={addWhitelistModal}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Add WhiteList Client</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="select-div">
            <ControlledDropDown
              name="merchantId"
              label="Merchant"
              placeholder="Select Merchant"
              id="merchantId"
              options={addclientOption()}
              control={control}
            />
          </div>
        </div>
        <div className="modal-footer z-0">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddWhiteListClient;
