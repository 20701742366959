import React, { useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import ControlledImagePicker from "../../../components/Custom/Forms/Controller/ControlledImagePicker";
import { useDispatch } from "react-redux";
import {
  screenshotRead,
  updateScreenshotStatus,
} from "../../../redux/slices/screenshotSupportSlice";
import Loader from "../../../components/Custom/Loader";
import { FiAlertTriangle } from "react-icons/fi";
import { responseToaster, seperator } from "../../../helperFunctions";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import { useRole } from "../../../redux/slices/authSlice";

const ScreenshotSupport = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedStatus, setselectedStatus] = useState();
  const [data, setData] = useState();
  const role = useRole("Screenshot Support");
  const { control, setValue } = useForm();

  const statusOptions = [
    {
      value: "Mark as Solved",
      label: "Mark as Solved",
    },
    {
      value: "Not Ours",
      label: "Not Ours",
    },
    {
      value: "Solved",
      label: "Solved",
    },
    {
      value: "Pending",
      label: "Pending",
    },
  ];

  const handleStatusChange = (option) => {
    setselectedStatus(option?.value);
  };

  const updateStatus = async () => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(
        updateScreenshotStatus({
          utr: data?.data?.utr_number,
          status: selectedStatus,
        })
      ).unwrap();
      responseToaster(res);
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onImageChange = async (e) => {
    try {
      setValue("image", e);
      if (!e?.file) {
        setData(false);
        return;
      }
      setIsLoading(true);
      const payload = new FormData();
      payload.append("image", e?.file);
      const res = await dispatch(screenshotRead(payload)).unwrap();
      setData(res);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  if (!role.includes("screenshotSupport-list")) {
    return;
  }
  return (
    <>
      <Card className="pb-5">
        <CardHeader className="flex-column align-items-start gap-2">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Screenshot Support</b>
            </h4>
          </div>
          <div className="row w-100">
            {role?.includes("read-utr") ? <form className="col-6 d-flex gap-2">
              <ControlledImagePicker
                isLoading={isLoading}
                className="w-100"
                name="image"
                control={control}
                onChange={onImageChange}
              />
            </form> : null}
            {isLoading ? (
              <div className="col-6 d-flex align-items-center justify-content-center">
                <Loader />
              </div>
            ) : (
              <div className="col-6 d-flex flex-column">
                <>
                  <h4>Screenshot Details</h4>
                  <div className="row border-b-1 py-25">
                    <div className="col-6">
                      <b>Amount :</b>
                    </div>
                    <div className="col-6">{seperator(data?.data?.Amount)}</div>
                  </div>
                  <div className="row border-b-1 py-25">
                    <div className="col-6">
                      <b>UTR Number :</b>
                    </div>
                    <div className="col-6">{data?.data?.utr_number}</div>
                  </div>
                  {!!data && !!data?.isShow ? (
                    <>
                      <div className="row border-b-1 py-25">
                        <div className="col-6">
                          <DropDown
                            placeholder="Select Status"
                            name="status"
                            options={statusOptions}
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <div className="col-6">
                          {role?.includes("update-screenshot-status") ? <CustomeButton
                            isButtonLoading={isButtonLoading}
                            onClick={updateStatus}
                          >
                            Update Status
                          </CustomeButton> : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <h4 className="pt-2">Fetch From DB</h4>
                  {data?.txnDetails?.account_number ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Account Number :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.account_number}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.account_holder_name ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>PG Name :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.account_holder_name} -{" "}
                        {data?.txnDetails?.pg_name}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.payment_amount ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Payment Amount :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.payment_amount}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.transaction_id ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Transaction ID :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.transaction_id}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.merchant_id ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Merchant Id :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.merchant_id}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.created_at_ist ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Date :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.created_at_ist}
                      </div>
                    </div>
                  ) : null}

                  {data?.txnDetails?.payment_status ? (
                    <div className="row border-b-1 py-25">
                      <div className="col-6">
                        <b>Payment Status :</b>
                      </div>
                      <div className="col-6">
                        {data?.txnDetails?.payment_status}
                      </div>
                    </div>
                  ) : null}
                </>
                {data?.warning ? (
                  <div className="d-flex gap-1 mt-2">
                    <FiAlertTriangle size={24} />
                    <p className="text-warning">{data?.message}</p>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </CardHeader>
      </Card>
    </>
  );
};

export default ScreenshotSupport;
