import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  checkAuthStatus,
  factorEnable,
  logIn,
  logInAgain,
  logOut,
  logOutUser,
  verifyOtp,
} from "../services/authServices";
import { getLocalData, setLocalData } from "../../helperFunctions";
import { useAppSelector } from "../../utility/hooks";

const initialState = {
  user: getLocalData("token"),
  isLoading: false,
  sidebar: {},
  verifyAuth: getLocalData("verifyAuth"),
  authToken: getLocalData("2faToken"),
  qr: getLocalData("qr"),
  hasResetPwd: getLocalData("mailPwd"),
  logged_in: false,
  userPayload: {},
};

export const login = createAsyncThunk("auth/login", async (payload) => {
  return await logIn(payload);
});

export const logoutUserr = createAsyncThunk(
  "auth/logout-user",
  async (payload) => {
    return await logOutUser(payload);
  }
);

export const verifyotp = createAsyncThunk("2fa-verify", async (payload) => {
  return await verifyOtp(payload);
});

export const factorAuth = createAsyncThunk("/2fa-enable", async (payload) => {
  return await factorEnable(payload);
});

export const checkAuth = createAsyncThunk(
  "/check-auth-status",
  async (payload) => {
    return await checkAuthStatus(payload);
  }
);

export const loginAgain = createAsyncThunk(
  "auth/loginAgain",
  async (payload) => {
    return await logInAgain(payload);
  }
);

export const Logout = createAsyncThunk("auth/logout", async (payload) => {
  return await logOut(payload);
});

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = !!payload;
    },
    setSidebar: (state, { payload }) => {
      state.sidebar = payload || {};
    },
    setUser: (state, { payload }) => {
      state.user = payload || {};
    },
    setAuthToken: (state, { payload }) => {
      state.authToken = payload;
    },
    verifyAuth: (state, { payload }) => {
      state.verifyAuth = payload;
    },
    setQr: (state, { payload }) => {
      state.qr = payload;
    },
    setloggedIn: (state, { payload }) => {
      state.logged_in = payload;
    },
    setHasResetPwd: (state, { payload }) => {
      state.hasResetPwd = payload;
    },
    resetAuth: (state) => {
      state.user = {};
      state.authToken = "";
      state.verifyAuth = null;
      state.qr = "";
      state.hasResetPwd = false;
    },
    clearAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      try {
        if (action.payload) {
          state.user = action.payload;
        } else {
          state.userPayload = action.meta.arg;
        }
        if (Object.keys?.(action?.payload?.SiderBar || {})?.length) {
          state.sidebar = action.payload?.SiderBar || {};
          state.verifyAuth = null;
        }
        if (action.payload?.is_set_secret_key !== undefined) {
          state.verifyAuth = action.payload.is_set_secret_key;
        }
      } catch (error) {
        console.log("error", error);
      }
    });

    builder.addCase(verifyotp.fulfilled, (state, action) => {
      const { token, ...rest } = action?.payload;
      state.user = { ...state?.user, ...rest };
      state.sidebar = action?.payload?.SiderBar || {};
      state.authToken = action?.payload?.token;
    });
    builder.addCase(loginAgain.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        token: action.payload?.token,
      };
    });
    builder.addCase(loginAgain.rejected, (state, action) => {
      state.user = {};
    });
    builder.addCase(Logout.fulfilled, (state, action) => {
      state.user = {};
      state.authToken = "";
      state.verifyAuth = null;
      state.qr = "";
      state.hasResetPwd = false;
    });
  },
});
export default authSlice.reducer;
export const {
  userPayload,
  setIsLoading,
  setSidebar,
  clearAuth,
  setUser,
  setAuthToken,
  verifyAuth,
  setQr,
  setHasResetPwd,
  resetAuth,
  setloggedIn,
} = authSlice.actions;

export const selectUser = (state) => {
  return state.auth.user;
};
export const useUser = () => {
  const user = useSelector(selectUser);
  setLocalData("token", user, {});
  return useMemo(() => ({ user }), [user]);
};

export const selectAuth = (state) => {
  return state.auth;
};
export const useAuth = () => {
  const auth = useSelector(selectAuth);
  setLocalData("2faToken", auth?.authToken, "");
  setLocalData("verifyAuth", auth?.verifyAuth, null);
  setLocalData("qr", auth?.qr, "");
  return useMemo(() => ({ auth }), [auth]);
};

export const selectSidebar = (state) => {
  return state.auth.sidebar;
};
export const useSidebar = () => {
  const sidebar = useSelector(selectSidebar);
  return useMemo(() => sidebar, [sidebar]);
};

export const selectIsLoading = (state) => {
  return state.auth.isLoading;
};
export const useIsLoading = () => {
  const isLoading = useSelector(selectIsLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectRole = (state) => state.auth.user;
export const useRole = (page = "") => {
  const user = useAppSelector(selectRole);
  const role = user?.permission?.[page] || [];
  return useMemo(() => role, [role]);
};
