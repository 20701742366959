import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RiskManagement } from "../services/riskManagementServices";

const initialState = {
  riskManagementState: [],
  isLoading: true,
};
export const getRiskManagement = createAsyncThunk(
  "/riskManagement",
  async (payload) => {
    return await RiskManagement(payload);
  }
);

const riskManagementSlice = createSlice({
  name: "riskManagementSlice",
  initialState,
  reducers: {
    clearRiskManagement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRiskManagement.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRiskManagement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.riskManagementState = action.payload;
    });
    builder.addCase(getRiskManagement.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default riskManagementSlice.reducer;
export const { clearRiskManagement } = riskManagementSlice.actions;

export const selectRiskManagement = (state) => {
  return state.riskManagement.riskManagementState;
};

export const useRiskManagement = () => {
  const riskManagementState = useSelector(selectRiskManagement);
  return useMemo(() => ({ riskManagementState }), [riskManagementState]);
};
