export const searchOptions = [
    {
        label: "is Call",
        value: "is_call",
    },
    {
        label: "PG Name",
        value: "pg_name",
    },
    {
        label: "Transaction ID",
        value: "transaction_id",
    },
    {
        label: "Token",
        value: "token",
    },
];
export const isCallOptions = [
    {
        label: "yes",
        value: "Yes",
    },
    {
        label: "no",
        value: "No",
    },
];