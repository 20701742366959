import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {  getCustomerManagement } from "../services/customerManagementServices";

const initialState = {
  items: {},
};

export const customerManagementGet = createAsyncThunk(
  "/customerManagementGet",
  async (payload) => {
    return await getCustomerManagement(payload);
  }
);

const customerManagementSlice = createSlice({
  name: "customerManagementSlice",
  initialState,
  reducers: {
    clearCustomerManagement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(customerManagementGet.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});
export default customerManagementSlice.reducer;
export const { clearCustomerManagement } = customerManagementSlice.actions;

export const selectCustomerManagement = (state) => {
  return state.customerManagement.items;
};
export const useCustomerManagement = () => {
  const items = useSelector(selectCustomerManagement);
  return useMemo(() =>  items , [items]);
};
