import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadExcel } from "../services/bankParseServices";

const initialState = {
  banksParseExl: {},
};

export const UploadExcel = createAsyncThunk("/uploadExcel", async (payload) => {
  return await uploadExcel(payload);
});

const bankParseSlice = createSlice({
  name: "bankParseSlice",
  initialState,
  reducers: {
    clearBankParse: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(UploadExcel.fulfilled, (state, action) => {
      state.banksParseExl = action.payload;
    });
  },
});
export default bankParseSlice.reducer;
export const { clearBankParse } = bankParseSlice.actions;
