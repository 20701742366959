import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIdfcMailWebhook } from "../services/idfcMailWebhookServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  data: {},
};

export const idfcMailWebhookGet = createAsyncThunk(
  "idfcMailWebhookGet",
  async (payload) => {
    return await getIdfcMailWebhook(payload);
  }
);

const idfcMailWebhookSlice = createSlice({
  name: "idfcMailWebhookSlice",
  initialState,
  reducers: {
    clearIdfcMailWebhook: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(idfcMailWebhookGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default idfcMailWebhookSlice.reducer;
export const { clearIdfcMailWebhook } = idfcMailWebhookSlice.actions;

export const selectIdfcMailWebhook = (state) => {
  return state.idfcMailWebhook.data;
};
export const useIdfcMailWebhook = () => {
  const data = useSelector(selectIdfcMailWebhook);
  return useMemo(() => data, [data]);
};
