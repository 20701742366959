import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import { proxyGet, useProxyData } from "../../../redux/slices/proxySlice";
import AddProxyModal from "./AddProxyModal";
import { useRole } from "../../../redux/slices/authSlice";

const Proxy = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [search, setSearch] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const role = useRole("Proxy");

  const dispatch = useDispatch();

  const proxy = useProxyData();
  const columns = [
    {
      title: "#",
      name: "id",
    },
    {
      title: "LABEL",
      name: "label_name",
    },
    {
      title: "PROXY IP",
      name: "ip_proxy",
    },
    {
      title: "IS GET",
      name: "is_get",
      Cell: (data) => {
        return (
          <>
            <span>{data?.is_get === 1 ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      title: "DATE",
      name: "created_at_ist",
    },
  ];

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

  const onGetProxy = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...rest } = filter;
      const payload = {
        ...rest,
      };
      const res = await dispatch(proxyGet(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareItems = () => {
    if (!search) {
      return proxy || [];
    }

    return proxy?.filter((item) => {
      const isGet = item?.is_get === 1 ? "Yes" : "No";
      return (
        item?.id?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.label_name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        item?.ip_proxy
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        item?.created_at
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        isGet?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
  };

  useEffect(() => {
    if (role.includes("proxy-list")) {
      onGetProxy();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("proxy-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <InputField
              placeholder="Enter Search Value"
              name="email_id"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            {role.includes("add-proxy") ? (
              <Button
                title="Add Proxy"
                color="primary"
                onClick={toggleAddModal}
              >
                Add Proxy
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={prepareItems() || []}
          isData={!!prepareItems()?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {prepareItems()?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
      <AddProxyModal
        isOpen={isAddModalOpen}
        onHide={toggleAddModal}
        onGet={() => setFilter({ ...filter })}
      />
    </div>
  );
};

export default Proxy;
