// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import CheckBox from "../CheckBox/CheckBox";

const ControlledCheckBox = (props) => {
    const { control, name, ...rest } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...restt } }) => {
                return <CheckBox
                    {...restt}
                    {...rest}
                />
            }}
        />
    );
};

export default ControlledCheckBox;
