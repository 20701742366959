import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { Table, TableRow } from "../../../Table";
import { FILTER } from "../../../../constant";
import { seperator } from "../../../../helperFunctions";

const ParseDataModal = (props) => {
  const { data, onClose } = props;

  const [filter, setFilter] = useState(FILTER);
  const [tableData, setTableData] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);

  useEffect(() => {
    const { limit, page_no } = filter;

    setTableData(
      data?.parsedData?.length
        ? data?.parsedData?.slice(
            limit * (page_no - 1),
            limit * (page_no - 1) + limit
          )
        : []
    );
  }, [data, filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const isNullorObject = (value) => {
    if (value === null) {
      return "null";
    } else if (typeof value === "object" || typeof value === "function") {
      return "object";
    } else {
      return "not null or object";
    }
  };

  const columns = [
    {
      title: "PAYMENT UTR",
      name: "paymentUtr",
    },
    {
      title: "AMOUNT",
      name: "amount",
      Cell: (data) => (<>
        {`${seperator(data?.amount)}`}
      </>)
    },
    {
      title: "PAYMENT STR",
      name: "payment_str",
    },
    {
      title: "DESCRIPTION",
      name: "description",
    },
  ];

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isNullorObject(data) !== "null"}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Parse Data</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Table
          columns={columns}
          data={tableData || []}
          // isLoading={isTableLoading}
          isData={!!tableData?.length}
          isExpandable={false}
          filterColumns={filterColumns}
          count={data?.parsedData?.length}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        >
          {tableData?.map((items) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                item={items}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ParseDataModal;
