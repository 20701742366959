import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import {
  resellerMerchantListGet,
  resellerToMerchantAdd,
  useResellerMerchantList,
} from "../../../redux/slices/resellerSlice";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";

const AddToMerchantModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const merchantList = useResellerMerchantList();

  const initialValues = {
    merchantId: "",
    resellerId: "",
    payinCommission: 0,
    payoutCommission: 0,
    startDate: null,
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        ...values,
        startDate: dateFormatter(values?.startDate, "start"),
      };
      const res = await dispatch(resellerToMerchantAdd(payload)).unwrap();
      responseToaster(res);
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const onClose = () => {
    resetForm();
    onHide();
  };

  const onGetMerchantList = async () => {
    try {
      const payload = {
        resellerId: isOpen?.reseller_id || "",
      };
      await dispatch(resellerMerchantListGet(payload)).unwrap();
    } catch (err) { }
  };

  const prepareMerchantOptions = () => {
    return merchantList?.data?.map((item) => ({
      value: item?.merchant_id,
      label: item?.merchant_name,
    }));
  };

  useEffect(() => {
    if (isOpen) {
      onGetMerchantList();
      setValues({
        ...values,
        resellerId: isOpen?.reseller_id,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">Add To Merchant</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <DatePicker
            label="Start Date"
            maxDate={new Date()}
            placeholder="Select Start Date"
            selected={values?.startDate}
            onChange={(selectedDate) => {
              setFieldValue("startDate", selectedDate);
            }}
          />
          <DropDown
            name="merchantId"
            label="Merchant ID"
            placeholder="Select Merchant ID"
            options={prepareMerchantOptions()}
            value={values?.merchantId}
            onChange={(e) => {
              setFieldValue("merchantId", e.value);
            }}
          />
          <InputField
            type="number"
            name="payinCommission"
            label="Deposit Commision"
            value={values?.payinCommission}
            onChange={handleChange}
            placeholder="Enter Deposit Commision"
          />
          <InputField
            type="number"
            name="payoutCommission"
            label="Withdrawal Commision"
            value={values?.payoutCommission}
            onChange={handleChange}
            placeholder="Enter Withdrawal Commision"
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddToMerchantModal;
