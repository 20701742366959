import BhimUpiIcon from "../../../assets/images/pages/bhim-upi-icon.svg";
import PayTmIcon from "../../../assets/images/pages/paytm-icon.svg";
import PhonePeIcon from "../../../assets/images/pages/phonepe-icon.svg";
import Mobiquick from "../../../assets/images/pages/mobiquick-icon.png";
import WhatsAppIcon from "../../../assets/images/pages/watsapp-icon.png";
import OtherIcon from "../../../assets/images/pages/otherIcon.svg";
import GpayIcon from "../../../assets/images/pages/gpay-icon.svg";

export const tranPaymentData = {
    BhimUpi: BhimUpiIcon,
    PayTM: PayTmIcon,
    PhonePe: PhonePeIcon,
    MobiKwik: Mobiquick,
    GooglePay: GpayIcon,
    WhatsApp: WhatsAppIcon,
    Other: OtherIcon,
  };