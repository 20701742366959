import { GET_FED_CONFIG_BANK, UPDATE_FED_CONFIG_BANK_CLAIM_CHECK, UPDATE_FED_CONFIG_BANK_STATUS } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getFedConfigBanks = (payload) => {
  return apiInstance.post(GET_FED_CONFIG_BANK, payload);
};
export const fedConfigBankStatusUpdate = (payload) => {
  return apiInstance.post(UPDATE_FED_CONFIG_BANK_STATUS, payload);
};

export const fedConfigBankClaimCheckUpdate = (payload) => {
  return apiInstance.post(UPDATE_FED_CONFIG_BANK_CLAIM_CHECK, payload);
};
