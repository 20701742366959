import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Get_Bouncer_Data } from "../services/bouncerSrevices";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const initialState = {
  isLoading: true,
  bouncerDataGetState: [],
};

export const Bouncer_Data_Get = createAsyncThunk(
  "GetBouncerData",
  async (payload) => {
    return await Get_Bouncer_Data(payload);
  }
);

const bounderSlice = createSlice({
  name: "bounderSlice",
  initialState,
  reducers: {
    clearBouncer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(Bouncer_Data_Get.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Bouncer_Data_Get.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bouncerDataGetState = action.payload;
    });
    builder.addCase(Bouncer_Data_Get.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default bounderSlice.reducer;
export const { clearBouncer } = bounderSlice.actions;

export const selectBouncerDataGetState = (state) => {
  return state.bouncer.bouncerDataGetState;
};

export const useBouncerDataGetState = () => {
  const bouncerDataGetState = useSelector(selectBouncerDataGetState);
  return useMemo(() =>  bouncerDataGetState , [bouncerDataGetState]);
};
