import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getShowAddedUtr,
  useShowAddedUtrState,
} from "../../../../redux/slices/bankStatementSlice";
import { Modal } from "reactstrap";
import { Table, TableRow } from "../../../Table";
import { responseToaster, seperator } from "../../../../helperFunctions";

const BankStatementModal = (props) => {
  const { id, onClose, isOpen } = props;
  const { isAddedUtrLoading } = useSelector((state) => state.statement);
  const [filterColumns, setFilterColumns] = useState([]);

  const [filter, setFilter] = useState({
    page_no: 1,
    limit: 25,
  });

  const dispatch = useDispatch();
  const getShowAddedUtrState = useShowAddedUtrState();
  const GetShowAddedUtr = async (payload) => {
    try {
      const res = await dispatch(getShowAddedUtr({ id, ...payload })).unwrap();
      responseToaster(res);
    } catch (err) { }
  };
  useEffect(() => {
    if (id) {
      GetShowAddedUtr(filter);
    }
  }, [isOpen, filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onHide = () => {
    onClose();
  };
  const columns = useMemo(() => [
    {
      title: "BANK STATEMENT ID",
      name: "bank_statement_id",
    },
    {
      title: "FILE NAME",
      name: "file_name",
    },
    {
      title: "BANK UTR",
      name: "bank_utr",
    },
    {
      title: "AMOUNT",
      name: "amount",
      Cell: (data) => (<>
        {`${seperator(data?.amount)}`}
      </>)
    },
    {
      title: "REMARK",
      name: "remark",
    },
    {
      title: "CREATED AT",
      name: "created_at",
    },
  ]);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isOpen}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Bank Statement</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Table
          columns={columns}
          isLoading={isAddedUtrLoading}
          data={getShowAddedUtrState?.data || []}
          isData={!!getShowAddedUtrState?.data?.length}
          filterColumns={filterColumns}
          count={getShowAddedUtrState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        //   onColumnsChange={(columns) => {
        //     setFilterColumns(columns);
        //   }}
        >
          {getShowAddedUtrState?.data?.map((items) => {
            return (
              <TableRow
                columns={columns}
                item={items}
                isExpandable={false}
                filterColumns={filterColumns}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <h5>
            <b>
              Total :{" "}
              <span className="themecolor">
                {seperator(getShowAddedUtrState?.totalAmt)}
              </span>
            </b>
          </h5>
        </div>
      </div>
    </Modal>
  );
};

export default BankStatementModal;
