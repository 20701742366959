import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddUserModal from "./AddUserModal";
import { setUsers, updateCrede, updateMultiLogin, useUsers, usersGet } from "../../../redux/slices/usersSlice";
import UserDeleteModal from "./UserDeleteModal";
import UserResetPassword from "./UsersResetPassword";
import { rolessGet } from "../../../redux/slices/rolesSlice";
import { useRole } from "../../../redux/slices/authSlice";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import LogoutUser from "./LogoutUser";
import { MdDelete, MdEdit, MdLockReset } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";

const Users = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [userResetPassword, setUserResetPassword] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState();

  const role = useRole("Role");
  const role_manager = useRole("Role Manager");

  const data = useUsers();
  const dispatch = useDispatch();

  const updateAuthCred = async (payload, type, keyType) => {
    try {
      setStatusLoading({
        loading: true,
        id: payload?.id,
        type
      });
      const res = type === "2fa" ? await dispatch(
        updateCrede(payload)
      ).unwrap() : await dispatch(
        updateMultiLogin(payload)
      ).unwrap()
      const index = data?.data?.findIndex(
        (item) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = {
          ...newData[index],
          [keyType]: payload?.[keyType],
        };
        dispatch(setUsers({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading({});
    } catch (err) {
      setStatusLoading({});
    }
  };

  const columns = [
    {
      title: "DATE",
      name: "createdAt",
    },
    {
      title: "USERNAME",
      name: "userName",
    },
    ...(role_manager?.includes("roleManager-list") && role_manager?.includes("update-sub-user-multi-login")
      ? [{
        title: "Multi-Login",
        name: "multi_login",
        Cell: (data) => {
          return (
            <div className="button-space d-flex">
              {role_manager.includes("update-sub-user-multi-login") ? (
                <ToggleSwitch
                  isLoading={
                    statusLoading?.id === data?.id && statusLoading?.type === "multi_login"
                  }
                  value={data?.is_allow_multi_login === 1 ? 1 : 0}
                  disabled={!!statusLoading?.loading}
                  onChange={async (value) => {
                    if (role_manager.includes("update-sub-user-multi-login")) {
                      try {
                        const payload = {
                          id: data?.id,
                          is_allow_multi_login: value,
                        };
                        await updateAuthCred(payload, "multi_login", "is_allow_multi_login");
                      } catch (err) {
                        console.log('err', err);
                      }
                    }
                  }}
                />
              ) : null}
            </div>
          );
        },
      }]
      : []),
    ...(role_manager?.includes("roleManager-list") && role_manager?.includes("update-sub-user")
      ? [{
        title: "2FA",
        name: "2fa",
        Cell: (data) => {
          return (
            <div className="button-space d-flex">
              <ToggleSwitch
                isLoading={
                  statusLoading?.id === data?.id && statusLoading?.type === "2fa"
                }
                value={data?.is_required_2fa === 1 ? 1 : 0}
                disabled={!!statusLoading?.loading}
                onChange={async (value) => {
                  if (role_manager.includes("update-sub-user")) {
                    try {
                      const payload = {
                        id: data?.id,
                        is_required_2fa: value,
                      };
                      await updateAuthCred(payload, "2fa", "is_required_2fa");
                    } catch (err) {
                      console.log('err', err);
                    }
                  }
                }}
              />
            </div>
          );
        },
      }]
      : []),
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <div className="button-space d-flex">
            {role_manager.includes("update-sub-user") ? (
              <Button
                title="Edit"
                color="primary"
                onClick={() => {
                  setIsOpenModal(data);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {role_manager.includes("update-sub-user") ? (
              <Button
                title="Reset Password"
                color="primary"
                onClick={() => setUserResetPassword(data?.id)}
              >
                <MdLockReset />
              </Button>
            ) : null}
            {role_manager.includes("update-sub-user") ? (
              <Button
                title="Logout"
                color="primary"
                disabled={!data?.is_logged_in}
                onClick={() => setLogoutModal(data?.id)}
              >
                <IoMdLogOut />
              </Button>
            ) : null}
            {role_manager.includes("delete-sub-user") ? (
              <Button
                title="Delete User"
                color="danger"
                onClick={() => setIsDeleteOpenModal(data?.id)}
              >
                <MdDelete />
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];
  


  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...payload } = filter;
      const res = await dispatch(usersGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetRolesData = async () => {
    try {
      const { isFilter, ...payload } = FILTER;
      await dispatch(rolessGet(payload)).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    if (role_manager.includes("roleManager-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("role-list")) {
      onGetRolesData();
    }
  }, []);

  if (!role_manager.includes("roleManager-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Users</b>
            </h4>
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ gap: "10px" }}
            >
              {role_manager.includes("add-sub-user") ? (
                <Button
                  title="Add User"
                  color="primary"
                  onClick={() => setIsOpenModal(true)}
                >
                  Add User
                </Button>
              ) : null}
            </div>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>

      <AddUserModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
      />
      <UserDeleteModal
        isOpen={isDeleteOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsDeleteOpenModal(false)}
      />
      <UserResetPassword
        userResetPassword={userResetPassword}
        onCloseUserResetPassword={() => setUserResetPassword(false)}
        id={userResetPassword}
      />
      <LogoutUser
        user={logoutModal}
        onCloseUser={() => setLogoutModal(false)}
        onGet={() => setFilter({ ...filter })}
        id={logoutModal}
      />
    </div>
  );
};

export default Users;
