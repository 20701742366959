import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Pg_Web_Hook } from "../services/pgWebHookServices";

const initialState = {
  getPgWebHookState: [],
  isLoading: true,
};

export const GetPgWebHook = createAsyncThunk(
  "/support/Pg_Web_Hook",
  async (payload) => {
    return await Pg_Web_Hook(payload);
  }
);

const pgWebHookSlice = createSlice({
  name: "pgWebHookSlice",
  initialState,
  // reducers: {
  //   clearPgWebHook: () => initialState,
  // },
  extraReducers: (builder) => {
    builder.addCase(GetPgWebHook.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPgWebHook.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getPgWebHookState = action.payload;
    });
    builder.addCase(GetPgWebHook.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default pgWebHookSlice.reducer;
// export const { clearPgWebHook } = pgWebHookSlice.actions;

export const selectPgWebHookState = (state) => {
  return state.pgWebHook.getPgWebHookState;
};

export const usePgWebHook = () => {
  const getPgWebHookState = useSelector(selectPgWebHookState);
  return useMemo(() =>  getPgWebHookState , [getPgWebHookState]);
};
