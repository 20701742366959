import {
  BANK_MONITORING,
  BANK_MONITORING_BANKS,
  BANK_MONITORING_CHART,
  BANK_MONITORING_DEPOSIT_CHART,
  BANK_MONITORING_PG,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getBankMonitoring = () => {
  return apiInstance.post(BANK_MONITORING);
};

export const getBankMonitoringPg = (payload) => {
  return apiInstance.post(BANK_MONITORING_PG,payload);
};

export const getBankMonitoringBanks = () => {
  return apiInstance.post(BANK_MONITORING_BANKS);
};

export const getBankMonitoringDepositChart = (payload) => {
  return apiInstance.post(BANK_MONITORING_DEPOSIT_CHART, payload);
};

export const getBankMonitoringChart = (payload) => {
  return apiInstance.post(BANK_MONITORING_CHART, payload);
};
