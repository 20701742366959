import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Merchant_upload_File,
  Show_added_Utr,
  Statement,
} from "../services/bankstatementService";

const initialState = {
  bankstatementState: [],
  getShowAddedUtrState: [],
  isLoading: true,
  isAddedUtrLoading: true,
};
export const getStatement = createAsyncThunk(
  "/get-statement",
  async (payload) => {
    return await Statement(payload);
  }
);
export const getShowAddedUtr = createAsyncThunk(
  "/Show_added_Utr",
  async (payload) => {
    return await Show_added_Utr(payload);
  }
);
export const merchantupload = createAsyncThunk(
  "/upload-statement",
  async (payload) => {
    return await Merchant_upload_File(payload);
  }
);

const bankstatementSlice = createSlice({
  name: "bankstatementSlice",
  initialState,
  reducers: {
    clearBankStatement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStatement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankstatementState = action.payload;
    });
    builder.addCase(getStatement.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStatement.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getShowAddedUtr.fulfilled, (state, action) => {
      state.isAddedUtrLoading = false;
      state.getShowAddedUtrState = action.payload;
    });
    builder.addCase(getShowAddedUtr.pending, (state, action) => {
      state.isAddedUtrLoading = true;
    });
    builder.addCase(getShowAddedUtr.rejected, (state, action) => {
      state.isAddedUtrLoading = false;
    });

    builder.addCase(merchantupload.fulfilled, (state, action) => {
      state.isMerchantuploadLoading = false;
      state.getBankStatementState = action.payload;
    });
    builder.addCase(merchantupload.pending, (state, action) => {
      state.isMerchantuploadLoading = true;
    });
    builder.addCase(merchantupload.rejected, (state, action) => {
      state.isMerchantuploadLoading = false;
    });
  },
});

export default bankstatementSlice.reducer;
export const { clearBankStatement } = bankstatementSlice.actions;

export const selectStatement = (state) => {
  return state.statement.bankstatementState;
};

export const useStatement = () => {
  const bankstatementState = useSelector(selectStatement);
  return useMemo(() => bankstatementState, [bankstatementState]);
};

export const selectgetShowAddedUtrState = (state) => {
  return state.statement.getShowAddedUtrState;
};

export const useShowAddedUtrState = () => {
  const getShowAddedUtrState = useSelector(selectgetShowAddedUtrState);
  return useMemo(() => getShowAddedUtrState, [getShowAddedUtrState]);
};

export const selectuploadBankStatementState = (state) => {
  return state.statement.getBankStatementState;
};

export const uploadBankStatementState = () => {
  const getBankStatementState = useSelector(selectuploadBankStatementState);
  return useMemo(() => getBankStatementState, [getBankStatementState]);
};
