import { getAvatarText } from "../../../../helperFunctions";
import A from "@mui/material/Avatar";
import React from "react";
import "./Avatar.scss";

const Avatar = (props) => {
  const { name, className = "", style = {}, ...rest } = props;

  return (
    <A
      className={`${className}`}
      {...rest}
      style={{ color: "#7f56d9", backgroundColor: "#f9f5ff", ...style }}
    >
      {getAvatarText(name)}
    </A>
  );
};

export default Avatar;
