import React, { useState } from "react";

const TableCell = (props) => {
  const { className = "", style = {}, children, type, ...rest } = props;

  const [isOpen, setIsOpen] = useState(false);
  const isEllipsis = type === "ellipsis";

  return (
    <td
      className={`${className} open-padding table-cell`}
      style={
        isEllipsis
          ? isOpen
            ? {
                ...style,
                whiteSpace: "normal",
                maxWidth: 330,
                minWidth: 330,
              }
            : {
                ...style,
                maxWidth: 330,
                minWidth: 330,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }
          : {
              ...style,
              whiteSpace: "nowrap",
            }
      }
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      {...rest}
    >
      {children}
    </td>
  );
};

export default TableCell;
