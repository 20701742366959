import React, { useEffect, useState } from "react";
import {
  Payout_DownBank_Delete,
  Payout_DownBanks_Get,
  usePayoutDownBanksGetState,
} from "../../../redux/slices/withdrawalBankDownSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";
import { Table } from "../../../components/Table";
import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const WithdrawalBankDown = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.withdrawalBankDown);
  const PayoutDownBanksGetState = usePayoutDownBanksGetState();
  const [filterColumns, setFilterColumns] = useState([]);
  const role = useRole("W/D Bank Down");

  const PayoutDownBanksGet = async () => {
    try {
      let payload = {};
      await dispatch(Payout_DownBanks_Get(payload)).unwrap();
    } catch (e) {}
  };
  
  const PayoutDownBankDeleteAll = async () => {
    try {
      const res = await dispatch(Payout_DownBank_Delete()).unwrap();
      responseToaster(res);
    } catch (e) {}
  };

  useEffect(() => {
    if (role.includes("bankDown-list")) {
      PayoutDownBanksGet();
    }
  }, []);

  const columns = [
    {
      title: "BANK",
      name: "bank_name",
    },
    {
      title: "IFSC PREFIX	",
      name: "ifsc_prefix",
    },
    {
      title: "IS_DOWN",
      name: "is_down",
      Cell: (data) => {
        return (
          <>
            <span>{data?.is_down ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      title: "RECORD",
      name: "total_count",
    },
    {
      title: "AMOUNT",
      name: "total_amount",
      Cell: (data) => <>{`${seperator(data?.total_amount)}`}</>,
    },
    {
      title: "LAST DOWN AT",
      name: "last_down_at",
    },
    {
      title: "ACTION",
      name: "delete",
      Cell: (data) => {
        return <Button>Delete</Button>;
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("bankDown-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Withdrawal Bank Down</b>
            </h4>
            {role.includes("delete-payout-bank-down") ? (
              <Button color="primary" onClick={() => PayoutDownBankDeleteAll()}>
                Delete All
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={PayoutDownBanksGetState?.data}
          isData={!!PayoutDownBanksGetState?.data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {PayoutDownBanksGetState?.data?.map((item) => {
            return (
              <TableRow
                tdClassName="position-relative btncell-height"
                item={item}
                columns={columns}
                isExpandable={false}
                filterColumns={filterColumns}
              />
            );
          })}
        </Table>
      </Card>
    </>
  );
};

export default WithdrawalBankDown;
