import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PaymentMethod } from "../services/paymentMethodServices";

const initialState = {
  paymentMethodState: [],
  isLoading: true,
};

export const GetPaymentMethod = createAsyncThunk(
  "/support/GetPaymentMethods",
  async (payload) => {
    return await PaymentMethod(payload);
  }
);

const paymentMethodSlice = createSlice({
  name: "paymentMethodSlice",
  initialState,
  reducers: {
    clearPaymentMethod: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetPaymentMethod.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentMethodState = action.payload;
    });
    builder.addCase(GetPaymentMethod.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetPaymentMethod.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default paymentMethodSlice.reducer;
export const { clearPaymentMethod } = paymentMethodSlice.actions;

export const selectPaymentMethod = (state) => {
  return state.paymentMethod.paymentMethodState;
};

export const usePaymentMethod = () => {
  const paymentMethodState = useSelector(selectPaymentMethod);
  return useMemo(() => ({ paymentMethodState }), [paymentMethodState]);
};
