import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { resellerDeletId } from "../../../redux/slices/resellerSlice";
import { responseToaster } from "../../../helperFunctions";

const ResellerDelet = (props) => {
  const { onHide, onGet, isOpen } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const ResetPassword = async () => {
    try {
      setIsButtonLoading(true);
      let response = await dispatch(
        resellerDeletId({ resellerId: isOpen?.reseller_id })
      ).unwrap();
      responseToaster(response);
      if (!!response) {
        onHide();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      onHide();
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onHide} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Delete Reseller !</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12}>
            <div className="">
              <p>Are you sure you want to delete reseller ?</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onHide()}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={ResetPassword}
            isButtonLoading={isButtonLoading}
          >
            Delete
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default ResellerDelet;
