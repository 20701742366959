import Box from "@mui/material/Box";
import HorizontalNavItems from "./HorizontalNavItems";
import LogoutConfirmModal from "../../../Custom/Modals/LogoutConfirmModal/LogoutConfirmModal";
import { useState } from "react";
import LogoutIcon from '../../../../assets/images/pages/logout-icon.svg'

const Navigation = (props) => {
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);

  const onToggleModal = () => {
    setIsOpenLogoutModal(!isOpenLogoutModal);
  };

  return (
    <>
      <Box
        className="menu-content"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          fontSize: 14,
          "& > *": {
            "&:not(:last-child)": { mr: 1 },
          },
        }}
      >
        <HorizontalNavItems {...props} />
      </Box>
      <div className="logoutsec">
        <div onClick={onToggleModal} className="cursor-pointer text-nowrap d-flex align-items-center gap">
          <a>LOGOUT</a>
          <img src={LogoutIcon} alt="" className="img-fluid"/>
        {/* <i class="fa-solid fa-angle-right"></i> */}
        </div>
      </div>

      <LogoutConfirmModal
        isOpenLogoutModal={isOpenLogoutModal}
        onCloseModal={onToggleModal}
      />
    </>
  );
};

export default Navigation;
