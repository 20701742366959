import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { dateFormatter, onFixed, responseToaster } from "../../../helperFunctions";
import { resellerSettlementAdd } from "../../../redux/slices/resellerSlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";

const typeOptions = [
  { value: "USDT", label: "USDT" },
  { value: "CASH", label: "CASH" },
  { value: "BANK", label: "BANK" },
  // { value: "Currency", label: "Currency" },
];

const usdtOptions = [
  {
    value: "TRC",
    label: "TRC",
  },
  {
    value: "ERC",
    label: "ERC",
  },
];
const bankCashOptions = [
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "AED",
    label: "AED",
  },
];

const subTypeOptions = {
  USDT: usdtOptions,
  CASH: bankCashOptions,
  BANK: bankCashOptions,
};

const AddToSettlementModal = (props) => {
  const { onHide, isOpen, onGet, options } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    resellerId: "",
    releaseAmt: "",
    type: "BANK",
    subType: "INR",
    transactionId: "",
    date: null,
    value: 0,
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        ...values,
        date: dateFormatter(values?.date, "start"),
        settlementAmt: prepareAmount(),
      };
      const res = await dispatch(resellerSettlementAdd(payload)).unwrap();
      responseToaster(res);
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    handleChange,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const onClose = () => {
    resetForm();
    onHide();
  };

  const prepareAmount = () =>
    values?.value ? onFixed(+values?.value * +values?.releaseAmt) : +values?.releaseAmt;

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={isOpen}
      centered={true}
      toggle={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">New Settlement</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <DatePicker
            label="Date"
            maxDate={new Date()}
            placeholder="Select Date"
            selected={values?.date}
            onChange={(selectedDate) => {
              setFieldValue("date", selectedDate);
            }}
          />
          <DropDown
            name="resellerId"
            label="Reseller ID"
            isDefaultOption={false}
            placeholder="Select Reseller ID"
            options={options()}
            value={values.resellerId}
            onChange={(e) => {
              setFieldValue("resellerId", e.value);
            }}
          />
          <div className="d-flex gap w-100">
            <DropDown
              className="w-100"
              name="type"
              label="Type"
              isDefaultOption={false}
              options={typeOptions}
              value={values.type}
              onChange={(e) => {
                setValues({
                  ...values,
                  type: e.value,
                  subType: "",
                });
              }}
            />
            <DropDown
              className="w-100"
              name="subType"
              label="Mode"
              isDefaultOption={false}
              options={subTypeOptions?.[values?.type] || []}
              value={values.subType}
              onChange={(e) => {
                setValues({
                  ...values,
                  subType: e.value,
                  value: e.value !== "INR" ? values?.value : 0,
                });
              }}
            />
          </div>
          <InputField
            type="number"
            name="releaseAmt"
            label="Release Amount"
            value={values?.releaseAmt}
            onChange={handleChange}
            placeholder="Enter Release Amount"
          />

          <div className="d-flex gap w-100">
            <InputField
              name="value"
              className="w-100"
              disabled={!values?.subType || values?.subType === "INR"}
              label={`Per ${values?.subType}/INR`}
              value={values?.value}
              onChange={handleChange}
              placeholder={`Enter Per ${values?.subType}/INR`}
            />
            <InputField
              name="value"
              className="w-100"
              disabled
              label={`Final Settlement Amount (INR)`}
              value={prepareAmount()}
              onChange={handleChange}
              placeholder={`Enter Final Settlement Amount (INR)`}
            />
          </div>
          <InputField
            name="transactionId"
            label="Transaction Id"
            value={values?.transactionId}
            onChange={handleChange}
            placeholder="Enter Transaction Id"
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddToSettlementModal;
