import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import {
  getPayinReqResLogs,
  usePayinReqResLogs,
} from "../../../redux/slices/payinReqResLogsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo } from "react";

import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import {
  TRA_FILTER,
  SUPPORT_LOG_LOCAL,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import {
  getLocalData,
  responseToaster,
  setLocalData,
} from "../../../helperFunctions";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import PayinReqResLogsModal from "../../../components/Custom/Modals/PayinReqResLogsModal/PayinReqResLogsModal";
import "./PayinReqResLogs.scss";
import { useRole } from "../../../redux/slices/authSlice";

const PayinReqResLogs = () => {
  const initialValues = {
    searchdata: "",
  };
  const [filter, setFilter] = useState(TRA_FILTER);
  const [filterColumns, setFilterColumns] = useState([]);
  const { isLoading } = useSelector((state) => state.payinReqResLogs);
  const { payinReqResLogsState } = usePayinReqResLogs();
  const [data, setData] = useState({});
  const [isViewsendDataModal, setIsViewsendDataModal] = useState(false);

  const dispatch = useDispatch();
  const role = useRole("Payin Req Res Logs");

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleModal = (data) => {
    setData(data);
    setIsViewsendDataModal(!isViewsendDataModal);
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        name: "payinId",
      },
      {
        title: "REQUEST METHOD",
        name: "requestMethod",
      },
      {
        title: "REQUEST URL",
        name: "requestUrl",
      },

      {
        title: "SECONDS",
        name: "seconds ",
        Cell: (data) => {
          return (
            <>
              <span>{data?.seconds || ""}</span>
            </>
          );
        },
      },
      {
        title: "RESPONSE HTTP CODE",
        name: "responseHttpCode ",
        Cell: (data) => {
          return (
            <>
              <span
                className={
                  data?.responseHttpCode > 200
                    ? "badge badge-danger"
                    : "badge badge-success"
                }
              >
                {data?.responseHttpCode || ""}
              </span>
            </>
          );
        },
      },
      {
        title: "CREATED AT",
        name: "createdAtIst",
      },
      {
        title: "DETAILS",
        name: "success_txn_per",
        Cell: (data) => {
          return (
            <>
              <Button
                color="primary"
                className="btn-smallsize"
                style={{ backgroundColor: "var(--chatlook-darkblue--)" }}
                onClick={() => handleModal(data)}
              >
                Details
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  const GetPayinReqResLogs = async () => {
    try {
      const { isFilter, page_no, limit, date, searchdata, ...rest } = filter;
      const payload = isFilter
        ? {
            filter_data: {
              searchdata,
              ...rest,
            },
            page_no,
            limit,
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(getPayinReqResLogs(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("payinRequestResponseLog-list")) {
      GetPayinReqResLogs();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(SUPPORT_LOG_LOCAL, false)
  );
  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(SUPPORT_LOG_LOCAL, !isFilterOpen);
  };

  return (
    <>
      {role.includes("payinRequestResponseLog-list") ? (
        <>
          <div className="payinReqResLogs-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Payin Req Res Logs</b>
                    </h4>
                  </div>

                  <div className="d-flex">
                    <Button
                      type="button"
                      className="filterbtn me-1"
                      onClick={toggleIsFilterOpen}
                    >
                      <i className="fa-solid fa-filter"></i> Apply Filter
                    </Button>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {isFilterOpen && (
                    <>
                      <ControlledInput
                        placeholder="Search by Payin Id"
                        name="searchdata"
                        label="Search"
                        control={control}
                      />
                      <Button type="submit" color="primary">
                        Apply
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => {
                          reset();
                          setFilter({ ...TRA_FILTER });
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                </Form>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={payinReqResLogsState?.data || []}
                isData={!!payinReqResLogsState?.data?.length}
                filterColumns={filterColumns}
                count={payinReqResLogsState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {payinReqResLogsState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
            <PayinReqResLogsModal
              data={data}
              isViewsendDataModal={isViewsendDataModal}
              onCloseModal={() => handleModal()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default PayinReqResLogs;
