import React, { useRef } from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import Loader from "../Custom/Loader";
import Pagination from "./Pagination";
import { LIMIT, PAGINATION_OPTIONS } from "../../constant";
import { sortByKey } from "../../helperFunctions";
import "./Table.scss";

const Table = (props) => {
  const {
    className = "",
    style = {},
    children,
    columns = [],
    filterColumns = [],
    count = 0,
    isLoading = false,
    data = [],
    isData = false,
    config,
    bottomspace = "pb-3",
    isExpandable = true,
    onColumnsChange,
    setData,
    minHeight = 500,
    maxHeight = "unset",
    pagination,
    handlePaginationChange,
    paginationOptions = PAGINATION_OPTIONS,
    tableBodyRef
  } = props;
  // const tableHeaderRef = useRef(null);

  return (
    <div className="position-relative shdow shadow-sm bg-white">
      <div
        className={`main-table border ${bottomspace}`}
        style={{
          minHeight: minHeight,
          maxHeight: maxHeight,
        }}
      >
        <table
          // ref={tableHeaderRef}
          className={`${className} w-100 h-100 table `}
         
          style={{
            ...style,
          }}
        >
          <TableHeader
            columns={columns}
            config={config}
            filterColumns={filterColumns}
            onColumnsChange={onColumnsChange}
            isExpandable={isExpandable}
            onSort={(column, sorting) => {
              if (sorting === "AESC" || sorting === "DESC") {
                setData(sortByKey(data, column?.sortableKey, sorting));
              } else if (sorting === "DATE") {
                setData(data);
              }
            }}
          />
          <TableBody  reff={tableBodyRef}>
            {isLoading ? (
              <div className="table-loader">
                <Loader />
              </div>
            ) : isData ? (
              children
            ) : (
              <div className="table-loader text-center">No data found</div>
            )}
          </TableBody>
          {pagination ? (
            <div className="table-pagination">
              <Pagination
                page_no={pagination?.page_no || 1}
                limit={pagination?.limit || LIMIT}
                count={count || 0}
                handleRowChange={(limit) => {
                  handlePaginationChange({ ...pagination, limit, page_no: 1 });
                }}
                handlePageChange={(page_no) => {
                  window.scrollTo({ top: 0, behavior: "instant" });
                  handlePaginationChange({ ...pagination, page_no });
                }}
                paginationOptions={paginationOptions}
              />
            </div>
          ) : null}
        </table>
      </div>
    </div>
  );
};

export default Table;
