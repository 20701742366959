import React, { useState } from "react";
import { Button, Input, Label, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const UpdateTransactionModal = (props) => {
  const { isOpen, onCancelClick, onDoneClick, isButtonLoading } = props;

  const [text, setText] = useState("");
  return (
    <Modal isOpen={!!isOpen} toggle={onCancelClick} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Set Payment ID</h3>
        <i
          onClick={() => {
            onCancelClick();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div className="select-div">
          <Label>Payment Ref</Label>
          <Input
            type="text"
            placeholder="Enter Payment Ref"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onCancelClick?.();
              setText("");
            }}
          >
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={() => {
              onDoneClick(text);
              setText("");
            }}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateTransactionModal;
