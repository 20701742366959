import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Delete_Payout_DownBank,
  Get_Payout_DownBanks,
} from "../services/withdrawalBankDownSrevices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  isLoading: true,
  PayoutDownBanksGetState: [],
};

export const Payout_DownBanks_Get = createAsyncThunk(
  "getpayoutdownBanks",
  async (payload) => {
    return await Get_Payout_DownBanks(payload);
  }
);
export const Payout_DownBank_Delete = createAsyncThunk(
  "Delete_Payout_DownBank",
  async (payload) => {
    return await Delete_Payout_DownBank(payload);
  }
);

const withdrawalBankDownSlice = createSlice({
  name: "withdrawalBankDownSlice",
  initialState,
  reducers: {
    clearWithdrawalBankDown: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(Payout_DownBanks_Get.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(Payout_DownBanks_Get.fulfilled, (state, action) => {
      state.isLoading = false;
      state.PayoutDownBanksGetState = action.payload;
    });
    builder.addCase(Payout_DownBanks_Get.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default withdrawalBankDownSlice.reducer;
export const { clearWithdrawalBankDown } = withdrawalBankDownSlice.actions;

export const selectPayoutDownBanksGetState = (state) => {
  return state.withdrawalBankDown.PayoutDownBanksGetState;
};

export const usePayoutDownBanksGetState = () => {
  const PayoutDownBanksGetState = useSelector(selectPayoutDownBanksGetState);
  return useMemo(() => PayoutDownBanksGetState, [PayoutDownBanksGetState]);
};
