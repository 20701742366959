import React from "react";
import { Button, Col, Modal as RModal, Row } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const Modal = (props) => {
  const {
    isOpen,
    onCancelClick,
    onDoneClick,
    title,
    content,
    isLoading,
    doneText = "Submit",
    cancelText = "Cancel",
  } = props;

  return (
    <RModal isOpen={!!isOpen} toggle={onCancelClick} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">{title}</h3>
        <i
          onClick={() => {
            onCancelClick();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {typeof content === "string" ? <p>{content}</p> : content}
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onCancelClick}>
            {cancelText}
          </Button>
          <CustomeButton
            isButtonLoading={isLoading}
            onClick={onDoneClick}
            className="submitbutton"
          >
            {doneText}
          </CustomeButton>
        </div>
      </div>
    </RModal>
  );
};

export default Modal;
