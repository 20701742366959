import React, { useState } from "react";
import { CiLock } from "react-icons/ci";
import OTPInput from "../../components/Custom/Forms/OtpInput/OTPInput";
import { Button, Modal } from "reactstrap";
import { Logout, setAuthToken, verifyotp } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responseToaster, verifyingUser } from "../../helperFunctions";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useClearData } from "../../utility/hooks";
import Loader from "../../components/Custom/Loader";


const AuthVerifyOtp = (isOpen, onHide) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [triggerVibrate, setTriggerVibrate] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)
  const dispatch = useDispatch();

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    if (newOtp.length === 6) {
      setIsValid(true);
      setTriggerVibrate(false);
    }
  };

  if (!isOpen) return null;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      setIsValid(false);
      setTriggerVibrate(true);
      return;
    }

    try {
      setIsLoading(true)
      const payload = {
        one_time_password: otp,
      };

      const res = await dispatch(verifyotp(payload)).unwrap();
      if (res?.status) {
        dispatch(setAuthToken(res?.token));
        verifyingUser(dispatch, null);
        navigate(res?.firstRoute);
        // window.location.reload();
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("error", error);
    }
  };

  const userlogout = async () => {
    try {
      setIsLoadingLogout(true)
      const res = await dispatch(Logout()).unwrap();
      navigate("/login");
      // responseToaster(res);
      useClearData(false);
      setIsLoadingLogout(false)
    } catch (err) {
      setIsLoadingLogout(false)
      // onCloseModal();
    }
  };
  const onClose = () => {
    userlogout()
  };

  return (
    <Modal
      className="update-manual-bank-modal"
      isOpen={isOpen}
      centered={true}
    >
      <div className="modal-header">
        <div className=" d-flex gap-50 align-items-center"><CiLock size={24} color="#5e5873" />
          <h3 className="modal-title mt-0"><b>Authenticate your account</b></h3>
        </div>
        {isLoadingLogout ? <Loader /> :
          <i onClick={onClose} className="fa-solid fa-xmark"></i>}
      </div>

      <form onSubmit={onSubmit}>
        <div className="modal-body border-b-0 py-3">
          <OTPInput
            numInputs={6}
            onOtpChange={handleOtpChange}
            isValid={isValid}
            triggerVibrate={triggerVibrate}
          />
        </div>
        <div className="d-flex justify-content-end p-2 button-space">
          <CustomeButton
            type="submit"
            className="submitbutton"
            isButtonLoading={isLoading}
          >
            Submit
          </CustomeButton>

        </div>
      </form>
    </Modal>
  );
};

export default AuthVerifyOtp;
