import React, { Fragment, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ToggleSwitch from "../Custom/Forms/ToggleSwitch/ToggleSwitch";

const TableHeader = (props) => {
  const {
    className = "",
    style = {},
    config,
    columns = [],
    filterColumns = [],
    onSort,
    onColumnsChange,
    isExpandable = true,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (name) => {
    setIsOpen((isOpen) => (isOpen ? false : name));
  };

  return (
    <thead
      className={`${className} table-header`}
      style={{ ...style, zIndex: 1 }}
    >
      <tr>
        {isExpandable ? (
          <th className="p-10 no-wrap heading-color">
            <i class="fa-solid fa-bars"></i>
          </th>
        ) : null}
        {columns?.map((column, index) => {
          if (
            !filterColumns?.includes(column?.name) ||
            (config && !config?.show_columns?.includes(column?.checkKey))
          ) {
            return <Fragment key={index}></Fragment>;
          }
          return (
            <>
              <th key={index} className="p-10 no-wrap heading-color">
                <div className="d-flex justify-content-between ">
                  {column?.title}
                  {column?.sortable ? (
                    <Dropdown
                      isOpen={isOpen === column?.name}
                      toggle={() => {
                        toggleDropdown(column?.name);
                      }}
                    >
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent "
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <div className="d-flex flex-column text-capitalize">
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              onSort(column, "AESC");
                              setIsOpen(false);
                            }}
                          >
                            Sort By AESC
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              onSort(column, "DESC");
                              setIsOpen(false);
                            }}
                          >
                            Sort By DESC
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              onSort(column, "DATE");
                              setIsOpen(false);
                            }}
                          >
                            Sort By DATE
                          </span>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
              </th>
            </>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
