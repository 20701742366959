import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Request_Response_Logs } from "../services/requestResponseLogsServices";

const initialState = {
  getRequestResponseLogsState: [],
  isLoading: true,
};

export const GetRequestResponseLogs = createAsyncThunk(
  "/support/Request_Response_Logs",
  async (payload) => {
    return await Request_Response_Logs(payload);
  }
);

const requestResponseLogsSlice = createSlice({
  name: "requestResponseLogsSlice",
  initialState,
  // reducers: {
  //   clearRequestResponseLogs: () => initialState,
  // },
  extraReducers: (builder) => {
    builder.addCase(GetRequestResponseLogs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRequestResponseLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getRequestResponseLogsState = action.payload;
    });
    builder.addCase(GetRequestResponseLogs.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default requestResponseLogsSlice.reducer;
// export const { clearRequestResponseLogs } = requestResponseLogsSlice.actions;

export const selectRequestResponseLogsState = (state) => {
  return state.requestResponseLogs.getRequestResponseLogsState;
};

export const useRequestResponseLogs = () => {
  const getRequestResponseLogsState = useSelector(selectRequestResponseLogsState);
  return useMemo(() =>  getRequestResponseLogsState , [getRequestResponseLogsState]);
};
