import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

const withdrawalClientsSlice = createSlice({
  name: "withdrawalClientsSlice",
  initialState,
  reducers: {
    clearWithdrawalClients: () => initialState,
  },
  extraReducers: (builder) => {},
});
export default withdrawalClientsSlice.reducer;
export const { clearWithdrawalClients } = withdrawalClientsSlice.actions;
