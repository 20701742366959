import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { readScreenshot, updateStatusScreenshot } from "../services/screenshotSupportService";

const initialState = {
  data: {},
};

export const screenshotRead = createAsyncThunk(
  "screenshotRead",
  async (payload) => {
    return await readScreenshot(payload);
  }
);

export const updateScreenshotStatus = createAsyncThunk(
  "updateScreenshotStatus",
  async (payload) => {
    return await updateStatusScreenshot(payload);
  }
);

const screenshotSlice = createSlice({
  name: "screenshotSlice",
  initialState,
  reducers: {
    clearScreenshot: () => initialState,
  },
});
export default screenshotSlice.reducer;
export const { clearScreenshot } = screenshotSlice.actions;
