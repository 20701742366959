import {
  GET_PGPAYIN_DEPOSIT_SUMMARY,
  GET_PGPAYIN_WITHDRAWAL_SUMMARY,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Get_PgPayin_Deposit_Summary = (payload) => {
  return apiInstance.post(GET_PGPAYIN_DEPOSIT_SUMMARY, payload);
};

export const Get_PgPayin_Withdrawal_Summary = (payload) => {
  return apiInstance.post(GET_PGPAYIN_WITHDRAWAL_SUMMARY, payload);
};
