import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal} from "reactstrap";
import { Initialized_Payout_Cancelled } from "../../../../redux/slices/payoutSlice";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../../helperFunctions";

const CancelPayoutModal = (props) => {
  const { onHide, isOpen, onGet } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const Payout_Cancelled = async () => {
    try {
      setIsButtonLoading(true);
      let Payload = {
        withdrawalId: isOpen,
      };
      const res = await dispatch(
        Initialized_Payout_Cancelled(Payload)
      ).unwrap();

      responseToaster(res);
      if (!!res) {
        onHide();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={!!isOpen} toggle={() => onHide()} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Cancel Request</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>
            Are you sure you want to cancel this withdraw request(
            {isOpen})
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onHide()}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={Payout_Cancelled}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default CancelPayoutModal;
