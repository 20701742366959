import {
    REPORT_CHART_DEPOSIT_CHART,
    REPORT_CHART_WITHDRAWAL_CHART
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getReportAndChartDepositChart = (payload) => {
    return apiInstance.post(REPORT_CHART_DEPOSIT_CHART, payload);
};
export const getReportAndChartWithdrawalChart = (payload) => {
    return apiInstance.post(REPORT_CHART_WITHDRAWAL_CHART, payload);
};
