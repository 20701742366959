import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Support } from "../services/supportService";

const initialState = {
  supportState: [],
  isLoading: true,
};
export const getSupport = createAsyncThunk(
  "/support/GetSupportLogs",
  async (payload) => {
    return await Support(payload);
  }
);

const supportSlice = createSlice({
  name: "supportSlice",
  initialState,
  reducers: {
    clearSupport: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSupport.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSupport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supportState = action.payload;
    });
    builder.addCase(getSupport.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default supportSlice.reducer;
export const { clearSupport } = supportSlice.actions;

export const selectSupport = (state) => {
  return state.support.supportState;
};

export const useSupport = () => {
  const supportState = useSelector(selectSupport);
  return useMemo(() => ({ supportState }), [supportState]);
};
