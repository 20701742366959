// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import DatePicker from "../DatePicker/DatePicker";

const ControlledDatePicker = (props) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, ...restt } }) => {
        return (
          <DatePicker
            {...restt}
            selected={!Array.isArray(value) ? value : null}
            startDate={Array.isArray(value) ? value?.[0] : null}
            endDate={Array.isArray(value) ? value?.[1] : null}
            {...rest}
          />
        );
      }}
    />
  );
};

export default ControlledDatePicker;
