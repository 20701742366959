import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addWithdrawalCredit,
  deleteWithdrawalCredit,
  editWithdrawalCredit,
  getGatewayPayoutStatement,
  getWithdrawalCredit,
  readWithdrawalCredit,
} from "../services/withdrawalCreditServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  withdrawalCreditState: {},
  withdrawalCreditState: [],
  withdrawalCreditIsLoading: false,
  addWithdrawalLoading: false
};

export const withdrawalCreditRead = createAsyncThunk("withdrawalCreditRead", async (payload) => {
  return await readWithdrawalCredit(payload);
});
export const withdrawalCreditGet = createAsyncThunk(
  "getWithdrawalCredit",
  async (payload) => {
    return await getWithdrawalCredit(payload);
  }
);

export const EditWithdrawalCredit = createAsyncThunk(
  "/Edit_withdrawal_credit",
  async (payload) => {
    return await editWithdrawalCredit(payload);
  }
);
export const AddWithdrawalCredit = createAsyncThunk(
  "AddWithdrawalCredit",
  async (payload) => {
    return await addWithdrawalCredit(payload);
  }
);

export const DeleteWithdrawalCredit = createAsyncThunk(
  "/deleteWithdrawalCredit",
  async (payload) => {
    return await deleteWithdrawalCredit(payload);
  }
);

const withdrawalCreditSlice = createSlice({
  name: "withdrawalCreditSlice",
  initialState,
  reducers: {
    clearWithdrawalCredit: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(withdrawalCreditGet.pending, (state, action) => {
      state.withdrawalCreditIsLoading = true;
    });
    builder.addCase(withdrawalCreditGet.fulfilled, (state, action) => {
      state.withdrawalCreditIsLoading = false;
      state.withdrawalCreditState = action.payload;
    });
    builder.addCase(withdrawalCreditGet.rejected, (state, action) => {
      state.withdrawalCreditIsLoading = false;
    });
    builder.addCase(AddWithdrawalCredit.pending, (state, action) => {
      state.addWithdrawalLoading = true;
    });
    builder.addCase(AddWithdrawalCredit.fulfilled, (state, action) => {
      state.addWithdrawalLoading = false;
      state.withdrawalCreditState = action.payload;
    });
    builder.addCase(AddWithdrawalCredit.rejected, (state, action) => {
      state.addWithdrawalLoading = false;
    });
  },
});
export default withdrawalCreditSlice.reducer;
export const { clearWithdrawalCredit, withdrawalCreditIsLoading } = withdrawalCreditSlice.actions;

export const selectWithdrawalCreditState = (state) =>
  state.withdrawalCredit.withdrawalCreditState;

export const useWithdrawalCreditState = () => {
  const withdrawalCreditState = useSelector(selectWithdrawalCreditState);
  return useMemo(() => withdrawalCreditState, [withdrawalCreditState]);
};

export const selectWithdrawalCreditPaymentState = (state) =>
  state.withdrawalCredit.withdrawalCreditState;

export const useGatewayPaymentStatementState = () => {
  const withdrawalCreditState = useSelector(selectWithdrawalCreditPaymentState);
  return useMemo(() => withdrawalCreditState, [withdrawalCreditState]);
};

