import { READ_SCREENSHOT, UPDATE_SCREENSHOT_STATUS } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const readScreenshot = (payload) => {
  return apiInstance.post(READ_SCREENSHOT,payload);
};

export const updateStatusScreenshot = (payload) => {
  return apiInstance.post(UPDATE_SCREENSHOT_STATUS, payload);
};
