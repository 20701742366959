// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import DropDown from "../DropDown/DropDown";

const ControlledDropDown = (props) => {
    const { control, name, ...rest } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, onChange, ...restt } }) => {
                return <DropDown {...restt} onChange={(e, option) => onChange?.(e?.value, option)} {...rest} />;
            }}
        />
    );
};

export default ControlledDropDown;
