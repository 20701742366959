import React, { useEffect, useState } from "react";
import {
  dashboardWithdrawalChartBank,
  useGetWithChartBankState,
} from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Custom/Loader";
import { Modal } from "reactstrap";
import { Table, TableCell, TableRow } from "../../../components/Table";
import { FILTER } from "../../../constant";
import { dateFormatter } from "../../../helperFunctions";

const ChartDetailsWdModal = (props) => {
  const { isOpen, onHide, hourForWd, chartFilter } = props;
  const { getWithChartBankLoading } = useSelector((state) => state.dashboard);
  const getWithChartBankState = useGetWithChartBankState();
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const dispatch = useDispatch();

  const onClose = () => {
    onHide();
    setFilter(FILTER);
  };

  const dashboardWithdrawalChartBankGet = async () => {
    const {
      isCall,
      isLast4,
      startDate,
      endDate,
      custLevel,
      isFilter,
      ...rest
    } = chartFilter;
    const { page_no, limit } = filter;
    try {
      const payload = {
        filter_data: rest,
        startDate: dateFormatter(startDate, "start"),
        endDate: dateFormatter(endDate, "end", startDate),
        chartHours: hourForWd,
        limit,
        page_no,
      };
      await dispatch(dashboardWithdrawalChartBank(payload)).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (isOpen) {
      dashboardWithdrawalChartBankGet();
    }
  }, [isOpen, filter]);

  const columns = [
    {
      title: "BANK NAME",
      name: "bankName",
      // Cell: (data) => {
      //   return (
      //     <div>
      //       {data?.bankName} - {data?.pgLabel}
      //     </div>
      //   );
      // },
    },
    {
      title: "AC NUMBER",
      name: "acNumber",
    },
    {
      title: "TRANSACTION",
      name: "chart_count",
      Cell: (data) => {
        return (
          <div>
            <span
              style={{
                color: "green",
              }}
            >
              {`${data?.total_failed_txn || 0} `}
            </span>
            /
            <span
              style={{
                color: "var(--chatlook-darkblue--)",
              }}
            >
              {` ${data?.chart_count || 0}`}
            </span>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
    >
      <div className="max-h-modal">
        <div className="modal-header">
          <h3 className="modal-title mt-0">Bank Details</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          {getWithChartBankLoading ? (
            <div
              className="loader-div"
              style={{ color: "gray", background: "white" }}
            >
              <Loader />
            </div>
          ) : (
            <Table
              columns={columns}
              filterColumns={filterColumns}
              isLoading={getWithChartBankLoading}
              data={getWithChartBankState?.data || []}
              isData={getWithChartBankState?.data?.length > 0}
              count={getWithChartBankState?.total_item || 0}
              pagination={filter}
              isExpandable={false}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                });
              }}
              onColumnsChange={(columns) => {
                setFilterColumns(columns);
              }}
              className="mb-0 p-0"
            >
              {getWithChartBankState?.data?.map((item) => {
                return (
                  <TableRow
                    columns={columns}
                    filterColumns={filterColumns}
                    item={item}
                    isExpandable={false}
                  />
                );
              })}
              <tr
                style={{
                  borderWidth: 2,
                  borderColor: "black",
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <div>
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {`${getWithChartBankState?.totalFailed || 0} `}
                    </span>
                    /
                    <span
                      style={{
                        color: "var(--chatlook-darkblue--)",
                      }}
                    >
                      {` ${getWithChartBankState?.totalCount || 0}`}
                    </span>
                  </div>
                </TableCell>
              </tr>
            </Table>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChartDetailsWdModal;
