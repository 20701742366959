import React, { useState } from "react";
import {
  get_merchant_list,
  get_bank_ticket_summary,
} from "../../../redux/slices/payinPayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { Table, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { Merchant_Pay_In_Available_Payment_Get } from "../../../redux/slices/merchantSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useForm } from "react-hook-form";
import { DROPDOWN_ALL_VALUE } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const BankTicketSummery = () => {
  const dispatch = useDispatch();
  const { getBankTicketSummaryLoading, getBankTicketSummaryState } =
    useSelector((state) => state.payinPayout);

  const { merchantPayInAvailablePaymentState } = useSelector(
    (state) => state.merchant
  );

  const ticketSizeOptions = [
    { value: "500", label: "500" },
    { value: "1000", label: "1000" },
    { value: "5000", label: "5000" },
    { value: "15000", label: "15000" },
    { value: "50000", label: "50000" },
    { value: "100000", label: "100000" },
  ];

  const prepareBanklistOptions = () => {
    const opttions = merchantPayInAvailablePaymentState?.data?.manual
      ?.slice()
      ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
      ?.map((val) => ({
        value: val?.accountId,
        label: `${val?.bankName} - ${val?.pgLabel} - ${val?.acNumber}`,
      }));
    return opttions;
  };
  const initialValues = {
    date: [new Date(), new Date()],
    isFilter: false,
    bankId: DROPDOWN_ALL_VALUE,
    tiketSize: ticketSizeOptions?.[0]?.value,
  };

  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const role = useRole("Ticket Summary");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "TXN RANGE",
      name: "txnRange",
      Cell: (data) => {
        const range = data?.txnRange?.split("-")?.map((val) => {
          return val;
        });

        return (
          <div className="">
            <span>{`${seperator(range?.[0], false)} - ${seperator(
              range?.[1],
              false
            )}`}</span>
          </div>
        );
      },
    },
    {
      title: "TOTAL TXN",
      name: "totalTxn",
      Cell: (data) => {
        return (
          <>
            <span>{`${seperator(data?.totalTxn, false)}`}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS TXN",
      name: "success_txn_count",
      Cell: (data) => {
        return (
          <>
            <span>{`${seperator(data?.success_txn_count, false)}`}</span>
          </>
        );
      },
    },
    {
      title: "TOTAL PAYMENT AMT",
      name: "totalPaymentAmt",
      Cell: (data) => {
        return (
          <>
            <span>{`${seperator(data?.totalPaymentAmt)}`}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS PAYMENT AMOUNT",
      name: "success_payment_amount",
      Cell: (data) => {
        return (
          <>
            <span>{`${seperator(data?.success_payment_amount)}`}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS RATE",
      name: "success_txn_per",
      Cell: (data) => {
        return (
          <>
            <span>{onFixed(data?.success_txn_per)}%</span>
          </>
        );
      },
    },
  ];

  const Get_ticket_summary = async () => {
    try {
      const { isFilter, date, bankId, ...rest } = filter;
      const payload = {
        startDate: dateFormatter(date?.[0], "start"),
        endDate: dateFormatter(date?.[1], "end", date?.[0]),
        ...rest,
        filterData: {
          bankId: bankId,
        },
      };
      const res = await dispatch(get_bank_ticket_summary(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("ticketSummary-list")) {
      Get_ticket_summary();
    }
  }, [filter]);

  const Get_merchant_list = async () => {
    try {
      await dispatch(get_merchant_list()).unwrap();
    } catch (err) {}
  };

  const onGetAvailablePayment = async () => {
    try {
      await dispatch(Merchant_Pay_In_Available_Payment_Get()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    Get_merchant_list();
    onGetAvailablePayment();
  }, []);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("ticketSummary-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Bank Ticket Summary</b>
                    </h4>
                  </div>

                  <div className="d-flex">
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledDropDown
                    name="tiketSize"
                    label="Bank Ticket Size"
                    isDefaultOption={false}
                    options={ticketSizeOptions}
                    control={control}
                  />

                  <ControlledDropDown
                    label="Bank Name"
                    id="merchant_id"
                    name="bankId"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareBanklistOptions()}
                    control={control}
                  />
                  <ControlledDatePicker
                    name="date"
                    maxDate={new Date()}
                    placeholder="Select Date"
                    selectsRange
                    label="Date"
                    control={control}
                  />
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      setFilter({ ...initialValues });
                      reset();
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={getBankTicketSummaryLoading}
                data={getBankTicketSummaryState?.summary || []}
                isData={!!getBankTicketSummaryState?.summary?.length}
                filterColumns={filterColumns}
                isExpandable={false}
              >
                {getBankTicketSummaryState?.summary?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankTicketSummery;
