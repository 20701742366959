import { Mobile_Sync_Get } from "../../../redux/slices/dashboardSlice";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { Card, CardHeader } from "reactstrap";
import "./MobileSync.scss";
import { useRole } from "../../../redux/slices/authSlice";

const MobileSync = () => {
  const dispatch = useDispatch();
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const { mobileSyncGetState, mobileSyncGetLoading } = useSelector(
    (state) => state.dashboard
  );
  const role = useRole("Mobile Sync");

  const mobile_Sync_Get = async () => {
    try {
      const { isFilter, ...payload } = filter;
      await dispatch(Mobile_Sync_Get(payload)).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role.includes("mobileSync-list")) {
      mobile_Sync_Get();
    }
  }, [filter]);

  const columns = [
    {
      title: "#",
      name: "",
      Cell: (data) => {
        return (
          <>
            <div className="">
              <span
                className={`${
                  data?.finalSuccessMindeffIstAgo > 15
                    ? "mo-red-light"
                    : data?.finalSuccessMindeffIstAgo <= 15 &&
                      data?.finalSuccessMindeffIstAgo > 2
                    ? "mo-orange-light"
                    : "mo-green-light"
                }`}
              ></span>
            </div>
          </>
        );
      },
    },
    {
      title: "HARDWARE ID",
      name: "hardwareId",
    },
    {
      title: "ACCOUNT NUMBER",
      name: "acNumber",
    },
    {
      title: "BATTERY",
      name: "Cell",
    },
    {
      title: "LAST SYNC DATE",
      name: "finalSyncDateIst",
    },
    {
      title: "LAST SYNC AGO",
      name: "finalSuccessMindeffIst",
    },
    {
      title: "CREATED AT",
      name: "created_at_ist",
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      {role.includes("mobileSync-list") ? (
        <>
          <Card>
            <CardHeader className="flex-column  align-items-start">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div>
                  <h4 className="main-title">
                    <b>Mobile Sync</b>
                  </h4>
                </div>
              </div>
            </CardHeader>
            <Table
              columns={columns}
              filterColumns={filterColumns}
              isLoading={mobileSyncGetLoading}
              data={mobileSyncGetState?.data || []}
              isData={mobileSyncGetState?.data?.length > 0}
              count={mobileSyncGetState?.total_item || 0}
              pagination={filter}
              isExpandable={false}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                });
              }}
              onColumnsChange={(columns) => {
                setFilterColumns(columns);
              }}
            >
              {mobileSyncGetState?.data?.map((item) => {
                return (
                  <TableRow
                    tdClassName={`${
                      item?.finalSuccessMindeffIstAgo > 15
                        ? "pinkcolor"
                        : item?.finalSuccessMindeffIstAgo <= 15 &&
                          item?.finalSuccessMindeffIstAgo > 2
                        ? "sceen-color"
                        : null
                    }`}
                    columns={columns}
                    filterColumns={filterColumns}
                    item={item}
                    isExpandable={false}
                  />
                );
              })}
            </Table>
          </Card>{" "}
        </>
      ) : null}
    </>
  );
};

export default MobileSync;
