export const getBanklistOptions = [
  // { value: "All", label: "All" },
  { value: "HDFC Bank Ltd", label: "HDFC" },
  { value: "RBL Bank Ltd.", label: "RBL" },
  { value: "Tamilnad Mercantile Bank Ltd.", label: "TMB" },
  // Public Sector Banks
  { value: "Bank of Baroda", label: "BOB" }, // Bank of Baroda
  { value: "Bank of India", label: "BOI" }, // Bank of India
  { value: "Bank of Maharashtra", label: "BOM" }, // Bank of Maharashtra
  { value: "Canara Bank", label: "CNB" }, // Canara Bank
  { value: "Central Bank of India", label: "CBI" }, // Central Bank of India
  { value: "Indian Bank", label: "IB" }, // Indian Bank
  { value: "Indian Overseas Bank", label: "IOB" }, // Indian Overseas Bank
  { value: "Punjab & Sind Bank", label: "PSB" }, // Punjab & Sind Bank
  { value: "Punjab National Bank", label: "PNB" }, // Punjab National Bank
  { value: "State Bank of India", label: "SBI" }, // State Bank of India
  { value: "UCO Bank", label: "UCOB" }, // UCO Bank
  { value: "Union Bank of India", label: "UBI" }, // Union Bank of India

  // Private Sector Banks
  { value: "Axis Bank Ltd.", label: "AXIS" }, // Axis Bank Ltd.
  { value: "Bandhan Bank Ltd.", label: "BB" }, // Bandhan Bank Ltd.
  { value: "CSB Bank Ltd.", label: "CSB" }, // CSB Bank Ltd.
  { value: "City Union Bank Ltd.", label: "CUB" }, // City Union Bank Ltd.
  { value: "DCB Bank Ltd.", label: "DCB" }, // DCB Bank Ltd.
  { value: "Dhanlaxmi Bank Ltd.", label: "DLB" }, // Dhanlaxmi Bank Ltd.
  { value: "Federal Bank Ltd.", label: "FBL" }, // Federal Bank Ltd.
  { value: "ICICI Bank Ltd", label: "ICICI" }, // ICICI Bank Ltd
  { value: "IDFC First Bank Ltd.", label: "IDFC" }, // IDFC First Bank Ltd.
  { value: "Jammu & Kashmir Bank Ltd.", label: "JK" }, // Jammu & Kashmir Bank Ltd.
  { value: "Karnataka Bank Ltd.", label: "KB" }, // Karnataka Bank Ltd.
  { value: "Karur Vysya Bank Ltd.", label: "KVB" }, // Karur Vysya Bank Ltd.
  { value: "Kotak Mahindra Bank Ltd", label: "KMB" }, // Kotak Mahindra Bank Ltd
  { value: "Nainital Bank Ltd.", label: "NB" }, // Nainital Bank Ltd.
  { value: "South Indian Bank Ltd.", label: "SIB" }, // South Indian Bank Ltd.
  { value: "YES Bank Ltd.", label: "YB" }, // YES Bank Ltd.

  // Small Finance Banks
  { value: "AU Small Finance Bank Limited", label: "AU" }, // AU Small Finance Bank Limited
  { value: "Capital Small Finance Bank Limited", label: "CSF" }, // Capital Small Finance Bank Limited
  { value: "Equitas Small Finance Bank Limited", label: "ESF" }, // Equitas Small Finance Bank Limited
  { value: "Suryoday Small Finance Bank Limited", label: "SSF" }, // Suryoday Small Finance Bank Limited
  { value: "Ujjivan Small Finance Bank Limited", label: "USF" }, // Ujjivan Small Finance Bank Limited
  { value: "Utkarsh Small Finance Bank Limited", label: "USFB" }, // Utkarsh Small Finance Bank Limited
  { value: "ESAF Small Finance Bank Limited", label: "ESSF" }, // ESAF Small Finance Bank Limited
  { value: "Fincare Small Finance Bank Limited", label: "FSF" }, // Fincare Small Finance Bank Limited
  { value: "Jana Small Finance Bank Limited", label: "JSF" }, // Jana Small Finance Bank Limited
  { value: "North East Small Finance Bank Limited", label: "NESF" }, // North East Small Finance Bank Limited
  { value: "Shivalik Small Finance Bank Limited", label: "SSFB" }, // Shivalik Small Finance Bank Limited
  { value: "Unity Small Finance Bank Limited", label: "USBL" }, // Unity Small Finance Bank Limited

  // Payments Banks
  { value: "India Post Payments Bank Limited", label: "IPPB" }, // India Post Payments Bank Limited
  { value: "Fino Payments Bank Limited", label: "FPB" }, // Fino Payments Bank Limited
  { value: "Paytm Payments Bank Limited", label: "PPB" }, // Paytm Payments Bank Limited
  { value: "Airtel Payments Bank Limited", label: "APB" }, // Airtel Payments Bank Limited

  // Regional Rural Banks
  { value: "Andhra Pragathi Grameena Bank", label: "APGB" }, // Andhra Pragathi Grameena Bank
  { value: "Andhra Pradesh Grameena Vikas Bank", label: "APGV" }, // Andhra Pradesh Grameena Vikas Bank
  { value: "Arunachal Pradesh Rural Bank", label: "ARB" }, // Arunachal Pradesh Rural Bank
  { value: "Aryavart Bank", label: "AB" }, // Aryavart Bank
  { value: "Assam Gramin Vikash Bank", label: "AGVB" }, // Assam Gramin Vikash Bank
  { value: "Bangiya Gramin Vikas Bank", label: "BGVB" }, // Bangiya Gramin Vikas Bank
  { value: "Baroda Gujarat Gramin Bank", label: "BGG" }, // Baroda Gujarat Gramin Bank
  { value: "Baroda Rajasthan Kshetriya Gramin Bank", label: "BRKG" }, // Baroda Rajasthan Kshetriya Gramin Bank
  { value: "Baroda UP Bank", label: "BUP" }, // Baroda UP Bank
  { value: "Chaitanya Godavari Grameena Bank", label: "CGGB" }, // Chaitanya Godavari Grameena Bank
  { value: "Chhattisgarh Rajya Gramin Bank", label: "CRGB" }, // Chhattisgarh Rajya Gramin Bank
  { value: "Dakshin Bihar Gramin Bank", label: "DBG" }, // Dakshin Bihar Gramin Bank
  { value: "Ellaquai Dehati Bank", label: "EDB" }, // Ellaquai Dehati Bank
  { value: "Himachal Pradesh Gramin Bank", label: "HPGB" }, // Himachal Pradesh Gramin Bank
  { value: "J&K Grameen Bank", label: "JKGB" }, // J&K Grameen Bank
  { value: "Jharkhand Rajya Gramin Bank", label: "JRGB" }, // Jharkhand Rajya Gramin Bank
  { value: "Karnataka Gramin Bank", label: "KGB" }, // Karnataka Gramin Bank
  { value: "Karnataka Vikas Grameena Bank", label: "KGVB" }, // Karnataka Vikas Grameena Bank
  { value: "Kerala Gramin Bank", label: "KGBL" }, // Kerala Gramin Bank
  { value: "Madhya Pradesh Gramin Bank", label: "MPGB" }, // Madhya Pradesh Gramin Bank
  { value: "Madhyanchal Gramin Bank", label: "MG" }, // Madhyanchal Gramin Bank
  { value: "Maharashtra Gramin Bank", label: "MGB" }, // Maharashtra Gramin Bank
  { value: "Manipur Rural Bank", label: "MRB" }, // Manipur Rural Bank
  { value: "Mizoram Rural Bank", label: "MizR" }, // Mizoram Rural Bank
  { value: "Nagaland Rural Bank", label: "NGB" }, // Nagaland Rural Bank
  { value: "Odisha Gramya Bank", label: "OGB" }, // Odisha Gramya Bank
  { value: "Paschim Banga Gramin Bank", label: "PBG" }, // Paschim Banga Gramin Bank
  { value: "Prathama UP Gramin Bank", label: "PUGB" }, // Prathama UP Gramin Bank
  { value: "Punjab Gramin Bank", label: "PGB" }, // Punjab Gramin Bank
  { value: "Rajasthan Marudhara Gramin Bank", label: "RMGB" }, // Rajasthan Marudhara Gramin Bank
  { value: "Saptagiri Grameena Bank", label: "SGB" }, // Saptagiri Grameena Bank
  { value: "Sarva Haryana Gramin Bank", label: "SHGB" }, // Sarva Haryana Gramin Bank
  { value: "Tamil Nadu Grama Bank", label: "TNGB" }, // Tamil Nadu Grama Bank
  { value: "Telangana Grameena Bank", label: "TGB" }, // Telangana Grameena Bank
  { value: "Tripura Gramin Bank", label: "TrGB" }, // Tripura Gramin Bank
  { value: "Utkal Grameen bank", label: "UGB" }, // Utkal Grameen bank
  { value: "Uttar Bihar Gramin Bank", label: "UBGB" }, // Uttar Bihar Gramin Bank
  { value: "Uttarabanga Kshetriya Gramin Bank", label: "UKGB" }, // Uttarabanga Kshetriya Gramin Bank
  { value: "Vidharbha Konkan Gramin Bank", label: "VKB" }, // Vidharbha Konkan Gramin Bank

  // Foreign Banks
  { value: "AB Bank Ltd.", label: "ABBL" }, // AB Bank Ltd.
  { value: "American Express Banking Corporation", label: "AEBCL" }, // American Express Banking Corporation
  { value: "Australia and New Zealand Banking Group Ltd.", label: "ANZB" }, // Australia and New Zealand Banking Group Ltd.
  { value: "Barclays Bank Plc.", label: "BP" }, // Barclays Bank Plc.
  { value: "Bank of America", label: "BOA" }, // Bank of America
  { value: "Bank of Bahrain & Kuwait BSC", label: "BBK" }, // Bank of Bahrain & Kuwait BSC
  { value: "Bank of Ceylon", label: "BOC" }, // Bank of Ceylon
  { value: "Bank of China", label: "BOCH" }, // Bank of China
  { value: "Bank of Nova Scotia", label: "BNS" }, // Bank of Nova Scotia
  { value: "BNP Paribas", label: "BNPP" }, // BNP Paribas
  { value: "Citibank N.A.", label: "CITI" }, // Citibank N.A.
  { value: "Cooperatieve Rabobank U.A.", label: "CRU" }, // Cooperatieve Rabobank U.A.
  { value: "Credit Agricole Corporate & Investment Bank", label: "CACIB" }, // Credit Agricole Corporate & Investment Bank
  { value: "Credit Suisse A.G", label: "CS" }, // Credit Suisse A.G
  { value: "CTBC Bank Co., Ltd.", label: "CBC" }, // CTBC Bank Co., Ltd.
  { value: "DBS Bank India Limited*", label: "DBSI" }, // DBS Bank India Limited*
  { value: "Deutsche Bank", label: "DB" }, // Deutsche Bank
  { value: "Doha Bank Q.P.S.C", label: "DBQP" }, // Doha Bank Q.P.S.C
  { value: "Emirates Bank NBD", label: "ENBD" }, // Emirates Bank NBD
  { value: "First Abu Dhabi Bank PJSC", label: "FAB" }, // First Abu Dhabi Bank PJSC
  { value: "HSBC Ltd", label: "HSBC" }, // HSBC Ltd
  { value: "Industrial & Commercial Bank of China Ltd.", label: "ICBC" }, // Industrial & Commercial Bank of China Ltd.
  { value: "Industrial Bank of Korea", label: "IBK" }, // Industrial Bank of Korea
  { value: "J.P. Morgan Chase Bank N.A.", label: "JP" }, // J.P. Morgan Chase Bank N.A.
  { value: "JSC VTB Bank", label: "JSCV" }, // JSC VTB Bank
  { value: "KEB Hana Bank", label: "KEBH" }, // KEB Hana Bank
  { value: "Krung Thai Bank Public Co. Ltd.", label: "KTBP" }, // Krung Thai Bank Public Co. Ltd.
  { value: "Mashreq Bank PSC", label: "MB" }, // Mashreq Bank PSC
  { value: "Mizuho  Bank Ltd.", label: "MBL" }, // Mizuho  Bank Ltd.
  { value: "MUFG Bank, Ltd.", label: "MBL2" }, // MUFG Bank, Ltd.
  { value: "NatWest Markets Plc", label: "NMP" }, // NatWest Markets Plc
  { value: "NongHyup Bank", label: "NHB" }, // NongHyup Bank
  { value: "PT Bank Maybank Indonesia TBK", label: "PMIT" }, // PT Bank Maybank Indonesia TBK
  { value: "Qatar National Bank (Q.P.S.C.)", label: "QNBP" }, // Qatar National Bank (Q.P.S.C.)
  { value: "Sberbank", label: "SB" }, // Sberbank
  { value: "Societe Generale", label: "SG" }, // Societe Generale
  { value: "Sonali Bank Ltd.", label: "SBL" }, // Sonali Bank Ltd.
  { value: "Standard Chartered Bank", label: "SCB" }, // Standard Chartered Bank
  { value: "Sumitomo Mitsui Banking Corporation", label: "SMBC" }, // Sumitomo Mitsui Banking Corporation
  { value: "United Overseas Bank Ltd", label: "UOB" }, // United Overseas Bank Ltd
  { value: "Woori Bank", label: "WB" }, // Woori Bank
];
