import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { Generated_Report_Get } from "../../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../../helperFunctions";

const GenerateReportModal = (props) => {
  const { isGenerateReportModal, onCloseGenerateRepostModal, payload } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onHide = () => {
    onCloseGenerateRepostModal();
    setIsButtonLoading(false);
  };

  const generateReport = async () => {
    try {
      setIsButtonLoading(true);
      const newPayload = {
        ...payload,

        report_type:
          location?.pathname.split("/")?.[1] === "bank-transaction"
            ? "BANK_TRANSACTION"
            : location?.pathname === "/payout"
            ? "WITHDRAWAL"
            : location?.pathname === "/transaction"
            ? "DEPOSIT"
            : location?.pathname === "/block-info"
            ? "BLOCK_INFO"
            : "",
      };
      onHide();
      let response = await dispatch(Generated_Report_Get(newPayload)).unwrap();
      responseToaster(response);
      setIsButtonLoading(false);
      if (response && response?.status) {
        const dataaa = response?.data;
        const wb = XLSX.utils.book_new();
        const headers = [
          "Count",
          "Created At",
          "Created At (IST)",
          "Download ID",
          "Download URL",
          "Email ID",
          "Expire At (IST)",
          "Expired At",
          "File Name",
          "Is Expire",
          "Progress",
          "Report Type",
          "Status",
          "Updated At",
          "Updated At (IST)",
        ];

        const ws_data = [
          headers,
          ...dataaa.map((obj) => [
            setFileName(obj?.file_name),
            obj?.count,
            obj?.created_at,
            obj?.createdAtIst,
            obj?.download_id,
            obj?.download_url,
            obj?.email_id,
            obj?.expire_at_ist,
            obj?.expired_at,
            obj?.file_name,
            obj?.is_expire,
            obj?.progress,
            obj?.report_type,
            obj?.status,
            obj?.updated_at,
            obj?.updatedAtIst,
          ]),
        ];
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          fileName
        );
      } else {
      }
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isGenerateReportModal}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Generate Report</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p> Do you want to generate report ?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={generateReport}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default GenerateReportModal;
