import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useDispatch, useSelector } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import { Filter_Data_Transaction } from "../../../../redux/slices/transactionSlice";
import { AddWithdrawalCredit } from "../../../../redux/slices/withdrawalCreditSlice";

const WithdrawalCreditModal = (props) => {
  const { isModalOpen, onCloseModal, onGet } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ddLoading, setDdLoading] = useState(false);
  const { transactionFilterState } = useSelector((state) => state.transaction);
  const getFilterTransactions = async () => {
    try {
      setDdLoading(true);
      await dispatch(Filter_Data_Transaction()).unwrap();
      setDdLoading(false);
    } catch (err) {
      setDdLoading(false);
    }
  };
  const schema = Yup.object({
    merchantId: Yup.string().required("Please select merchant."),
    remark: Yup.string().required("Please enter remark."),
    amount: Yup.string().required("Please enter settled amount."),
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const payload = {
        ...values,
      };
      const res = await dispatch(AddWithdrawalCredit(payload)).unwrap();
      if (res) {
        await onGet();
        responseToaster(res);
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareMerchantOption = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  useEffect(() => {
    if (isModalOpen) {
      getFilterTransactions();
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Withdrawal Credit</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <ControlledDropDown
            id="merchantId"
            name="merchantId"
            label="Merchant Name"
            isLoading={ddLoading}
            errors={errors}
            options={prepareMerchantOption()}
            control={control}
          />
          <ControlledInput
            name="remark"
            label="Remark"
            errors={errors}
            type="text"
            placeholder="Enter Remark"
            control={control}
          />
          <ControlledInput
            name="amount"
            label="Settled Amount"
            min={1}
            errors={errors}
            type="number"
            placeholder="Enter Settled Amount"
            control={control}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default WithdrawalCreditModal;
