import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  addMerchantGoogleAnalytic,
  deletMerchantGoogleAnalytic,
  getMerchantGoogleAnalytic,
  statusMerchantGoogleAnalytic,
  updateMerchantGoogleAnalytic,
} from "../services/merchantGoogleAnalyticServices";

const initialState = {
  data: {},
};

export const merchantGoogleAnalyticGet = createAsyncThunk(
  "merchantGoogleAnalyticsGet",
  async (payload) => {
    return await getMerchantGoogleAnalytic(payload);
  }
);
export const merchantGoogleAnalyticAdd = createAsyncThunk(
  "merchantGoogleAnalyticsAdd",
  async (payload) => {
    return await addMerchantGoogleAnalytic(payload);
  }
);
export const merchantGoogleAnalyticUpdate = createAsyncThunk(
  "merchantGoogleAnalyticsUpdate",
  async (payload) => {
    return await updateMerchantGoogleAnalytic(payload);
  }
);
export const merchantGoogleAnalyticDelete = createAsyncThunk(
  "merchantGoogleAnalyticsDelet",
  async (payload) => {
    return await deletMerchantGoogleAnalytic(payload);
  }
);
export const merchantGoogleAnalyticStatus = createAsyncThunk(
  "merchantGoogleAnalyticsStatus",
  async (payload) => {
    return await statusMerchantGoogleAnalytic(payload);
  }
);
const merchantGoogleAnalyticsSlice = createSlice({
  name: "merchantGoogleAnalyticsSlice",
  initialState,
  reducers: {
    clearMerchantGoogleAnalytic: () => initialState,
    setMerchantGoogleAnalytic: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(merchantGoogleAnalyticGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default merchantGoogleAnalyticsSlice.reducer;
export const { clearMerchantGoogleAnalytic,setMerchantGoogleAnalytic } =
  merchantGoogleAnalyticsSlice.actions;

export const selectMerchantGoogleAnalytic = (state) => {
  return state?.merchantGoogleAnalytic?.data;
};
export const useMerchantGoogleAnalytic = () => {
  const data = useSelector(selectMerchantGoogleAnalytic);
  return useMemo(() => data, [data]);
};
