import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { view_Dashboard_Logs } from "../../../../redux/slices/merchantSlice";
import { Button, Modal } from "reactstrap";
import { Table, TableRow } from "../../../Table";

const MerchantDashboardLogs = (props) => {
  const { id, onCloseMerchantDashboardLog, merchantDashboardLog } = props;
  const { viewDashboardLogs, viewDashboardLogsLoading } = useSelector(
    (state) => state.merchant
  );

  const [filterColumns, setFilterColumns] = useState([]);

  const [filter, setFilter] = useState({
    page_no: 1,
    limit: 10,
  });
  const dispatch = useDispatch();

  const columns = [
    {
      title: "ACTION TYPE",
      name: "activity_type",
    },
    {
      title: "ACTION",
      name: "activity",
    },
    {
      title: "REQUEST IP",
      name: "reqIp",
    },
    {
      title: "USER AGENT",
      name: "userAgent",
    },
    {
      title: "CREATED AT",
      name: "createdAt",
    },
  ];
  const Statement_View = async (payload) => {
    try {
      await dispatch(
        view_Dashboard_Logs({ ...payload, merchantId: id })
      ).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (merchantDashboardLog) {
      Statement_View(filter);
    }
  }, [filter, merchantDashboardLog]);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={merchantDashboardLog}
      toggle={onCloseMerchantDashboardLog}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Merchant Dashboard Logs</h3>
        <i
          onClick={() => {
            onCloseMerchantDashboardLog();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Table
          columns={columns}
          isLoading={viewDashboardLogsLoading}
          data={viewDashboardLogs?.data || []}
          isData={!!viewDashboardLogs?.data?.length}
          filterColumns={filterColumns}
          count={viewDashboardLogs?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {viewDashboardLogs?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                isExpandable={false}
                filterColumns={filterColumns}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => onCloseMerchantDashboardLog()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MerchantDashboardLogs;
