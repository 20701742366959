export const prepareMerchantFormData = (currentItem) => {
  if (!currentItem) {
    return null;
  }
  return {
    merchantName: currentItem?.merchantName,
    merchantId: currentItem?.merchantId,
    merchantEmail: currentItem?.merchantEmail,
    depositEnable: currentItem?.deposit_availability ? 1 : 0,
    isVerifyUpiFirst: currentItem?.isVerifyUpiFirst ? 1 : 0,
    isFlowV2: currentItem?.isFlowV2 ? 1 : 0,
    depositManualFees: +currentItem?.deposit_manual_fees ?? "",
    depositNotify: currentItem?.deposit_notify ?? "",
    depositAssociateFees: +currentItem?.deposit_astFees ?? "",
    depositAutoFees: +currentItem?.deposit_auto_fees ?? "",
    withdrawalEnable: currentItem?.withdrawal_availability ? 1 : 0,
    IsEnablewithdrawalBalanceCheck: currentItem?.check_withdrawal_eligibility
      ? 1
      : 0,
    withdrawalWebhook: currentItem?.withdrawal_Notify ?? "",
    IsDashboardwithdrawalEnable:
      +currentItem?.enable_dashboard_withdrawal ?? "",
    withdrawalDelayedTime: +currentItem?.withdrawal_hold_duration ?? "",
    withdrawalFees: +currentItem?.withdrawal_fees ?? "",
    withdrawalAssociateFees: +currentItem?.withdrawalAssociateFees ?? "",
    checkOutUrl: currentItem?.checkOutUrl ?? "",
    acStatus: currentItem?.accountSts ?? "",
    minLimit: +currentItem?.lower_limit ?? "",
    maxLimit: +currentItem?.upper_limit ?? "",
    minPayoutLimit: +currentItem?.minPayoutLimit ?? "",
    maxPauoutLimit: +currentItem?.maxPauoutLimit ?? "",
    IsFailedWebhookRequired: currentItem?.failed_notify_req ? 1 : 0,
    IsEnableBrowserCheck: currentItem?.browser_check_sts ? 1 : 0,
    SettlementCycle: +currentItem?.financial_cycle ?? 0,
    OldUsersDays: +currentItem?.elderly_users_time ?? 0,
    CheckoutColor: currentItem?.closure_Color ?? "",
    CheckoutThemeUrl: currentItem?.closure_themeUrl ?? "",
    IsAutoApprovedwithdrawal: currentItem?.auto_approved_withdrawal ? 1 : 0,
    ShowCustomerDetailsPage: currentItem?.display_cust_info_page ? 1 : 0,
    IsCustomerDetailsRequired: currentItem?.cust_info_required ? 1 : 0,
    IsSettlementEnable: currentItem?.settlement_action ? 1 : 0,
    merchantBouncerUrl: currentItem?.merchantBouncerUrl ?? "",
    isIntentInResponse: currentItem?.isIntentInResponse ?? 0,
    isDesktopEnable: currentItem?.isDesktopEnable ? 1 : 0,
  };
};
