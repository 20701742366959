import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { resetPasswordUser } from "../../../redux/slices/usersSlice";
import { responseToaster } from "../../../helperFunctions";
import { CopyToClipboard } from "react-copy-to-clipboard";

const UserResetPassword = (props) => {
  const { id, onCloseUserResetPassword, userResetPassword } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const ResetPassword = async () => {
    try {
      setIsButtonLoading(true);
      const payload = {
       id,
      };
      let response = await dispatch(resetPasswordUser(payload)).unwrap();
      responseToaster(response);
      if (response && response?.status) {
        setIsCopy(response?.data);
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    onCloseUserResetPassword();
    setIsCopy(false);
  };

  return (
    <Modal isOpen={userResetPassword} toggle={onClose} centered={true}>
      {isCopy?.email_id ? (
        <>
          <div>
            <div className="modal-header">
              <h3 className="modal-title mt-0">Reset Password!</h3>
              <i
                onClick={() => {
                  onClose();
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <div className="modal-body py-4">
              <p className="mb-50">
                <span>Email ID :- </span>
                <span className="bold">{isCopy?.email_id}</span>
              </p>
              <p>
                <span>Password :- </span>
                <span className="bold">{isCopy?.password}</span>
              </p>
            </div>
            <div className="modal-footer d-flex align-items-center justify-content-end">
              <CopyToClipboard text={`Email :${isCopy?.email_id} \nPassword :${isCopy?.password}`}>
                <Button className="submitbutton" onClick={() => onClose()}>
                  Copy & Close
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <h3 className="modal-title mt-0">Reset Password !</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p> Are you sure you want to reset user password ?</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                onClick={ResetPassword}
                className="submitbutton"
                isButtonLoading={isButtonLoading}
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default UserResetPassword;
