import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { add_Manual_Payin } from "../../../../redux/slices/merchantSlice";
import { Button, Form, Modal, Row } from "reactstrap";
import moment from "moment";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import ControlledCheckBox from "../../Forms/Controller/ControlledCheckBox";
import ControlledDatePicker from "../../Forms/Controller/ControlledDatePicker";

const AddManualPayin = (props) => {
  const { onCloseAddManualPayinModal, addManualPayinModal, id } = props;

  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onClose = () => {
    onCloseAddManualPayinModal();
    reset();
  };

  const initialValues = {};

  const onSubmit = async () => {
    const values = getValues();
    let payload = {
      ...values,
      deposit_date: values?.transaction_date
        ? moment(values?.transaction_date).format("YYYY-MM-DD")
        : null,
      merchantId: id,
    };
    try {
      setIsButtonLoading(true);
      const res = await dispatch(add_Manual_Payin(payload)).unwrap();
      responseToaster(res);
      if (!!res) {
        onClose();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={addManualPayinModal}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Add Manual Deposit</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <div className="select-div">
              <ControlledInput
                name="amount"
                label="Amount"
                type="number"
                placeholder="Enter Amount"
                control={control}
              />
            </div>

            <div className="select-div">
              <ControlledInput
                name="utrRef"
                label="Bank Ref"
                type="text"
                placeholder="Enter Bank Ref"
                control={control}
              />
            </div>

            <div className="select-div">
              <ControlledInput
                name="remark"
                label="Remark"
                type="text"
                placeholder="Enter Remark"
                control={control}
              />
            </div>
            <div className="select-div py-1 d-flex gap-1 align-items-center">
              <div className="d-flex justify-content-center">
                <ControlledCheckBox
                  label="With Fee"
                  type="checkbox"
                  name="withfee"
                  style={{ padding: "8px", backgroundSize: "10px" }}
                  placeholder="Enter Withdraw Associate Fees"
                  control={control}
                />
              </div>
            </div>

            <div className="d-flex flex-column">
              <ControlledDatePicker
                name="transaction_date"
                placeholder="Select Date"
                label="Date"
                maxDate={new Date()}
                control={control}
              />
            </div>
          </Row>

        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddManualPayin;
