import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { resellerAdd } from "../../../redux/slices/resellerSlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showToaster } from "../../../constant";

const AddResellerModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const initialValues = {
    email_id: "",
    reseller_id: "",
    full_name: "",
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        ...values,
      };
      const res = await dispatch(resellerAdd(payload)).unwrap();
      if (res?.status) {
        setIsCopy(res?.data);
      }
      if (res.status) {
        showToaster(res.message, "Success");
      } else {
        showToaster(res.message, "Error");
      }
      onClose(false);
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { values, handleSubmit, handleChange, setFieldValue, resetForm } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const onClose = (isClose = true) => {
    resetForm();
    if (isClose) {
      setIsCopy(false);
      onHide();
    }
  };
  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={isOpen}
      centered={true}
      toggle={onClose}
    >
      {isCopy ? (
        <div>
          <div className="modal-header">
            <h3 className="modal-title mt-0">Add Reseller</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div
            className="modal-body"
            style={{
              padding: 100,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              <span>Email ID :- </span>
              <span className="bold">{isCopy?.email_id}</span>
            </span>
            <span>
              <span>Password :- </span>
              <span className="bold">{isCopy?.password}</span>
            </span>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <CopyToClipboard text={`${isCopy?.email_id} ${isCopy?.password}`}>
                <Button className="submitbutton" onClick={() => onClose()}>
                  Copy & Close
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h3 className="modal-title mt-0">Add Reseller</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <InputField
              name="reseller_id"
              label="Reseller ID"
              value={values?.reseller_id}
              onChange={handleChange}
              placeholder="Enter Reseller ID"
            />
            <InputField
              name="full_name"
              label="Full Name"
              value={values?.full_name}
              onChange={handleChange}
              placeholder="Enter Full Name"
            />
            <InputField
              type="email"
              name="email_id"
              label="Email ID"
              value={values?.email_id}
              onChange={handleChange}
              placeholder="Enter Email ID"
            />
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                type="submit"
                className="submitbutton"
                isButtonLoading={isLoading}
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default AddResellerModal;
