import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Download_Batch_File,
  Manual_Payout,
  Manual_Payout_Banklist,
  Reconciliation_Payment,
  Reconciliation_Payment_Action,
  getCustomerLevelData,
  get_Refund,
  mark_as_used_Manual,
  account_bank_load,
  generate_file,
  payout_amount_total,
  upload_status_file,
  bank_ticket_summary,
  merchant_list,
  fed_transcation,
  ticket_summary,
  updateIsManualLevel,
  get_payout_banklimit,
  get_payment_banklimit,
  get_payment_banklimit_data,
  update_payment_banklimit,
  update_payout_banklimit,
  updateFedTransaction,
  payout_amount_total_amount,
  active_merchant_PG,
} from "../services/payinPayoutServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  getRefundState: [],
  payinPayoutState: [],
  reconciliationPaymentState: [],
  accountBankLoadState: [],
  manualPayloadState: [],
  manualpayoutBankState: [],
  payoutTotalAmountState: [],
  genrateFileState: [],
  isCustLoading: true,
  refundLoading: true,
  reconciliationPaymentLoading: true,
  manualpayoutBankLoading: true,
  accountBankLoadLoading: true,
  generateFileLoading: true,
  getBankTicketSummaryState: [],
  getBankTicketSummaryLoading: true,
  getTicketSummaryState: [],
  getTicketSummaryLoading: true,
  getMerchantListState: [],
  getFedTransactionLoading: true,
  getFedTranscationState: [],
  getPayoutbanklimitLoading: true,
  getPayoutListBankLimitState: [],
  getpaymentBankLimitLoading: true,
  getPaymentBankLimitState: [],
  getPaymentBankLimitStateData: [],
  getTotalMerchantStateData: [],
  getTotalMerchantLoading: false,
  getActiveMerchantPGState: [],
};

export const GetCustomerLevelData = createAsyncThunk(
  "/getcustomerleveldata",
  async (payload) => {
    return await getCustomerLevelData(payload);
  }
);
export const Refund_Get = createAsyncThunk("/getrefund", async (payload) => {
  return await get_Refund(payload);
});
export const Reconciliation_Payment_Get = createAsyncThunk(
  "/reconciliationpayment",
  async (payload) => {
    return await Reconciliation_Payment(payload);
  }
);
export const Action_Reconciliation_Payment = createAsyncThunk(
  "/reconciliationpaymentaction",
  async (payload) => {
    return await Reconciliation_Payment_Action(payload);
  }
);
export const payout_Manual = createAsyncThunk(
  "/manual_payout",
  async (payload) => {
    return await Manual_Payout(payload);
  }
);
export const Payout_Banklist_Manual = createAsyncThunk(
  "/manual_payout_banklist",
  async (payload) => {
    return await Manual_Payout_Banklist(payload);
  }
);
export const Batch_File_Download = createAsyncThunk(
  "/Download_Batch_File",
  async (payload) => {
    return await Download_Batch_File(payload);
  }
);
export const Mark_as_Manual_Payout = createAsyncThunk(
  "/mark_as_used_Manual",
  async (payload) => {
    return await mark_as_used_Manual(payload);
  }
);
export const Mark_as_Account_Bankload = createAsyncThunk(
  "/account_bank_load",
  async (payload) => {
    return await account_bank_load(payload);
  }
);
export const Generate_File = createAsyncThunk(
  "/generate_file",
  async (payload) => {
    return await generate_file(payload);
  }
);
export const Payout_Amount_Total = createAsyncThunk(
  "/payout_amount_total",
  async () => {
    return await payout_amount_total();
  }
);
export const Upload_Status_File = createAsyncThunk(
  "/upload_status_file",
  async (payload) => {
    return await upload_status_file(payload);
  }
);
export const get_bank_ticket_summary = createAsyncThunk(
  "/get_bank_ticket_summary",
  async (payload) => {
    return await bank_ticket_summary(payload);
  }
);
export const get_ticket_summary = createAsyncThunk(
  "/get_ticket_summary",
  async (payload) => {
    return await ticket_summary(payload);
  }
);
export const get_merchant_list = createAsyncThunk(
  "/merchant_list",
  async (payload) => {
    return await merchant_list(payload);
  }
);
export const get_fed_transcation = createAsyncThunk(
  "/get_fed_transcation",
  async (payload) => {
    return await fed_transcation(payload);
  }
);
export const fedTransactionUpdate = createAsyncThunk(
  "/fedTransactionUpdate",
  async (payload) => {
    return await updateFedTransaction(payload);
  }
);

export const isManualLevelUpdate = createAsyncThunk(
  "/isManualLevelUpdate",
  async (payload) => {
    return await updateIsManualLevel(payload);
  }
);
export const payout_banklimit_get = createAsyncThunk(
  "/get_payout_banklimit",
  async (payload) => {
    return await get_payout_banklimit(payload);
  }
);
export const payment_banklimit_get = createAsyncThunk(
  "/get_payment_banklimit",
  async (payload) => {
    return await get_payment_banklimit(payload);
  }
);
export const payment_banklimit_data_get = createAsyncThunk(
  "/get_payment_banklimit_data",
  async (payload) => {
    return await get_payment_banklimit_data(payload);
  }
);
export const payment_banklimit_update = createAsyncThunk(
  "/update_payment_banklimit",
  async (payload) => {
    return await update_payment_banklimit(payload);
  }
);
export const payout_banklimit_update = createAsyncThunk(
  "/update_payout_banklimit",
  async (payload) => {
    return await update_payout_banklimit(payload);
  }
);
export const Payout_amount_total_amount = createAsyncThunk(
  "/payout_amount_total_amount",
  async (payload) => {
    return await payout_amount_total_amount(payload);
  }
);
export const Active_Merchant_PG = createAsyncThunk(
  "/active_merchant_PG",
  async (payload) => {
    return await active_merchant_PG(payload);
  }
);

const payinPayoutSlice = createSlice({
  name: "payinPayoutSlice",
  initialState,
  reducers: {
    clearPayinPayout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetCustomerLevelData.fulfilled, (state, action) => {
      state.isCustLoading = false;
      state.payinPayoutState = action.payload;
    });
    builder.addCase(GetCustomerLevelData.pending, (state, action) => {
      state.isCustLoading = true;
    });
    builder.addCase(GetCustomerLevelData.rejected, (state, action) => {
      state.isCustLoading = false;
    });
    builder.addCase(Refund_Get.fulfilled, (state, action) => {
      state.refundLoading = false;
      state.getRefundState = action.payload;
    });
    builder.addCase(Refund_Get.pending, (state, action) => {
      state.refundLoading = true;
    });
    builder.addCase(Refund_Get.rejected, (state, action) => {
      state.refundLoading = false;
    });
    builder.addCase(Reconciliation_Payment_Get.fulfilled, (state, action) => {
      state.reconciliationPaymentLoading = false;
      state.reconciliationPaymentState = action.payload;
    });
    builder.addCase(Reconciliation_Payment_Get.pending, (state, action) => {
      state.reconciliationPaymentLoading = true;
    });
    builder.addCase(Reconciliation_Payment_Get.rejected, (state, action) => {
      state.reconciliationPaymentLoading = false;
    });
    builder.addCase(payout_Manual.fulfilled, (state, action) => {
      state.manualPayloadState = action.payload;
      state.manualpayoutBankLoading = false;
    });
    builder.addCase(payout_Manual.pending, (state, action) => {
      state.manualPayloadState = action.payload;
      state.manualpayoutBankLoading = true;
    });
    builder.addCase(payout_Manual.rejected, (state, action) => {
      state.manualPayloadState = action.payload;
      state.manualpayoutBankLoading = false;
    });
    builder.addCase(Mark_as_Account_Bankload.fulfilled, (state, action) => {
      state.accountBankLoadState = action.payload;
      state.accountBankLoadLoading = false;
    });
    builder.addCase(Mark_as_Account_Bankload.pending, (state, action) => {
      state.accountBankLoadState = action.payload;
      state.accountBankLoadLoading = true;
    });
    builder.addCase(Mark_as_Account_Bankload.rejected, (state, action) => {
      state.accountBankLoadState = action.payload;
      state.accountBankLoadLoading = false;
    });
    builder.addCase(Generate_File.fulfilled, (state, action) => {
      state.genrateFileState = action.payload;
      state.generateFileLoading = false;
    });
    builder.addCase(Generate_File.pending, (state, action) => {
      state.genrateFileState = action.payload;
      state.generateFileLoading = true;
    });
    builder.addCase(Generate_File.rejected, (state, action) => {
      state.genrateFileState = action.payload;
      state.generateFileLoading = false;
    });
    builder.addCase(Payout_Amount_Total.fulfilled, (state, action) => {
      state.payoutTotalAmountState = action.payload;
    });
    builder.addCase(Payout_Banklist_Manual.fulfilled, (state, action) => {
      state.manualpayoutBankState = action.payload;
    });
    builder.addCase(get_bank_ticket_summary.fulfilled, (state, action) => {
      state.getBankTicketSummaryState = action.payload;
      state.getBankTicketSummaryLoading = false;
    });
    builder.addCase(get_bank_ticket_summary.pending, (state, action) => {
      state.getBankTicketSummaryLoading = true;
    });
    builder.addCase(get_bank_ticket_summary.rejected, (state, action) => {
      state.getBankTicketSummaryLoading = false;
    });

    builder.addCase(get_ticket_summary.fulfilled, (state, action) => {
      state.getTicketSummaryState = action.payload;
      state.getTicketSummaryLoading = false;
    });
    builder.addCase(get_ticket_summary.pending, (state, action) => {
      state.getTicketSummaryLoading = true;
    });
    builder.addCase(get_ticket_summary.rejected, (state, action) => {
      state.getTicketSummaryLoading = false;
    });

    builder.addCase(get_merchant_list.fulfilled, (state, action) => {
      state.getMerchantListState = action.payload;
    });
    builder.addCase(get_fed_transcation.pending, (state, action) => {
      state.getFedTransactionLoading = true;
    });
    builder.addCase(get_fed_transcation.rejected, (state, action) => {
      state.getFedTransactionLoading = false;
    });
    builder.addCase(get_fed_transcation.fulfilled, (state, action) => {
      state.getFedTranscationState = action.payload;
      state.getFedTransactionLoading = false;
    });
    builder.addCase(payout_banklimit_get.fulfilled, (state, action) => {
      state.getPayoutListBankLimitState = action.payload;
      state.getPayoutbanklimitLoading = false;
    });
    builder.addCase(payout_banklimit_get.pending, (state, action) => {
      state.getPayoutbanklimitLoading = true;
    });
    builder.addCase(payout_banklimit_get.rejected, (state, action) => {
      state.getPayoutbanklimitLoading = false;
    });
    builder.addCase(payment_banklimit_get.pending, (state, action) => {
      state.getpaymentBankLimitLoading = true;
    });
    builder.addCase(payment_banklimit_get.rejected, (state, action) => {
      state.getpaymentBankLimitLoading = false;
    });
    builder.addCase(payment_banklimit_get.fulfilled, (state, action) => {
      state.getPaymentBankLimitState = action.payload;
      state.getpaymentBankLimitLoading = false;
    });
    builder.addCase(payment_banklimit_data_get.fulfilled, (state, action) => {
      state.getPaymentBankLimitStateData = action.payload;
      state.getpaymentBankLimitLoading = false;
    });
    
    builder.addCase(Payout_amount_total_amount.fulfilled, (state, action) => {
      state.getTotalMerchantStateData = action.payload;
      state.getTotalMerchantLoading = false;
    });
    builder.addCase(Payout_amount_total_amount.pending, (state, action) => {
      state.getTotalMerchantLoading = true;
    });
    builder.addCase(Payout_amount_total_amount.rejected, (state, action) => {
      state.getTotalMerchantLoading = false;
    });
    builder.addCase(Active_Merchant_PG.fulfilled, (state, action) => {
      state.getActiveMerchantPGState = action.payload;
    });
  },
});
export default payinPayoutSlice.reducer;
export const { clearPayinPayout } = payinPayoutSlice.actions;

export const selectPayoutListBankLimit = (state) => {
  return state.payinPayout.getPayoutListBankLimitState;
};

export const usePayoutListBankLimit = () => {
  const getPayoutListBankLimitState = useSelector(selectPayoutListBankLimit);
  return useMemo(
    () => getPayoutListBankLimitState,
    [getPayoutListBankLimitState]
  );
};

export const selectPaymentBankLimit = (state) => {
  return state.payinPayout.getPaymentBankLimitState;
};

export const usePaymentBankLimit = () => {
  const getPaymentBankLimitState = useSelector(selectPaymentBankLimit);
  return useMemo(() => getPaymentBankLimitState, [getPaymentBankLimitState]);
};
export const selectPaymentBankLimitStateData = (state) => {
  return state.payinPayout.getPaymentBankLimitStateData;
};

export const usePaymentBankLimitStateData = () => {
  const getPaymentBankLimitStateData = useSelector(
    selectPaymentBankLimitStateData
  );
  return useMemo(
    () => getPaymentBankLimitStateData,
    [getPaymentBankLimitStateData]
  );
};
