import {
  ADD_BANK_PROXY,
  DELETE_BANK_PROXY,
  GET_BANK_PROXY,
  UPDATE_BANK_PROXY_STATUS,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getBankProxy = (payload) => {
  return apiInstance.post(GET_BANK_PROXY, payload);
};

export const addBankProxy = (payload) => {
  return apiInstance.post(ADD_BANK_PROXY, payload);
};

export const updateBankProxyStatus = (payload) => {
  return apiInstance.post(UPDATE_BANK_PROXY_STATUS, payload);
};
export const deleteBankProxy = (payload) => {
  return apiInstance.post(DELETE_BANK_PROXY, payload);
};
