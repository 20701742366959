import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeFeesTransaction } from "../../../../redux/slices/transactionSlice";
import { Button, Modal } from "reactstrap";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const TransactionRemoveFeesModal = (props) => {
  const { isOpen, onHide, onGet } = props;

  const dispatch = useDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onRemoveFees = async () => {
    try {
      setIsButtonLoading(true);
      let payload = {
        txnId: isOpen?.txnId,
        payment_amount: isOpen?.paymentAmt,
      };
      let response = await dispatch(removeFeesTransaction(payload)).unwrap();
      responseToaster(response);
      if (!!response) {
        onClose();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    onHide();
  };

  return (
    <Modal isOpen={!!isOpen} toggle={onClose} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Remove Fees</h3>
        <i onClick={() => onClose()} className="fa-solid fa-xmark"></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to remove fees?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onClose()}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={onRemoveFees}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionRemoveFeesModal;
