import React from "react";
import { Card, CardHeader } from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo } from "react";

import { Table, TableRow } from "../../../components/Table";
import {
  useActiveUpiIds,
  getActiveUpiIds,
} from "../../../redux/slices/activeUpiIdsSlice";
import { useRole } from "../../../redux/slices/authSlice";

const ActiveUpiIds = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const { isLoading } = useSelector((state) => state.activeUpiIds);
  const { activeUpiIdsState } = useActiveUpiIds();

  const dispatch = useDispatch();
  const role = useRole("Active UPI ids");

  const columns = useMemo(
    () => [
      {
        title: "PG Name ",
        name: "pg_name",
      },
      {
        title: "PG Bank UPI ID",
        name: "pg_bank_upi_id",
      },
      {
        title: "PG Bank UPI Name",
        name: "pg_bank_upi_name",
      },
      // {
      //   title: "STATUS",
      //   name: "STATUS",
      //   className: "px-0",
      //   Cell: (data) => {
      //     return (
      //       <>
      //         <div
      //           className={`${
      //             data?.isOpen ? "cell-order-white" : "cell-order"
      //           } d-flex flex-column `}
      //         >
      //           <span className="px-1">Success :-</span>
      //           <b className="border-b-1"></b>
      //           <span className="px-1">Pending :-</span>
      //         </div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   title: "PHONEPE ",
      //   name: "total_PhonePe_txn",
      //   className: "px-0",
      //   Cell: (data) => (
      //     <>
      //       <div
      //         className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
      //       >
      //         <div className="order-singal-div border-b-1">
      //           <p className="themecolor">
      //             <span> {data?.success_PhonePe_txn || "0"} ~ </span>
      //           </p>
      //         </div>
      //         <div className="order-singal-div">
      //           <p className="themecolor">
      //             <span> {data?.pending_PhonePe_txn || "0"} ~ </span>
      //           </p>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   title: "PAYTM",
      //   name: "total_PayTM_txn",
      //   className: "px-0",
      //   Cell: (data) => (
      //     <>
      //       <div
      //         className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
      //       >
      //         <div className="order-singal-div border-b-1">
      //           <p className="themecolor">
      //             <span> {data?.success_PayTM_txn || "0"} ~ </span>
      //           </p>
      //         </div>
      //         <div className="d-flex gap order-singal-div">
      //           <p className="themecolor  mb-0">
      //             <span> {data?.pending_PayTM_txn || "0"} ~ </span>
      //           </p>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   title: "GOOGLEPAY",
      //   name: "total_GooglePay_txn",
      //   className: "px-0",
      //   Cell: (data) => (
      //     <>
      //       <div
      //         className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
      //       >
      //         <div className="order-singal-div border-b-1">
      //           <p className="themecolor">
      //             <span> {data?.success_GooglePay_txn || ""} ~ </span>
      //           </p>
      //         </div>
      //         <div className="order-singal-div">
      //           <p className="themecolor">
      //             <span> {data?.pending_GooglePay_txn || ""} ~ </span>
      //           </p>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   title: "TOTAL TXN",
      //   name: "total_pending",
      //   className: "px-0",
      //   Cell: (data) => (
      //     <>
      //       <div
      //         className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
      //       >
      //         <div className="order-singal-div border-b-1 ">
      //           <p className="themecolor">
      //             <span> {`${data?.total_success_txn}`} </span>
      //           </p>
      //         </div>
      //         <div className="order-singal-div">
      //           <p className="themecolor">
      //             <span> {`${data?.total_pending_txn}`} </span>
      //           </p>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
    ],
    []
  );

  const GetActiveUpiIds = async () => {
    try {
      await dispatch(getActiveUpiIds()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("activeUpiIds-list")) {
      GetActiveUpiIds();
    }
  }, []);

  return (
    <>
      {role.includes("activeUpiIds-list") ? (
        <>
          <div className="activeUpiIds-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Active Upi Ids</b>
                    </h4>
                  </div>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={activeUpiIdsState?.data || []}
                isData={!!activeUpiIdsState?.data?.length}
                filterColumns={filterColumns}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                isExpandable={false}
              >
                {activeUpiIdsState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ActiveUpiIds;
