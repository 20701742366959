import React, { useState } from "react";
import { Form, Modal } from "reactstrap";
import "./ViewSenddataModal.scss";
import { useEffect } from "react";
import { isParsable } from "../../../../helperFunctions";

const ViewSentdataModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);
  const [parsedData2, setParsedData2] = useState(null);

  useEffect(() => {
    try {
      // const parsedNotifyRes = JSON.parse(data?.notify_res || null);
      setParsedData1(isParsable(data?.notify_res) ? JSON.parse(data?.notify_res || null) : data?.notify_res);
    } catch (error) { }

    try {
      const parsedSendNotifyContent = isParsable(data?.send_notify_content)
        ? JSON.parse(data?.send_notify_content || null)
        : null;
      setParsedData2(parsedSendNotifyContent);
    } catch (error) { }
  }, [data?.notify_res, data?.send_notify_content]);

  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="view-send-data-modal"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">View Send Data</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100 json-style">
          <h5>Webhook Response Data</h5>
          <div className="webevent-sec  mb-1">
            <pre>{JSON.stringify(parsedData2, null, 1)}</pre>
          </div>
          <h5>Send Webhook Data</h5>
          <div className="webevent-sec">
            <pre>{JSON.stringify(parsedData1, null, 1)}</pre>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default ViewSentdataModal;
