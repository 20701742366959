import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Webhook } from "../services/webhookService";

const initialState = {
  webhookState: [],
  isLoading: true,
};
export const getWebhook = createAsyncThunk(
  "/support/GetWebhookEvents",
  async (payload) => {
    return await Webhook(payload);
  }
);

const webhookSlice = createSlice({
  name: "webhookSlice",
  initialState,
  reducers: {
    clearWebhook: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWebhook.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getWebhook.fulfilled, (state, action) => {
      state.isLoading = false;
      state.webhookState = action.payload;
    });
    builder.addCase(getWebhook.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webhookSlice.reducer;
export const { clearWebhook } = webhookSlice.actions;

export const selectWebhook = (state) => {
  return state.webhook.webhookState;
};

export const useWebhook = () => {
  const webhookState = useSelector(selectWebhook);
  return useMemo(() => ({ webhookState }), [webhookState]);
};
