import { ADD_PROXY, GET_PROXY } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getProxy = (payload) => {
  return apiInstance.post(GET_PROXY, payload);
};

export const addProxy = (payload) => {
  return apiInstance.post(ADD_PROXY, payload);
};
