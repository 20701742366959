import { REPORT, REPORT_MERCHANT_LIST } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Report = (payload) => {
  return apiInstance.post(REPORT, payload);
};

export const getReportMerchantList = (payload) => {
  return apiInstance.post(REPORT_MERCHANT_LIST, payload);
};
