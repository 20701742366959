import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Bank_Available } from '../../../redux/slices/bankTransactionSlice';
import { useForm } from 'react-hook-form';
import { capitalCaseLabel, responseToaster } from '../../../helperFunctions';
import { Button, Row, Card, CardBody, Form, CardHeader } from 'reactstrap';
import { proxyGet, useProxyData } from '../../../redux/slices/proxySlice';
import CustomeButton from '../../../components/Custom/Forms/CustomeButton/CustomeButton';
import ControlledInput from '../../../components/Custom/Forms/Controller/ControlledInput';
import { NUMBER_TYPE_FIELDS, UPPERCASE_LABEL } from '../../../constant';
import ControlledDropDown from '../../../components/Custom/Forms/Controller/ControlledDropDown';
import { getBanklistOptions } from '../../Pg/mock';
import { addPgAdd } from '../../../redux/slices/addPgSlice';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { setSidebar, setUser, useRole, useUser } from '../../../redux/slices/authSlice';

const AddPg = () => {
    const dispatch = useDispatch();
    const proxy = useProxyData();
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useUser();
    const role = useRole("Add PG");

    const initialValues = {
        pg_type: "",
        pg_name: "",
        "account_id": "",
        "label": "",
        "type": "",
        "email_id": "",
        "username": "",
        "password": "",
        "api_key": "",
        "api_token": "",
        "secret_key": "",
        "salt": "",
        "bank_name": "",
        "bank_type": "",
        "account_number": "",
        "ifsc_code": "",
        "upi_id": "",
        "mobile_number": "",
        "bouncer_sub_domain_url": "",
        "callback_sub_domain_url": "",
        "webhook_url": "",
        "min_limit": "",
        "max_limit": "",
        "daily_limit": "",
        "proxy_id": "",
        "merchant_id": ""
    };

    const schema = Yup.object({
        pg_type: Yup.string().trim().required("Please Select PG Type"),
        pg_name: Yup.string().trim().required("Please Add PG Name"),
        type: Yup.string().trim().required("Please Add Type"),
        label: Yup.string().trim().required("Please Add label"),
        account_id: Yup.string().trim().required("Please Add Account ID"),
        email_id: Yup.string().trim().required("Please Add Email ID"),
        bank_name: Yup.string().trim().trim().nullable().required("Please Add Bank Name"),
        bank_type: Yup.string().trim().required("Please Add Bank Type"),
        account_number: Yup.string().trim().required("Please Add Account Number"),
        ifsc_code: Yup.string().trim().required("Please Add IFSC Code"),
        upi_id: Yup.string().trim().required("Please Add UPI Id"),
        min_limit: Yup.string().trim().required("Please Add Min Limit"),
        max_limit: Yup.string().trim().required("Please Add Max Limit"),
        daily_limit: Yup.string().trim().required("Please Add Daily Limit"),
    });

    const bank_Available = async () => {
        try {
            const payload = {};
            await dispatch(Bank_Available(payload)).unwrap();
        } catch (err) { }
    };

    const onGetProxy = async () => {
        try {
            await dispatch(proxyGet()).unwrap();
        } catch (err) {
        }
    };

    useEffect(() => {
        bank_Available();
        onGetProxy();
    }, []);


    const { control, getValues, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValues,

    });

    const onSubmit = async () => {
        try {
            const values = getValues();
            setIsLoading(true);
            const payload = {
                formData: { ...values },
            };

            const res = await dispatch(addPgAdd(payload)).unwrap();
            if (res?.status) {
                const updatedSidebar = { ...user?.SiderBar, PG: res?.PG };
                const updatedUser = {
                    ...user,
                    SiderBar: {
                        ...user.SiderBar,
                        PG: res?.PG,
                    },
                };
                dispatch(setSidebar(updatedSidebar));
                dispatch(setUser(updatedUser));
                reset()
            }
            responseToaster(res);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const getOptions = (column) => {
        let options = [];
        if (column === "bank_name") {
            options = getBanklistOptions?.sort((a, b) => a?.label.localeCompare(b?.label))?.map((val) => ({
                value: val?.label,
                label: val?.label,
            }));
        } else if (proxy?.length) {
            if (column === "proxy_id") {
                options = [
                    ...proxy?.map((item) => ({
                        value: item?.id,
                        label: item?.label_name,
                    })),
                ];
            } else if (column === "is_seamless") {
                options = [
                    {
                        value: "1",
                        label: "Yes",
                    },
                    {
                        value: "0",
                        label: "No",
                    },
                ];
            } else if (column === "pg_type") {
                options = [
                    {
                        value: "Payout",
                        label: "Payout",
                    },
                    {
                        value: "Payin",
                        label: "Payin",
                    },
                ]
            }
            else if (column === "type") {
                options = [
                    {
                        value: "auto",
                        label: "Auto",
                    },
                    {
                        value: "manual",
                        label: "Manual",
                    },
                ]
            }
            else if (column === "bank_type") {
                options = [
                    { value: "current", label: "current" },
                    { value: "saving", label: "saving" },
                ]
            }
        }
        return [...options];
    };

    if (!role.includes("add-pg")) {
        return;
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader>
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <h4 className="main-title text-nowrap"><b>Add PG</b></h4>
                        <div className="w-100 d-flex justify-content-end button-space">
                            <Button className="graybutton" onClick={() => reset()}>
                                Cancel
                            </Button>
                            {role.includes("add-pg") ? <CustomeButton
                                type="submit"
                                className="submitbutton"
                                isButtonLoading={isLoading}
                            >
                                Submit
                            </CustomeButton> : null}
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className='py-1 border-bottom'>
                        <div className="d-flex align-items-start button-space flex-wrap">
                            {["pg_name",
                                "pg_type",
                                "type",

                            ]?.map((item, index) => {
                                if (
                                    item === "proxy_id" ||
                                    item === "is_seamless" ||
                                    item === "bank_name" ||
                                    item === "pg_type" ||
                                    item === "type" ||
                                    item === "bank_type"
                                ) {
                                    return (
                                        <div className="select-div" key={index}>
                                            <ControlledDropDown
                                                name={item}
                                                label={capitalCaseLabel(item)}
                                                options={getOptions(item)}
                                                placeholder={`Select ${capitalCaseLabel(item)}`}
                                                control={control}
                                                errors={errors}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: "width",
                                                        minHeight: "30px",
                                                        borderRadius: "6px",
                                                        borderColor: "#c9d5e1",
                                                        fontSize: 14,
                                                        height: "40px",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <div className="select-div" key={index}>
                                        <ControlledInput
                                            name={item}
                                            label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                                            placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                                            type={["min_limit", "max_limit", "daily_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : "text"}
                                            errors={errors}
                                            control={control}
                                            inputClassName="addpg_input"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="py-1 border-bottom">
                        <div className="d-flex align-items-start button-space flex-wrap">
                            {["bank_name",
                                "bank_type",
                                "account_number",
                                "ifsc_code",
                                "upi_id"
                            ]?.map((item, index) => {
                                if (
                                    item === "proxy_id" ||
                                    item === "is_seamless" ||
                                    item === "bank_name" ||
                                    item === "pg_type" ||
                                    item === "type" ||
                                    item === "bank_type"
                                ) {
                                    return (
                                        <div className="select-div" key={index}>
                                            <ControlledDropDown
                                                name={item}
                                                label={capitalCaseLabel(item)}
                                                options={getOptions(item)}
                                                placeholder={`Select ${capitalCaseLabel(item)}`}
                                                control={control}
                                                errors={errors}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: "width",
                                                        minHeight: "30px",
                                                        borderRadius: "6px",
                                                        borderColor: "#c9d5e1",
                                                        fontSize: 14,
                                                        height: "40px",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <div className="select-div" key={index}>
                                        <ControlledInput
                                            name={item}
                                            label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                                            placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                                            type={["min_limit", "max_limit", "daily_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : "text"}
                                            errors={errors}
                                            control={control}
                                            inputClassName="addpg_input"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='py-1 border-bottom'>
                        <div className="d-flex align-items-start button-space flex-wrap">
                            {[
                                "email_id",
                                "username",
                                "password",
                                "mobile_number",
                            ]?.map((item, index) => {
                                if (
                                    item === "proxy_id" ||
                                    item === "is_seamless" ||
                                    item === "bank_name" ||
                                    item === "pg_type" ||
                                    item === "type" ||
                                    item === "bank_type"
                                ) {
                                    return (
                                        <div className="select-div" key={index}>
                                            <ControlledDropDown
                                                name={item}
                                                label={capitalCaseLabel(item)}
                                                options={getOptions(item)}
                                                placeholder={`Select ${capitalCaseLabel(item)}`}
                                                control={control}
                                                errors={errors}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: "width",
                                                        minHeight: "30px",
                                                        borderRadius: "6px",
                                                        borderColor: "#c9d5e1",
                                                        fontSize: 14,
                                                        height: "40px",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <div className="select-div" key={index}>
                                        <ControlledInput
                                            name={item}
                                            label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                                            placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                                            type={["min_limit", "max_limit", "daily_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : "text"}
                                            errors={errors}
                                            control={control}
                                            inputClassName="addpg_input"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='py-1'>
                        <div className="d-flex align-items-start button-space flex-wrap">
                            {[
                                "account_id",
                                "proxy_id",
                                "api_key",
                                "api_token",
                                "secret_key",
                                "salt",
                                "bouncer_sub_domain_url",
                                "callback_sub_domain_url",
                                "webhook_url",
                                "min_limit",
                                "max_limit",
                                "daily_limit",
                                "label",
                                "merchant_id"
                            ]?.map((item, index) => {
                                if (
                                    item === "proxy_id" ||
                                    item === "is_seamless" ||
                                    item === "bank_name" ||
                                    item === "pg_type" ||
                                    item === "type" ||
                                    item === "bank_type"
                                ) {
                                    return (
                                        <div className="select-div" key={index}>
                                            <ControlledDropDown
                                                name={item}
                                                label={capitalCaseLabel(item)}
                                                options={getOptions(item)}
                                                placeholder={`Select ${capitalCaseLabel(item)}`}
                                                control={control}
                                                errors={errors}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: "width",
                                                        minHeight: "30px",
                                                        borderRadius: "6px",
                                                        borderColor: "#c9d5e1",
                                                        fontSize: 14,
                                                        height: "40px",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <div className="select-div" key={index}>
                                        <ControlledInput
                                            name={item}
                                            label={Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}
                                            placeholder={`Enter ${Object.keys(UPPERCASE_LABEL)?.includes(item) ? UPPERCASE_LABEL?.[item] : capitalCaseLabel(item)}`}
                                            type={["min_limit", "max_limit", "daily_limit", NUMBER_TYPE_FIELDS].includes(item) ? "number" : "text"}
                                            errors={errors}
                                            control={control}
                                            inputClassName="addpg_input"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Form>
    );
}

export default AddPg