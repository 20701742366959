import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { pgTestPayment } from "../../redux/slices/pgSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";

const PgTestModal = (props) => {
  const { onHide, isOpen } = props;
  const dispatch = useDispatch();

  const locations = useLocation()?.pathname?.split("/");
  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const payload = {
        payload: {
          bankId: isOpen?.account_id,
          pgName: params?.pgName,
          paymentAmt: values?.paymentAmt,
        },
        params,
      };
      await dispatch(pgTestPayment(payload)).unwrap();
      onClose();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: {},
  });

  const onClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">PG Test Modal</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <ControlledInput
            name="search"
            placeholder="Enter Amount"
            control={control}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PgTestModal;
