import HorizontalNavLink from "./HorizontalNavLink";
import HorizontalNavGroup from "./HorizontalNavGroup";

const resolveComponent = (item) => {
  if (item.child) return HorizontalNavGroup;
  return HorizontalNavLink;
};

const HorizontalNavItems = (props) => {
  const { horizontalNavItems } = props;
  const RenderMenuItems = horizontalNavItems?.map((item, index) => {
    const TagName = resolveComponent(item);

    return (
      <TagName
        {...props}
        hasParent={item?.hasParent === false ? false : true}
        key={index}
        item={item}
      />
    );
  });

  return <>{RenderMenuItems}</>;
};

export default HorizontalNavItems;
