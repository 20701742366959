import React, { useState } from "react";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { merchantMinMaxLimitUpdate } from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { responseToaster, seperator } from "../../../helperFunctions";
import { showToaster } from "../../../constant";
import Modal from "../../../components/Custom/Modals/Modal/Modal";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantMaxLimit = (props) => {
  const {
    updateDetails,
    setUpdateDetails,
    data,
    onGet,
    label = "Max Limit",
    modalText = "This action will truncate all checkout setting",
  } = props;

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const role = useRole("Merchant");

  const onUpdate = async (e) => {
    try {
      const payload = {
        merchantId: data?.merchantId,
        maxLimit: updateDetails?.maxLimit,
      };
      if (+data?.lower_limit >= payload?.maxLimit) {
        showToaster("Please enter upper amount than min limit", "Error");
        return;
      }

      setIsLoading(true);
      const res = await dispatch(merchantMinMaxLimitUpdate(payload)).unwrap();
      setIsLoading(false);
      responseToaster(res);
      await onGet();
      setUpdateDetails({});
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalopen(true);
  };

  return (
    <>
      <div
        className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="order-singal-div">
          <div>
            {label ? <span>{label} : </span> : null}
            {updateDetails.input === "max" &&
            updateDetails?.merchantId === data?.merchantId ? (
              <form className="d-flex tableinput" onSubmit={onSubmit}>
                <InputField
                  type="number"
                  autoFocus
                  value={updateDetails?.maxLimit}
                  placeholder={`Enter ${label}`}
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      maxLimit: e.target.value,
                    })
                  }
                />
                <Button
                  type="submit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={onSubmit}
                >
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className={`cursor-pointer`}
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={(e) => {
                  if (!role.includes("merchant-update")) {
                    return;
                  }
                  setUpdateDetails({
                    merchantId: data?.merchantId,
                    input: "max",
                    maxLimit: data?.upper_limit,
                  });
                }}
              >
                {`${seperator(data?.upper_limit)}`}
              </span>
            )}
          </div>
        </div>
      </div>
      <Modal
        isLoading={isLoading}
        isOpen={isModalOpen}
        onCancelClick={() => {
          setIsModalopen((isModalOpen) => !isModalOpen);
        }}
        onDoneClick={() => {
          onUpdate();
        }}
        title="Confirmation"
        content={
          <div>
            <p className="mb-50">Are you sure?</p>
            <p>{modalText}</p>
          </div>
        }
        doneText="Submit"
      />
    </>
  );
};

export default MerchantMaxLimit;
