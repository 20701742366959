import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getRoles, updateRoles,roleDetail, addRoles, deleteRoles } from "../services/rolesServices";

const initialState = {
  data: {},
  getRoleLoading:false,
  addRoleLoading:false,
};

export const rolessGet = createAsyncThunk("rolessGet", async (payload) => {
  return await getRoles(payload);
});

export const rolesAdd = createAsyncThunk("rolessAdd", async (payload) => {
  return await addRoles(payload);
});

export const rolessUpdate = createAsyncThunk("rolessUpdate", async (payload) => {
  return await updateRoles(payload);
});

export const rolesDelete = createAsyncThunk("roleDelete", async (payload) => {
  return await deleteRoles(payload);
});

export const rolesDetail = createAsyncThunk("rolesDetail", async (payload) => {
  return await roleDetail(payload);
});


const rolessSlice = createSlice({
  name: "rolessSlice",
  initialState,
  reducers: {
    clearRoles: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(rolessGet.pending, (state, action) => {
      state.getRoleLoading = true;
    });
    builder.addCase(rolessGet.fulfilled, (state, action) => {
      state.getRoleLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rolessGet.rejected, (state, action) => {
      state.getRoleLoading = false;
    });
  
  },
});
export default rolessSlice.reducer;
export const { clearRoles } = rolessSlice.actions;

export const selectRoles = (state) => {
  return state.roless.data;
};
export const useRoles = () => {
  const data = useSelector(selectRoles);
  return useMemo(() => data, [data]);
};
