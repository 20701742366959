import React from "react";
import { Button } from "reactstrap";
import LoadingGif from "../../../../assets/images/pages/loading.gif";

const CustomeButton = (props) => {
  const {
    onClick,
    isButtonLoading,
    children,
    href,
    disabled,
    type = "button",
    ...rest
  } = props;
  return (
    <>
    
    {href ?
    <a
    href={href}
    className={`${className} ${buttonClassName} ${
      !disabled ? "appl-btn" : "px-4"
    } rounded h-10 flex items-center justify-center`}
    {...rest}
  >
    {isLoading ? <Loader /> : children}
  </a> :
    <Button
      color="primary"
      onClick={onClick}
      disabled={isButtonLoading || disabled}
      type={type}
      {...rest}
    >
      {isButtonLoading ? (
        <img src={LoadingGif} alt="" className="img-fluid loaderimg" />
      ) : (
        children
      )}
    </Button>}
    </>
  );
};

export default CustomeButton;
