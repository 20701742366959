import { FACTORENABLE, ISAUTH, LOGIN, LOGIN_AGAIN, LOGOUT, LOGOUTUSER, VERIFYOTP } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const logIn = (payload) => {
  return apiInstance.post(LOGIN, payload);
};
export const logOutUser = (payload) => {
  return apiInstance.post(LOGOUTUSER, payload);
};

export const verifyOtp = (payload) => {
  return apiInstance.post(VERIFYOTP, payload);
};

export const factorEnable = (payload) => {
  return apiInstance.get(`${FACTORENABLE}/${payload}`);
};

export const checkAuthStatus = () => {
  return apiInstance.get(`${ISAUTH}`);
};

export const logInAgain = (payload) => {
  return apiInstance.post(LOGIN_AGAIN, payload);
};

export const logOut = (payload) => {
  return apiInstance.post(LOGOUT, payload);
};
