import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Modal } from "reactstrap";
import { Table, TableRow } from "../../../Table";
import { Mark_as_Account_Bankload } from "../../../../redux/slices/payinPayoutSlice";
import { seperator } from "../../../../helperFunctions";

const AccounLoadModal = (props) => {
  const { onHide, isOpen } = props;
  const { accountBankLoadState, accountBankLoadLoading } = useSelector(
    (state) => state.payinPayout
  );

  const [filterColumns, setFilterColumns] = useState([]);

  const [filter, setFilter] = useState({
    page_no: 1,
    limit: 10,
  });

  const dispatch = useDispatch();

  const columns = [
    {
      title: "BANK DETAILS",
      name: "label",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <span>{data?.label}</span>
              </div>
              <div className="order-singal-div">
                <span>{data?.pg_name}</span>
              </div>
              <div className="order-singal-div">
                <span>{data?.meta_id}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT	",
      name: "totalLoad",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <span>{seperator(data?.total_load)}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "TOTAL COUNT",
      name: "count",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <span>{seperator(data?.total_count,false)}</span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const On_Mark_As_manual_payout = async () => {
    try {
      await dispatch(Mark_as_Account_Bankload()).unwrap();
    } catch (err) {}
  };
  const onNewColums = () => {
    const newColumns = columns?.map((item) => item?.name);
    if (newColumns) {
      setFilterColumns(newColumns);
    }
  };

  useEffect(() => {
    onNewColums();
  }, []);

  useEffect(() => {
    if (isOpen) {
      On_Mark_As_manual_payout(isOpen);
    }
  }, [isOpen]);

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={isOpen}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Account Load Details</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body w-100">
        <Table
          columns={columns}
          isLoading={accountBankLoadLoading}
          data={Object.entries(accountBankLoadState?.data || {}) || []}
          isData={!!Object.keys(accountBankLoadState?.data || {})?.length}
          filterColumns={filterColumns}
          count={accountBankLoadState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {Object.entries(accountBankLoadState?.data || {})?.map(
            ([_, items]) => {
              return (
                <>
                  <Fragment>
                    <>
                      <TableRow
                        isExpandable={false}
                        columns={columns}
                        item={items}
                        filterColumns={filterColumns}
                      />
                    </>
                  </Fragment>
                </>
              );
            }
          )}
        </Table>
      </div>
      <div className="modal-footer">
        <Button className="graybutton"  onClick={() => onHide()}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default AccounLoadModal;
