import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Label, Modal } from "reactstrap";
import Uploadicon from "../../../../assets/images/pages/upload-cloud.png";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import {
  gatewayPaymentStatementGet,
  gatewayPayoutStatementGet,
  metriwsRead,
  useGatewayPaymentStatementState,
} from "../../../../redux/slices/metriwsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { useRole } from "../../../../redux/slices/authSlice";

const MetriwesFileUpload = (props) => {
  const { isModalOpen, onCloseModal, onGet } = props;
  const dispatch = useDispatch();
  const gatewayPaymentState = useGatewayPaymentStatementState();
  const [isLoading, setIsLoading] = useState(true);
  const [fileNotSupported, setFileNotSupported] = useState(null);
  const [mappings, setMappings] = useState({});
  const [availableHeadings, setAvailableHeadings] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const role = useRole("Gateway Statement");
  const schema = Yup.object({
    type: Yup.string().nullable().required("Please select type."),
    account: Yup.string().nullable().required("Please select account."),
    excelFile: Yup.string().required("Please upload excel file."),
    selectedHeadings: Yup.object().shape(
      fileNotSupported?.setRow?.reduce?.((acc, setRowItem) => {
        acc[setRowItem] = Yup.string().required(
          `Please select a heading for ${setRowItem}`
        );
        return acc;
      }, {})
    ),
  });
  const prepareTypeOption = [
    { label: "Deposit", value: "Deposit" },
    { label: "Withdrawal", value: "Withdrawal" },
  ];
  const {
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: null,
      account: null,
      selectedHeadings:{}
    },
  });

  const onClose = () => {
    onCloseModal();
    reset();
    setMappings({});
    setAvailableHeadings([]);
    setFileNotSupported(null);
  };

  const onSubmit = async () => {
    const values = getValues();
    const splitArray = values?.account?.split("-");
    setIsButtonLoading(true);
    try {
      const fileUpload = new FormData();
      if (values?.excelFile) {
        fileUpload.append("excelFile", values?.excelFile);
      }
      fileUpload.append("type", values?.type);
      fileUpload.append("accountId", splitArray?.[0]);
      fileUpload.append("pgName", splitArray?.[1]);

      Object.entries?.(mappings)?.forEach?.(([key, value]) => {
        fileUpload.append(`header_mappings[${key}]`, value);
        fileUpload.append(`header[]`, value);
      });

      const res = await dispatch(metriwsRead(fileUpload)).unwrap();
      if (res?.message === "File data not supported") {
        setFileNotSupported(res);
        setAvailableHeadings(res?.heading);
      } else {
        if (res) {
          responseToaster(res);
          onClose();
          await onGet();
        }
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const handleAccountOptions = async () => {
    try {
      const type = getValues().type;
      setIsLoading(true);
      if (type === "Deposit") {
        await dispatch(gatewayPaymentStatementGet()).unwrap();
      }
      if (type === "Withdrawal") {
        await dispatch(gatewayPayoutStatementGet()).unwrap();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (watch("type")) {
      handleAccountOptions();
    }
  }, [watch("type")]);

  const handleMappingChange = (setRowItem, selectedHeading) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [setRowItem]: selectedHeading,
    }));
    setValue(`selectedHeadings.${setRowItem}`, selectedHeading);
    trigger(`selectedHeadings.${setRowItem}`);
  };

  const prepareAccountOption = () => {
    const elseOptions =
      gatewayPaymentState?.data
        ?.filter?.((item) => item?.pgName !== "UPIPAY")
        ?.map?.((item) => ({
          value: `${item?.accountId}-${item?.pgName}`,
          label: `${item?.pgLabel} - ${item?.pgName}`,
        })) || [];

    return elseOptions || [];
  };

  const disabledOptions = Object.values?.(mappings) || [];

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Gateway Statement</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          {role.includes("add-gateway-statement-list") ? (
            <ControlledDropDown
              name="type"
              label="Type"
              placeholder="Select Type"
              options={prepareTypeOption}
              control={control}
              errors={errors}
            />
          ) : null}
          <ControlledDropDown
            name="account"
            label="Account"
            placeholder="Select Account"
            options={prepareAccountOption()}
            control={control}
            errors={errors}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
          <div className="select-div mt-1">
            <Label>Upload File</Label>
            <div className="proof-sec text-start">
              <label className="label">
                <input
                  id="excelFile"
                  name="excelFile"
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => {
                    setValue("excelFile", e?.target?.files[0]);
                    trigger("excelFile");
                  }}
                />
                <p>
                  <img src={Uploadicon} className="me-1 img-fluid" />
                  <span
                    className={`${
                      watch("excelFile")?.name ? "themecolor" : ""
                    }`}
                  >
                    {watch("excelFile")?.name || "Upload File (xlsx)"}
                  </span>
                </p>
              </label>
              {errors?.excelFile?.message ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  {errors?.excelFile?.message}
                </span>
              ) : null}
            </div>
          </div>
          {fileNotSupported ? (
            <div className="pt-50">
              {fileNotSupported?.setRow?.map?.((setRowItem) => {
                return (
                  <div key={setRowItem} className="row mb-25">
                    <div className="col-3">
                      <Label style={{ paddingTop: "6px" }}>{setRowItem}</Label>
                    </div>
                    <div className="col-9">
                      <ControlledDropDown
                        name={`selectedHeading-${setRowItem}`}
                        isDefaultOption={false}
                        placeholder={`Select ${setRowItem}`}
                        value={mappings?.[setRowItem]}
                        menuPlacement="auto"
                        isOptionDisabled={(option) => option?.disabled}
                        options={availableHeadings?.map?.((heading) => ({
                          value: heading,
                          label: heading,
                          disabled: disabledOptions?.includes?.(heading),
                        }))}
                        onChange={(e) =>
                          handleMappingChange(setRowItem, e.value)
                        }
                        control={control}
                      />
                      {errors?.selectedHeadings?.[setRowItem] ? (
                        <span style={{ color: "red", fontSize: 12 }}>
                          {errors?.selectedHeadings?.[setRowItem]?.message}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isButtonLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default MetriwesFileUpload;
