import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Get_PgPayin_Deposit_Summary,
  Get_PgPayin_Withdrawal_Summary,
} from "../services/pgPayInServices";

const initialState = {
  depositSummary: [],
  withdrawalSummary: [],
};

export const PgPayin_Deposit_Summary_Get = createAsyncThunk(
  "PgPayin_Deposit_Summary_Get",
  async (payload) => {
    return await Get_PgPayin_Deposit_Summary(payload);
  }
);

export const PgPayin_Withdrawal_Summary_Get = createAsyncThunk(
  "PgPayin_Withdrawal_Summary_Get",
  async (payload) => {
    return await Get_PgPayin_Withdrawal_Summary(payload);
  }
);

const pgPayInSlice = createSlice({
  name: "pgPayInSlice",
  initialState,
  reducers: {
    clearPgPayin: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(PgPayin_Deposit_Summary_Get.fulfilled, (state, action) => {
      state.depositSummary = action.payload;
    });
    builder.addCase(
      PgPayin_Withdrawal_Summary_Get.fulfilled,
      (state, action) => {
        state.withdrawalSummary = action.payload;
      }
    );
  },
});
export default pgPayInSlice.reducer;
export const { clearPgPayin } = pgPayInSlice.actions;

export const selectPgPayinDepositSummary = (state) => {
  return state.pgPayin.depositSummary;
};

export const usePgPayinDepositSummary = () => {
  const depositSummary = useSelector(selectPgPayinDepositSummary);
  return useMemo(() => depositSummary, [depositSummary]);
};

export const selectPgPayinWithdrawalSummary = (state) => {
  return state.pgPayin.withdrawalSummary;
};

export const usePgPayinWithdrawalSummary = () => {
  const withdrawalSummary = useSelector(selectPgPayinWithdrawalSummary);
  return useMemo(() => withdrawalSummary, [withdrawalSummary]);
};
