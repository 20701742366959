import InputField from "../../../components/Custom/Forms/InputField/InputField";
import {
  Merchant_Pay_In_Daily_Limit_Update,
  Merchant_Pay_In_Max_Limit_Update,
  Merchant_Pay_In_Min_Limit_Update,
  Merchant_Pay_In_Per_Limit_Update,
} from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import React from "react";
import { Button } from "reactstrap";
import { onFixed, responseToaster, seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantPayinAmount = (props) => {
  const {
    data,
    onGetMerchantPayIn,
    updateDetails,
    setUpdateDetails,
    setIsLoading,
  } = props;
  const dispatch = useDispatch();
  const role = useRole("Merchant");
  const perTurnOver = (+data?.present_revenue / +data?.daily_threshold) * 100 || 0;
  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const payload = {
        merchantId: data?.merchantId,
        pgName: data?.pgName,
        id: data?.id,
        pgId: data?.pgId,
        value: updateDetails?.value,
      };
      switch (updateDetails?.input) {
        case "min":
          const resMin = await dispatch(
            Merchant_Pay_In_Min_Limit_Update(payload)
          ).unwrap();
          responseToaster(resMin);
          setUpdateDetails({});
          break;
        case "max":
          const resMax = await dispatch(
            Merchant_Pay_In_Max_Limit_Update(payload)
          ).unwrap();
          responseToaster(resMax);
          setUpdateDetails({});
          break;
        case "daily":
          const resDaily = await dispatch(
            Merchant_Pay_In_Daily_Limit_Update(payload)
          ).unwrap();
          responseToaster(resDaily);
          setUpdateDetails({});
          break;
        case "per":
          const resPer = await dispatch(
            Merchant_Pay_In_Per_Limit_Update(payload)
          ).unwrap();
          responseToaster(resPer);
          setUpdateDetails({});
          break;
        default:
          break;
      }
      await onGetMerchantPayIn();
      setUpdateDetails({});
      setIsLoading(false);

    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
        <div className="order-singal-div">
          <div>
            <span>Min Amount : </span>
            {updateDetails?.input === "min" &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  value={updateDetails?.value}
                  placeholder="Enter Min Amount"
                  autoFocus
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button type="submit" color="primary" className="btn-smallsize">
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className="cursor-pointer"
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  if (!role.includes("merchant-deposit-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: "min",
                    value: data?.lower_amount,
                  });
                }}
              >
                {`${seperator(data?.lower_amount)}`}
              </span>
            )}
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Max Amount : </span>
            {updateDetails?.input === "max" &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  autoFocus
                  value={updateDetails?.value}
                  placeholder="Enter Max Amount"
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button type="submit" color="primary" className="btn-smallsize">
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className="cursor-pointer"
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  if (!role.includes("merchant-deposit-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: "max",
                    value: data?.upper_amount,
                  });
                }}
              >
                {`${seperator(data?.upper_amount)}`}
              </span>
            )}
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Current Turnover : </span>
            <span
              className={
                perTurnOver > 79
                  ? "text-red"
                  : ""
              }
            >
              {`${seperator(data?.present_revenue)}`}
            </span>
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Daily Limit : </span>
            {updateDetails?.input === "daily" &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  autoFocus
                  value={updateDetails?.value}
                  placeholder="Enter Daily Limit"
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button type="submit" color="primary" className="btn-smallsize">
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className="cursor-pointer"
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  if (!role.includes("merchant-deposit-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: "daily",
                    value: data?.daily_threshold,
                  });
                }}
              >
                {`${seperator(data?.daily_threshold)}`}
              </span>
            )}
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>% Limit : </span>
            {updateDetails?.input === "per" &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  autoFocus
                  id={"limit"}
                  value={updateDetails?.value}
                  placeholder="Enter % Limit"
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button
                  type="submit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={onUpdate}
                >
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className="cursor-pointer"
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  if (!role.includes("merchant-deposit-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: "per",
                    value: data?.perLimit,
                  });
                }}
              >
                {`${onFixed(data?.perLimit)}%`}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantPayinAmount;
