import React from "react";
import { Card, CardHeader } from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo } from "react";

import { Table, TableRow } from "../../../components/Table";
import {
  getLastUpiRate,
  useLastUpdateRate,
} from "../../../redux/slices/lastUpiRateSlice";
import { useRole } from "../../../redux/slices/authSlice";

const LastUpiRate = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const { isLoading } = useSelector((state) => state.lastUpiRate);
  const { lastUpiRateState } = useLastUpdateRate();

  const dispatch = useDispatch();
  const role = useRole("Last upi Rate");

  const columns = useMemo(
    () => [
      {
        title: "PG Name ",
        name: "pg_name",
      },
      {
        title: "PAYMENT METHOD",
        name: "payment_method",
      },

      {
        title: "PG BANK UPI ID",
        name: "pg_bank_upi_id",
      },
      {
        title: "PG BANK UPI Name",
        name: "pg_bank_upi_name",
      },
      {
        title: "Success",
        name: "success_count",
      },
      {
        title: "Pending",
        name: "pending_count",
      },
    ],
    []
  );

  const GetLastUpiRate = async () => {
    try {
      await dispatch(getLastUpiRate()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("lastUpiRate-list")) {
      GetLastUpiRate();
    }
  }, []);

  return (
    <>
      {role.includes("lastUpiRate-list") ? (
        <>
          <div className="activeUpiIds-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Last UPI Rate</b>
                    </h4>
                  </div>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={lastUpiRateState?.data || []}
                isData={!!lastUpiRateState?.data?.length}
                filterColumns={filterColumns}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                isExpandable={false}
              >
                {lastUpiRateState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default LastUpiRate;
