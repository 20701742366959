import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Add_Payout_Whitelist_Client,
  Edit_Payout_WhiteList_Limit,
  Get_Payout_Whitelist_Client,
  Update_Payout_Whitelist_Status,
  Update_Payout_Whitelist_Status_Manual,
} from "../services/whitelistClientServices";

const initialState = {
  data: [],
  whitelistIsLoading: true,
};
export const Payout_Whitelist_Client_Get = createAsyncThunk(
  "/Get_Payout_Whitelist_Client",
  async () => {
    return await Get_Payout_Whitelist_Client();
  }
);
export const UpdatePayoutWhitelistStatus = createAsyncThunk(
  "/Update_Payout_Whitelist_Status",
  async (payload) => {
    return await Update_Payout_Whitelist_Status(payload);
  }
);
export const UpdatePayoutWhitelistStatusManual = createAsyncThunk(
  "/Update_Payout_Whitelist_Status_Manual",
  async (payload) => {
    return await Update_Payout_Whitelist_Status_Manual(payload);
  }
);

export const EditPayoutWhiteListLimit = createAsyncThunk(
  "/Edit_Payout_WhiteList_Limit",
  async (payload) => {
    return await Edit_Payout_WhiteList_Limit(payload);
  }
);
export const addPayoutWhitelistClient = createAsyncThunk(
  "/addPayoutWhitelistClient",
  async (payload) => {
    return await Add_Payout_Whitelist_Client(payload);
  }
);
const whitelistClientSlice = createSlice({
  name: "whitelistClientSlice",
  initialState,
  reducers: {
    clearWhiteListProxy: () => initialState,
    setWhiteList: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Payout_Whitelist_Client_Get.fulfilled, (state, action) => {
      state.whitelistIsLoading = false;
      state.data = action.payload;
    });
    builder.addCase(Payout_Whitelist_Client_Get.pending, (state, action) => {
      state.whitelistIsLoading = true;
    });
    builder.addCase(Payout_Whitelist_Client_Get.rejected, (state, action) => {
      state.whitelistIsLoading = false;
    });
  },
});
export default whitelistClientSlice.reducer;
export const { clearWhiteListProxy, setWhiteList } = whitelistClientSlice.actions;

export const selectWhitelistState = (state) => {
  return state.whitelistClient.data;
};

export const useWhitelistState = () => {
  const data = useSelector(selectWhitelistState);
  return useMemo(() =>  data , [data]);
};
