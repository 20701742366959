import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { reset_Merchant_Account_Password } from "../../../../redux/slices/merchantSlice";
import { Button, Modal } from "reactstrap";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const ResetPassword = (props) => {
  const { id, onCloseResetPassword, resetPassword } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [latestPass, setLatestPass] = useState("");
  const ResetPassword = async () => {
    try {
      setIsButtonLoading(true);
      let response = await dispatch(
        reset_Merchant_Account_Password({ merchantId: id })
      ).unwrap();
      responseToaster(response);
      if (!!response) {
        setLatestPass(response?.data?.temp_password);
      }
      setIsButtonLoading(false);
    } catch (err) {
      onCloseResetPassword();
      setIsButtonLoading(false);
    }
  };
  return (
    <Modal isOpen={resetPassword} toggle={onCloseResetPassword} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Reset Password !</h3>
        <i
          onClick={() => {
            onCloseResetPassword();
            setLatestPass("");
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>
            {latestPass
              ? "Your new password is given below. Please store your password in a safe place."
              : " Are you sure you want to reset merchant password ?"}
          </p>
          <p>
            <b>{latestPass}</b>
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="text-center">
          <Button
            className="graybutton"
            onClick={() => {
              onCloseResetPassword();
              setLatestPass("");
            }}
          >
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={ResetPassword}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassword;
