// THIRD - PARTY IMPORT
import {
  Chart as ChartJS,
  CategoryScale,
  Title,
  Tooltip,
  LinearScale,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
// ChartJS.defaults.datasets.bar.maxBarThickness = 40;
// ChartJS.defaults.borderColor = "#00000008";

const LineChart = (props) => {
  const {
    options,
    data,
    height = 80,
    label,
    isLoading = false,
    showDataLabels = false,
    ...rest
  } = props;

  useEffect(()=>{
    if (!showDataLabels) {
      ChartJS.unregister(ChartDataLabels);
    }else{
      ChartJS.register(ChartDataLabels);
    }
  },[showDataLabels])

  return (
    <>
      {data?.datasets && !isLoading ? (
        <div className="px-2 py-2 space-y-4">
          {label ? (
            <span className="font-bold text-gray-600">{label}</span>
          ) : null}
          <Line options={options} data={data} height={height} {...rest} />
        </div>
      ) : null}
    </>
  );
};

export default LineChart;
