import React from "react";
import { useDispatch } from "react-redux";
import { verifyingUser } from "../../helperFunctions";
import { Button, Modal } from "reactstrap";
import { setQr } from "../../redux/slices/authSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";

const TwoFactorAuthModal = ({ isOpen, onHide, svgString }) => {
  const dispatch = useDispatch();
  if (!isOpen) return null;

  const handleOtpModal = () => {
    onHide?.();
    verifyingUser(dispatch, true);
    dispatch(setQr(""))
    localStorage.removeItem("qr")
  };

  return (
    <Modal
      className="update-manual-bank-modal"
      isOpen={isOpen}
      centered={true}
    >
      <div className="modal-header">
        <h4 className="modal-title mb-1 font-bold capitalize">
          Two factor authentication
        </h4>
      </div>
      <div className="modal-body px-2 pb-2">
        <div className="text-center">
          <img
            src={svgString}
            alt="QR Code"
            style={{ width: "200px", height: "200px" ,margin:"auto"}}
          />
          <p className="mt-50">
            To set up two-factor authentication, please scan the QR code above
            with Authenticator App.
          </p>
        </div>
      </div>
      <div className="p-1">
        <div className="d-flex align-items-center justify-content-end gap-2">
          <CustomeButton
          // className="graybutton"
            onClick={() => handleOtpModal()}
          >
            Scanned
          </CustomeButton>
        </div>
      </div>
    </Modal>

  );
};

export default TwoFactorAuthModal;
