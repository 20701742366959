import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestResponseModal from "../../../components/Custom/Modals/RequestResponseModal/RequestResponseModal";
import {
  GetPgWebHook,
  usePgWebHook,
} from "../../../redux/slices/pgWebHookSlice";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { Table, TableRow } from "../../../components/Table";
import { responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { TRA_PAGINATION_OPTIONS } from "../../../constant";
import PgWebhookModal from "../../../components/Custom/Modals/PgWebhook/PgWebhookModal";
import { useRole } from "../../../redux/slices/authSlice";

const PgWebHook = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.pgWebHook);

  const [data, setData] = useState({});
  const getPgWebHookState = usePgWebHook();
  const [isViewsendDataModal, setIsViewsendDataModal] = useState(false);

  const initialValues = {
    isFilter: false,
    page_no: 1,
    limit: 100,
    search: "",
  };

  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const role = useRole("PG Webhook");

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "TXN",
      name: "txnId",
    },
    {
      title: "PG ID",
      name: "pgId",
    },
    {
      title: "Is get",
      name: "isGet",
      Cell: (data) => <>{data?.isGet?.toString()}</>,
    },
    {
      title: "Pg name",
      name: "pgName",
    },
    {
      title: "CREATED AT",
      name: "createdAt",
    },
    {
      title: "DETAILS",
      name: "success_txn_per",
      Cell: (data) => {
        return (
          <>
            <Button
              color="primary"
              className="btn-smallsize"
              style={{ backgroundColor: "var(--chatlook-darkblue--)" }}
              onClick={() => onCloseModal(data)}
            >
              Details
            </Button>
          </>
        );
      },
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onCloseModal = (data) => {
    setData(data);
    setIsViewsendDataModal(!isViewsendDataModal);
  };

  const Get_Request_Response_Logs = async () => {
    try {
      const { page_no, limit, search } = filter;
      const payload = {
        filter_data: {
          pgName: search,
        },
        page_no,
        limit,
      };

      const res = await dispatch(GetPgWebHook(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("pgWebhook-list")) {
      Get_Request_Response_Logs();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("pgWebhook-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>PG Webhook</b>
                    </h4>
                  </div>

                  <div className="d-flex">
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledInput
                    placeholder="Enter Search Value"
                    name="search"
                    label="Search"
                    control={control}
                  />
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...initialValues });
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={getPgWebHookState?.data || []}
                isData={!!getPgWebHookState?.data?.length}
                filterColumns={filterColumns}
                isExpandable={false}
                paginationOptions={TRA_PAGINATION_OPTIONS}
                pagination={filter}
                count={getPgWebHookState?.total_item || 0}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
              >
                {getPgWebHookState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
            <PgWebhookModal
              data={data}
              isViewsendDataModal={isViewsendDataModal}
              onCloseModal={() => onCloseModal()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default PgWebHook;
