import { Table, TableRow } from "../../../components/Table";
import { Card, CardHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import {
  pgConfigGet,
  pgConfigUpdate,
  usePgConfigData,
} from "../../../redux/slices/pgConfigSlice";
import { useRole } from "../../../redux/slices/authSlice";

const PgConfig = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const role = useRole("PG config");

  const dispatch = useDispatch();

  const data = usePgConfigData();

  const columns = [
    {
      title: "Config Name",
      name: "config_name",
    },
    {
      title: "Config Value",
      name: "config_value",
      Cell: (data) => {
        return (
          <div>
            <ToggleSwitch
              isLoading={statusLoading === data?.config_name}
              value={data?.config_value ? 1 : 0}
              className={`${statusLoading ? "disabled" : ""}`}
              onChange={async (value) => {
                if (role.includes("update-pg-config-status")) {
                  const payload = {
                    pk: data?.config_name,
                    value,
                  };
                  await onUpdateStatus(payload);
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      setIsLoading(true);
      await dispatch(pgConfigGet()).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.pk);
      const res = await dispatch(pgConfigUpdate(payload)).unwrap();
      responseToaster(res);
      onGetData();
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("pgConfig-list")) {
      onGetData();
    }
  }, []);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("pgConfig-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>PG Config</b>
            </h4>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
    </div>
  );
};

export default PgConfig;
