import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrlImg } from "../../../redux/api/baseUrl";
import { useDispatch } from "react-redux";
import { matchResellerPwddata } from "../../../redux/slices/trackingSlice";
import toast from "react-hot-toast";
import { useRole, useUser } from "../../../redux/slices/authSlice";
import { getLocalData } from "../../../helperFunctions";

const customChilds = ["merchant", "ss-transaction", "bank-transaction"];
const SidebarMenu = (props) => {
  const { val, isDrawerOpen, level = 1, onClick, setIsSidebarOpen } = props;
  const role = useRole("Role Manager");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const getActiveLink = (val) => {
    const currentLocation = location?.pathname?.split("/")?.[1];
    const currentRoute = val?.route?.split("/")?.[1];
    return (
      (location?.pathname === val?.route && !val?.is_child) ||
      (customChilds?.includes(currentRoute) && currentLocation === currentRoute)
    );
  };
  const childRoutes = val?.child?.map((item) => item?.route);
  const activeLink = getActiveLink(val);

  useEffect(() => {
    if(childRoutes?.includes(location.pathname)){
      setIsOpen(true)
    }
  },[childRoutes])
  return (
    <>
      <li
        key={val}
        className={` d-flex align-items-center sidebar-item ${activeLink ? "active " : ""
          }`}
        // style={
        //   val?.group ? (

        //     paddingLeft: level * 10,
        //     gap: level > 1 ? 15 : 0,
        //     ) : ""
        // }
        style={{
          paddingLeft:
            val?.group === "PG" || val?.group === "DEVELOPER"
              ? level * 24
              : level * 10,
          gap: level > 1 ? 15 : 0,
        }}
        onClick={async () => {
          if (val?.is_child) {
            if (!childRoutes?.includes(location?.pathname)) {
              setIsOpen(!isOpen);
            }
            onClick?.();
          } else {
           /*  if (val?.route === "/resellerList" && role.includes("match-sub-user-pwd")) {
              try {
                const password = prompt("Please enter your password");
                if (password !== null) {
                  const token = getLocalData("token");

                  const res = await dispatch(
                    matchResellerPwddata({
                      email_id: token?.emailId,
                      password,
                    })
                  ).unwrap();
                  if (res?.status === true) {
                    navigate("/resellerList");
                    setIsSidebarOpen(false);
                  } else {
                    toast.error(res?.message, {
                      position: "top-right",
                    });
                  }
                }
              } catch (err) { }
            } else { */
              navigate(val?.route);
              setIsSidebarOpen(false);
            // }
          }
        }}
      >
        {val?.icon_class ? (
          <img
            className={`${isDrawerOpen ? "pe-1 larger-image" : "mini-sidebar smaller-image"
              } `}
            src={`${baseUrlImg}${val?.icon_class}`}
          />
        ) : (
          <div className="dot"></div>
        )}

        {isDrawerOpen ? (
          <span
            className={`text-nowrap ${childRoutes?.includes(location?.pathname) || isOpen
              ? "themecolor"
              : ""
              }`}
          >
            {val.name}
          </span>
        ) : null}
      </li>

      {isOpen && val?.is_child
        ? val?.child?.map((subVal) => {
          return (
            <SidebarMenu
              val={subVal}
              isDrawerOpen={isDrawerOpen}
              level={1 + level}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          );
        })
        : null}
    </>
  );
};

export default SidebarMenu;
