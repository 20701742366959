import {
  ADD_RESELLER,
  ADD_RESELLER_SETTLEMENT,
  ADD_RESELLER_TO_MERCHANT,
  DELETE_RESELLER_COMMISSION,
  DELET_RESELLER,
  GET_RESELLER,
  GET_RESELLER_COMISSION,
  GET_RESELLER_DETAILS,
  GET_RESELLER_MERCHANT_LIST,
  GET_RESELLER_SETTLEMENT,
  GET_RESELLER_SETTLEMENT_BALANCE,
  RESET_RESELLER_PASSWORD,
  UPDATE_RESELLER_COMMISSION,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getReseller = (payload) => {
  return apiInstance.post(GET_RESELLER, payload);
};
export const getResellerDetails = (payload) => {
  return apiInstance.post(GET_RESELLER_DETAILS, payload);
};
export const getResellerComission = (payload) => {
  return apiInstance.post(GET_RESELLER_COMISSION, payload);
};
export const getResellerMerchantList = (payload) => {
  return apiInstance.post(GET_RESELLER_MERCHANT_LIST, payload);
};
export const getResellerSettlement = (payload) => {
  return apiInstance.post(GET_RESELLER_SETTLEMENT, payload);
};
export const getResellerSettlementBalance = (payload) => {
  return apiInstance.post(GET_RESELLER_SETTLEMENT_BALANCE, payload);
};
export const addReseller = (payload) => {
  return apiInstance.post(ADD_RESELLER, payload);
};
export const addResellerToMerchant = (payload) => {
  return apiInstance.post(ADD_RESELLER_TO_MERCHANT, payload);
};
export const updateResellerCommission = (payload) => {
  return apiInstance.post(UPDATE_RESELLER_COMMISSION, payload);
};
export const deleteResellerCommission = (payload) => {
  return apiInstance.post(DELETE_RESELLER_COMMISSION, payload);
};
export const addResellerSettlement = (payload) => {
  return apiInstance.post(ADD_RESELLER_SETTLEMENT, payload);
};
export const resetResellerPassword = (payload) => {
  return apiInstance.post(RESET_RESELLER_PASSWORD, payload);
};
export const deletReseller = (payload) => {
  return apiInstance.post(DELET_RESELLER, payload);
};
