import React from "react";
import { Button, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const MerchantPayInBankModal = (props) => {
  const { isOpen, onCancelClick, onDoneClick, isButtonLoading } = props;

  return (
    <Modal isOpen={!!isOpen} toggle={onCancelClick} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Stop All Fedral Bank</h3>
        <i onClick={onCancelClick} className="fa-solid fa-xmark"></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to stop all fedral bank ?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onCancelClick}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={onDoneClick}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default MerchantPayInBankModal;
