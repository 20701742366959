import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { usersAdd, usersUpdate } from "../../../redux/slices/usersSlice";
// import { useSidebar } from "../../../redux/slices/authSlice";
import { DROPDOWN_ALL_VALUE, showToaster } from "../../../constant";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import CopyToClipboard from "react-copy-to-clipboard";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import { rolessGet, useRoles } from "../../../redux/slices/rolesSlice";

// const depositOptions = [
//   {
//     label: "Resend Webhook",
//     value: "Resend Webhook",
//   },
//   {
//     label: "Update New TempUtr",
//     value: "Update New TempUtr",
//   },
//   {
//     label: "Mark as Failed",
//     value: "Mark as Failed",
//   },
//   {
//     label: "Block Customer",
//     value: "Block Customer",
//   },
//   {
//     label: "Refund",
//     value: "Refund",
//   },
//   {
//     label: "Delete",
//     value: "Delete",
//   },
//   {
//     label: "Remove Fees",
//     value: "Remove Fees",
//   },
//   {
//     label: "Charge Back",
//     value: "Charge Back",
//   },
// ];
// const withdrawalOptions = [
//   {
//     label: "Update",
//     value: "Update",
//   },
//   {
//     label: "Set Initialized",
//     value: "Set Intialized",
//   },
//   {
//     label: "Set Withdraw To Failed",
//     value: "Set Withdraw To Failed",
//   },
//   {
//     label: "Resend Webhook",
//     value: "Resend Webhook",
//   },
// ];

// const merchantOptions = [
//   {
//     label: "Update",
//     value: "Update",
//   },
// ];

const AddUserModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const roleList = useRoles();
  const [isLoading, setIsLoading] = useState(false);
  // const [depositRoles, setDepositRoles] = useState([]);
  // const [withdrawalRoles, setWithdrawalRoles] = useState([]);
  // const [merchantlRoles, setMerchantRoles] = useState([]);
  const [isCopy, setIsCopy] = useState(false);
  // const sidebar = useSidebar();
  // const getInitialData = () => {
  //   const newData =
  //     sidebar?.MAIN?.concat(sidebar?.["DEPOSIT WITHDRAWAL"] || [])
  //       ?.concat(sidebar?.BANK || [])
  //       ?.concat(sidebar?.SUPPORT || [])
  //       ?.concat(sidebar?.SETTING?.PG || [])
  //       ?.concat(sidebar?.SETTING?.DEVELOPER || []) || [];
  //   return newData;
  // };
  // const [data, setData] = useState(getInitialData());
  const initialValues = {
    user_name: "",
    role_id: "",
  };

  const isEdit = !!isOpen?.id;
  const onSubmit = async () => {
    try {
      const values = getValuesForm();
      setIsLoading(true);
      const payload = {
        ...values,
        // permission: data
        //   ?.filter((item) => item?.checked)
        //   ?.map((item) => item?.name),
        // statusPermission: {
        //   Deposit: depositRoles,
        //   Withdrawal: withdrawalRoles,
        //   Merchant: merchantlRoles
        // },
      };
      let res;
      if (isEdit) {
        res = await dispatch(
          usersUpdate({ ...payload, id: isOpen?.id })
        ).unwrap();
        onClose();
      } else {
        res = await dispatch(usersAdd(payload)).unwrap();
        setIsCopy(res);
      }
      responseToaster(res);
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  // const onGetData = async () => {
  //   try {
  //     const res = await dispatch(rolessGet()).unwrap();

  //   } catch (err) {
  //     console.log('err', err)
  //   }
  // };

  // useEffect(() => {
  //   onGetData();
  // }, []);

  const {
    control,
    setValue,
    getValues: getValuesForm,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    // setData(getInitialData());
    // setDepositRoles([]);
    // setWithdrawalRoles([]);
    reset();
    setIsCopy(false);
    onHide();
  };

  // const prepareOptions = (key) => {
  //   if (key === "DEVELOPER" || key === "PG") {
  //     return (
  //       sidebar?.SETTING?.[key]?.map((item) => ({
  //         ...item,
  //         value: item?.name,
  //         label: item?.name,
  //       })) || []
  //     );
  //   } else if (key === "SUPPORT") {
  //     const newData =
  //       sidebar?.[key]
  //         ?.filter(
  //           (item) =>
  //             item?.name !== "User" &&
  //             item?.name !== "Role Manager" &&
  //             item?.name !== "Reseller User"
  //         )
  //         ?.map((item) => ({
  //           ...item,
  //           value: item?.name,
  //           label: item?.name,
  //         })) || [];
  //     return newData;
  //   } else {
  //     const newData =
  //       sidebar?.[key]
  //         ?.filter(
  //           (item) => item?.name !== "User" && item?.name !== "Role Manager"
  //         )
  //         ?.map((item) => ({
  //           ...item,
  //           value: item?.name,
  //           label: item?.name,
  //         })) || [];
  //     return newData;
  //   }
  // };

  // const getValues = () =>
  //   data?.filter((item) => item?.checked)?.map((item) => item?.name);

  useEffect(() => {
    if (isEdit) {
      setValue("user_name", isOpen?.userName);
      setValue("role_id", isOpen?.role_id);

      // setData(
      //   getInitialData()?.map((item) => ({
      //     ...item,
      //     checked: isOpen?.permission?.includes(item?.name),
      //   }))
      // );
      // setDepositRoles(isOpen?.statusPermission?.Deposit);
      // setWithdrawalRoles(isOpen?.statusPermission?.Withdrawal);
      // setMerchantRoles(isOpen?.statusPermission?.Merchant);
    }
  }, [isEdit, isOpen]);
  // useEffect(() => {
  //   setData(getInitialData());
  // }, [sidebar]);

  // const onChange = (e, option) => {
  //   const checked = option?.action === "select-option";
  //   const val = option?.option || option?.removedValue;
  //   if (checked) {
  //     const selectedData = [];
  //     prepareOptions(option?.name)?.forEach((item) => {
  //       if (item?.names?.length) {
  //         selectedData.push(...item.names);
  //       }
  //     });

  //     if (val?.names?.length > 1) {
  //       showToaster("All required permissions selected");
  //     }

  //     const newData = data?.map((item) => ({
  //       ...item,
  //       checked:
  //         val?.value === DROPDOWN_ALL_VALUE
  //           ? selectedData?.includes(item?.name)
  //           : val?.names?.some(
  //             (name) =>
  //               name.toLowerCase() === item?.name?.toLowerCase() ||
  //               (name.toLowerCase() === "ticket summery" &&
  //                 "ticket summary" === item?.name?.toLowerCase())
  //           )
  //             ? true
  //             : !!item?.checked,
  //     }));
  //     setData([...newData]);
  //   } else {
  //     if (val?.parents?.length) {
  //       const parentVal =
  //         data
  //           ?.filter((item) => item?.checked)
  //           ?.find((item) => val?.parents?.includes(item?.name)) || -1;
  //       if (parentVal?.name) {
  //         showToaster(`${parentVal?.name} Is Selected`, "Error");
  //         return;
  //       }
  //     }

  //     if (val.value === "Deposit") {
  //       setDepositRoles([]);
  //     }
  //     if (val.value === "Withdrawal") {
  //       setWithdrawalRoles([]);
  //     }
  //     if (val.value === "Merchant") {
  //       setMerchantRoles([]);
  //     }

  //     const newData = data?.map((item) => ({
  //       ...item,
  //       checked:
  //         val?.names?.some(
  //           (name) =>
  //             name.toLowerCase() === item?.name?.toLowerCase() ||
  //             (name.toLowerCase() === "ticket summery" &&
  //               "ticket summary" === item?.name?.toLowerCase())
  //         ) && !val?.blockChild?.includes(item?.name)
  //           ? false
  //           : !!item?.checked,
  //     }));
  //     setData([...newData]);
  //   }
  // };

  // const onDepositChange = (e, option) => {
  //   const checked = option?.action === "select-option";
  //   const val = option?.option || option?.removedValue;
  //   const data = depositRoles?.length ? [...depositRoles] : [];
  //   if (checked) {
  //     if (val.value === DROPDOWN_ALL_VALUE) {
  //       const newData = depositOptions
  //         ?.filter((item) => item?.value !== DROPDOWN_ALL_VALUE)
  //         ?.map((item) => item?.value);
  //       setDepositRoles(newData);
  //     } else {
  //       data.push(val.value);
  //       setDepositRoles(data);
  //     }
  //   } else {
  //     const newData = data?.filter((item) => item !== val.value);
  //     setDepositRoles(newData);
  //   }
  // };

  // const onWithdrawalChange = (e, option) => {
  //   const checked = option?.action === "select-option";
  //   const val = option?.option || option?.removedValue;
  //   const data = withdrawalRoles?.length ? [...withdrawalRoles] : [];
  //   if (checked) {
  //     if (val.value === DROPDOWN_ALL_VALUE) {
  //       const newData = withdrawalOptions
  //         ?.filter((item) => item?.value !== DROPDOWN_ALL_VALUE)
  //         ?.map((item) => item?.value);
  //       setWithdrawalRoles(newData);
  //     } else {
  //       data.push(val.value);
  //       setWithdrawalRoles(data);
  //     }
  //   } else {
  //     const newData = data?.filter((item) => item !== val.value);
  //     setWithdrawalRoles(newData);
  //   }
  // };
  // const onMerchantChange = (e, option) => {
  //   const checked = option?.action === "select-option";
  //   const val = option?.option || option?.removedValue;
  //   const data = merchantlRoles?.length ? [...merchantlRoles] : [];
  //   if (checked) {
  //     if (val.value === DROPDOWN_ALL_VALUE) {
  //       const newData = merchantOptions
  //         ?.filter((item) => item?.value !== DROPDOWN_ALL_VALUE)
  //         ?.map((item) => item?.value);
  //       setMerchantRoles(newData);
  //     } else {
  //       data.push(val.value);
  //       setMerchantRoles(data);
  //     }
  //   } else {
  //     const newData = data?.filter((item) => item !== val.value);
  //     setMerchantRoles(newData);
  //   }
  // };

  const prepareRoleOption = () => {
    const options = roleList?.data
      ?.slice()
      ?.sort((a, b) => a?.label?.localeCompare(b?.label))
      ?.map((val) => ({
        value: val?.id,
        label: val?.name,
      }));
    return options;
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      {isCopy?.name ? (
        <>
          <div>
            <div className="modal-header">
              <h3 className="modal-title mt-0">
                Your in! User account created.
              </h3>
              <i
                onClick={() => {
                  onClose();
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <div
              className="modal-body"
              style={{
                padding: 100,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                <span>Username :- </span>
                <span className="bold">{isCopy?.name}</span>
              </span>
              <span>
                <span>Password :- </span>
                <span className="bold">{isCopy?.password}</span>
              </span>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-end button-space">
                <CopyToClipboard text={`${isCopy?.name} ${isCopy?.password}`}>
                  <Button
                    className="submitbutton"
                    onClick={() => {
                      onClose();
                      showToaster("Copied");
                    }}
                  >
                    Copy & Close
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h3 className="modal-title mt-0">{`${
              isEdit ? "Edit" : "Add"
            } User`}</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <div className="selectitems">
              <ControlledInput
                name="user_name"
                label="Username"
                disabled={!!isEdit}
                placeholder="Enter Username"
                control={control}
              />
              {/* <ControlledDropDown
                name="MAIN"
                label="Main"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                placeholder="Select Main"
                options={prepareOptions("MAIN")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              <ControlledDropDown
                name="DEPOSIT WITHDRAWAL"
                label="Permission"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                placeholder="Select Permission"
                options={prepareOptions("DEPOSIT WITHDRAWAL")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              <ControlledDropDown
                name="BANK"
                label="Bank"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                placeholder="Select Bank"
                options={prepareOptions("BANK")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              <ControlledDropDown
                name="SUPPORT"
                label="Support"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                placeholder="Select Support"
                options={prepareOptions("SUPPORT")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              <ControlledDropDown
                name="PG"
                label="PG"
                isDefaultOption={false}
                placeholder="Select PG"
                options={prepareOptions("PG")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              <ControlledDropDown
                name="DEVELOPER"
                label="Developer"
                isDefaultOption={false}
                placeholder="Select Developer"
                options={prepareOptions("DEVELOPER")}
                isMulti
                value={getValues()}
                onChange={onChange}
                control={control}
              />
              {getValues()?.includes("Deposit") ? (
                <ControlledDropDown
                  name="Deposit"
                  label="Deposit"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  placeholder="Select Deposit"
                  options={depositOptions}
                  isMulti
                  value={depositRoles}
                  onChange={onDepositChange}
                  control={control}
                />
              ) : null}
              {getValues()?.includes("Withdrawal") ? (
                <ControlledDropDown
                  name="Withdrawal"
                  label="Withdrawal"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  placeholder="Select Withdrawal"
                  options={withdrawalOptions}
                  isMulti
                  value={withdrawalRoles}
                  onChange={onWithdrawalChange}
                  control={control}
                />
              ) : null}
              {getValues()?.includes("Merchant") ? (
                <ControlledDropDown
                  name="Merchant"
                  label="Merchant"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  placeholder="Select Merchant"
                  options={merchantOptions}
                  isMulti
                  value={merchantlRoles}
                  onChange={onMerchantChange}
                  control={control}
                />
              ) : null} */}
              <ControlledDropDown
                name="role_id"
                label="Role"
                // isDefaultOption={DROPDOWN_ALL_VALUE}
                placeholder="Select Role"
                options={prepareRoleOption()}
                // isMulti
                control={control}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                type="submit"
                className="submitbutton"
                isButtonLoading={isLoading}
              >
                {isEdit ? "Update" : "Submit"}
              </CustomeButton>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default AddUserModal;
