import { ROLE_DETAIL, GET_ROLE, UPDATE_ROLE, ADD_ROLE, ROLE_DELETE } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getRoles = (payload) => {
  return apiInstance.post(GET_ROLE, payload);
};
export const addRoles = (payload) => {
  return apiInstance.post(ADD_ROLE, payload);
};
export const roleDetail = (payload) => {
  return apiInstance.post(ROLE_DETAIL, payload);
};
export const updateRoles = (payload) => {
  return apiInstance.post(UPDATE_ROLE, payload);
};

export const deleteRoles = (payload) => {
  return apiInstance.post(ROLE_DELETE, payload);
};
