import { GET_PG_CONFIG, UPDATE_PG_CONFIG } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getPgConfig = (payload) => {
  return apiInstance.post(GET_PG_CONFIG, payload);
};

export const updatePgConfig = (payload) => {
  return apiInstance.post(UPDATE_PG_CONFIG, payload);
};
