import {
  AVAILABLE_BALANCE,
  DASHBOARD_DEPOSIT_CHART,
  DASHBOARD_WITHDRAWAL_CHART,
  GET_BANK_SYNC,
  GET_MM_DASHBOARD_DATA,
  GET_MM_DASHBOARD_SUMMARY,
  GET_MOBILE_SYNC,
  GET_PAYOUT_SUMMARY,
  GET_PG_SUMMARY,
  GET_TRANSACTION_SUMMARY,
  PAYOUT_CR_DATA,
  PAYOUT_SUMMARY,
  PAYMENT_DATA,
  DASHBOARD_DEPOSIT_CHART_LAST4,
  DASHBOARD_WITHDRAWAL_CHART_LAST4,
  DASHBOARD_DEPOSIT_CHART_GET,
  DASHBOARD_DEPOSIT_CHART_BANK,
  DASHBOARD_WITHDRAWAL_CHART_BANK
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Get_Dashboard_Deposit_Chart = (payload) => {
  return apiInstance.post(DASHBOARD_DEPOSIT_CHART, payload);
};
export const Get_Dashboard_Deposit_Chart_Last4 = (payload) => {
  return apiInstance.post(DASHBOARD_DEPOSIT_CHART_LAST4, payload);
};
export const Get_Dashboard_Withdrawal_Chart = (payload) => {
  return apiInstance.post(DASHBOARD_WITHDRAWAL_CHART, payload);
};
export const Get_Dashboard_Withdrawal_Chart_Last4 = (payload) => {
  return apiInstance.post(DASHBOARD_WITHDRAWAL_CHART_LAST4, payload);
};
export const Get_Available_Balance = (payload) => {
  return apiInstance.post(AVAILABLE_BALANCE, payload);
};
export const Get_Transaction_Summary = (payload) => {
  return apiInstance.post(GET_TRANSACTION_SUMMARY, payload);
};
export const Get_Payout_Summary = (payload) => {
  return apiInstance.post(GET_PAYOUT_SUMMARY, payload);
};
export const Get_Pg_Summary = (payload) => {
  return apiInstance.post(GET_PG_SUMMARY, payload);
};
export const Payout_CR_Data = (payload) => {
  return apiInstance.post(PAYOUT_CR_DATA, payload);
};
export const Get_MM_Dashboard_Data = (payload) => {
  return apiInstance.post(GET_MM_DASHBOARD_DATA, payload);
};
export const Get_MM_Dashboard_Summary = (payload) => {
  return apiInstance.post(GET_MM_DASHBOARD_SUMMARY, payload);
};
export const Payout_Summary = (payload) => {
  return apiInstance.post(PAYOUT_SUMMARY, payload);
};
export const Get_Bank_Sync = (payload) => {
  return apiInstance.post(GET_BANK_SYNC, payload);
};
export const Get_Mobile_Sync = (payload) => {
  return apiInstance.post(GET_MOBILE_SYNC, payload);
};
export const Get_Payment_data = (payload) => {
  return apiInstance.post(PAYMENT_DATA, payload);
};
export const Dashboard_Deposit_Chart_Bank = (payload) => {
  return apiInstance.post(DASHBOARD_DEPOSIT_CHART_BANK, payload);
};
export const Dashboard_Withdrawal_Chart_Bank = (payload) => {
  return apiInstance.post(DASHBOARD_WITHDRAWAL_CHART_BANK, payload);
};