import { useState } from "react";
import "./ProgressBar.scss";
import { onFixed, seperator } from "../../../../helperFunctions";

export const ProgressBar = ({ done, pendingLimit, dailyLimit }) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
      background: done > 99 ? "#ff0000" : "#1976d2",
    };

    setStyle(newStyle);
  }, 100);

  return (
    <>
      <div className="progress">
        <div className="progress-done" style={style}></div>
        <span style={{ color: "white", fontSize: "12px" }}>
          {done >= 100 ? 100 : onFixed(done)}%
        </span>
      </div>
      <div style={{ fontSize: "11px" }}>
        {seperator(pendingLimit, false)} pending of{" "}
        {seperator(dailyLimit, false)}
      </div>
    </>
  );
};
