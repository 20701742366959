import React, { useEffect } from "react";
import { Button, Form, Label, Modal } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  Add_Merchant,
  Merchant_Module_Get,
  useMerchantModuleState,
} from "../../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";

import Uploadicon from "../../../../assets/images/pages/upload-cloud.png";
import CheckBox from "../../Elements/CheckBox";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { showToaster } from "../../../../constant";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import CopyToClipboard from "react-copy-to-clipboard";
import { responseToaster } from "../../../../helperFunctions";
import { useRole } from "../../../../redux/slices/authSlice";

const AddMerchant = (props) => {
  const { onCloseModal, addMerchantModal, GetMerchantData } = props;
  const [merchantPermission, setMerchantPerssion] = useState([]);
  const dispatch = useDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [userMail, serUserMail] = useState("");

  const role = useRole("Merchant");

  const onClose = () => {
    reset();
    setIsCopy(false);
    onCloseModal();
  };
  const merchantModuleState = useMerchantModuleState();
  const initialValues = {
    merchantEmail: "",
    merchantName: "",
  };

  const onMerchantModule = async () => {
    try {
      await dispatch(Merchant_Module_Get()).unwrap();
    } catch (err) {}
  };
  useEffect(() => {
    if (role.includes("merchant-add")) {
      onMerchantModule();
    }
  }, []);

  const { control, getValues, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async () => {
    const values = getValues();
    serUserMail(values?.merchantEmail);
    const fileUpload = new FormData();
    if (values?.innerLogo) {
      fileUpload.append("innerLogo", values?.innerLogo);
    }
    if (values?.outerLogo) {
      fileUpload.append("outerLogo", values?.outerLogo);
    }
    fileUpload.append("merchantEmail", values?.merchantEmail);
    fileUpload.append("merchantName", values?.merchantName);
    // fileUpload.append("permission", JSON.stringify(merchantPermission) || []);

    try {
      setIsButtonLoading(true);
      const res = await dispatch(Add_Merchant(fileUpload)).unwrap();
      setIsCopy(res);
      // onClose();
      responseToaster(res);
      if (!!res) {
        GetMerchantData();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const allModule = merchantModuleState?.data?.map((val) => {
    return (
      <CheckBox
        name={val}
        value={val}
        label={val}
        onChange={(e) => {
          if (e.target.checked) {
            setMerchantPerssion([...merchantPermission, e.target.defaultValue]);
          }
        }}
      />
    );
  });

  if (!role.includes("merchant-add")) {
    return;
  }

  return (
    <Modal
      className="add-manual-payout-modal modal-lg"
      backdrop={"static"}
      isOpen={addMerchantModal}
      toggle={onClose}
      centered={true}
    >
      {isCopy?.data?.password ? (
        <>
          <div>
            <div className="modal-header">
              <h3 className="modal-title mt-0">
                You are in! User account has been created.
              </h3>
              <i
                onClick={() => {
                  onClose();
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <div
              className="modal-body"
              style={{
                padding: 100,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                <span>Account ID :- </span>
                <span className="bold text-[#4d4444]">
                  {isCopy?.data?.account_id}
                </span>
              </span>
              <span>
                <span>Account key :- </span>
                <span
                  className="bold text-[#4d4444]"
                  style={{ wordWrap: "break-word" }}
                >
                  {isCopy?.data?.account_key}
                </span>
              </span>
              <span>
                <span>Email ID :- </span>
                <span className="bold text-[#4d4444]">
                  {isCopy?.data?.email_id}
                </span>
              </span>
              <span>
                <span>Password :- </span>
                <span className="bold text-[#4d4444]">
                  {isCopy?.data?.password}
                </span>
              </span>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-end button-space">
                <CopyToClipboard
                  text={`Account ID: ${isCopy?.data?.account_id}\nAccount key: ${isCopy?.data?.account_key}\nEmail ID: ${isCopy?.data?.email_id}\nPassword: ${isCopy?.data?.password}`}
                >
                  <Button
                    className="submitbutton"
                    onClick={() => {
                      onClose();
                      showToaster("Copied");
                    }}
                  >
                    Copy & Close
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h3 className="modal-title mt-0">Add Merchant</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <div className="select-div">
              <ControlledInput
                name="merchantEmail"
                label="Merchant Email"
                className="w-100"
                type="email"
                placeholder="Enter Merchant Email"
                control={control}
              />
            </div>

            <div className="select-div">
              <ControlledInput
                name="merchantName"
                label="Merchant Name"
                className="w-100"
                type="text"
                placeholder="Enter Merchant Name"
                control={control}
              />
            </div>
            <div className="select-div mt-1">
              <Label>Upload Inner Logo</Label>
              <div className="proof-sec text-center">
                <label className="label">
                  <input
                    id="myfile"
                    accept=".svg"
                    type="file"
                    onChange={(e) => setValue("innerLogo", e?.target?.files[0])}
                  />
                  <p>
                    <img src={Uploadicon} className="me-2 img-fluid" />
                    <span
                      className={`${
                        watch("innerLogo")?.name ? "themecolor" : ""
                      }`}
                    >
                      {watch("innerLogo")?.name || "Upload Inner Logo (SVG)"}
                    </span>
                  </p>
                </label>
              </div>
            </div>

            <div className="select-div mt-1">
              <Label>Upload Outer Logo</Label>
              <div className="proof-sec text-center">
                <label className="label">
                  <input
                    id="myfile"
                    accept=".svg"
                    type="file"
                    onChange={(e) => {
                      setValue("outerLogo", e?.target?.files[0]);
                    }}
                  />
                  <p>
                    <img src={Uploadicon} className="me-2 img-fluid" />
                    <span
                      className={`${
                        watch("outerLogo")?.name ? "themecolor" : ""
                      }`}
                    >
                      {watch("outerLogo")?.name || "Upload Outer Logo (SVG)"}
                    </span>
                  </p>
                </label>
              </div>
              {/* Merchant Permission  */}
              {/* {allModule} */}
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button
                type="button"
                className="graybutton"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <CustomeButton
                className="submitbutton"
                isButtonLoading={isButtonLoading}
                type="submit"
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default AddMerchant;
