import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

const dashboardDeveloperSlice = createSlice({
  name: "dashboardDeveloperSlice",
  initialState,
  reducers: {
    clearDashboardDeveloper: () => initialState,
  },
  extraReducers: (builder) => {},
});
export default dashboardDeveloperSlice.reducer;
export const { clearDashboardDeveloper } = dashboardDeveloperSlice.actions;
