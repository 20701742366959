import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "reactstrap";
import { Webhook_Resend } from "../../../../redux/slices/payoutSlice";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const PayoutWebhookModal = (props) => {
  const { isOpen, onHide, onGet } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const Webhook_resend = async () => {
    try {
      setIsButtonLoading(true);
      let Payload = {
        withdrawalId: isOpen,
      };
      let response = await dispatch(Webhook_Resend(Payload)).unwrap();
      responseToaster(response);
      onHide();
      onGet();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };
  return (
    <Modal isOpen={!!isOpen} toggle={() => onHide()} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Resend Webhook</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to resend webhook?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onHide()}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={Webhook_resend}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default PayoutWebhookModal;
