import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import LineChart from "../../../components/Custom/Elements/Chart/LineChart";
import {
  bankMonitoringChartGet,
  bankMonitoringBanksGet,
  useBankMonitoringChart,
  useBankMonitoringBanksData,
  useBankMonitoringData,
  bankMonitoringGet,
  bankMonitoringDepositChartGet,
  useBankMonitoringDepositChart,
  useBankMonitoringPgData,
  bankMonitoringPgGet,
} from "../../../redux/slices/bankMonitoringSlice";
import { Table, TableCell, TableRow } from "../../../components/Table";
import { Payment_Data_Get } from "../../../redux/slices/dashboardSlice";
import BarChart from "../../../components/Custom/Elements/Chart/BarChart";
import Loader from "../../../components/Custom/Loader";
import { get_merchant_list } from "../../../redux/slices/payinPayoutSlice";
import {
  PgPayin_Deposit_Summary_Get,
  usePgPayinDepositSummary,
} from "../../../redux/slices/pgPayInSlice";
import { tranPaymentData } from "../../Main/Home/mock";
import StatsHorizontal from "../../../components/widgets/stats/StatsHorizontal";
import { Merchant_Pay_In_Available_Payment_Get } from "../../../redux/slices/merchantSlice";
import Tracking from "../../Support/Tracking/Tracking";
import UpiPendingTxn from "../../Support/UpiPendingTxn/UpiPendingTxn";
import {
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
  showToaster,
} from "../../../constant";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
    },
    title: {
      display: false,
      text: "Withdrawal Chart",
      align: "start",
      font: {
        size: 16,
      },
      padding: {
        bottom: 15,
        left: 10,
        top: 20,
      },
    },
    tooltip: {
      mode: "index",
      intersect: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        callback: (value, index) => {
          return +index % 2 === 0 ? value : null;
        },
      },
    },
    y: {
      beginAtZero: true,
    },
  },
};

const depositOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
    },
    title: {
      display: false,
      text: "Deposit Chart",
      align: "start",
      font: {
        size: 16,
      },
      padding: {
        bottom: 15,
        left: 10,
        top: 20,
      },
    },
  },
};

const levelOptions = [
  { value: "1", label: "Level 1" },
  { value: "2", label: "Level 2" },
  { value: "3", label: "Level 3" },
  { value: "4", label: "Level 4" },
];

const initialChartFilter = {
  metaId: DROPDOWN_ALL_VALUE,
  custLevel: DROPDOWN_ALL_VALUE,
  date: [new Date(), new Date()],
  isFilter: false,
};

const initialBankChartFilter = {
  bankId: DROPDOWN_ALL_VALUE,
  date: new Date(),
};

const initialBankSummaryFilter = {
  date: [new Date(), new Date()],
  merchant_id: DROPDOWN_ALL_VALUE,
  isLast24: false,
};

const initialUpiAppFilter = {
  date: [new Date(), new Date()],
  acNo: DROPDOWN_ALL_VALUE,
  isFilter: false,
};

const BankMonitoring = () => {
  const [chartFilter, setChartFilter] = useState({
    ...initialChartFilter,
  });
  const [bankChartFilter, setBankChartFilter] = useState(
    initialBankChartFilter
  );
  const [bankSummaryFilter, setBankSummaryFilter] = useState(
    initialBankSummaryFilter
  );
  const [upiAppFilter, setUpiAppFilter] = useState(initialUpiAppFilter);

  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPgLoading, setIsPgLoading] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [pgFilterColumns, setPgFilterColumns] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pgRefresh, setPgRefresh] = useState(false);
  const [bankSummaryFilterColumns, setBankSummaryFilterColumns] = useState([]);
  const [bankSummaryIsLoading, setBankSummaryIsLoading] = useState(false);
  const [pgFilter, setPgFilter] = useState(TRA_FILTER);
  const role = useRole("Bank Monitoring");
  const roleForSummery = useRole("Bank Summary");

  const {
    depositChartIsLoading,
    withdrawalChartIsLoading,
    getPaymentDataState,
  } = useSelector((state) => state.dashboard);

  const { merchantPayInAvailablePaymentState } = useSelector(
    (state) => state.merchant
  );

  const dispatch = useDispatch();
  const banks = useBankMonitoringBanksData();
  const data = useBankMonitoringData();
  const pgData = useBankMonitoringPgData();

  const chartData = useBankMonitoringChart();
  const depositChartData = useBankMonitoringDepositChart();
  const PgPayinDepositSummaryGetState = usePgPayinDepositSummary();

  const columns = [
    {
      title: "BANK",
      name: "BANK",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> {data?.avBankId || ""}</span>
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>{data?.acHolderName || ""}</span>
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>A/C : </span>
                  {data?.acNumber || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> IFSC : </span>
                  {data?.ifscCode || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTIVE",
      name: "ACTIVE",
      Cell: (data) => {
        return (
          <div className="center">
            <span
              className={data?.is_sync_active ? "green-light" : "red-light"}
            ></span>
          </div>
        );
      },
    },
    {
      title: "Success - 10M",
      name: "per_10_min_success",
      Cell: (data) => <span>{onFixed(data?.per_10_min_success)}%</span>,
    },
    {
      title: "Success - 20M",
      name: "per_20_min_success",
      Cell: (data) => <span>{onFixed(data?.per_20_min_success)}%</span>,
    },
    {
      title: "Success - 30M",
      name: "per_30_min_success",
      Cell: (data) => <span>{onFixed(data?.per_30_min_success)}%</span>,
    },
    {
      title: "Success - 40M",
      name: "per_40_min_success",
      Cell: (data) => <span>{onFixed(data?.per_40_min_success)}%</span>,
    },
    {
      title: "Success - 50M",
      name: "per_50_min_success",
      Cell: (data) => <span>{onFixed(data?.per_50_min_success)}%</span>,
    },
    {
      title: "Success - 60M",
      name: "per_60_min_success",
      Cell: (data) => <span>{onFixed(data?.per_60_min_success)}%</span>,
    },
  ];
  const pgColumns = [
    {
      title: "#",
      name: "ACTIVE",
      Cell: (data) => {
        return (
          <div className="">
            <span
              className={data?.is_sync_active ? "green-light" : "red-light"}
            ></span>
          </div>
        );
      },
    },
    {
      title: "BANK",
      name: "BANK",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span>{data.pgLabel}</span> ({data.bankId})
                </p>
              </div>
              {/* <div className="order-singal-div">
                <p>
                  <span> {data?.bankId || ""}</span>
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>{data?.pgLabel || ""}</span>
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>A/C : </span>
                  {data?.acNumber || ""}
                </p>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      title: "PG",
      name: "pgName",
    },
    {
      title: "Success - 10M",
      name: "per_10_min_success",
      Cell: (data) => <span>{onFixed(data?.per_10_min_success)}%</span>,
    },
    {
      title: "Success - 20M",
      name: "per_20_min_success",
      Cell: (data) => <span>{onFixed(data?.per_20_min_success)}%</span>,
    },
    {
      title: "Success - 30M",
      name: "per_30_min_success",
      Cell: (data) => <span>{onFixed(data?.per_30_min_success)}%</span>,
    },
    {
      title: "Success - 40M",
      name: "per_40_min_success",
      Cell: (data) => <span>{onFixed(data?.per_40_min_success)}%</span>,
    },
    {
      title: "Success - 50M",
      name: "per_50_min_success",
      Cell: (data) => <span>{onFixed(data?.per_50_min_success)}%</span>,
    },
    {
      title: "Success - 60M",
      name: "per_60_min_success",
      Cell: (data) => <span>{onFixed(data?.per_60_min_success)}%</span>,
    },
  ];

  const bankSummaryColumns = [
    {
      title: "#",
      name: "",
      Cell: (data) => {
        return (
          <>
            <div className="">
              <span
                className={`${checkIsHigherFailedRatio(
                  data?.Success,
                  data?.total_txn,
                  data?.Pending
                )}`}
              ></span>
            </div>
          </>
        );
      },
    },
    {
      title: "LABEL",
      name: "label",
      // Cell: (data) => {
      //   return (
      //     <>
      //       <div className="">
      //         <h6>
      //           <span class="font-weight-bold">{data.label}</span>(
      //           {data.pg_name}) ({data.meta_id})
      //         </h6>
      //       </div>
      //     </>
      //   );
      // },
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span>{data.label}</span> ({data.meta_id})
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PG",
      name: "pg_name",
    },
    {
      title: "PROCESSING",
      name: "Processing",
      Cell: (data) => {
        return (
          <>
            {/* <div className="">
              <h6>
                {data?.Processing || "-"}
                {preparePercentage(data?.Processing, data?.total_txn)}
              </h6>
            </div> */}
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  {data?.Processing || "-"}
                  {preparePercentage(data?.Processing, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PENDING",
      name: "Pending",
      Cell: (data) => {
        return (
          <>
            {/* <div className="">
              <h6>
                {data?.Pending || "-"}
                {preparePercentage(data?.Pending, data?.total_txn)}
              </h6>
            </div> */}

            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  {data?.Pending || "-"}
                  {preparePercentage(data?.Pending, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "SUCCESS",
      name: "Success",
      Cell: (data) => {
        return (
          <>
            {/* <div className="">
              <h6>
                {data?.Success || "-"}
                {preparePercentage(data?.Success, data?.total_txn)}
              </h6>
            </div> */}

            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  {data?.Success || "-"}
                  {preparePercentage(data?.Success, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "FAILED",
      name: "Failed",
      Cell: (data) => {
        return (
          <>
            {/* <div className="">
              <h6>
                {data?.Failed || "-"}
                {preparePercentage(data?.Failed, data?.total_txn)}
              </h6>
            </div> */}
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  {data?.Failed || "-"}
                  {preparePercentage(data?.Failed, data?.total_txn)}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "LAST TRANSACTION AT",
      name: "last_success_txn_date",
    },
    {
      title: "TOTAL",
      name: "total_txn",
    },
  ];

  const prepareOptions = () => {
    const options = banks?.data
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.account_id,
        label: `${val?.bank_name} ( ${val?.account_number} ) ( ${val?.account_holder_name} )`,
      }));
    return options;
  };

  const prepareBankOptions = () => {
    const options = depositChartData?.bankList
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.account_id,
        label: `${val?.bank_name} ( ${val?.account_number} ) ( ${val?.account_holder_name} )`,
      }));
    return options;
  };

  const preparePercentage = (status, total) => {
    status = status ? status : 0;
    total = total ? total : 0;
    if (status > 0) {
      return " (" + onFixed((status * 100) / total) + "%)";
    }
    return "";
  };

  const preparePayInPgOption = () => {
    const options = PgPayinDepositSummaryGetState?.merchantList
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.merchant_id,
        label: val?.merchant_name,
      }));
    return options;
  };

  const checkIsHigherFailedRatio = (failed, total, pending) => {
    failed = failed ? failed : 0;
    total = total ? total : 0;
    let isFailedDetect = false;
    let isPendingDetect = false;

    if ((failed * 100) / total < 25) {
      isFailedDetect = true;
    }
    if (!isFailedDetect) {
      if ((pending * 100) / total > 20) {
        isPendingDetect = true;
      }
    }

    if (isFailedDetect) {
      return "mo-red-light";
    }
    if (isPendingDetect) {
      return "mo-orange-light";
    }
  };

  const prepareTotalData = () => {
    let totalPending = 0;
    let totalFailed = 0;
    let totalProcessing = 0;
    let totalSuccess = 0;
    let totalTxn = 0;

    PgPayinDepositSummaryGetState?.data?.forEach((item, index) => {
      totalPending =
        totalPending + (item?.Pending ? parseInt(item?.Pending) : 0);
      totalFailed = totalFailed + (item?.Failed ? parseInt(item?.Failed) : 0);
      totalProcessing =
        totalProcessing + (item?.Processing ? parseInt(item?.Processing) : 0);
      totalSuccess =
        totalSuccess + (item?.Success ? parseInt(item?.Success) : 0);
      totalTxn = totalTxn + (item?.total_txn ? parseInt(item?.total_txn) : 0);
    });

    return (
      <>
        <tr className="lasttablerow">
          <TableCell>{""}</TableCell>
          <TableCell>{"Total"}</TableCell>
          <TableCell>{""}</TableCell>
          <TableCell>{`${seperator(totalProcessing, false)}`}</TableCell>
          <TableCell>{`${seperator(totalPending, false)}`}</TableCell>
          <TableCell>{`${seperator(totalSuccess, false)}`}</TableCell>
          <TableCell>{`${seperator(totalFailed, false)}`}</TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>{`${seperator(totalTxn, false)}`}</TableCell>
        </tr>
      </>
    );
  };

  const prepareBanklistOptions = () => {
    const opttions = merchantPayInAvailablePaymentState?.data?.manual
      ?.slice()
      ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
      ?.map((val) => ({
        value: val?.acNumber,
        label: `${val?.bankName} - ${val?.pgLabel} - ${val?.acNumber}`,
      }));
    return opttions;
  };

  const onGetChart = async () => {
    const { isFilter, date, ...rest } = chartFilter;
    const payload = {
      filter_data: rest,
      startDate: dateFormatter(date?.[0], "start"),
      endDate: dateFormatter(date?.[1], "end", date?.[0]),
    };
    try {
      const res = await dispatch(
        bankMonitoringDepositChartGet(payload)
      ).unwrap();
      if (isFilter) {
        showToaster(res?.message, res?.status ? "Success" : "Warning");
      }
    } catch (err) { }
  };

  const onGetBankChart = async () => {
    try {
      setIsLoading(true);
      setIsChartLoading(true);
      const { isFilter, date, ...rest } = bankChartFilter;
      const payload = {
        startDate: dateFormatter(date, "start"),
        endDate: dateFormatter(date, "end"),
        ...rest,
      };
      const res = await dispatch(bankMonitoringChartGet(payload)).unwrap();
      if (isFilter) {
        showToaster(res?.message, res?.status ? "Success" : "Warning");
      }
      setIsChartLoading(false);
      setIsLoading(false);
    } catch (err) {
      setIsChartLoading(false);
    }
  };

  const onGetBankMonitoring = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      await dispatch(bankMonitoringGet()).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetBankMonitoringPg = async (loading = false) => {
    try {
      if (loading) {
        setIsPgLoading(true);
      }
      const payload = {
        page_no: pgFilter?.page_no,
        limit: pgFilter?.limit,
      };
      await dispatch(bankMonitoringPgGet(payload)).unwrap();
      setIsPgLoading(false);
    } catch (err) {
      setIsPgLoading(false);
    }
  };

  const onGetUpiAppSummary = async () => {
    try {
      const { isFilter, date, ...rest } = upiAppFilter;
      const payload = {
        filter_data: {
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          ...rest,
        },
      };
      const res = await dispatch(Payment_Data_Get(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  const onGetBankMonitoringBanks = async () => {
    try {
      await dispatch(bankMonitoringBanksGet()).unwrap();
    } catch (err) { }
  };

  const onGetBankSummary = async () => {
    try {
      setBankSummaryIsLoading(true);
      const { isFilter, isLast24, date, ...rest } = bankSummaryFilter;
      const last24 = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      const payload = {
        startDate: dateFormatter(
          isLast24 ? last24 : date?.[0],
          isLast24 ? "default" : "start"
        ),
        endDate: dateFormatter(
          isLast24 ? new Date() : date?.[1],
          isLast24 ? "default" : "end",
          date?.[0]
        ),
        ...rest,
      };
      const res = await dispatch(PgPayin_Deposit_Summary_Get(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setBankSummaryIsLoading(false);
    } catch (err) {
      setBankSummaryIsLoading(false);
    }
  };

  const onGetMerchantList = async () => {
    try {
      await dispatch(get_merchant_list()).unwrap();
    } catch (err) { }
  };

  const onGetAvailablePayment = async () => {
    try {
      await dispatch(Merchant_Pay_In_Available_Payment_Get()).unwrap();
    } catch (err) { }
  };

  const onSubmitChart = () => {
    const values = getValuesChartFilter();
    setChartFilter({ ...chartFilter, ...values, isFilter: true });
  };

  const onSubmitBankChart = () => {
    const values = getValuesBankChartFilter();
    setBankChartFilter({ ...bankChartFilter, ...values, isFilter: true });
  };

  const onClearBankChart = () => {
    resetBankChartFilter();
    setBankChartFilter({
      ...initialBankChartFilter,
      isFilter: false,
    });
  };

  const onSubmitBankSummary = () => {
    const values = getValuesBankSummaryFilter();
    setBankSummaryFilter({
      ...bankSummaryFilter,
      ...values,
      isFilter: true,
    });
  };

  const onBankSummaryLast24 = () => {
    setBankSummaryFilter({
      ...bankSummaryFilter,
      isLast24: !bankSummaryFilter?.isLast24,
      isFilter: false,
    });
  };

  const onClearBankSummary = () => {
    resetBankSummaryFilter();
    setBankSummaryFilter({
      ...initialBankSummaryFilter,
      isFilter: false,
    });
  };

  const onSubmitUpiApp = () => {
    const values = getValuesUpiAppFilter();
    setUpiAppFilter({
      ...upiAppFilter,
      ...values,
      isFilter: true,
    });
  };

  useEffect(() => {
    if (role.includes("bankMonitering-list")) {
      onGetChart();
    }
  }, [chartFilter]);

  useEffect(() => {
    if (role.includes("bank-monitoring")) {
      onGetBankChart();
    }
  }, [bankChartFilter]);

  useEffect(() => {
    if (roleForSummery.includes("bankSummary-list")) {
      onGetBankSummary();
    }
  }, [bankSummaryFilter]);

  useEffect(() => {
    onGetUpiAppSummary();
  }, [upiAppFilter]);

  useEffect(() => {
    const newBankSummaryColumns = bankSummaryColumns?.map((item) => item?.name);
    const newFilterColumns = columns?.map((item) => item?.name);
    const newPgFilterColumns = pgColumns?.map((item) => item?.name);

    setBankSummaryFilterColumns(newBankSummaryColumns);
    setFilterColumns(newFilterColumns);
    setPgFilterColumns(newPgFilterColumns);

    onGetBankMonitoringBanks();
    if (role.includes("bankMonitering-list")) {
      onGetBankMonitoring(true);
    }
    onGetAvailablePayment();
    onGetMerchantList();
  }, []);

  useEffect(() => {
    if (role.includes("bank-pg-data-list")) {
      onGetBankMonitoringPg(true);
    }
  }, [pgFilter]);

  useEffect(() => {
    if (role.includes("bankMonitering-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetBankMonitoring(true);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh]);

  useEffect(() => {
    if (role.includes("bank-pg-data-list")) {
      const IntervalClick = setInterval(() => {
        if (pgRefresh) {
          onGetBankMonitoringPg(true);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [pgRefresh, pgFilter]);

  const {
    control: controlChartFilter,
    handleSubmit: handleSubmitChartFilter,
    getValues: getValuesChartFilter,
  } = useForm({
    defaultValues: initialChartFilter,
  });

  const {
    control: controlBankChartFilter,
    handleSubmit: handleSubmitBankChartFilter,
    getValues: getValuesBankChartFilter,
    reset: resetBankChartFilter,
  } = useForm({
    defaultValues: initialBankChartFilter,
  });

  const {
    control: controlBankSummaryFilter,
    handleSubmit: handleSubmitBankSummaryFilter,
    getValues: getValuesBankSummaryFilter,
    reset: resetBankSummaryFilter,
  } = useForm({
    defaultValues: initialBankSummaryFilter,
  });

  const {
    control: controlUpiAppFilter,
    handleSubmit: handleSubmitUpiAppFilter,
    getValues: getValuesUpiAppFilter,
  } = useForm({
    defaultValues: initialUpiAppFilter,
  });

  if (!role.includes("bankMonitering-list")) {
    return;
  }

  return (
    <div className={`payoutmanual-main-div `}>
      {role.includes("bankMonitering-list") ? <Card>
        <CardHeader className="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-start">
          <h4 className="main-title">
            <b>Deposit Chart</b>
          </h4>
          <div className="my-xl-auto right-content align-items-center">
            <Form
              className="d-flex align-items-center mt-md-0 mt-1"
              style={{ flexWrap: "wrap" }}
              onSubmit={handleSubmitChartFilter(onSubmitChart)}
            >
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-end inputgap">
                    <ControlledDropDown
                      name="metaId"
                      label="Bank"
                      isDefaultOption={DROPDOWN_ALL_VALUE}
                      options={prepareBankOptions()}
                      control={controlChartFilter}
                    />
                    <ControlledDropDown
                      name="custLevel"
                      label="Level"
                      isDefaultOption={DROPDOWN_ALL_VALUE}
                      options={levelOptions}
                      control={controlChartFilter}
                    />
                    <ControlledDatePicker
                      name="date"
                      label="Date"
                      maxDate={new Date()}
                      placeholder="Select Date"
                      selectsRange
                      control={controlChartFilter}
                    />
                    <Button color="primary" type="submit">
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </CardHeader>
        <CardBody>
          {depositChartIsLoading || withdrawalChartIsLoading ? (
            <div
              style={{
                height: 600,
              }}
              className="flex-center"
            >
              <Loader />
            </div>
          ) : (
            <div className="chartcss">
              <BarChart
                data={depositChartData?.data}
                options={depositOptions}
                isLoading={depositChartIsLoading}
              />
            </div>
          )}
        </CardBody>
      </Card> : null}

      {role.includes("bank-monitoring") ? <Card>
        <Form onSubmit={handleSubmitBankChartFilter(onSubmitBankChart)}>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>Bank Monitoring</b>
                </h4>
              </div>
              <div className="d-flex align-items-end button-space">
                <div
                  className="d-flex my-xl-auto right-content align-items-center button-space "
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="mb-0">Bank</p>
                  <ControlledDropDown
                    id="merchant_id"
                    name="bankId"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareOptions()}
                    control={controlBankChartFilter}
                  />
                  <div className="d-flex flex-column select-div">
                    <ControlledDatePicker
                      name="date"
                      placeholder="Select Date"
                      maxDate={new Date()}
                      control={controlBankChartFilter}
                    />
                  </div>
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={onClearBankChart}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </CardHeader>
        </Form>
        <div className="chartcss">
          <LineChart
            data={chartData?.data}
            options={chartOptions}
            isLoading={isChartLoading}
          />
        </div>
        {/* <CardHeader className="d-flex justify-content-between">
          <h6>Bank Monitoring</h6>
          <div className="mb-xl-0 d-flex align-items-center">
            <Button
              type="button"
              className={`${refresh ? "" : "filterbtn"} gap`}
              color={refresh ? "primary" : ""}
              onClick={() => setRefresh(!refresh)}
              title="Auto refresh"
            >
              {refresh ? (
                <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
              ) : (
                <i className="fa-solid fa-arrows-rotate"></i>
              )}
              Auto Refresh
            </Button>
          </div>
        </CardHeader> 
        <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading && !refresh}
            data={data || []}
            isData={!!data?.data?.length}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
            isExpandable={false}
          >
            {data?.data?.map((item) => {
              return (
                <TableRow
                  tdClassName="position-relative"
                  columns={columns}
                  filterColumns={filterColumns}
                  item={item}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </div>*/}
      </Card> : null}

      {role.includes("bank-pg-data-list") ? <Card>
        <CardHeader className="d-flex justify-content-between">
          <h6>MID Success Rate</h6>
          <div className="mb-xl-0 d-flex align-items-center">
            <Button
              type="button"
              className={`${pgRefresh ? "" : "filterbtn"} gap`}
              color={pgRefresh ? "primary" : ""}
              onClick={() => setPgRefresh(!pgRefresh)}
              title="Auto refresh"
            >
              {pgRefresh ? (
                <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
              ) : (
                <i className="fa-solid fa-arrows-rotate"></i>
              )}
              Auto Refresh
            </Button>
          </div>
        </CardHeader>
        <div className={`${isPgLoading && pgRefresh ? "opacity-25" : ""}`}>
          <Table
            columns={pgColumns}
            filterColumns={pgFilterColumns}
            isLoading={isPgLoading && !pgRefresh}
            data={pgData || []}
            isData={!!pgData?.data?.length}
            count={pgData?.total_item || 0}
            pagination={pgFilter}
            handlePaginationChange={(pagination) => {
              setPgFilter({
                ...pgFilter,
                ...pagination,
              });
            }}
            paginationOptions={TRA_PAGINATION_OPTIONS}
            onColumnsChange={(columns) => {
              setPgFilterColumns(columns);
            }}
            isExpandable={false}
          >
            {pgData?.data?.map((item) => {
              return (
                <TableRow
                  tdClassName="position-relative"
                  columns={pgColumns}
                  filterColumns={pgFilterColumns}
                  item={item}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </div>
      </Card> : null}

      <Card>
        <CardHeader className="d-flex flex-column justify-content-md-between justify-content-start align-items-start">
          <Form
            className="d-flex justify-content-between w-100"
            onSubmit={handleSubmitUpiAppFilter(onSubmitUpiApp)}
          >
            <div className=" ">
              <h5 className="tab-table-heading">UPI App Summary</h5>
            </div>
            <div className="d-flex button-space ">
              <ControlledDropDown
                name="acNo"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={prepareBanklistOptions()}
                control={controlUpiAppFilter}
              />
              <ControlledDatePicker
                name="date"
                maxDate={new Date()}
                placeholder="Select Date"
                selectsRange
                control={controlUpiAppFilter}
              />
              <Button color="primary" type="submit">
                Apply
              </Button>
            </div>
          </Form>
          <div className=" button-space withcssupi pt-1">
            {getPaymentDataState?.data
              ? getPaymentDataState?.data?.map((sale, index) => {
                return (
                  <div
                    className={` ${index === 4
                      ? ""
                      : index === 4
                        ? ""
                        : index === 5
                          ? ""
                          : ""
                      }`}
                    key={index}
                  >
                    <StatsHorizontal
                      className="fontstyle imgstyle"
                      color="primary"
                      index={index}
                      image={tranPaymentData?.[sale?.payment_method]}
                      count_per={`~ ${onFixed(sale?.percentage_count)}%`}
                      count={sale?.payment_method}
                      statTitle={seperator(sale?.total_amount)}
                      renderStats={
                        <h5
                          className="fw-bolder"
                          title={sale?.percentage_count}
                        >
                          {sale?.payment_method}
                        </h5>
                      }
                    />
                  </div>
                );
              })
              : null}
          </div>
        </CardHeader>
      </Card>

      {roleForSummery.includes("bankSummary-list") ? <div className="merchant-main-div">
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title">
                <b>Bank Summary</b>
              </h4>
              <Form
                className="d-flex my-xl-auto right-content align-items-center button-space flex-wrap "
                onSubmit={handleSubmitBankSummaryFilter(onSubmitBankSummary)}
              >
                <p className="mb-0">Client</p>
                <ControlledDropDown
                  name="merchant_id"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={preparePayInPgOption()}
                  control={controlBankSummaryFilter}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  maxDate={new Date()}
                  selectsRange
                  control={controlBankSummaryFilter}
                />
                <Button
                  type="button"
                  className={bankSummaryFilter?.isLast24 ? "" : "filterbtn"}
                  color={bankSummaryFilter?.isLast24 ? "primary" : ""}
                  onClick={onBankSummaryLast24}
                >
                  {bankSummaryFilter?.isLast24
                    ? "Set Default"
                    : "Set Last 24 Hour"}
                </Button>
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={onClearBankSummary}
                >
                  Clear
                </Button>
              </Form>
            </div>
          </CardHeader>
          <Table
            columns={bankSummaryColumns}
            filterColumns={bankSummaryFilterColumns}
            isLoading={bankSummaryIsLoading && !refresh}
            data={PgPayinDepositSummaryGetState?.data}
            isData={!!PgPayinDepositSummaryGetState?.data?.length}
            isExpandable={false}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
          >
            {PgPayinDepositSummaryGetState?.data?.map((val, index) => {
              return (
                <TableRow
                  columns={bankSummaryColumns}
                  filterColumns={bankSummaryFilterColumns}
                  isExpandable={false}
                  item={val}
                />
              );
            })}
            {prepareTotalData()}
          </Table>
        </Card>
      </div> : null}
      {role.includes("tracking-list") ?
        <Tracking bankOptions={prepareBankOptions()} />
        : null}
      <UpiPendingTxn />
    </div>
  );
};
export default BankMonitoring;
