import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import "./UpdateManualBankTraModal.scss";
import { useDispatch } from "react-redux";
import { Status_Update_Payout } from "../../../../redux/slices/payoutSlice";
import DropDown from "../../Forms/DropDown/DropDown";
import InputField from "../../Forms/InputField/InputField";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const UpdateManualBankTraModal = ({ isOpen, onHide, onGet }) => {
  const [bankUTR, setBankUTR] = useState(isOpen || {});

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setBankUTR(isOpen);
    }
  }, [isOpen]);

  const Update_Payout = async () => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Status_Update_Payout(bankUTR)).unwrap();
      if (res) {
        responseToaster(res);
        onGet();
        onClose();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    setBankUTR({});
    onHide();
  };

  const statusOptions = [
    {
      value: "Success",
      label: "Success",
    },
  ];

  return (
    <Form>
      <Modal
        isOpen={!!isOpen}
        toggle={() => onClose()}
        className="update-manual-bank-modal"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Manual Bank Transaction</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100">
          <InputField
            label="Bank UTR"
            type="text"
            value={bankUTR?.utr}
            placeholder="Enter Bank UTR"
            onChange={(e) => setBankUTR({ ...bankUTR, utr: e.target.value })}
          />
          <DropDown
            label="Status"
            placeholder="Select Status"
            isDefaultOption={false}
            options={statusOptions}
            value={bankUTR?.withdrawalSts}
            onChange={(e) => {
              setBankUTR({
                ...bankUTR,
                withdrawalSts: e.value,
              });
            }}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              className="submitbutton"
              onClick={Update_Payout}
              isButtonLoading={isButtonLoading}
            >
              Update
            </CustomeButton>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default UpdateManualBankTraModal;
