import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

const depositSummarySlice = createSlice({
  name: "depositSummarySlice",
  initialState,
  reducers: {
    clearDepositSummary: () => initialState,
  },
  extraReducers: (builder) => {},
});
export default depositSummarySlice.reducer;
export const { clearDepositSummary } = depositSummarySlice.actions;
