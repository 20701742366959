import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSmsLogs } from "../services/smsLogsSrevices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  data: {},
};

export const smsLogsGet = createAsyncThunk("/smsLogsGet", async (payload) => {
  return await getSmsLogs(payload);
});

const smsLogsSlice = createSlice({
  name: "smsLogsSlice",
  initialState,
  reducers: {
    clearSmsLogs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(smsLogsGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default smsLogsSlice.reducer;
export const { clearSmsLogs } = smsLogsSlice.actions;

export const selectSmsLogs = (state) => {
  return state.smsLogs.data;
};
export const useSmsLogsData = () => {
  const smsLogs = useSelector(selectSmsLogs);
  return useMemo(() => smsLogs, [smsLogs]);
};
