import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import {
  EditPayoutWhiteListLimit,
  Payout_Whitelist_Client_Get,
  UpdatePayoutWhitelistStatus,
  UpdatePayoutWhitelistStatusManual,
  setWhiteList,
  useWhitelistState,
} from "../../../redux/slices/whitelistClientSlice";
import Avatar from "../../../components/Custom/Elements/Avatar/Avatar";
import AddWhiteListClient from "../../../components/Custom/Modals/AddWhiteListClient/AddWhiteListClient";
import { responseToaster, seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const MobileCell = (data) => {
  const [mobileText, setMobileText] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setMobileText(data?.mobileId);
  }, [data, isOpen]);

  const onUpdate = async (e) => {
    try {
      e.preventDefault();

      await data
        ?.onUpdateMobileNumber({
          pk: data?.merchant_id,
          value: +mobileText,
          column_name: data?.type,
        })
        .unwrap();

      setMobileText();
      setIsOpen(!isOpen);
    } catch (err) { }
  };
  return (
    <>
      {isOpen ? (
        <form onSubmit={onUpdate}>
          <div className="d-flex tableinput">
            <InputField
              type="number"
              placeholder={data?.placeholder}
              value={mobileText}
              onChange={(e) => {
                setMobileText(e.target.value);
              }}
            />
            <Button type="submit" color="primary" className="btn-smallsize">
              <i className="fa-solid fa-check"></i>
            </Button>
            <Button
              color="danger"
              className="btn-smallsize"
              onClick={async () => {
                setMobileText();
                setIsOpen(!isOpen);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </div>
        </form>
      ) : (
        <span
          onClick={(e) => {
            setMobileText(+data?.mobile_number);
            setIsOpen(!isOpen);
          }}
          style={{
            color: "var(--chatlook-darkblue--)",
          }}
          className="cursor-pointer"
        >
          {seperator(data?.mobileId, data?.isRupee)}
        </span>
      )}
    </>
  );
};

const WhitelistClient = () => {
  const dispatch = useDispatch();
  const data = useWhitelistState();
  const { whitelistIsLoading } = useSelector((state) => state.whitelistClient);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusAutoLoading, setStatusAutoLoading] = useState(false);
  const [statusManualLoading, setStatusManualLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const role = useRole("Client Withdrawal");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [addWhitelistModal, setAddWhitelistModal] = useState(false);

  const onCloseaddWhitelistModal = () => {
    setAddWhitelistModal(false);
  };

  const statusOption = [
    { value: 1, label: "Enabled", activeClassName: "green" },
    { value: 0, label: "Disabled", activeClassName: "red" },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const Get_Payout_Whitelist_Client = async (payload) => {
    try {
      if (role.includes("clientWithdrawal-list")) {
        await dispatch(Payout_Whitelist_Client_Get(payload)).unwrap();
      }
    } catch (err) { }
  };
  const onUpdatePayoutWhitelistStatus = async (payload) => {
    try {
      setStatusAutoLoading(payload?.merchant_id);

      const res = await dispatch(UpdatePayoutWhitelistStatus(payload)).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.merchant_id === payload?.merchant_id
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], is_active: payload?.status };
        dispatch(setWhiteList({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusAutoLoading(false);
    } catch (err) {
      setStatusAutoLoading(false);
    }
  };

  const onUpdatePayoutWhitelistStatusManual = async (payload) => {
    try {
      setStatusManualLoading(payload?.merchant_id);

      const res = await dispatch(
        UpdatePayoutWhitelistStatusManual(payload)
      ).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.merchant_id === payload?.merchant_id
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = {
          ...newData[index],
          is_manual_payout: payload?.is_manual_payout,
        };
        dispatch(setWhiteList({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusManualLoading(false);
    } catch (err) {
      setStatusManualLoading(false);
    }
  };

  const onEditPayoutWhiteListLimit = async (payload) => {
    try {
      const res = await dispatch(EditPayoutWhiteListLimit(payload)).unwrap();
      if (res) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (role.includes("clientWithdrawal-list")) {
      Get_Payout_Whitelist_Client();
    }
  }, []);

  const columns = [
    {
      title: "CLIENT",
      name: "",
      sortablekey: "",
      sortable: true,
      Cell: (data) => {
        return (
          <>
            <div className="d-flex button-space align-items-center profilesec">
              <div className="d-flexcursor-pointer align-items-center avtarstyle">
                <Avatar
                  name={data?.merchant_details?.merchant_name}
                  sx={{ width: 30, height: 30, fontSize: 14 }}
                />
              </div>
              <div>
                <div className="order-singal-div">
                  <h5>{data?.merchant_details?.merchant_name || ""}</h5>
                  <p>{data?.merchant_id || ""}</p>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AUTO STATUS",
      name: "auto_status",

      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusAutoLoading === data?.merchant_id}
              options={statusOption}
              className={`${statusAutoLoading || statusManualLoading ? "disabled" : ""
                }`}
              disabled={!role?.includes("client-withdrawal-status")}
              value={data?.is_active ? 1 : 0}
              onChange={async (value) => {
                try {
                  const payload = {
                    merchant_id: data?.merchant_id,
                    status: value,
                  };
                  const res = await onUpdatePayoutWhitelistStatus(payload);
                  if (res) {
                    responseToaster(res);
                  }
                } catch (err) { }
              }}
            />
          </>
        );
      },
    },
    {
      title: "PRIORITY",
      name: "priority",
      Cell: (data) => {
        return (
          <>
            <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
              <div className="order-singal-div">
                {role.includes("client-withdrawal-limit") ?
                  <MobileCell
                    placeholder="Enter Priority"
                    {...data}
                    mobileId={data?.priority}
                    type="priority"
                    onUpdateMobileNumber={async (payload) => {
                      await onEditPayoutWhiteListLimit(payload);
                      await Get_Payout_Whitelist_Client();
                    }}
                    isRupee={false}
                  />
                  : data?.priority}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "MIN LIMIT",
      name: "min_limit",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                {role.includes("client-withdrawal-limit") ?
                  <MobileCell
                    placeholder="Enter Min Limit"
                    {...data}
                    isRupee={true}
                    mobileId={data?.min_auto_limit}
                    type="min_auto_limit"
                    onUpdateMobileNumber={async (payload) => {
                      await onEditPayoutWhiteListLimit(payload);
                      await Get_Payout_Whitelist_Client();
                    }}
                  />
                  : data?.min_auto_limit}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "MAX LIMIT",
      name: "max_limit",
      Cell: (data) => {
        return (
          <div>
            {role.includes("client-withdrawal-limit") ?
              <MobileCell
                {...data}
                placeholder="Enter Max Limit"
                isRupee={true}
                mobileId={data?.max_auto_limit}
                type="max_auto_limit"
                onUpdateMobileNumber={async (payload) => {
                  await onEditPayoutWhiteListLimit(payload);
                  await Get_Payout_Whitelist_Client();
                }}
              /> : data?.max_auto_limit}
          </div>
        );
      },
    },
    {
      title: "MANUAL STATUS",
      name: "manual_status",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusManualLoading === data?.merchant_id}
              options={statusOption}
              className={`${statusManualLoading || statusAutoLoading ? "disabled" : ""
                }`}
              disabled={!role?.includes("client-withdrawal-status")}
              value={data?.is_manual_payout ? 1 : 0}
              onChange={async (value) => {
                try {
                  const payload = {
                    merchant_id: data?.merchant_id,
                    is_manual_payout: value,
                  };
                  const res = await onUpdatePayoutWhitelistStatusManual(
                    payload
                  );
                  if (res) {
                    responseToaster(res);
                  }
                } catch (err) { }
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setTableData(data?.data?.length ? [...data.data] : []);
  }, [data]);

  if (!role.includes("clientWithdrawal-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>White List Client</b>
            </h4>
            <div className="d-flex" style={{ gap: "10px" }}>
              {role.includes("add-client-withdrawal") ? <Button
                type="submit"
                color="primary"
                onClick={() => setAddWhitelistModal(true)}
              >
                Add
              </Button> : null}
              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                  title="Column Filter"
                >
                  <IoFilterOutline fill="#7367F0" />
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={whitelistIsLoading || isLoading}
          data={data?.data}
          setData={setTableData}
          isData={!!data?.data?.length}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {tableData?.map((item) => {
            return (
              <TableRow
                tdClassName="position-relative"
                item={item}
                columns={columns}
                isExpandable={false}
                filterColumns={filterColumns}
              />
            );
          })}
        </Table>
      </Card>
      <AddWhiteListClient
        addWhitelistModal={addWhitelistModal}
        onCloseaddWhitelistModal={onCloseaddWhitelistModal}
        onGet={() => Get_Payout_Whitelist_Client()}
      />
    </>
  );
};

export default WhitelistClient;
