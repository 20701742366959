import React, { useEffect, useState } from "react";
import {
  Bouncer_Data_Get,
  useBouncerDataGetState,
} from "../../../redux/slices/bouncerSlice";
import { useDispatch } from "react-redux";
import { BOUNCER_LOCAL, DROPDOWN_ALL_VALUE, FILTER } from "../../../constant";
import {
  dateFormatter,
  getLocalData,
  responseToaster,
  setLocalData,
} from "../../../helperFunctions";
import { Table, TableRow } from "../../../components/Table";
import { isCallOptions, searchOptions } from "./mock";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { Button, Card, CardHeader, Form } from "reactstrap";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const Bouncer = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);

  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(BOUNCER_LOCAL, false)
  );
  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(BOUNCER_LOCAL, !isFilterOpen);
  };

  const dispatch = useDispatch();
  const role = useRole("Bouncer");

  const bouncerDataGetState = useBouncerDataGetState();
  const preparePayInOption = () => {
    const payInPgDataOption = bouncerDataGetState?.payInPgList
      ?.slice()
      ?.sort((a, b) => a?.val?.localeCompare(b?.val))
      ?.map((val) => ({
        value: val,
        label: val,
      }));
    return payInPgDataOption;
  };
  const columns = [
    {
      title: "TOKEN",
      name: "token",
    },
    {
      title: "TRANSACTION ID",
      name: "transaction_id",
    },
    {
      title: "PG",
      name: "pg_name",
    },
    {
      title: "IS CALL",
      name: "action_info",
      Cell: (data) => {
        return <div>{data?.is_call ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "IP",
      name: "ip",
    },
    {
      title: "REDIRECT URL",
      name: "createdAt",
    },
    {
      title: "ACTION",
      name: "createdAt",
      Cell: (data) => {
        return <Button>Delete</Button>;
      },
    },
  ];

  const initialValues = {
    filter: "",
    is_call: DROPDOWN_ALL_VALUE,
    search: "",
    date: [null, null],
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const {
        isFilter,
        filter: dropdown,
        search,
        date,
        page_no,
        limit,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
            page_no,
            limit,
            filter_data: {
              [dropdown]: search,
              startDate: dateFormatter(date?.[0], "start"),
              endDate: dateFormatter(date?.[1], "end", startDate),
              ...rest,
            },
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(Bouncer_Data_Get(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("bouncer-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      {role.includes("bouncer-list") ? (
        <>
          <div className="merchant-main-div">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title">
                    <b>Bouncer</b>
                  </h4>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <Button
                      type="button"
                      className="filterbtn"
                      onClick={toggleIsFilterOpen}
                      title="Apply Filter"
                    >
                      <i className="fa-solid fa-filter"></i> Apply Filter
                    </Button>
                  </div>
                </div>

                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {isFilterOpen && (
                    <>
                      <ControlledDropDown
                        name="filter"
                        label="Filter"
                        isDefaultOption={false}
                        options={searchOptions}
                        control={control}
                      />
                      <ControlledInput
                        name="search"
                        placeholder="Enter Search Value"
                        label="Search"
                        control={control}
                      />
                      <ControlledDropDown
                        name="pg_name"
                        label="PG"
                        isDefaultOption={false}
                        options={preparePayInOption()}
                        control={control}
                      />
                      <ControlledDropDown
                        name="is_call"
                        label="IS Call"
                        isDefaultOption={DROPDOWN_ALL_VALUE}
                        options={isCallOptions}
                        control={control}
                      />
                      <ControlledDatePicker
                        name="date"
                        placeholder="Select Date"
                        label="Date"
                        maxDate={new Date()}
                        selectsRange
                        control={control}
                      />
                      <Button type="submit" color="primary">
                        Apply
                      </Button>
                      <Button
                        type="button"
                        color="danger "
                        onClick={() => {
                          reset();
                          setFilter({ ...FILTER });
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                </Form>
              </CardHeader>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={isLoading}
                data={bouncerDataGetState?.data || []}
                isData={!!bouncerDataGetState?.data?.length}
                count={bouncerDataGetState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
              >
                {bouncerDataGetState?.data?.map((val) => {
                  return (
                    <TableRow
                      columns={columns}
                      filterColumns={filterColumns}
                      isExpandable={false}
                      item={val}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Bouncer;
