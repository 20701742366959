import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster } from "../../helperFunctions";
import { useLocation } from "react-router-dom";
import { pgUpdateProxyInfo } from "../../redux/slices/pgSlice";
import DropDown from "../../components/Custom/Forms/DropDown/DropDown";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";

const PgProxyId = (props) => {
  const { item, onGet, columns, setIsLoading, options } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const { user } = useUser();
  const [isInput, setIsInput] = useState(false);
  const [value, setValue] = useState("");
  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        payload: { pk: item?.account_id, value: value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      const resMin = await dispatch(pgUpdateProxyInfo(payload)).unwrap();
      responseToaster(resMin);
      onGet();
    } catch (err) {
      setIsLoading(false);
    }
  };
  const prepareOptions = () => {
    const option = options?.proxyList
      ?.sort?.((a, b) => a?.label_name?.localeCompare(b?.label_name))
      ?.map?.((val) => ({
        value: val?.id?.toString(),
        label: `${val?.label_name} ( ${val?.id} )`,
      }));
    return option;
  };

  return (
    <>
      <div
        className={`${item?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="order-singal-div">
          <span>Proxy ID : </span>
          {isInput ? (
            <div className="gap-1">
              <DropDown
                options={prepareOptions()}
                value={value}
                isDefaultOption={false}
                className="fixed-dropdown"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setValue(e.value)
                }}
              />
              <div className="d-flex gap-1 justify-content-end" style={{ marginTop: "10px" }}>

                <Button color="primary" onClick={onUpdate}>
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsInput(false);
                  }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </div>
            </div>
          ) : (
            <span
              className="cursor-pointer"
              style={{
                color: columns?.includes("proxy_id") ? "var(--chatlook-darkblue--)" : "",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (columns?.includes("proxy_id")) {
                  setIsInput(true);
                  setValue(item?.proxy_id);
                }
              }}
            >
              <div className="order-singal-div">
                <div style={{ color: "var(--chatlook-darkblue--)" }}>{item?.proxy_id || "Not Set"}</div>
              </div>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PgProxyId;
