import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import { responseToaster, seperator } from "../../../helperFunctions";
import { bankMetaGet, useBankMeta } from "../../../redux/slices/bankMetaSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import {
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const BankMeta = () => {
  const initialValues = {
    merchantId: DROPDOWN_ALL_VALUE,
  };
  const [filter, setFilter] = useState(TRA_FILTER);
  const [isLoading, setIsLoading] = useState(true);
  const [filterColumns, setFilterColumns] = useState([]);

  const data = useBankMeta();
  const dispatch = useDispatch();
  const role = useRole("Bank Meta");

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "Merchant Name",
      name: "merchantName",
    },
    {
      title: "Bank Name With Meta",
      name: "pg_id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span>{data?.bankName} </span>
                  <span>({data?.pg_id || ""})</span>
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Label : </span>
                  {data?.pgLabel || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>A/C : </span>
                  {data?.acNumber || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>IFSC : </span>
                  {data?.pgLabel || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Bank : </span>
                  {data?.bankName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>UPI : </span>
                  {data?.upiId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Start Time",
      name: "start_date",
    },
    {
      title: "End Time",
      name: "end_date",
    },
    {
      title: "Total Txn",
      name: "total_count",
      Cell: (data) => {
        return <span>{seperator(data?.total_count, false)}</span>;
      },
    },
    {
      title: "Success Txn",
      name: "success_count",
      Cell: (data) => {
        return <span>{seperator(data?.success_count, false)}</span>;
      },
    },
  ];

  const onGetBankMeta = async () => {
    try {
      setIsLoading(true);
      const { isFilter, page_no, limit, merchantId } = filter;
      const payload = isFilter
        ? {
            filter_data: {
              merchantId,
            },
            page_no,
            limit,
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(bankMetaGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("bankMeta-list")) {
      onGetBankMeta();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const prepareMerchantOptions = () => {
    const options = data?.merchantList?.map((item) => ({
      value: item?.merchant_id,
      label: item?.merchant_details?.merchant_name,
    }));

    return options;
  };

  return (
    <>
      {role.includes("bankMeta-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Bank Meta</b>
                    </h4>
                  </div>

                  <div className="d-flex gap">
                    <Form
                      className="d-flex my-xl-auto right-content align-items-center button-space"
                      style={{ flexWrap: "wrap" }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <span>Merchant</span>
                      <ControlledDropDown
                        id="merchantId"
                        name="merchantId"
                        isDefaultOption={DROPDOWN_ALL_VALUE}
                        options={prepareMerchantOptions()}
                        control={control}
                      />
                      <Button type="submit" color="primary">
                        Apply
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => {
                          reset();
                          setFilter({ ...TRA_FILTER });
                        }}
                      >
                        Clear
                      </Button>
                    </Form>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={data?.data || []}
                isData={!!data?.data?.length}
                filterColumns={filterColumns}
                count={data?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {data?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankMeta;
