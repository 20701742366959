import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddBankProxyModal from "./AddBankProxyModal";
import {
  bankProxyDelete,
  bankProxyGet,
  bankProxyStatusUpdate,
  setBankProxy,
  useBankProxyData,
} from "../../../redux/slices/bankProxySlice";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { useRole } from "../../../redux/slices/authSlice";

const BankProxy = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const role = useRole("Bank Proxy");
  const dispatch = useDispatch();

  const data = useBankProxyData();

  const columns = [
    {
      title: "#",
      name: "id",
    },
    {
      title: "LABEL",
      name: "label_name",
    },
    {
      title: "PROXY IP",
      name: "ip_proxy",
    },
    {
      title: "ACTIVE",
      name: "active",
      Cell: (data) => {
        return (
          <div>
            <ToggleSwitch
              isLoading={statusLoading === data?.id}
              value={data?.is_active}
              className={`${statusLoading ? "disabled" : ""}`}
              onChange={async (value) => {
                if (role.includes("update-bank-proxy-status")) {
                  const payload = {
                    pk: data?.id,
                    value,
                  };
                  await onUpdateStatus(payload);
                }
              }}
            />
          </div>
        );
      },
    },
    {
      title: "DATE",
      name: "created_at",
    },
    {
      title: "ACTION",
      name: "action",
      isOpen: role.includes("delete-bank-proxy"),
      Cell: (data) => {
        return (
          <>
            <h6 className="mb-0">
              <Button
                color="danger"
                className="btn-smallsize"
                onClick={() => {
                  setDeleteId(data?.id);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </h6>
          </>
        );
      },
    },
  ];

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

  const onGet = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...rest } = filter;
      const payload = {
        ...rest,
      };
      const res = await dispatch(bankProxyGet(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.pk);
      const res = await dispatch(bankProxyStatusUpdate(payload)).unwrap();

      const index = data?.data?.findIndex((item) => item?.id === payload?.pk);
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], is_active: payload?.value };
        dispatch(setBankProxy({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: deleteId,
      };
      const res = await dispatch(bankProxyDelete(payload)).unwrap();
      responseToaster(res);
      setFilter({
        ...filter,
      });
      setDeleteId("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareItems = () => {
    if (!search) {
      return data?.data || [];
    }

    return data?.data?.filter((item) => {
      const isActive = item?.is_active === 1 ? "Enabled" : "Disabled";
      return (
        item?.id?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.label_name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        item?.ip_proxy
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        item?.created_at
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        isActive?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
  };

  useEffect(() => {
    if (role.includes("bankProxy-list")) {
      onGet();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("bankProxy-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <InputField
              placeholder="Enter Search Value"
              name="email_id"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            {role.includes("add-bank-proxy") ? (
              <Button
                title="Add Bank Proxy"
                color="primary"
                onClick={toggleAddModal}
              >
                Add Bank Proxy
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={prepareItems() || []}
          isData={!!prepareItems()?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {prepareItems()?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
      <AddBankProxyModal
        isOpen={isAddModalOpen}
        onHide={toggleAddModal}
        onGet={() => setFilter({ ...filter })}
      />
      <DeleteModal
        title="Delete Bank Proxy"
        content="Are you sure you want to delete bank proxy ?"
        isOpen={!!deleteId}
        onDoneClick={() => onDelete()}
        onCancelClick={() => setDeleteId("")}
        isButtonLoading={isLoading}
      />
    </div>
  );
};

export default BankProxy;
