import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { UTR_Merge } from "../../../../redux/slices/bankTransactionSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";

const MergeUTRModal = ({ mergeUtr, onCloseModal, onGet }) => {
  const dispatch = useDispatch();

  const initialValues = {};

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsButtonLoading(true);
      const res = await dispatch(UTR_Merge(values)).unwrap();
      responseToaster(res);
      if (!!res) {
        onClose();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={!!mergeUtr}
        toggle={() => onClose()}
        className="update-manual-bank-modal"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Merge Two UTR</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div>
              <ControlledInput
                name="utrRef1"
                label="UTR 1"
                type="text"
                placeholder="Enter UTR 1"
                control={control}
              />
            </div>
            <div className="mt-1">
              <ControlledInput
                name="utrRef2"
                label="UTR 2"
                type="text"
                placeholder="Enter UTR 2"
                control={control}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button
                type="button"
                className="graybutton"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <CustomeButton
                type="submit"
                className="submitbutton"
                isButtonLoading={isButtonLoading}
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default MergeUTRModal;
