import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { pgEmptyBank } from "../../redux/slices/pgSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";

const PgEmptyBankModal = (props) => {
  const { onHide, isOpen } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onDoneClick = async () => {
    try {
      setIsLoading(true);
      const payload = {
        acId: isOpen?.account_id,
      };
      await dispatch(pgEmptyBank(payload)).unwrap();
      onHide();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">PG Empty Bank Modal</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <span>Are you sure you want to empty bank ?</span>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onHide()}>
            Cancel
          </Button>
          <CustomeButton
            onClick={() => onDoneClick()}
            className="submitbutton"
            isButtonLoading={isLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default PgEmptyBankModal;
