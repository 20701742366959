import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableRow } from "../../../components/Table";
import {
  withdrawalCreditGet,
  useWithdrawalCreditState,
  EditWithdrawalCredit,
  DeleteWithdrawalCredit,
} from "../../../redux/slices/withdrawalCreditSlice";
import { onFixed, responseToaster, seperator } from "../../../helperFunctions";
// import WithdrawalCreditParseModal from "./WithdrawalCreditParseModal";
import { TRA_FILTER } from "../../../constant";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import WithdrawalCreditModal from "../../../components/Custom/Modals/WithdrawalCredit/WithdrawalCreditModal";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { useRole } from "../../../redux/slices/authSlice";

const MobileCell = (data) => {
  const [mobileText, setMobileText] = useState();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setMobileText(data?.mobileId);
  }, [data, isOpen]);

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      await data
        ?.onUpdateMobileNumber({
          pk: data?.id,
          value: data?.type === "remark" ? mobileText : +mobileText ?? 0,
          name: data?.type,
        })
        .unwrap();

      setMobileText();
      setIsOpen(!isOpen);
    } catch (err) { }
  };
  return (
    <>
      {isOpen ? (
        <form onSubmit={onUpdate}>
          <div className="d-flex tableinput">
            <InputField
              type={data?.type === "remark" ? "text" : "number"}
              placeholder={data?.placeholder}
              value={mobileText}
              {...(data?.type !== "remark" && { min: 1 })}
              onChange={(e) => {
                setMobileText(e.target.value);
              }}
            />
            <Button type="submit" color="primary" className="btn-smallsize">
              <i className="fa-solid fa-check"></i>
            </Button>
            <Button
              color="danger"
              className="btn-smallsize"
              onClick={async () => {
                setMobileText();
                setIsOpen(!isOpen);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </div>
        </form>
      ) : (
        <span
          onClick={(e) => {
            setMobileText(
              data?.type === "remark" ? data?.mobileId : +data?.mobileId
            );
            setIsOpen(!isOpen);
          }}
          style={{
            color: "var(--chatlook-darkblue--)",
          }}
          className={`cursor-pointer ${data?.type === "remark" ? "text-wrap" : ""}`}
        >
          {data?.type === "remark"
            ? data?.mobileId
            : seperator(data?.mobileId, data?.isRupee)}
        </span>
      )}
    </>
  );
};

const WithdrawalCredit = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(TRA_FILTER);
  const withdrawalCreditState = useWithdrawalCreditState();
  const role = useRole("Withdrawal Credit");

  const { withdrawalCreditIsLoading } = useSelector(
    (state) => state.withdrawalCredit
  );
  const [filterColumns, setFilterColumns] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onEditSettledAmountLimit = async (payload) => {
    try {
      const res = await dispatch(EditWithdrawalCredit(payload)).unwrap();
      if (res) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "MERCHANT",
      name: "merchant",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>{data?.merchantDetails?.merchant_name || ""}</p>
              </div>
              <div className="order-singal-div">
                <p>{data?.merchantDetails?.merchant_id || ""}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "SETTLED AMOUNT",
      name: "settledAmount",
      Cell: (data) => {
        return (
          <>
            {data?.requestAmt === null && role?.includes("withdrawal-credit-update") ? (
              <div
                className={`${data?.isOpen ? "cell-order-white" : "cell-order"
                  }`}
              >
                <div className="order-singal-div">
                  <MobileCell
                    placeholder="Enter Settled Amount"
                    {...data}
                    isRupee={true}
                    mobileId={data?.creditAmt}
                    type="credit_amount"
                    onUpdateMobileNumber={async (payload) => {
                      await onEditSettledAmountLimit(payload);
                      await WithdrawalCreditGet();
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="cell-order">
                <div className="order-singal-div">
                  <p>{`${seperator(data?.creditAmt)}`}</p>
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "REMARK",
      name: "remark",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              {data?.requestAmt === null && role?.includes("withdrawal-credit-update") ? (
                <div
                  className={`${data?.isOpen ? "cell-order-white" : "cell-order"
                    }`}
                >
                  <div className="order-singal-div">
                    <MobileCell
                      placeholder="Enter Remark"
                      {...data}
                      isRupee={false}
                      mobileId={data?.remark}
                      type="remark"
                      onUpdateMobileNumber={async (payload) => {
                        await onEditSettledAmountLimit(payload);
                        await WithdrawalCreditGet();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="cell-order">
                  <div className="order-singal-div">
                    <p>{data?.remark}</p>
                  </div>
                </div>
              )}
              {data.requestAmt > 0 ? (
                <>
                  {data?.requestAmt ? (
                    <div className="order-singal-div">
                      <p>
                        <span>Request : </span>
                        {seperator(data?.requestAmt) || ""}
                      </p>
                    </div>
                  ) : null}
                  {data?.requestType ? (
                    <div className="order-singal-div">
                      <p>
                        <span>Request Type : </span>
                        {data?.requestType || ""}
                      </p>
                    </div>
                  ) : null}
                  {data?.crediFeesAmount ? (
                    <div className="order-singal-div">
                      <p>
                        <span>Fees : </span>
                        {seperator(data?.crediFeesAmount) || ""}
                      </p>
                    </div>
                  ) : null}
                  {data?.isApproved ? (
                    <div className="order-singal-div">
                      <p>
                        <span>Status : </span>
                        {data?.isApproved ? "Approved" : ""}
                      </p>
                    </div>
                  ) : null}
                  {data?.approvedBy ? (
                    <div className="order-singal-div">
                      <p>
                        <span>Approved By : </span>
                        {data?.approvedBy || ""}
                      </p>
                    </div>
                  ) : null}{" "}
                </>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "DATE",
      name: "createdAtIst",
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <>
            <div className="d-flex flex-column gap-50">
              {data?.requestAmt === null && role?.includes("withdrawal-credit-delete") ? (
                <Button
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => setDeleteId(data?.id)}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onDelete = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: deleteId,
      };
      const res = await dispatch(DeleteWithdrawalCredit(payload)).unwrap();
      responseToaster(res);
      setFilter({
        ...filter,
      });
      setDeleteId("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const WithdrawalCreditGet = async () => {
    try {
      const { isFilter, page_no, limit, ...rest } = filter;
      const payload = isFilter
        ? { filter_data: {}, page_no, limit }
        : {
          page_no,
          limit,
        };
      await dispatch(withdrawalCreditGet(payload)).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    if (role?.includes("withdrawalCredit-list")) {
      WithdrawalCreditGet();
    }
  }, [filter]);

  if (!role.includes("withdrawalCredit-list")) {
    return;
  }

  return (
    <>
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Withdrawal Credit</b>
            </h4>
            {role?.includes("withdrawal-credit-add") ? <Button
              color="primary"
              title="Bank Statement"
              onClick={() => setIsModalOpen(true)}
            >
              Add Withdrawal Credit
            </Button> : null}
          </div>
          <div
            className="d-flex my-xl-auto right-content align-items-end button-space"
            style={{ flexWrap: "wrap" }}
          ></div>
        </CardHeader>
        <Table
          columns={columns}
          isLoading={withdrawalCreditIsLoading}
          data={withdrawalCreditState?.data || []}
          isData={!!withdrawalCreditState?.data?.length}
          filterColumns={filterColumns}
          count={withdrawalCreditState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {withdrawalCreditState?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <WithdrawalCreditModal
        isModalOpen={isModalOpen}
        onGet={() => WithdrawalCreditGet()}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
      <DeleteModal
        title="Delete Withdrawal Credit"
        content="Are you sure you want to delete withdrawal credit ?"
        isOpen={!!deleteId}
        onDoneClick={() => onDelete()}
        onCancelClick={() => setDeleteId("")}
        isButtonLoading={isLoading}
      />
    </>
  );
};

export default WithdrawalCredit;
