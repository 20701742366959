import React from "react";
import { Button, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const DeleteModal = (props) => {
  const {
    isOpen,
    onCancelClick,
    onDoneClick,
    title,
    content,
    isButtonLoading,
  } = props;

  return (
    <Modal isOpen={!!isOpen} toggle={onCancelClick} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">{title}</h3>
        <i
          onClick={() => {
            onCancelClick();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>{content}</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onCancelClick()}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            isButtonLoading={isButtonLoading}
            onClick={onDoneClick}
          >
            Delete
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
