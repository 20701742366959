import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster, seperator } from "../../helperFunctions";
import InputField from "../../components/Custom/Forms/InputField/InputField";
import { useLocation } from "react-router-dom";
import {
  pgUpdateMaxLimit,
  pgUpdateMinLimit,
  pgUpdatePaymentMaxCountLimit,
  pgUpdatePaymentTurnOver,
  pgUpdateRemainLimit,
} from "../../redux/slices/pgSlice";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";

const PgLimit = (props) => {
  const {
    updateDetails,
    setUpdateDetails,
    data,
    columns,
    onGet,
    setIsLoading,
  } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isPayout = params?.pgType === "payout";
  const isUpiPay = params?.pgName === "UPIPAY" && params?.pgType === "payin";
  const { user } = useUser();

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const payload = {
        payload: { pk: data?.account_id, value: updateDetails?.value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      switch (updateDetails?.input) {
        case "min":
          const resMin = await dispatch(pgUpdateMinLimit(payload)).unwrap();
          responseToaster(resMin);
          break;
        case "max":
          const resMax = await dispatch(pgUpdateMaxLimit(payload)).unwrap();
          responseToaster(resMax);
          break;
        case "maxCount":
          const resDaily = await dispatch(
            pgUpdatePaymentMaxCountLimit(payload)
          ).unwrap();
          responseToaster(resDaily);
          break;
        case "turnOver":
          const resPer = await dispatch(
            pgUpdatePaymentTurnOver(payload)
          ).unwrap();
          responseToaster(resPer);
          break;
        case "remain_limit":
          payload.payload.name = "remain_limit";
          payload.payload.account_id = data?.account_id;
          const resRemainLimit = await dispatch(
            pgUpdateRemainLimit({ ...payload, ...payload.payload })
          ).unwrap();
          responseToaster(resRemainLimit);
          break;
        default:
          break;
      }
      onGet();
      setUpdateDetails({});
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => e.stopPropagation()}
      >
        {isUpiPay || isPayin || isPayout ? (
          <div className="order-singal-div">
            <div onClick={(e) => e.stopPropagation()}>
              <span>Min Limit : </span>
              {updateDetails.input === "min" &&
                (updateDetails?.id === data?.id ||
                  updateDetails?.id === data?.account_id) ? (
                <form className="d-flex tableinput" onSubmit={onUpdate}>
                  <InputField
                    autoFocus
                    value={updateDetails?.value}
                    placeholder="Enter Min Limit"
                    type="number"
                    onChange={(e) =>
                      setUpdateDetails({
                        ...updateDetails,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    type="submit"
                    className="btn-smallsize"
                    color="primary"
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={(e) => {
                      e.stopPropagation()
                      setUpdateDetails({})
                    }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </form>
              ) : (
                <span
                  className="cursor-pointer"
                  style={{
                    color: columns?.includes("min_limit")
                      ? "var(--chatlook-darkblue--)"
                      : "#000000cb",
                  }}
                  onClick={() => {
                    if (columns?.includes("min_limit")) {
                      setUpdateDetails({
                        id: data?.id || data?.account_id,
                        input: "min",
                        value: data?.min_limit,
                      });
                    }
                  }}
                >
                  {`${seperator(data?.min_limit)}`}
                </span>
              )}
            </div>
          </div>
        ) : null}

        {isUpiPay || isPayin || isPayout ? (
          <div className="order-singal-div">
            <div onClick={(e) => e.stopPropagation()}>
              <span>Max Limit : </span>
              {updateDetails?.input === "max" &&
                (updateDetails?.id === data?.id ||
                  updateDetails?.id === data?.account_id) ? (
                <form className="d-flex tableinput" onSubmit={onUpdate}>
                  <InputField
                    autoFocus
                    value={updateDetails?.value}
                    placeholder="Enter Max Limit"
                    type="number"
                    onChange={(e) =>
                      setUpdateDetails({
                        ...updateDetails,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    type="submit"
                    className="btn-smallsize"
                    color="primary"
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => setUpdateDetails({})}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </form>
              ) : (
                <span
                  className="cursor-pointer"
                  style={{
                    color: columns?.includes("max_limit")
                      ? "var(--chatlook-darkblue--)"
                      : "#000000cb",
                  }}
                  onClick={() => {
                    if (columns?.includes("max_limit")) {
                      setUpdateDetails({
                        id: data?.id || data?.account_id,
                        input: "max",
                        value: data?.max_limit,
                      });
                    }
                  }}
                >
                  {`${seperator(data?.max_limit)}`}
                </span>
              )}
            </div>
          </div>
        ) : null}

        {isUpiPay || isPayin || isPayout ? (
          <div className="order-singal-div">
            <div onClick={(e) => e.stopPropagation()}>
              <span>Max Count Limit : </span>
              {updateDetails?.input === "maxCount" &&
                (updateDetails?.id === data?.id ||
                  updateDetails?.id === data?.account_id) ? (
                <form className="d-flex tableinput" onSubmit={onUpdate}>
                  <InputField
                    autoFocus
                    value={updateDetails?.value}
                    placeholder="Enter Max Count Limit"
                    type="number"
                    onChange={(e) =>
                      setUpdateDetails({
                        ...updateDetails,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-smallsize"
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => setUpdateDetails({})}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </form>
              ) : (
                <span
                  className="cursor-pointer editable"
                  style={{
                    color: columns?.includes("max_count_limit")
                      ? "var(--chatlook-darkblue--)"
                      : "#000000cb",
                  }}
                  onClick={() => {
                    if (columns?.includes("max_count_limit")) {
                      setUpdateDetails({
                        id: data?.id || data?.account_id,
                        input: "maxCount",
                        value: data?.max_count_limit,
                      });
                    }
                  }}
                >
                  {`${seperator(data?.max_count_limit, false)}`}
                </span>
              )}
            </div>
          </div>
        ) : null}

        {isUpiPay || isPayin ? (
          <div className="order-singal-div">
            <div onClick={(e) => e.stopPropagation()}>
              <span>Turnover : </span>
              {updateDetails?.input === "turnOver" &&
                (updateDetails?.id === data?.id ||
                  updateDetails?.id === data?.account_id) ? (
                <form className="d-flex tableinput" onSubmit={onUpdate}>
                  <InputField
                    autoFocus
                    value={updateDetails?.value}
                    placeholder="Enter Turover"
                    type="number"
                    onChange={(e) =>
                      setUpdateDetails({
                        ...updateDetails,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-smallsize"
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => setUpdateDetails({})}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </form>
              ) : (
                <span
                  className="cursor-pointer"
                  style={{
                    color: columns?.includes("turn_over")
                      ? "var(--chatlook-darkblue--)"
                      : "#000000cb",
                  }}
                  onClick={() => {
                    if (columns?.includes("turn_over")) {
                      setUpdateDetails({
                        id: data?.id || data?.account_id,
                        input: "turnOver",
                        value: data?.turn_over,
                      });
                    }
                  }}
                >
                  {`${seperator(data?.turn_over)}`}
                </span>
              )}
            </div>
          </div>
        ) : null}
        {isUpiPay || isPayin ? (
          <div className="order-singal-div">
            <p onClick={(e) => e.stopPropagation()}>
              <span>Current Turnover : </span>
              {`${seperator(data?.current_turn_over)}`}
            </p>
          </div>
        ) : null}
        {isUpiPay ? (
          <div className="order-singal-div">
            <p onClick={(e) => e.stopPropagation()}>
              <span>Live Balance : </span>
              {`${seperator(data?.live_bank_balance)}`}
            </p>
          </div>
        ) : null}
        {isPayout ? (
          <div className="order-singal-div">
            <div onClick={(e) => e.stopPropagation()}>
              <span>Remain Limit : </span>
              {updateDetails?.input === "remain_limit" &&
                (updateDetails?.id === data?.id ||
                  updateDetails?.id === data?.account_id) ? (
                <form className="d-flex tableinput" onSubmit={onUpdate}>
                  <InputField
                    autoFocus
                    value={updateDetails?.value}
                    placeholder="Enter Remain Limit"
                    type="number"
                    onChange={(e) =>
                      setUpdateDetails({
                        ...updateDetails,
                        value: e.target.value,
                      })
                    }
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-smallsize"
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="btn-smallsize"
                    onClick={() => setUpdateDetails({})}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </form>
              ) : (
                <span
                  className="cursor-pointer editable"
                  style={{
                    color: columns?.includes("remain_limit")
                      ? "var(--chatlook-darkblue--)"
                      : "#000000cb",
                  }}
                  onClick={() => {
                    if (columns?.includes("remain_limit")) {
                      setUpdateDetails({
                        id: data?.id || data?.account_id,
                        input: "remain_limit",
                        value: data?.remain_limit,
                      });
                    }
                  }}
                >
                  {`${seperator(data?.remain_limit, false)}`}
                </span>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PgLimit;
