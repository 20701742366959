import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import BankParseFileUpload from "../../../components/Custom/Modals/BankParse/BankParseFileUpload";
import { useRole } from "../../../redux/slices/authSlice";

const BankParse = () => {
  const [isLoading, setIsLoading] = useState();
  const [filterColumns, setFilterColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { banksParseExl } = useSelector((state) => state.bankParse);

  const role = useRole("Bank Parse");

  const columns = [
    {
      title: "Account Number",
      name: "account_number",
    },
    {
      title: "Bank Name",
      name: "bank_name",
    },
    {
      title: "Deposit",
      name: "transaction_id",
    },
    {
      title: "Desc",
      name: "received_from",
    },
    {
      title: "Date",
      name: "transaction_date",
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      {role.includes("bankParse-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Bank Parse</b>
                    </h4>
                  </div>
                  <Button
                    color="primary"
                    title="Bank Statement"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Apply Bank Parse
                  </Button>
                </div>
                <div
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                ></div>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={banksParseExl?.data || []}
                isData={!!banksParseExl?.data?.length}
                filterColumns={filterColumns}
                isExpandable={false}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
              >
                {banksParseExl?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
            <BankParseFileUpload
              setIsButtonLoading={setIsLoading}
              isButtonLoading={isLoading}
              isModalOpen={isModalOpen}
              onCloseModal={() => {
                setIsModalOpen(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankParse;
