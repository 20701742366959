import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { MarkAs_Failed_Deposite } from "../../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const MarkAsFailedDeposite = (props) => {
  const { markAsFailed, isMarkAsFailedId, onCloseMarkfailed, onGet } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const Mark_As_Failed = async () => {
    try {
      setIsButtonLoading(true);
      let Payload = {
        txnId: isMarkAsFailedId,
      };
      let response = await dispatch(MarkAs_Failed_Deposite(Payload)).unwrap();
      responseToaster(response);
      if (!!response) {
        onCloseMarkfailed();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={markAsFailed} toggle={onCloseMarkfailed} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Mark As Failed</h3>
        <i
          onClick={() => {
            onCloseMarkfailed();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to fail transaction?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onCloseMarkfailed();
            }}
          >
            Cancel
          </Button>
          <CustomeButton
          className="submitbutton"
            onClick={Mark_As_Failed}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default MarkAsFailedDeposite;
