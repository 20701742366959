import React, { useState } from "react";
import { DROPDOWN_ALL_VALUE, FILTER } from "../../../constant";
import { useEffect } from "react";
import {
  dateFormatter,
  exportDateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  CardBody,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { useForm } from "react-hook-form";
import { Table, TableCell, TableRow } from "../../../components/Table";
import {
  getStateSummary,
  useStateSummary,
} from "../../../redux/slices/stateSummarySlice";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { get_merchant_list } from "../../../redux/slices/payinPayoutSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { statusDataOptions } from "./mock";
import Export from "../../../components/Custom/Elements/Export/Export";
import { useRole } from "../../../redux/slices/authSlice";

const StateSummary = () => {
  const [refresh, setRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({ ...FILTER });
  const [isOpen, setIsOpen] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const { getMerchantListState } = useSelector((state) => state?.payinPayout);
  const stateSummaryState = useStateSummary();
  const dispatch = useDispatch();
  const role = useRole("State Summary");

  useEffect(() => {
    GetMerchantList();
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const GetMerchantList = async () => {
    try {
      await dispatch(get_merchant_list()).unwrap();
    } catch (err) {}
  };

  const getMerchantDataOptions = () => {
    const options = getMerchantListState?.data
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchant_id,
          label: val?.merchant_name,
        };
        return clientDataOption;
      });
    return options;
  };

  const initialValues = {
    date: [null, null],
    merchantId: DROPDOWN_ALL_VALUE,
    status: DROPDOWN_ALL_VALUE,
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...FILTER,
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  const onGetStateSummary = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const { isFilter, merchantId, date, status } = filter;
      const payload = {
        startDate: dateFormatter(isFilter ? date?.[0] : new Date(), "start"),
        endDate: dateFormatter(
          isFilter ? date?.[1] : new Date(),
          "end",
          date?.[0]
        ),
        merchantId: merchantId || "ALL",
        status: status || "All",
      };
      const allRes = await Promise.all([
        dispatch(getStateSummary(payload)).unwrap(),
      ]);
      if (filter?.isFilter) {
        responseToaster(allRes?.[0]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const prepareTotalData = () => {
    let depositeCount = 0;
    let totalDepositeAmount = 0;
    let withdrawalCount = 0;
    let totalWithdrawalCount = 0;

    stateSummaryState?.data?.forEach((item) => {
      depositeCount =
        depositeCount +
        (item?.deposit_count ? parseInt(item?.deposit_count) : 0);
      totalDepositeAmount =
        totalDepositeAmount +
        (item?.total_deposit_amount ? parseInt(item?.total_deposit_amount) : 0);
      withdrawalCount =
        withdrawalCount +
        (item?.withdrawal_count ? parseInt(item?.withdrawal_count) : 0);
      totalWithdrawalCount =
        totalWithdrawalCount +
        (item?.total_withdrawal_amount
          ? parseInt(item?.total_withdrawal_amount)
          : 0);
    });

    return (
      <>
        <tr className="lasttablerow">
          <TableCell>{"Total"}</TableCell>
          <TableCell>{seperator(depositeCount, false)}</TableCell>
          <TableCell>{seperator(totalDepositeAmount)}</TableCell>
          <TableCell>{seperator(withdrawalCount, false)}</TableCell>
          <TableCell>{seperator(totalWithdrawalCount, true)}</TableCell>
        </tr>
      </>
    );
  };

  useEffect(() => {
    if (role.includes("stateSummary-list")) {
      onGetStateSummary(true);
    }
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        onGetStateSummary(true);
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
  }, [refresh, filter]);

  const columns = [
    {
      title: "State",
      name: "cust_state",
    },
    {
      title: "Deposit Count",
      name: "deposit_count",
      Cell: (data) => {
        return <>{`${seperator(data?.deposit_count, false)}`}</>;
      },
    },
    {
      title: "Deposit Amount",
      name: "total_deposit_amount",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <span>{`${seperator(data?.total_deposit_amount)}`}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Withdrawal Count",
      name: "withdrawal_count",
      Cell: (data) => {
        return <>{`${seperator(data?.withdrawal_count, false)}`}</>;
      },
    },
    {
      title: "Withdrawal Amount",
      name: "total_withdrawal_amount",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <span>{`${seperator(data?.total_withdrawal_amount)}`}</span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const prepareCsvProps = () => {
    const merchantName = getMerchantListState?.data?.find(
      (val) => val?.merchant_id === filter?.merchantId
    );

    const { date, isFilter } = filter;

    const startDate = exportDateFormatter(isFilter ? date?.[0] : new Date());
    const endDate = exportDateFormatter(
      isFilter ? date?.[1] || date?.[0] : new Date()
    );

    const filename = `${merchantName?.merchant_name || "All"} ${
      filter?.status || "All"
    } ${date?.length ? startDate + "To" + endDate : ""}`;

    const data = stateSummaryState?.data || [];
    const headers =
      columns?.map((val) => ({
        label: val?.title,
        key: val?.name,
      })) || [];

    return {
      filename,
      data,
      headers,
    };
  };

  const isExportable = () => !isLoading;

  return (
    <>
      {role.includes("stateSummary-list") ? (
        <>
          <div className={refresh && isLoading ? "opacity-25" : ""}>
            <Card>
              <CardHeader className="flex-column align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title ">
                    <b>State Summary</b>
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "10px",
                    }}
                  >
                    <Export
                      {...prepareCsvProps()}
                      className={`btn btn-primary rounded ${
                        isExportable() ? "" : "disabled opacity-50"
                      }`}
                    >
                      Export
                    </Export>
                    <div className="mb-xl-0 d-flex align-items-center">
                      <Button
                        type="button"
                        className={`${refresh ? "" : "filterbtn"} gap`}
                        color={refresh ? "primary" : ""}
                        onClick={() => setRefresh(!refresh)}
                        title="Auto refresh"
                      >
                        {refresh ? (
                          <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                        ) : (
                          <i className="fa-solid fa-arrows-rotate"></i>
                        )}
                        Auto Refresh
                      </Button>
                    </div>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                        title="Column Filter"
                      >
                        <IoFilterOutline
                          value={{ color: "#7367F0" }}
                          fill="#7367F0"
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="transaction-main-div"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="d-flex align-items-end button-space flex-wrap">
                    <ControlledDropDown
                      name="merchantId"
                      label="Merchant"
                      isDefaultOption={DROPDOWN_ALL_VALUE}
                      options={getMerchantDataOptions()}
                      control={control}
                    />
                    <ControlledDropDown
                      name="status"
                      label="Status"
                      isDefaultOption={DROPDOWN_ALL_VALUE}
                      options={statusDataOptions}
                      control={control}
                    />
                    <ControlledDatePicker
                      autoComplete="off"
                      name="date"
                      placeholder="Select Date"
                      label="Date"
                      maxDate={new Date()}
                      selectsRange
                      control={control}
                    />

                    <Button type="submit" color="primary">
                      Apply
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => {
                        reset();
                        setFilter({ ...FILTER });
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={isLoading && !refresh}
                data={stateSummaryState?.data || []}
                isData={!!stateSummaryState?.data?.length}
                filterColumns={filterColumns}
                isExpandable={false}
                //   count={stateSummaryState?.total_item || 0}
                //   pagination={filter}
                //   handlePaginationChange={(pagination) => {
                //     setFilter({
                //       ...filter,
                //       ...pagination,
                //       isCall: true,
                //     });
                //   }}
              >
                {stateSummaryState?.data?.map((item) => {
                  return (
                    <TableRow
                      tdClassName="position-relative"
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    ></TableRow>
                  );
                })}
                {prepareTotalData()}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default StateSummary;
