import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import "./Support_log.scss";
import { getSupport, useSupport } from "../../../redux/slices/supportSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo } from "react";

import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import {
  DATE_RANGE,
  TRA_FILTER,
  SUPPORT_LOG_LOCAL,
  TRA_PAGINATION_OPTIONS,
  DROPDOWN_ALL_VALUE,
} from "../../../constant";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import {
  dateFormatter,
  getLocalData,
  responseToaster,
  setLocalData,
} from "../../../helperFunctions";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useRole } from "../../../redux/slices/authSlice";
import ControlledCheckBox from "../../../components/Custom/Forms/Controller/ControlledCheckBox";

const Support_log = () => {
  const emailidOptions = [
    { value: "action", label: "Action" },
    { value: "emailId", label: "Email_id" },
  ];
  const initialValues = {
    filter: emailidOptions?.[0]?.value,
    search: "",
    emailId: DROPDOWN_ALL_VALUE,
    date: DATE_RANGE,
    is_change_route: 0
  };
  const [filter, setFilter] = useState(TRA_FILTER);
  const [filterColumns, setFilterColumns] = useState([]);
  const { isLoading } = useSelector((state) => state.support);
  const { supportState } = useSupport();
  const dispatch = useDispatch();
  const role = useRole("Support Logs");

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        name: "id",
      },
      {
        title: "EMAIL ID",
        name: "emailId",
      },
      {
        title: "ACTION",
        name: "action",
      },
      {
        title: "ACTION DETAILS",
        name: "action_info",
        type: "ellipsis",
      },
      {
        title: "CLIENT IP	",
        name: "clientIp",
      },
      {
        title: "CREATED AT",
        name: "createdAt",
      },
    ],
    []
  );

  const GetSupport = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        date,
        filter: dropdown,
        search,
        is_change_route,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
          filter_data: {
            startDate: dateFormatter(date?.[0], "start"),
            endDate: dateFormatter(date?.[1], "end", date?.[0]),
            is_change_route: is_change_route ? 1 : 0,
            ...rest,
            [dropdown]: search,
          },
          page_no,
          limit,
        }
        : {
          page_no,
          limit,
          filter_data: {
            is_change_route: is_change_route ? 1 : 0,
          }
        };
      const res = await dispatch(getSupport(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  const prepareProcessByData = () => {
    const options = supportState?.emailIds
      ?.slice()
      ?.sort((a, b) => a?.localeCompare(b))
      ?.map((val) => {
        const clientDataOption = {
          value: val,
          label: val,
        };
        return clientDataOption;
      });
    return options;
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("supportLogs-list")) {
      GetSupport();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(SUPPORT_LOG_LOCAL, false)
  );
  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(SUPPORT_LOG_LOCAL, !isFilterOpen);
  };

  return (
    <>
      {role.includes("supportLogs-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Support Logs</b>
                    </h4>
                  </div>

                  <div className="d-flex">
                    <Button
                      type="button"
                      className="filterbtn me-1"
                      onClick={toggleIsFilterOpen}
                    >
                      <i className="fa-solid fa-filter"></i> Apply Filter
                    </Button>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {isFilterOpen && (
                    <>
                      <ControlledDropDown
                        name="filter"
                        label="Filter"
                        isDefaultOption={false}
                        options={emailidOptions}
                        control={control}
                      />
                      <ControlledInput
                        placeholder="Enter Search Value"
                        name="search"
                        label="Search"
                        control={control}
                      />
                      <ControlledDropDown
                        label="Update By"
                        name="emailId"
                        isDefaultOption={DROPDOWN_ALL_VALUE}
                        options={prepareProcessByData()}
                        control={control}
                      />
                      <ControlledDatePicker
                        name="date"
                        placeholder="Select Date"
                        label="Date"
                        maxDate={new Date()}
                        selectsRange
                        control={control}
                      />
                      <ControlledCheckBox
                        label="Change Route"
                        name="is_change_route"
                        control={control}
                      />
                      <Button type="submit" color="primary">
                        Apply
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => {
                          reset();
                          setFilter({ ...TRA_FILTER });
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                </Form>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={supportState?.data || []}
                isData={!!supportState?.data?.length}
                filterColumns={filterColumns}
                count={supportState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {supportState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Support_log;
