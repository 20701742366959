import { useState } from "react";
import { Button } from "reactstrap";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { useDispatch } from "react-redux";
import {
  resellerCommissionDelete,
  resellerCommissionUpdate,
} from "../../../redux/slices/resellerSlice";
import { onFixed, responseToaster } from "../../../helperFunctions";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useRole } from "../../../redux/slices/authSlice";

const initialValues = {
  payin_commission: 0,
  payout_commission: 0,
};
const UpdateCommission = (props) => {
  const {
    merchant_id,
    reseller_id,
    merchant_details,
    payin_commission,
    payout_commission,
    onGet,
  } = props;

  const dispatch = useDispatch();
  const role = useRole("Reseller User");
  const [values, setValues] = useState(initialValues);
  const [isInput, setIsInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        merchantId: merchant_id,
        resellerId: reseller_id,
      };
      if (payin_commission !== values?.payin_commission) {
        payload["payin_commission"] = values?.payin_commission;
      }
      if (payout_commission !== values?.payout_commission) {
        payload["payout_commission"] = values?.payout_commission;
      }
      const res = await dispatch(resellerCommissionUpdate(payload)).unwrap();
      responseToaster(res);
      onGet();
      setIsLoading(false);
      setIsInput(false);
      setValues(initialValues);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const payload = {
        merchantId: merchant_id,
        resellerId: reseller_id,
      };
      const res = await dispatch(resellerCommissionDelete(payload)).unwrap();
      responseToaster(res);
      onGet();
      setIsDeleteLoading(false);
    } catch (err) {
      setIsDeleteLoading(false);
    }
  };

  return (
    <tr>
      <td>
        <span>{merchant_details?.merchant_name}</span>
      </td>
      <td>
        {isInput ? (
          <div className="d-flex tableinput">
            <InputField
              type="number"
              placeholder="Enter Deposit Commision"
              value={values?.payin_commission}
              onChange={(e) => {
                setValues({
                  ...values,
                  payin_commission: e.target.value,
                });
              }}
            />
          </div>
        ) : (
          <span className="cursor-pointer">{onFixed(payin_commission || 0)} %</span>
        )}
      </td>
      <td>
        {isInput ? (
          <div className="d-flex tableinput">
            <InputField
              type="number"
              placeholder="Enter Withdrawal Commision"
              value={values?.payout_commission}
              onChange={(e) => {
                setValues({
                  ...values,
                  payout_commission: e.target.value,
                });
              }}
            />
          </div>
        ) : (
          <span className="cursor-pointer">{onFixed(payout_commission || 0)} %</span>
        )}
      </td>
      <td className="gap">
        {role.includes("update-reseller-commission") ?
          <CustomeButton
            color="primary"
            className="btn-smallsize"
            isButtonLoading={isLoading}
            onClick={() => {
              if (isInput) {
                onUpdate();
              } else {
                setIsInput(true);
                setValues({
                  ...values,
                  payin_commission: payin_commission,
                  payout_commission: payout_commission,
                });
              }
            }}
          >
            {isInput ? <i className="fa-solid fa-check"></i> : "Edit"}
          </CustomeButton> : null}
        {role.includes("delete-reseller-merchant") ? <CustomeButton
          isLoading={isDeleteLoading}
          className="btn-smallsize"
          color="danger"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <i className="fa-solid fa-trash"></i>
        </CustomeButton> : null}
        {isInput ? (
          <Button
            color="danger"
            className="btn-smallsize"
            onClick={async () => {
              setValues(initialValues);
              setIsInput(false);
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </Button>
        ) : null}
      </td>
    </tr>
  );
};

export default UpdateCommission;
