import React, { useState } from "react";
import { Button, Form, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { proxyAdd } from "../../../redux/slices/proxySlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";

const AddProxyModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    proxy_ip: "",
    label: "",
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = await dispatch(proxyAdd(values)).unwrap();
      responseToaster(res);
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">Add New Proxy</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <Row>
            <ControlledInput
              name="label"
              label="Label"
              placeholder="Enter Label"
              type="text"
              control={control}
            />
            <ControlledInput
              name="proxy_ip"
              label="Proxy IP"
              placeholder="Enter Proxy IP"
              type="text"
              control={control}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProxyModal;
