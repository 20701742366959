import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";

const ConfirmationModal = (props) => {
    const { isOpen, onUpdate, onClose, isLoading } = props;

    return (
        <Modal isOpen={isOpen} toggle={onClose} centered={true}>
            <div className="modal-header">
                <h3 className="modal-title mt-0">Update Credential</h3>
                <i
                    onClick={() => {
                        onClose();
                    }}
                    className="fa-solid fa-xmark"
                ></i>
            </div>
            <div className="modal-body">
                <div>
                    <p>Are you sure you want to Update Credential?</p>
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex justify-content-end button-space">
                    <Button
                        className="graybutton"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <CustomeButton
                        className="submitbutton"
                        onClick={() => {
                            onUpdate()
                        }}
                        isButtonLoading={isLoading}
                    >
                        Submit
                    </CustomeButton>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
