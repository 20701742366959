import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "reactstrap";
import {
  resellerComissionGet,
  useResellerComission,
} from "../../../redux/slices/resellerSlice";
import { useDispatch } from "react-redux";
import UpdateCommission from "./UpdateCommission";
import { Table } from "../../../components/Table";

const SettingModal = (props) => {
  const { isOpen, onHide } = props;

  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const dispatch = useDispatch();
  const comission = useResellerComission();
  const columns = [
    {
      title: "Merchant",
      name: "merchant",
    },
    {
      title: "Deposit Percentage",
      name: "payin_commission",
      Cell: (item) => {
        return <span>{onFixed(item?.payin_commission)}%</span>;
      },
    },
    {
      title: "Withdrawal Percentage",
      name: "payout_commission",
      Cell: (item) => {
        return <span>{onFixed(item?.payout_commission)}%</span>;
      },
    },
    {
      title: "Action",
      name: "submit",
    },
  ];

  const onGetResellerComission = async () => {
    try {
      setIsLoading(true);
      const payload = {
        resellerId: params?.id,
      };
      await dispatch(resellerComissionGet(payload)).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);

    onGetResellerComission();
  }, []);

  useEffect(() => {
    if (isOpen) {
      onGetResellerComission();
    }
  }, [isOpen]);

  const onClose = () => {
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
    >
      <div className="less-h-modal">
        <div className="modal-header ">
          <h3 className="modal-title mt-0">Setting</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading}
            isData={!!comission?.length}
            minHeight={100}
            isExpandable={false}
            className="m-0"
          >
            {comission?.map((val) => {
              return (
                <UpdateCommission
                  className="reseller-detail"
                  reseller_id={params?.id}
                  onGet={onGetResellerComission}
                  {...val}
                />
              );
            })}
          </Table>
        </div>
      </div>
    </Modal>
  );
};

export default SettingModal;
