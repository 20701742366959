import React, { useState } from "react";
import { Refund_Transaction } from "../../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import { Button, Form, Modal } from "reactstrap";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import ControlledInput from "../../Forms/Controller/ControlledInput";

const refundTypeOptions = [
  { value: "Full", label: "Full Refund" },
  { value: "Partial", label: "Partial Refund" },
];

const RefundModal = (props) => {
  const { isRefundId, isRefund, onCloseRefundOpen, onGet } = props;

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    type: "Full",
    remark: "",
    refundAmt: "",
  };

  const onClose = () => {
    onCloseRefundOpen();
    reset();
  };

  const refund_Transaction = async () => {
    try {
      setIsButtonLoading(true);
      const values = getValues();
      let payload = {
        ...values,
        txnId: isRefundId?.txnId,
        refundAmt:
          values?.type === "Partial"
            ? values?.refundAmt
            : isRefundId?.refundAmt,
      };
      let response = await dispatch(Refund_Transaction(payload)).unwrap();
      responseToaster(response);
      onClose();
      onGet();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, watch, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal isOpen={isRefund} toggle={onClose} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Refund</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(refund_Transaction)}>
        <div className="modal-body">
          <ControlledDropDown
            label="Refund Type"
            name="type"
            options={refundTypeOptions}
            control={control}
          />
          <div className="select-div mt-1">
            <ControlledInput
              name="remark"
              label="Remark"
              type="text"
              placeholder="Enter Remark"
              control={control}
            />
          </div>
          {watch("type") === "Partial" && (
            <div className="select-div mt-1">
              <ControlledInput
                name="refundAmt"
                label="Amount"
                type="number"
                placeholder="Enter Amount"
                control={control}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RefundModal;
