import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  merchant_Settlement_Add,
  pending_Settlement_Get,
  useMerchantPendingSettlementState,
} from "../../../../redux/slices/merchantSlice";
import { useParams } from "react-router";
import Loader from "../../Loader";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import { responseToaster, seperator } from "../../../../helperFunctions";

const MerchantReleaseSettlement = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { releseSettlement, onCloseReleseSettlement } = props;
  const { merchantPendingSettlementIsLoading } = useSelector(
    (state) => state.merchant
  );
  const merchantPendingSettlementState = useMerchantPendingSettlementState();
  const onHide = () => {
    onCloseReleseSettlement();
    reset();
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = {};

  const GetPendingSettlement = async () => {
    try {
      await dispatch(pending_Settlement_Get({ merchantId: id })).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (releseSettlement) {
      GetPendingSettlement();
    }
  }, [releseSettlement]);

  const onSubmit = async () => {
    const values = getValues();
    let payload = {
      ...values,
      merchantId: id,
    };
    try {
      setIsButtonLoading(true);
      const res = await dispatch(merchant_Settlement_Add(payload)).unwrap();
      responseToaster(res);
      if (!!res) {
        GetPendingSettlement();
        onHide();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal
      releseSettlement={!!releseSettlement}
      isOpen={releseSettlement}
      toggle={onHide}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Add Settlement</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {merchantPendingSettlementIsLoading ? (
          <div className="text-center">
            <Loader className="pb-4" />
          </div>
        ) : (
          <>
            <div className="modaltable">
              <span>Pay Date</span>
              <span>Settled</span>
              <span>Unsettled</span>
              <span>Closing Balance</span>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <p>
                    Current Balance :
                    <b>
                      {` ${seperator(merchantPendingSettlementState?.data?.withdrawalBalance)}`}
                    </b>
                  </p>
                </div>
                <div>
                  <p>
                    Available Unsettled Balance :
                    <b>
                      {` ${seperator(merchantPendingSettlementState?.data?.UnsettledBalance)}`}
                    </b>
                  </p>
                </div>

                <div className="select-div">
                  <ControlledInput
                    name="releaseAmt"
                    label="Amount To Release"
                    placeholder="Enter Amount To Release"
                    type="number"
                    control={control}
                  />
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-end button-space">
                <Button className="graybutton" onClick={() => onHide()}>
                  Cancel
                </Button>
                <CustomeButton
                  type="submit"
                  className="submitbutton"
                  isButtonLoading={isButtonLoading}
                >
                  Submit
                </CustomeButton>
              </div>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default MerchantReleaseSettlement;
