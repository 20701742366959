import React, { useEffect, useState } from "react";
import { useGetDepoChartBankState } from "../../../redux/slices/dashboardSlice";
import { useSelector } from "react-redux";
import Loader from "../../../components/Custom/Loader";
import { Modal } from "reactstrap";
import { Table, TableCell, TableRow } from "../../../components/Table";

const ChartDetailsModal = (props) => {
  const { isOpen, onHide } = props;
  const { getDepoChartBankLoading } = useSelector((state) => state.dashboard);
  const getDepoChartBankState = useGetDepoChartBankState();
  const [filterColumns, setFilterColumns] = useState([]);
  const onClose = () => {
    onHide();
  };
  const onTotal = () => {
    let total = getDepoChartBankState?.data?.reduce(
      (sum, item) => sum + item.chart_count,
      0
    );
    return total;
  };
  const columns = [
    {
      title: "BANK NAME",
      name: "bankName",
      Cell: (data) => {
        return (
          <div>
            {data?.bankName} - {data?.pgLabel}
          </div>
        );
      },
    },
    {
      title: "AC NUMBER",
      name: "acNumber",
    },
    {
      title: "TRANSACTION",
      name: "chart_count",
      Cell: (data) => {
        return (
          <div>
            <span
              style={{
                color: "green",
              }}
            >
              {`${data?.total_success_txn || 0} `}
            </span>
            /
            <span
              style={{
                color: "var(--chatlook-darkblue--)",
              }}
            >
              {` ${data?.total_pending_txn || 0}`}
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
    >
      <div className="max-h-modal">
        <div className="modal-header">
          <h3 className="modal-title mt-0">Bank Details</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          {getDepoChartBankLoading ? (
            <div
              className="loader-div"
              style={{ color: "gray", background: "white" }}
            >
              <Loader />
            </div>
          ) : (
            <Table
              columns={columns}
              filterColumns={filterColumns}
              isLoading={getDepoChartBankLoading}
              data={getDepoChartBankState?.data || []}
              isData={getDepoChartBankState?.data?.length > 0}
              count={getDepoChartBankState?.total_item || 0}
              isExpandable={false}
              className="mb-0 p-0"
            >
              {getDepoChartBankState?.data?.map((item) => {
                return (
                  <TableRow
                    columns={columns}
                    filterColumns={filterColumns}
                    item={item}
                    isExpandable={false}
                  />
                );
              })}
              <tr style={{
                borderWidth:2,
                borderColor:'black'
              }}>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <div>
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {`${getDepoChartBankState?.totalSuccess || 0} `}
                    </span>
                    /
                    <span
                      style={{
                        color: "var(--chatlook-darkblue--)",
                      }}
                    >
                      {` ${getDepoChartBankState?.totalPending || 0}`}
                    </span>
                  </div>
                </TableCell>
              </tr>
            </Table>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChartDetailsModal;
