import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster, seperator } from "../../../helperFunctions";
import { payment_banklimit_update } from "../../../redux/slices/payinPayoutSlice";
import Loader from "../../../components/Custom/Loader";

const PayInBankAmount = (props) => {
  const {
    data,
    PaymentBanklimitGet,
    updateDetails,
    setUpdateDetails,
    setIsLoading,
  } = props;
  const dispatch = useDispatch();
  
  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const payload = {
        pgId: data?.pg_id,
        dailyLimit: updateDetails?.value,
      };
      const resMin = await dispatch(payment_banklimit_update(payload)).unwrap();
      responseToaster(resMin);
      await PaymentBanklimitGet();
      setUpdateDetails({});
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
        <div className="order-singal-div">
          <div>
            {updateDetails?.id === data?.pg_id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  autoFocus
                  value={updateDetails?.value}
                  placeholder="Enter Daily Limit"
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button
                  type="submit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={(e) => onUpdate(e)}
                >
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className="cursor-pointer"
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  setUpdateDetails({
                    id: data?.pg_id,
                    value: data?.daily_limit,
                  });
                }}
              >
                {`${seperator(data?.daily_limit)}`}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayInBankAmount;
