import React, { useMemo, useState, useEffect } from "react";
import { Bank_Sync_Get } from "../../../redux/slices/dashboardSlice";
import { Table, TableRow } from "../../../components/Table";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FILTER } from "../../../constant";
import "./BankSync.scss";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { onFixed, seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const BankSync = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter] = useState(FILTER);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { bankSyncGetState } = useSelector((state) => state.dashboard);
  const role = useRole("Bank Sync");

  const bank_Sync = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const { isFilter, ...payload } = filter;
      await dispatch(Bank_Sync_Get(payload)).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("bankSync-list")) {
      bank_Sync(true);
    }
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        bank_Sync(true);
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
  }, [refresh, filter]);

  const columns = useMemo(
    () => [
      {
        title: "BANK ID",
        name: "BANK ID",
        Cell: (data) => {
          return (
            <>
              <div
                className={`${
                  data?.isOpen ? "cell-order-white" : "cell-order"
                }`}
              >
                <div className="order-singal-div">
                  <p>
                    <span> {data?.avBankId || ""}</span>
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>{data?.acHolderName || ""}</span>
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>A/C : </span>
                    {data?.acNumber || ""}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span> IFSC : </span>
                    {data?.ifscCode || ""}
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        title: "ACTIVE",
        name: "ACTIVE",
        Cell: (data) => {
          return (
            <div className="center">
              <span
                className={!!data?.is_sync_active ? "green-light" : "red-light"}
              ></span>
            </div>
          );
        },
      },
      {
        title: "BALANCE",
        name: "liveBankBalance",
        Cell: (data) => {
          return <span>{seperator(data?.liveBankBalance)}</span>;
        },
      },
      {
        title: "TURNOVER",
        name: "TURN OVER",
        Cell: (data) => {
          return (
            <>
              <div className="cell-order">
                <div className="order-singal-div">
                  <p>
                    <span>Current Turnover : </span>
                    {seperator(data?.turnover || "")}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Daily Limit : </span>
                    {seperator(data?.dailyLimit)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>In Per : </span>
                    {`${onFixed(data?.dailyLimitPer || 0)} %`}
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        title: "SYNC AT",
        name: "lastBankSyncIst",
      },
      {
        title: "LAST SUCCESS AT",
        name: "lastSuccess",
      },
      {
        title: "LAST SUCCESS AGO",
        name: "lastSuccessAgo",
      },
      {
        title: "NOTE",
        name: "note",
      },
    ],
    []
  );

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      {role.includes("bankSync-list") ? (
        <>
          <div
            className={`bank-sync-main-div ${
              isLoading && refresh ? "opacity-25" : ""
            }`}
          >
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h6>
                  Bank Sync - Total Bal :{" "}
                  <b>{seperator(bankSyncGetState?.bank_bal || 0)}</b>
                </h6>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <span className=" aurorefresh-btn">
                    <Button
                      type="button"
                      className={`${refresh ? "" : "filterbtn"} gap`}
                      color={refresh ? "primary" : ""}
                      onClick={() => setRefresh(!refresh)}
                      title="Auto refresh"
                    >
                      {refresh ? (
                        <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                      ) : (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      )}
                      Auto Refresh
                    </Button>
                  </span>
                  <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                      id="page-header-user-dropdown"
                      tag="button"
                    >
                      <IoFilterOutline fill="#7367F0" />
                    </DropdownToggle>
                    <DropdownMenu
                      className="z-3"
                      style={{
                        whiteSpace: "nowrap",
                        padding: 20,
                        transform: "translate(-20px, 22px)",
                      }}
                    >
                      <li className="z-3 text-dark">
                        <span>
                          <i className="fa-solid fa-filter"></i> Filter Column
                        </span>
                      </li>
                      <div className="border-bottom border-light mt-2">
                        {columns?.map((column) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <p>{column?.title}</p>
                              <Switch
                                checked={filterColumns?.includes(column?.name)}
                                size="small"
                                onChange={(e) => {
                                  const newData = [...filterColumns];
                                  const index = newData?.indexOf(column?.name);
                                  if (index > -1) {
                                    newData.splice(index, 1);
                                  } else {
                                    newData.push(column?.name);
                                  }
                                  setFilterColumns(newData);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={isLoading && !refresh}
                data={bankSyncGetState?.data}
                isData={!!bankSyncGetState?.data?.length}
                isExpandable={false}
              >
                {bankSyncGetState?.data?.map((item) => {
                  let className = "";
                  if (+item?.lastBankSyncMindeffIst > 15) {
                    className = "bg-row-lightpink";
                  } else if (
                    item?.lastBankSyncMindeffIst <= 15 &&
                    item?.lastBankSyncMindeffIst > 2
                  ) {
                    className = "bg-row-yellow";
                  } else {
                    if (
                      item?.is_sync_active &&
                      item?.merchant_rid == "MID_3UOP4XZR4OO17D" &&
                      item?.level1 === 1
                    ) {
                      className = "bg-row-lightyellow";
                    } else {
                      if (item.last_success_mindeff_ist > 20) {
                        className = "bg-row-lightpurple";
                      }
                    }
                  }

                  return (
                    <TableRow
                      tdClassName={`position-relative ${className}`}
                      // tdClassName={`position-relative`}
                      columns={columns}
                      filterColumns={filterColumns}
                      item={item}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankSync;
