import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getReconciliation } from "../services/reconciliationServices";

const initialState = {
  data: {},
};
export const reconciliationGet = createAsyncThunk(
  "reconciliationGet",
  async (payload) => {
    return await getReconciliation(payload);
  }
);
const reconciliationSlice = createSlice({
  name: "reconciliations",
  initialState,
  reducers: {
    clearReconciliation: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default reconciliationSlice.reducer;
export const {clearReconciliation } =
  reconciliationSlice.actions;
