import { AVAILABLE_BANK, BANK_BALSHEET, MARK_AS_USED, MERGEUTR, UPDATE_BANK_TRANSACTION, UPDATE_BANK_TRANSACTION_MODE, UPDATE_PAYMENT, UPDATE_TRANSACTION } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Bank_BalSheet = (payload) => {
    return apiInstance.post(BANK_BALSHEET, payload);
  };
  
  export const Available_Bank = (payload) => {
    return apiInstance.post(AVAILABLE_BANK, payload);
  };
  export const Merge_UTR = (payload) => {
    return apiInstance.post(MERGEUTR, payload);
  };
  
  export const Mark_As_Used = (payload) => {
    return apiInstance.post(MARK_AS_USED, payload);
  };

  export const Update_Payment = (payload) => {
    return apiInstance.post(UPDATE_PAYMENT, payload);
  };

  export const Update_BalSheet = (payload) => {
    return apiInstance.post(UPDATE_TRANSACTION, payload);
  };  

  export const UpdateBankTransaction = (payload) => {
    return apiInstance.post(UPDATE_BANK_TRANSACTION, payload);
  };

  export const UpdateBankTransactionMode = (payload) => {
    return apiInstance.post(UPDATE_BANK_TRANSACTION_MODE, payload);
  };
