import React from "react";
import { Card, CardHeader } from "reactstrap";
import {
  getRiskManagement,
  useRiskManagement,
} from "../../../redux/slices/riskManagementSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo } from "react";

import { Table, TableRow } from "../../../components/Table";
import { TRA_FILTER, TRA_PAGINATION_OPTIONS } from "../../../constant";
import { responseToaster, seperator } from "../../../helperFunctions";
import RiskManagementDetails from "./RiskManagementDetails";
import { useRole } from "../../../redux/slices/authSlice";

const RiskManagement = () => {
  const [filter, setFilter] = useState(TRA_FILTER);
  const [filterColumns, setFilterColumns] = useState([]);
  const { isLoading } = useSelector((state) => state.riskManagement);
  const [tableData, setTableData] = useState([]);
  const { riskManagementState } = useRiskManagement();

  const dispatch = useDispatch();
  const role = useRole("Risk Management");

  const columns = useMemo(
    () => [
      {
        title: "txn count",
        name: "count",
        sortable: true,
        sortableKey: "count",
      },
      {
        title: "hardware ID",
        name: "browser_id",
      },
      {
        title: "customer id",
        name: "customer_id",
      },
      {
        title: "total payment amount",
        name: "total_payment_amount",
        Cell: (data) => <span>{seperator(data?.total_payment_amount)}</span>,
        sortable: true,
        sortableKey: "total_payment_amount",
      },
    ],
    []
  );

  const GetRiskManagement = async () => {
    try {
      const { isFilter, page_no, limit, date, searchdata, ...rest } = filter;
      const payload = {
        page_no,
        limit,
      };
      const res = await dispatch(getRiskManagement(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("riskManagement-list")) {
      GetRiskManagement();
    }
  }, [filter]);

  useEffect(() => {
    setTableData(
      riskManagementState?.data?.length ? [...riskManagementState.data] : []
    );
  }, [riskManagementState]);

  return (
    <>
      {role.includes("riskManagement-list") ? (
        <>
          <div className="riskManagement-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Risk Management</b>
                    </h4>
                  </div>
                </div>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={tableData || []}
                setData={setTableData}
                isData={!!riskManagementState?.data?.length}
                filterColumns={filterColumns}
                count={riskManagementState?.total_item || 0}
                pagination={filter}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {tableData?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                    >
                      <RiskManagementDetails item={item} />
                    </TableRow>
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RiskManagement;
