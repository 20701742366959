import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getGatewayPaymentStatement,
  getGatewayPayoutStatement,
  getGatewayStatement,
  readMetriws,
} from "../services/metriwsServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  metriwsState: {},
  metriwsPaymentState: [],
  metriwsIsLoading: false,
  metriwsPaymentIsLoading: false,
  gatewayStatementState: {},
  gatewayIsLoading: false,
};

export const metriwsRead = createAsyncThunk("metriwsRead", async (payload) => {
  return await readMetriws(payload);
});
export const gatewayStatementGet = createAsyncThunk(
  "getGatewayStatement",
  async (payload) => {
    return await getGatewayStatement(payload);
  }
);
export const gatewayPaymentStatementGet = createAsyncThunk(
  "gatewayPaymentStatementGet",
  async (payload) => {
    return await getGatewayPaymentStatement(payload);
  }
);
export const gatewayPayoutStatementGet = createAsyncThunk(
  "gatewayPayoutStatementGet",
  async (payload) => {
    return await getGatewayPayoutStatement(payload);
  }
);

const metriwsSlice = createSlice({
  name: "metriwsSlice",
  initialState,
  reducers: {
    clearMetriws: () => initialState,
  },
  extraReducers: (builder) => {
    // builder.addCase(metriwsRead.pending, (state, action) => {
    //   state.metriwsIsLoading = true;
    // });

    // builder.addCase(metriwsRead.fulfilled, (state, action) => {
    //   state.metriwsIsLoading = false;
    //   state.metriwsState = action.payload;
    // });
    // builder.addCase(metriwsRead.rejected, (state, action) => {
    //   state.metriwsIsLoading = false;
    // });
    builder.addCase(gatewayStatementGet.pending, (state, action) => {
      state.gatewayIsLoading = true;
    });
    builder.addCase(gatewayStatementGet.fulfilled, (state, action) => {
      state.gatewayIsLoading = false;
      state.gatewayStatementState = action.payload;
    });
    builder.addCase(gatewayStatementGet.rejected, (state, action) => {
      state.gatewayIsLoading = false;
    });
    builder.addCase(gatewayPaymentStatementGet.pending, (state, action) => {
      state.metriwsPaymentIsLoading = true;
    });
    builder.addCase(gatewayPaymentStatementGet.fulfilled, (state, action) => {
      state.metriwsPaymentIsLoading = false;
      state.metriwsPaymentState = action.payload;
    });
    builder.addCase(gatewayPaymentStatementGet.rejected, (state, action) => {
      state.metriwsPaymentIsLoading = false;
    });
    builder.addCase(gatewayPayoutStatementGet.pending, (state, action) => {
      state.metriwsPaymentIsLoading = true;
    });
    builder.addCase(gatewayPayoutStatementGet.fulfilled, (state, action) => {
      state.metriwsPaymentIsLoading = false;
      state.metriwsPaymentState = action.payload;
    });
    builder.addCase(gatewayPayoutStatementGet.rejected, (state, action) => {
      state.metriwsPaymentIsLoading = false;
    });
  },
});
export default metriwsSlice.reducer;
export const { clearMetriws, gatewayIsLoading } = metriwsSlice.actions;

export const selectGatewayStatementState = (state) =>
  state.metriws.gatewayStatementState;

export const useGatewayStatementState = () => {
  const gatewayStatementState = useSelector(selectGatewayStatementState);
  return useMemo(() => gatewayStatementState, [gatewayStatementState]);
};

export const selectMetriwsPaymentState = (state) =>
  state.metriws.metriwsPaymentState;

export const useGatewayPaymentStatementState = () => {
  const metriwsPaymentState = useSelector(selectMetriwsPaymentState);
  return useMemo(() => metriwsPaymentState, [metriwsPaymentState]);
};

