import React from "react";

const CheckBox = (props) => {
  const {
    className = "",
    labelClassName = "",
    containerClassName = "",
    id,
    name,
    value,
    label,
    checked,
    onChange,
    disabled = false,
  } = props;
  return (
    <div
      className={`d-flex checkboxtxt ${
        containerClassName === "" ? " align-items-center " : containerClassName
      }`}
    >
      <input
        className={`${className} h-4 w-4 me-25 `}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label ? (
        <label className={`text-ev-darkblue text-sm ${labelClassName}`}>
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default CheckBox;
