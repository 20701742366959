import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  addUsers,
  deletUsers,
  getUsers,
  updateUsers,
  resetUserPassword,
  updateCred,
  logoutUser,
  updateSubUserMultiLogin,
} from "../services/usersServices";

const initialState = {
  data: {},
};

export const usersGet = createAsyncThunk("usersGet", async (payload) => {
  return await getUsers(payload);
});
export const usersAdd = createAsyncThunk("usersAdd", async (payload) => {
  return await addUsers(payload);
});
export const usersUpdate = createAsyncThunk("usersUpdate", async (payload) => {
  return await updateUsers(payload);
});
export const usersDelet = createAsyncThunk("usersDelet", async (payload) => {
  return await deletUsers(payload);
});
export const updateCrede = createAsyncThunk("updateCred", async (payload) => {
  return await updateCred(payload);
});
export const updateMultiLogin = createAsyncThunk("updateMultiLogin", async (payload) => {
  return await updateSubUserMultiLogin(payload);
});
export const resetPasswordUser = createAsyncThunk(
  "usersResetPwd",
  async (payload) => {
    return await resetUserPassword(payload);
  }
);
export const logoutSubUser = createAsyncThunk(
  "logoutUser",
  async (payload) => {
    return await logoutUser(payload);
  }
);

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    clearUsers: () => initialState,
    setUsers: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default usersSlice.reducer;
export const { clearUsers, setUsers } = usersSlice.actions;

export const selectUsers = (state) => {
  return state.users.data;
};
export const useUsers = () => {
  const data = useSelector(selectUsers);
  return useMemo(() => data, [data]);
};
