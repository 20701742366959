// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import ImagePicker from "../ImagePicker/ImagePicker";

const ControlledImagePicker = (props) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return <ImagePicker {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledImagePicker;
