import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { reportMerchantListGet } from "../../../redux/slices/ReportSlice";
import { Filter_Data_Transaction } from "../../../redux/slices/transactionSlice";
import Export from "../../../components/Custom/Elements/Export/Export";
import { DROPDOWN_ALL_VALUE, showToaster } from "../../../constant";
import { Table, TableRow } from "../../../components/Table";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";

const initialValues = {
  date: [null, null],
  merchantId: DROPDOWN_ALL_VALUE,
};

const columns = [
  {
    name: "date",
    title: "Date",
  },
  {
    name: "merchantName",
    title: "Merchant",
  },
  {
    name: "deposit",
    title: "Deposit",
    Cell: (data) => {
      return (
        <>
          <span>{seperator(data?.deposit, false)}</span>
        </>
      );
    },
  },
  {
    name: "withdrawal",
    title: "Withdrawal",
    Cell: (data) => {
      return (
        <>
          <span>{seperator(data?.withdrawal, false)}</span>
        </>
      );
    },
  },
  {
    name: "success_payment_amount",
    title: "Deposit Amount",
    Cell: (data) => {
      return (
        <>
          <span>{seperator(data?.success_payment_amount)}</span>
        </>
      );
    },
  },
  {
    name: "success_payout_amount",
    title: "Withdrawal Amount",
    Cell: (data) => {
      return (
        <>
          <span>{seperator(data?.success_payout_amount)}</span>
        </>
      );
    },
  },
];

const ReportModal = (props) => {
  const { onHide, isOpen } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);

  const ref = useRef();

  const { transactionFilterState } = useSelector((state) => state.transaction);

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const prepareMerchantOptions = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const onGetFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) {}
  };

  const prepareHeaders = () => {
    return columns?.map((item) => ({
      key: item?.name,
      label: item?.title,
    }));
  };

  const onGetMerchantList = async () => {
    try {
      const values = getValues();
      if (!values?.merchantId) {
        showToaster("Please select a merchant", "Error");
        return;
      }
      setIsLoading(true);

      const { date, ...rest } = values;
      const payload = {
        startDate: dateFormatter(date?.[0], "start"),
        endDate: dateFormatter(date?.[1], "end", date?.[0]),
        ...rest,
      };
      const res = await dispatch(reportMerchantListGet(payload)).unwrap();
      setMerchantList(res?.data);
      responseToaster(res);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetFilterTransactions();
    }
  }, [isOpen]);

  useEffect(() => {
    onGetFilterTransactions();

    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Quick Report</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onGetMerchantList)}>
          <div className="d-flex button-space w-100 selectitems align-items-end mb-50">
            <ControlledDropDown
              name="merchantId"
              label="Merchant"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={prepareMerchantOptions()}
              control={control}
            />
            <ControlledDatePicker
              name="date"
              placeholder="Select Date"
              label="Date"
              maxDate={new Date()}
              selectsRange
              control={control}
            />
            <CustomeButton
              type="submit"
              className="btn btn-primary rounded"
              isButtonLoading={isLoading}
            >
              Generate
            </CustomeButton>
          </div>
        </Form>
        <Table
          columns={columns}
          isLoading={isLoading}
          data={merchantList || []}
          isData={!!merchantList?.length}
          filterColumns={filterColumns}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {merchantList?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onClose()}>
            Cancel
          </Button>
          <CustomeButton
            type="submit"
            className="submitbutton rounded"
            onClick={async () => {
              if (!merchantList?.length || isLoading) {
                showToaster("No Data Found", "Error");
                return;
              }
              ref?.current?.link?.click();
            }}
          >
            Download
          </CustomeButton>
          <Export
            className="hidden"
            refe={ref}
            headers={prepareHeaders()}
            data={merchantList}
            filename="Quick Report"
          >
            Done
          </Export>
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
