import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPgConfig, updatePgConfig } from "../services/pgConfigServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  data: {},
};

export const pgConfigGet = createAsyncThunk("/pgConfigGet", async (payload) => {
  return await getPgConfig(payload);
});

export const pgConfigUpdate = createAsyncThunk("/pgConfigUpdate", async (payload) => {
  return await updatePgConfig(payload);
});

const pgConfigSlice = createSlice({
  name: "pgConfigSlice",
  initialState,
  reducers: {
    clearPgConfig: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(pgConfigGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default pgConfigSlice.reducer;
export const { clearPgConfig } = pgConfigSlice.actions;

export const selectPgConfig = (state) => {
  return state.pgConfig.data;
};
export const usePgConfigData = () => {
  const pgConfig = useSelector(selectPgConfig);
  return useMemo(() => pgConfig, [pgConfig]);
};
