import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Modal } from "reactstrap";
import { responseToaster } from "../../../helperFunctions";
import Loader from "../../../components/Custom/Loader";
import {
  Merchant_Pay_In_Available_Payment_Get,
  Merchant_Pay_In_Pay_Out_Central_Bank_Add,
  Merchant_Pay_Out_Available_Payment_Get,
  useMerchantPayInAvailablePayment,
  useMerchantPayOutAvailablePayment,
} from "../../../redux/slices/merchantSlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";

const buttons = ["Deposit", "Withdrawal"];

const AddBankModal = (props) => {
  const { onHide, isOpen } = props;

  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const deposit = useMerchantPayInAvailablePayment();
  const withdrawal = useMerchantPayOutAvailablePayment();

  const [selected, setSelected] = useState([]);
  const [selectedButton, setSelectedButton] = useState(buttons?.[0]);
  const [isLoading, setIsLoading] = useState(false);

  const data =
    selectedButton === "Deposit"
      ? (deposit?.data?.auto || [])?.concat(deposit?.data?.manual || [])
      : withdrawal?.data || [];

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsButtonLoading(true);
      setIsLoading(true);
      let payload = {};
      if (selectedButton === "Deposit") {
        payload["bankData"] = selected
          ?.filter((i) => i?.type === "Deposit")
          ?.map((item) => ({
            pgId: item?.accountId,
            label: item?.pgLabel,
            pgName: item?.pgName,
            pgType: item?.pgType,
            paymentMethods: item?.meta_methods,
          }));
        payload["meta_type"] = "Payin";
      } else {
        payload["bankData"] = selected
          ?.filter((i) => i?.type === "Withdrawal")
          ?.map((item) => ({
            pgId: item?.accountId,
            label: item?.pgLabel,
            pgName: item?.pgName,
          }));
        payload["meta_type"] = "Payout";
      }
      const res = await dispatch(
        Merchant_Pay_In_Pay_Out_Central_Bank_Add(payload)
      ).unwrap();
      responseToaster(res);
      setIsLoading(false);
      onHide();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setSelected([]);
    onHide();
  };

  const onGetData = async () => {
    try {
      const payload = {};
      await Promise.all([
        dispatch(Merchant_Pay_In_Available_Payment_Get(payload)).unwrap(),
        dispatch(Merchant_Pay_Out_Available_Payment_Get(payload)).unwrap(),
      ]);
    } catch (err) {}
  };

  useEffect(() => {
    if(isOpen){
      onGetData();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
      className="modal-width"
    >
      <Form onSubmit={onSubmit} className="max-h-modal less-h-modal">
        <div className="modal-header">
          <h4 className="modal-title mt-0">Add Central Bank</h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-2 mt-1 mb-1">
          {buttons.map((item) => (
            <Button
              className={item === selectedButton ? "primary" : "filterbtn"}
              color={item === selectedButton ? "primary" : ""}
              onClick={() => setSelectedButton(item)}
            >
              {item}
            </Button>
          ))}
        </div>
        {data?.map((val) => {
          return (
            <div
              className="d-flex align-items-center px-2 centralbank "
              style={{
                gap: 10,
              }}
            >
              <Switch
                name="HDFC"
                checked={
                  !!selected?.find((item) => item?.accountId === val?.accountId)
                }
                disabled={isLoading}
                size="small"
                onChange={(e) => {
                  if (e.target.checked) {
                    const newSelected = [...selected];
                    newSelected.push({
                      ...val,
                      meta_methods: [],
                      type: selectedButton,
                    });
                    setSelected(newSelected);
                  } else {
                    const newSelected = selected?.filter(
                      (item) => item?.accountId !== val?.accountId
                    );
                    setSelected(newSelected);
                  }
                }}
              />
              <p>{`${val?.pgLabel} ( ${val?.pgName} )`}</p>
              {selectedButton === "Deposit" ? (
                <>
                  {val?.meta_methods?.map((item) => {
                    const isSelected = selected?.find(
                      (subItem) =>
                        subItem?.meta_methods?.includes(item) &&
                        val?.accountId === subItem?.accountId
                    );
                    return (
                      <Button
                        className={`btn-smallsize ${
                          isSelected ? "primary" : "filterbtn"
                        }`}
                        color={isSelected ? "primary" : ""}
                        // style={{ height: 15 }}
                        type="button"
                        onClick={() => {
                          const newSelected = [...selected];
                          const index = newSelected?.findIndex(
                            (v) => v?.accountId === val?.accountId
                          );
                          if (index > -1) {
                            if (isSelected) {
                              newSelected[index]["meta_methods"] = newSelected[
                                index
                              ]["meta_methods"]?.filter(
                                (inner) => inner !== item
                              );
                            } else {
                              newSelected[index]["meta_methods"]?.push(item);
                            }
                            setSelected([...newSelected]);
                          }
                        }}
                      >
                        {item}
                      </Button>
                    );
                  })}
                </>
              ) : null}
            </div>
          );
        })}
        {isLoading?.loading && (
          <div className="loader-div">
            <Loader />
          </div>
        )}
        <div className="modal-footer">
          {/* <Button type="submit" color="primary">
            Add
          </Button> */}
          <CustomeButton type="submit" isButtonLoading={isButtonLoading}>Add</CustomeButton>
          <Button type="button" color="danger" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddBankModal;
