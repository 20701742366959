import { GET_GATEWAY_PAYMENT_STATMENT, GET_GATEWAY_PAYOUT_STATMENT, GET_GATEWAY_STATMENT, READ_METRIWS } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const readMetriws = (payload) => {
  return apiInstance.post(READ_METRIWS,payload);
};
export const getGatewayStatement = (payload) => {
  return apiInstance.post(GET_GATEWAY_STATMENT,payload);
};
export const getGatewayPaymentStatement = (payload) => {
  return apiInstance.get(GET_GATEWAY_PAYMENT_STATMENT,payload);
};
export const getGatewayPayoutStatement = (payload) => {
  return apiInstance.post(GET_GATEWAY_PAYOUT_STATMENT,payload);
};