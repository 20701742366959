import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getRouter, updateIsPayinDown } from "../services/pgRoutersSrevices";

const initialState = {
  data: {},
};

export const pgRouterGet = createAsyncThunk("/pgrouterget", async (payload) => {
  return await getRouter(payload);
});
export const isPayinDownUpdate = createAsyncThunk(
  "/pgrouterget",
  async (payload) => {
    return await updateIsPayinDown(payload);
  }
);

const pgRouterSlice = createSlice({
  name: "pgRouterSlice",
  initialState,
  reducers: {
    clearPgRouter: () => initialState,
    setPgRouter: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pgRouterGet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
});
export default pgRouterSlice.reducer;
export const { clearPgRouter, setPgRouter } = pgRouterSlice.actions;

export const selectPgRouterData = (state) => {
  return state.pgRouter.data;
};
export const usePgRouterData = () => {
  const data = useSelector(selectPgRouterData);
  return useMemo(() => data, [data]);
};
