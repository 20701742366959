import { useSidebar } from "../../../redux/slices/authSlice";
import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { Fragment, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import "./VerticalLayout.scss";

// Image
import PgIcon from "../../../assets/images/pages/pgIcon.svg";
import DevIcon from "../../../assets/images/pages/developericon.svg";
import { prepareSvgUrl } from "../../../helperFunctions";

const Sidebar = (props) => {
  const { isSidebarOpen, setIsSidebarOpen } = props;
  const sidebar = useSidebar();

  const [open, setOpen] = useState("");
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const myStyle = {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const logo = process.env.REACT_APP_INNER_LOGO;

  return (
    <React.Fragment>
      <Fragment>
        <div
          className={`sidebar ${isSidebarOpen ? "open" : ""}`}
          style={{
            overflow: "scroll",
            width: isSidebarOpen ? "230px" : 0,
            padding: isSidebarOpen ? "13px" : "0",
            zIndex: 999,
          }}
        >
          <div className="py-2 main-sidebar">
            <div className="d-flex align-items-center justify-content-between">
              {logo ? (
                <img
                  src={prepareSvgUrl(logo)}
                  // src={Logo}
                  className="img-fluid"
                  width={150}
                  alt=""
                />
              ) : null}
             {/*  <img
                src={prepareSvgUrl(logo)}
                // src={Logo}
                className="img-fluid"
                width={150}
                alt=""
              /> */}
              <button
                type="button"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="btn btn-sm font-size-16 header-item waves-effect vertical-menu-btn nav-menubtn"
                id="vertical-menu-btn"
              >
                <i className="fa-solid fa-bars" />
              </button>
            </div>
          </div>
          {Object.entries(sidebar || {})?.map(([key, val]) => {
            if (key === "SETTING") {
              return (
                <Fragment key={val}>
                  <ul style={!isSidebarOpen ? myStyle : {}} className="mb-0">
                    <div
                      className={` sidebar-head-txt py-50`}
                      onClick={() => {
                        setIsSettingOpen(!isSettingOpen);
                      }}
                    >
                      <>
                        {isSidebarOpen ? `${key}` : <h3 className="m-0">-</h3>}
                      </>
                    </div>
                  </ul>
                  {Object.entries(val || {})?.map(([subKey, subVal]) => {
                    const included = subKey === "PG" || subKey === "DEVELOPER";

                    return (
                      <Fragment key={subVal}>
                        <ul
                          style={!isSidebarOpen ? myStyle : {}}
                          className={`mb-0 `}
                        >
                          <div
                            className={`${
                              subKey === "PG" ? " " : ""
                            } sidebar-head-txt`}
                            onClick={() => {
                              if (included) {
                                setMenuOpen(
                                  subKey === menuOpen ? false : subKey
                                );
                              }
                            }}
                          >
                            {subKey === "PG" || subKey === "DEVELOPER" ? (
                              <>
                                <div
                                  className={`cursor-pointer pgsec sidebar-item ${
                                    subKey === menuOpen ? "mainactive" : ""
                                  }`}
                                >
                                  <span className="d-flex align-items-center">
                                    {isSidebarOpen ? (
                                      <>
                                        <img
                                          src={
                                            subKey === "PG"
                                              ? `${PgIcon}`
                                              : `${DevIcon}`
                                          }
                                          alt=""
                                          className="img-fluid me-1"
                                        />
                                        {subKey}
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            subKey === "PG"
                                              ? `${PgIcon}`
                                              : `${DevIcon}`
                                          }
                                          alt=""
                                          className={`${
                                            isSidebarOpen ? "ime-1" : ""
                                          }img-fluid `}
                                        />
                                      </>
                                    )}
                                  </span>
                                  {isSidebarOpen ? (
                                    <span>
                                      {subKey === menuOpen ? (
                                        <i className="fa-solid fa-angle-down"></i>
                                      ) : (
                                        <i className="fa-solid fa-angle-right p-0"></i>
                                      )}
                                    </span>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                          {(included && menuOpen === subKey) || !included
                            ? subVal?.map((item, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div
                                      className={`${
                                        included ? "subpadding" : ""
                                      }  `}
                                    >
                                      <SidebarMenu
                                        val={item}
                                        isDrawerOpen={isSidebarOpen}
                                        isSidebarOpen={
                                          !!(item?.name && open === item?.name)
                                        }
                                        onClick={() => {
                                          setOpen(item?.name);
                                        }}
                                        setIsSidebarOpen={setIsSidebarOpen}
                                      />
                                    </div>
                                  </Fragment>
                                );
                              })
                            : null}
                        </ul>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            }
            return (
              <>
                <ul
                  style={!isSidebarOpen ? myStyle : {}}
                  className="mb-0"
                  key={val}
                >
                  <div
                    className={`${
                      key === "PG" ? " " : ""
                    } sidebar-head-txt py-50`}
                  >
                    {isSidebarOpen ? `${key}` : <h3 className="m-0">-</h3>}
                  </div>
                  {val?.map((item, val) => {
                    return (
                      <Fragment key={val}>
                        <SidebarMenu
                          val={item}
                          isDrawerOpen={isSidebarOpen}
                          isSidebarOpen={!!(item?.name && open === item?.name)}
                          onClick={() => {
                            setOpen(item?.name);
                          }}
                          setIsSidebarOpen={setIsSidebarOpen}
                        />
                      </Fragment>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </div>
      </Fragment>
    </React.Fragment>
  );
};

export default Sidebar;
