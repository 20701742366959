import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { Logout } from "../../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useClearData } from "../../../../utility/hooks";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../../helperFunctions";
import { useNavigate } from "react-router-dom";

const LogoutConfirmModal = (props) => {
  const { onCloseModal, isOpenLogoutModal } = props;

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userlogout = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(Logout()).unwrap();
      navigate("/login");
      // responseToaster(res);
      onCloseModal();
      useClearData(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      onCloseModal();
    }
  };
  const onConfirmClick = () => {
    userlogout();
  };

  return (
    <Modal isOpen={isOpenLogoutModal} toggle={onCloseModal} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Logout</h3>
        <i
          onClick={() => {
            onCloseModal();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to logout ?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onCloseModal}>
            Cancel
          </Button>
          <CustomeButton
            onClick={onConfirmClick}
            isButtonLoading={isLoading}
            className="submitbutton"
          >
            Logout
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutConfirmModal;
