import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getBankAnalytics, getBankAnalyticsForTable } from "../services/bankAnalyticsServices";

const initialState = {
  dataForDay: {},
  dataForDate: {},
};

export const bankAnalyticsGetForDay = createAsyncThunk("/bankAnalyticsGetForDay", async (payload) => {
  return await getBankAnalytics(payload);
});
export const bankAnalyticsGetForDate = createAsyncThunk("/bankAnalyticsGetForDate", async (payload) => {
  return await getBankAnalyticsForTable(payload);
});

const bankAnalyticsSlice = createSlice({
  name: "bankAnalyticsSlice",
  initialState,
  reducers: {
    clearBankAnalytics: () => initialState,
   
  },
  extraReducers: (builder) => {
    builder.addCase(bankAnalyticsGetForDay.fulfilled, (state, action) => {
      state.dataForDay = action.payload;
    });
    builder.addCase(bankAnalyticsGetForDate.fulfilled, (state, action) => {
      state.dataForDate = action.payload;
    });
  },
});
export default bankAnalyticsSlice.reducer;
export const { clearBankAnalytics } = bankAnalyticsSlice.actions;

export const selectBankAnalyticsForDay = (state) => {
  return state.bankAnalytics.dataForDay;
};
export const useBankAnalyticsForDay = () => {
  const data = useSelector(selectBankAnalyticsForDay);
  return useMemo(() => data, [data]);
};
export const selectBankAnalyticsForDate = (state) => {
  return state.bankAnalytics.dataForDay;
};
export const useBankAnalyticsForDate = () => {
  const data = useSelector(selectBankAnalyticsForDate);
  return useMemo(() => data, [data]);
};
