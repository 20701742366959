import {
  CURRENT_SSTRANSACTION,
  SSTRANSACTION,
  UPDATE_SSTRANSACTION,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const SsTransaction = (payload) => {
  return apiInstance.post(SSTRANSACTION, payload);
};

export const getCurrentSsTransaction = (payload) => {
  return apiInstance.post(CURRENT_SSTRANSACTION, payload);
};

export const updateSsTransaction = (payload) => {
  return apiInstance.post(UPDATE_SSTRANSACTION, payload);
};
