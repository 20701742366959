// React Imports
import { useState, useEffect, Fragment } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MuiListItem from "@mui/material/ListItem";

// Third Party Imports
import clsx from "clsx";
import { usePopper } from "react-popper";

import themeConfig from "../../../../configs/themeConfig";
import HorizontalNavItems from "./HorizontalNavItems";
import {
  hasActiveChild,
  hexToRGBA,
  prepareImageName,
} from "../../../../helperFunctions";
import { useLocation } from "react-router-dom";
import Icon from "../../../Custom/Elements/Icon/Icon";
import { baseUrlImg } from "../../../../redux/api/baseUrl";

// Styled Components
const ListItem = styled(MuiListItem)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    background: theme.palette.action.hover,
  },
}));

const NavigationMenu = styled(Paper)(({ theme }) => ({
  overflowY: "auto",
  padding: theme.spacing(1, 0),
  maxHeight: "calc(100vh - 13rem)",
  backgroundColor: theme.palette.background.paper,
  ...(themeConfig.menuTextTruncate
    ? { width: "100%", minWidth: 250 }
    : { minWidth: 250 }),
  "& > :not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
  "&::-webkit-scrollbar": {
    width: 6,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: 20,
    background: hexToRGBA(
      theme.palette.mode === "light" ? "#B0ACB5" : "#575468",
      0.6
    ),
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: 20,
    background: "transparent",
  },
  "& .MuiList-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .menu-group.Mui-selected": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const HorizontalNavGroup = (props) => {
  const { item, hasParent } = props;

  const router = useLocation();

  const currentURL = router.pathname;
  const { horizontalMenuToggle, horizontalMenuAnimation } = themeConfig;
  const popperOffsetHorizontal = -16;
  const popperPlacement = "bottom-start";
  const popperPlacementSubMenu = "right-start";

  const [menuOpen, setMenuOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: hasParent ? popperPlacementSubMenu : popperPlacement,
      modifiers: [
        {
          enabled: true,
          name: "offset",
          options: {
            offset: hasParent ? [-8, -9] : [popperOffsetHorizontal, -2],
          },
        },
        {
          enabled: true,
          name: "flip",
        },
      ],
    }
  );

  const handleGroupOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    update ? update() : null;
  };

  const handleGroupClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuToggleOnClick = (event) => {
    if (anchorEl) {
      handleGroupClose();
    } else {
      handleGroupOpen(event);
    }
  };
  useEffect(() => {
    handleGroupClose();
    // eslint-disable-next-line
  }, [router.asPath]);

  const toggleIcon = "tabler:chevron-right";
  const WrapperCondition = horizontalMenuToggle === "click";
  const MainWrapper = WrapperCondition ? ClickAwayListener : "div";
  const ChildWrapper = WrapperCondition ? "div" : Fragment;
  const AnimationWrapper = horizontalMenuAnimation ? Fade : Fragment;

  const childMenuGroupStyles = () => {
    if (attributes && attributes.popper) {
      if (attributes.popper["data-popper-placement"] === "right-start") {
        return "left";
      }
      if (attributes.popper["data-popper-placement"] === "left-start") {
        return "right";
      }
    }
  };

  return (
    <>
      <MainWrapper
        {...(WrapperCondition
          ? { onClickAway: handleGroupClose }
          : { onMouseLeave: handleGroupClose })}
      >
        <ChildWrapper>
          <List component="div">
            <ListItem
              aria-haspopup="true"
              {...(WrapperCondition ? {} : { onMouseEnter: handleGroupOpen })}
              className={clsx("menu-group", {
                "Mui-selected": hasActiveChild(item, currentURL),
              })}
              {...(horizontalMenuToggle === "click"
                ? { onClick: handleMenuToggleOnClick }
                : {})}
              sx={{
                ...(!hasParent
                  ? {
                      "&.Mui-selected": {
                        boxShadow:` var(--chatlook-darkblue--) 0px 4px 8px -3px, var(--chatlook-darkblue--) 0px 0px 0px 1px`,
                        background: `var(--chatlook-darkblue--)`,
                        "& .MuiTypography-root, & .MuiListItemIcon-root, & svg":
                          {
                            color: "common.white",
                          },
                      },
                    }
                  : { mx: 2, width: `100%` }),
              }}
            >
              <Box
                sx={{
                  gap: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                ref={setReferenceElement}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 10,
                      mr: 2,

                      color: menuOpen ? "text.primary" : "text.secondary",
                    }}
                  >
                    {item?.icon_class ? (
                      <img
                        className="subitem-icon"
                        src={`${baseUrlImg}${
                          hasActiveChild(item, currentURL) && !hasParent
                            ? prepareImageName(item?.icon_class)
                            : item?.icon_class
                        }`}
                      />
                    ) : (
                      <div className="dot"></div>
                    )}
                  </ListItemIcon>
                  <Typography
                    className="mb-0"
                    sx={{
                      color: true ? "text.primary" : "text.secondary",
                      display: "flex",
                      overflow: "visible",
                      flexWrap: "nowrap",
                      textOverflow: "visible",
                      fontSize: 14,
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: menuOpen ? "text.secondary" : "text.disabled",
                  }}
                >
                  {item.badgeContent ? (
                    <Chip
                      size="small"
                      label={item.badgeContent}
                      color={item.badgeColor || "primary"}
                      sx={{
                        mr: 2,
                        height: 22,
                        minWidth: 22,
                        "& .MuiChip-label": {
                          px: 1.5,
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  ) : null}
                  <Icon
                    icon={hasParent ? toggleIcon : "tabler:chevron-down"}
                    fontSize="1.125rem"
                  />
                </Box>
              </Box>
            </ListItem>
            <AnimationWrapper
              {...(horizontalMenuAnimation
                ? {
                    in: menuOpen,
                    timeout: { exit: 300, enter: 400 },
                  }
                : {})}
            >
              <Box
                style={styles.popper}
                ref={setPopperElement}
                {...attributes.popper}
                sx={{
                  zIndex: 10,
                  ...(!horizontalMenuAnimation && {
                    display: menuOpen ? "block" : "none",
                  }),
                  pl: childMenuGroupStyles() === "left" ? 1.25 : 0,
                  pr: childMenuGroupStyles() === "right" ? 1.25 : 0,
                  ...(hasParent
                    ? { position: "fixed !important" }
                    : { pt: 1.5 }),
                }}
              >
                <NavigationMenu
                  sx={{
                    ...(hasParent
                      ? {
                          overflowY: "auto",
                          overflowX: "visible",
                          maxHeight: "calc(100vh - 21rem)",
                        }
                      : {}),

                    boxShadow: 6,
                  }}
                >
                  <HorizontalNavItems
                    {...props}
                    hasParent={hasParent}
                    horizontalNavItems={item?.child}
                  />
                </NavigationMenu>
              </Box>
            </AnimationWrapper>
          </List>
        </ChildWrapper>
      </MainWrapper>
    </>
  );
};

export default HorizontalNavGroup;
