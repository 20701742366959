import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

const swiftCustomerSlice = createSlice({
  name: "swiftCustomerSlice",
  initialState,
  reducers: {
    clearSwiftCustomer: () => initialState,
  },
  extraReducers: (builder) => {},
});
export default swiftCustomerSlice.reducer;
export const { clearSwiftCustomer } = swiftCustomerSlice.actions;
