import React from "react";

const TableBody = (props) => {
    const {
        className = "",
        style = {},
        children,
        reff
    } = props;
    return (
        <tbody className={`${className}`} style={{ ...style }} ref={reff}>
            {children}
        </tbody>
    );
};

export default TableBody;
