import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "reactstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { loginAgain, useUser } from "../../../../redux/slices/authSlice";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import EyeOff from "../../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../../assets/images/pages/eye-on.svg";
import { getLocalData, setLocalData } from "../../../../helperFunctions";
import { useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { values } from "lodash";

const LoginAgainModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    emailId: "",
    password: "",
  };

  const schema = Yup.object({
    password: Yup.string().required("Please enter a password"),
  });

  const { control, errors, setValue, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    setIsLoading(false);
    setIsOpen(false);
    reset();
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await dispatch(loginAgain(values)).unwrap();
      responseToaster(response);
      onClose();
    } catch (err) {
      onClose();
      navigate("/login");
    } finally {
      setLocalData("is_block", false);
    }
  };

  useEffect(() => {
    if (user?.token) {
      const inter = setInterval(() => {
        const isBlock = getLocalData("is_block", JSON.stringify(false));
        if (isBlock && user?.token) {
          setIsOpen(true);
          clearInterval(inter);
        }
      }, 1000);
    }
  }, [window]);

  useEffect(() => {
    setValue("emailId", user?.emailId);
  }, [user]);

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!(isOpen && user?.token)}
      centered={true}
    >
      <div className="modal-header justify-content-center">
        <h3 className="modal-title text-center mt-0">
          <b>Log in again</b>
        </h3>
        {/* <h3 className="modal-title mt-0 text-red">You have been blocked</h3> */}
      </div>
      <p className="text-center mb-0 opacity-50">{user?.emailId}</p>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column gap-1 ">
            {/* <ControlledInput
              type="text"
              className="w-100"
              name="emailId"
              label="Username"
              disabled
              placeholder="Enter your username (Registered Email ID)"
              control={control}
              errors={errors}
            /> */}
            <ControlledInput
              type={isShow ? "text" : "password"}
              name="password"
              label="Password"
              className="w-100 mb-50"
              inputClassName="py-50"
              placeholder="Enter your password"
              rightIcon={
                <img
                  src={isShow ? EyeOn : EyeOff}
                  alt="Eye"
                  onClick={() => setIsShow(!isShow)}
                />
              }
              control={control}
              errors={errors}
            />
            <p className="bg-textinitialized text-center px-2">
              Someone attempted to log in with your credentials multiple times
              and failed. Please verify your details again.
            </p>
            <CustomeButton
              isLoading={isLoading}
              className="btn-login"
              block
              type="submit"
            >
              Sign In
            </CustomeButton>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default LoginAgainModal;
