import { smsLogsGet, useSmsLogsData } from "../../../redux/slices/smsLogsSlice";
import { Table, TableRow } from "../../../components/Table";
import { Button, Card, CardHeader, Form } from "reactstrap";
import React, { useEffect, useState } from "react";
import { DROPDOWN_ALL_VALUE, FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const isGetOptions = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

const searchOptions = [
  {
    label: "Hardware ID",
    value: "hardwareId",
  },
];
const SmsLogs = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const data = useSmsLogsData();
  const role = useRole("SMS Logs");

  const columns = [
    {
      title: "SENDER",
      name: "label_name",
      Cell: (data) => {
        const smsBody = JSON.parse(data?.sms_logs);
        return (
          <>
            <span>{smsBody?.sms_mobile_number}</span>
          </>
        );
      },
    },
    {
      title: "HARDWARE ID",
      name: "hardware_id",
    },
    {
      title: "DATE",
      name: "created_at_ist",
    },
    {
      title: "SMS DATE",
      name: "sms_date_long",
    },
    {
      title: "BODY",
      Cell: (data) => {
        const smsBody = JSON.parse(data?.sms_logs);
        return (
          <>
            <span>{smsBody?.sms_body}</span>
          </>
        );
      },
    },
    {
      title: "SMS PHONE DATE",
      name: "created_at",
      Cell: (data) => {
        const smsBody = JSON.parse(data?.sms_logs);
        return (
          <>
            <span>{smsBody?.sms_date}</span>
          </>
        );
      },
    },
    {
      title: "IS GET",
      name: "isget",
      Cell: (data) => {
        return (
          <>
            <span>{data?.isget == 1 ? "Yes" : "No"}</span>
          </>
        );
      },
    },
  ];

  const initialValues = {
    filter: "",
    search: "",
    isGet: DROPDOWN_ALL_VALUE,
    date: [null, null],
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const {
        isFilter,
        filter: dropdown,
        search,
        date,
        page_no,
        limit,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
            page_no,
            limit,
            filter_data: {
              [dropdown]: search,
              startDate: dateFormatter(date?.[0], "start"),
              endDate: dateFormatter(date?.[1], "end", date?.[0]),
              ...rest,
            },
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(smsLogsGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("smsLogs-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      {role.includes("smsLogs-list") ? (
        <>
          <div className="merchant-main-div">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title">
                    <b>SMS Logs</b>
                  </h4>
                </div>

                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledDropDown
                    name="filter"
                    label="Filter"
                    isDefaultOption={false}
                    options={searchOptions}
                    control={control}
                  />
                  <ControlledInput
                    name="search"
                    placeholder="Enter Search Value"
                    label="Search"
                    control={control}
                  />
                  <ControlledDropDown
                    name="isGet"
                    label="Is Get"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={isGetOptions}
                    control={control}
                  />
                  <ControlledDatePicker
                    name="date"
                    placeholder="Select Date"
                    label="Date"
                    selectsRange
                    maxDate={new Date()}
                    control={control}
                  />
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger "
                    onClick={() => {
                      reset();
                      setFilter({ ...FILTER });
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardHeader>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={isLoading}
                data={data?.data}
                isData={!!data?.data?.length}
                count={data?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
              >
                {data?.data?.map((val) => {
                  return (
                    <TableRow
                      columns={columns}
                      filterColumns={filterColumns}
                      isExpandable={false}
                      item={val}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SmsLogs;
