import React, { useMemo, useState, useEffect } from "react";
import { Button, Card, CardHeader, Form } from "reactstrap";
import "./Webhook_Event.scss";
import { getWebhook, useWebhook } from "../../../redux/slices/webhookSlice";
import { useDispatch, useSelector } from "react-redux";

// Formik validation
import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import {
  DATE_RANGE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
  WEBHOOK_EVENTS_LOCAL,
} from "../../../constant";
import ViewSentdataModal from "../../../components/Custom/Modals/ViewSentdataModal/ViewSentdataModal";
import {
  dateFormatter,
  getLocalData,
  responseToaster,
  setLocalData,
} from "../../../helperFunctions";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const Webhook_Event = () => {
  const emailidOptions = [
    { value: "activity_id", label: "Event_id" },
    { value: "activity_genre", label: "Event_Type" },
  ];
  const initialValues = {
    filter: emailidOptions?.[0]?.value,
    search: "",
    date: DATE_RANGE,
  };

  const [filterColumns, setFilterColumns] = useState([]);

  const [filter, setFilter] = useState(TRA_FILTER);
  const [data, setData] = useState({});
  const { isLoading } = useSelector((state) => state.webhook);
  const { webhookState } = useWebhook();
  const dispatch = useDispatch();
  const [isViewsendDataModal, setIsViewsendDataModal] = useState(false);
  const role = useRole("Webhook Event");

  const onCloseModal = (notify_res, send_notify_content) => {
    setData({ ...data, notify_res, send_notify_content });
    setIsViewsendDataModal(!isViewsendDataModal);
  };
  const columns = useMemo(
    () => [
      {
        title: "ID",
        name: "id",
      },
      {
        title: "DATE",
        name: "createdAt",
      },
      {
        title: "EVENT ID",
        name: "activity_id",
      },
      {
        title: "EVENT TYPE",
        name: "activity_genre",
      },
      {
        title: "STATUS CODE	",
        name: "notifySts_code ",
        Cell: (data) => {
          return (
            <>
              <span
                className={
                  data?.notifySts_code > 300
                    ? "badge badge-danger"
                    : "badge badge-success"
                }
              >
                {data?.notifySts_code || ""}
              </span>
            </>
          );
        },
      },
      {
        title: "Action",
        name: "",
        Cell: (data) => {
          return (
            <>
              <Button
                color="primary"
                className="btn-smallsize"
                style={{ backgroundColor: "var(--chatlook-darkblue--)" }}
                onClick={() =>
                  onCloseModal(data?.notify_res, data?.send_notify_content)
                }
              >
                View Sent Data
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("webhookEvent-list")) {
      GetWebhook();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };
  const { control, handleSubmit, getValues, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  const GetWebhook = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        date,
        filter: dropdown,
        search,
        ...rest
      } = filter;
      const payload =
        isFilter && watch("filter") == "activity_genre"
          ? {
              filter_data: {
                [dropdown]: search,
                startDate: dateFormatter(date?.[0], "start"),
                endDate: dateFormatter(date?.[1], "end", date?.[0]),
                ...rest,
              },
              page_no,
              limit,
            }
          : isFilter && watch("filter") == "activity_id"
          ? {
              filter_data: {
                [dropdown]: search,
                ...rest,
              },
              page_no,
              limit,
            }
          : {
              page_no,
              limit,
            };
      const res = await dispatch(getWebhook(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(WEBHOOK_EVENTS_LOCAL, false)
  );
  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(WEBHOOK_EVENTS_LOCAL, !isFilterOpen);
  };

  return (
    <>
      {role.includes("webhookEvent-list") ? (
        <>
          <div className="webhook_event-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h4 className="main-title">
                    <b>Webhook Event</b>
                  </h4>

                  <div className="d-flex">
                    <Button
                      type="button"
                      className="filterbtn"
                      onClick={toggleIsFilterOpen}
                    >
                      <i className="fa-solid fa-filter"></i> Apply Filter
                    </Button>
                  </div>
                </div>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {isFilterOpen && (
                    <>
                      <ControlledDropDown
                        id="filter"
                        name="filter"
                        label="Filter"
                        isDefaultOption={false}
                        options={emailidOptions}
                        control={control}
                      />
                      <ControlledInput
                        placeholder="Enter Search Value"
                        name="search"
                        label="Search"
                        control={control}
                      />
                      {watch("filter") != "activity_id" ? (
                        <ControlledDatePicker
                          name="date"
                          selectsRange
                          label="Date"
                          maxDate={new Date()}
                          placeholder="Select Date"
                          control={control}
                        />
                      ) : null}
                      <Button type="submit" color="primary">
                        Apply
                      </Button>
                      <Button
                        color="danger "
                        onClick={() => {
                          reset();
                          setFilter({ ...TRA_FILTER });
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                </Form>
              </CardHeader>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={webhookState?.data || []}
                isData={!!webhookState?.data?.length}
                filterColumns={filterColumns}
                count={webhookState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {webhookState?.data?.map((item) => {
                  return (
                    <TableRow
                      isExpandable={false}
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                    />
                  );
                })}
              </Table>
            </Card>

            <ViewSentdataModal
              data={data}
              isViewsendDataModal={isViewsendDataModal}
              onCloseModal={() => onCloseModal()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Webhook_Event;
