import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stateSummary } from "../services/stateSummaryServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  filter: false,
  stateSummaryState: [],
  stateSummaryFilterState: [],
  stateSummaryStateisLoading: true,
};

export const getStateSummary = createAsyncThunk(
  "/stateSummary",
  async (payload) => {
    return await stateSummary(payload);
  }
);

const stateSummarySlice = createSlice({
  name: "stateSummarySlice",
  initialState,
  reducers: {
    setstateSummaryFilter: (state, { payload }) => {
      state.filter = payload;
    },
    clearstateSummary: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStateSummary.pending, (state, action) => {
      state.stateSummaryStateisLoading = true;
    });
    builder.addCase(getStateSummary.fulfilled, (state, action) => {
      state.stateSummaryStateisLoading = false;
      state.stateSummaryState = action.payload;
    });
    builder.addCase(getStateSummary.rejected, (state, action) => {
      state.stateSummaryStateisLoading = false;
    });
  },
});

export default stateSummarySlice.reducer;

export const { setStateSummaryFilter, clearStateSummary } =
  stateSummarySlice.actions;

export const selectStateSummary = (state) => {
  return state?.stateSummary?.stateSummaryState;
};
export const useStateSummary = () => {
  const stateSummaryState = useSelector(selectStateSummary);
  return useMemo(() => stateSummaryState , [stateSummaryState]);
};

