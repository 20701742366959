import { ADD_MERCHANT_GOOGLE_ANALYTIC, DELETE_MERCHANT_GOOGLE_ANALYTIC, GET_MERCHANT_GOOGLE_ANALYTIC, STATUS_MERCHANT_GOOGLE_ANALYTIC, UPDATE_MERCHANT_GOOGLE_ANALYTIC } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getMerchantGoogleAnalytic = (payload) => {
  return apiInstance.post(GET_MERCHANT_GOOGLE_ANALYTIC, payload);
};
export const addMerchantGoogleAnalytic = (payload) => {
  return apiInstance.post(ADD_MERCHANT_GOOGLE_ANALYTIC, payload);
};
export const updateMerchantGoogleAnalytic = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_GOOGLE_ANALYTIC, payload);
};
export const deletMerchantGoogleAnalytic = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_GOOGLE_ANALYTIC, payload);
};
export const statusMerchantGoogleAnalytic = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_GOOGLE_ANALYTIC, payload);
};