import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../helperFunctions";
import { rolesDelete } from "../../../redux/slices/rolesSlice";

const RolesDeleteModal = (props) => {
  const { onHide, isOpen, onGet } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onDeleteRoles = async () => {
    try {
      setIsLoading(true);

      const payload = {
        id: isOpen,
      };
      const res = await dispatch(rolesDelete(payload)).unwrap();
      if (res) {
        responseToaster(res);
      }
      setIsLoading(false);
      onGet();
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Delete Role</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark "
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to delete role?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={() => onDeleteRoles()}
            isButtonLoading={isLoading}
          >
            Delete
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default RolesDeleteModal;
