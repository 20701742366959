import React, { useState } from "react";
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import { useEffect } from "react";
import { isParsable } from "../../../../helperFunctions";

const PgWebhookModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);
  const [parsedData3, setParsedData3] = useState(null);

  useEffect(() => {
    const jsonData = new URLSearchParams(data?.request_body);
    const json = {};
    jsonData?.forEach((value, key) => {
      json[key] = value;
    });
    setParsedData1(json);

    try {
      const parsedSendNotifyContent = isParsable(data?.pgRes)
        ? JSON.parse(data?.pgRes || null)
        : (data?.pgRes || null);
      setParsedData3(parsedSendNotifyContent);
    } catch (error) { }
  }, [data]);

  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="modal-width"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">PG Webhook</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100 json-style">
          <div className="row">
          
            <div className="col-lg-12">
              <Card>
                <CardBody>
                 
                  <h5>Response Body</h5>
                  <div className="webevent-sec mb-1">
                    <pre>{JSON.stringify(parsedData3, null, 1)}</pre>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default PgWebhookModal;
