import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getReportAndChartDepositChart, getReportAndChartWithdrawalChart } from "../services/reportAndChartServices";

const initialState = {
    chart: {},
    depositChart: {},
    isDepositeLoading: false,
    withdrawalChart: {},
    isWithdrawalLoading: false,
};

export const depositChartGet = createAsyncThunk(
    "/depositChartGet",
    async (payload) => {
        return await getReportAndChartDepositChart(payload);
    }
);
export const withdrawalChartGet = createAsyncThunk(
    "/withdrawalChartGet",
    async (payload) => {
        return await getReportAndChartWithdrawalChart(payload);
    }
);


const reportAndChartSlice = createSlice({
    name: "reportAndChartSlice",
    initialState,
    reducers: {
        clearReportAndChart: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(depositChartGet.pending, (state, action) => {
            state.isDepositeLoading = true;
        });
        builder.addCase(depositChartGet.fulfilled, (state, action) => {
            state.isDepositeLoading = false;
            state.depositChart = action.payload;
        });
        builder.addCase(depositChartGet.rejected, (state, action) => {
            state.isDepositeLoading = false;
        });
        builder.addCase(withdrawalChartGet.pending, (state, action) => {
            state.isWithdrawalLoading = true;
        });
        builder.addCase(withdrawalChartGet.fulfilled, (state, action) => {
            state.isWithdrawalLoading = false;
            state.withdrawalChart = action.payload;
        });
        builder.addCase(withdrawalChartGet.rejected, (state, action) => {
            state.isWithdrawalLoading = false;
        });
    },
});
export default reportAndChartSlice.reducer;
export const { clearReportAndChart } = reportAndChartSlice.actions;

export const selectReportAndChartDepositChart = (state) => {
    return state.reportAndChart.depositChart;
};
export const useReportAndChartDepositChart = () => {
    const depositChart = useSelector(selectReportAndChartDepositChart);
    return useMemo(() => depositChart, [depositChart]);
};
export const selectReportAndChartWithdrawalChart = (state) => {
    return state.reportAndChart.withdrawalChart;
};
export const useReportAndChartWithdrawalChart = () => {
    const withdrawalChart = useSelector(selectReportAndChartWithdrawalChart);
    return useMemo(() => withdrawalChart, [withdrawalChart]);
};
