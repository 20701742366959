import React, { Fragment } from "react";
import "./VerticalLayout.scss";
import "react-circular-progressbar/dist/styles.css";
import { useIsLoading } from "../../../redux/slices/authSlice";
import Loader from "../../Custom/Loader";
import Sidebar from "./Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const VerticalLayout = (props) => {
  const { isSidebarOpen, setIsSidebarOpen } = props
  const isLoading = useIsLoading();

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "white",
          zIndex: 99999,
        }}
      >
        <Loader />
      </div>
    );
  }

  const MainContentWrapper = styled(Box)({
    flexGrow: 1,
    minWidth: 0,
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  });

  return (
    <Fragment>
      <div
        className={`app ${isSidebarOpen ? "sidebar-open" : ""} vertical-layout`}
      >
        <MainContentWrapper
          style={{ minHeight: "unset" }}
          sx={{
            maxHeight: "100vh",
          }}
        >
          <div className="sticky" style={{ paddingTop: "-74px" }}>
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          </div>
        </MainContentWrapper>
      </div>
    </Fragment>
  );
};

export default VerticalLayout;
