import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import "./UpdateDepositeStatus.scss";
import { useDispatch } from "react-redux";
import { DepositeStatusUpdate } from "../../../../redux/slices/transactionSlice";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import InputField from "../../Forms/InputField/InputField";
import DropDown from "../../Forms/DropDown/DropDown";

const UpdateDepositeStatus = ({
  isOpen,
  bankutr,
  onCloseUpdateDepositeStatus,
  onGet,
}) => {
  const [bankUTR, setBankUTR] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onClose = () => {
    setBankUTR({});
    onCloseUpdateDepositeStatus();
  };

  const dispatch = useDispatch();
  const Update_Deposite_Status = async () => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(
        DepositeStatusUpdate({ ...bankUTR, depositId: bankutr?.depositId })
      ).unwrap();
      responseToaster(res);
      if (res) {
        onClose();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const statusOptions = [
    {
      value: "Success",
      label: "Success",
    },
  ];

  return (
    <Form>
      <Modal
        isOpen={!!isOpen}
        toggle={() => onClose()}
        className="update-manual-bank-modal"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Update Deposit Status</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100">
          <InputField
            label="Bank UTR"
            type="text"
            value={bankUTR?.utr !== "null" ? bankUTR?.utr : ""}
            placeholder="Enter Bank UTR"
            onChange={(e) => setBankUTR({ ...bankUTR, utr: e.target.value })}
          />
          <DropDown
            label="Status"
            placeholder="Select Status"
            isDefaultOption={false}
            options={statusOptions}
            value={bankUTR?.depositSts}
            onChange={(e) => {
              setBankUTR({
                ...bankUTR,
                depositSts: e.value,
              });
            }}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              className="submitbutton"
              onClick={Update_Deposite_Status}
              isButtonLoading={isButtonLoading}
            >
              Update
            </CustomeButton>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default UpdateDepositeStatus;
