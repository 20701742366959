import PropTypes from "prop-types";
import classnames from "classnames";

const StatsHorizontal = ({
  stats,
  image,
  renderStats,
  statTitle,
  className,
  statsMargin,
  count,
  count_per,
}) => {
  return (
    <div className="dollar-icon-main">
      <div className={className}>
        <div>
          <div className="d-flex align-items-center">
            <div className="iconimg">
              <img src={image} className="dollar-icon" />
            </div>
            <div>
              <p className="card-text mb-0 ">
                {statTitle} <span className="themecolor">{count_per}</span>
              </p>
              {renderStats ? (
                renderStats
              ) : (
                <h3
                  className={classnames("fw-bolder", {
                    "mb-0": !statsMargin,
                    [statsMargin]: statsMargin,
                  })}
                >
                  {stats} {count}
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsHorizontal;

StatsHorizontal.propTypes = {
  stats: PropTypes.string,
  renderStats: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.element,
  color: PropTypes.string.isRequired,
  statTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  statsMargin: PropTypes.oneOf([
    "mb-0",
    "mb-25",
    "mb-50",
    "mb-75",
    "mb-1",
    "mb-2",
    "mb-3",
    "mb-4",
    "mb-5",
  ]),
};
