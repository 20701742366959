import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import "./AddManualPayoutModal.scss";
import { add_Manual_Payout } from "../../../../redux/slices/merchantSlice";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";

const AddManualPayoutModal = (props) => {
  const { onCloseModal, addManualPayoutModal, id } = props;

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    withdrawalAmt: "",
    withdrawalFees: "",
    withdrawal_associate_fees: "",
    bankHolder: "",
    acNumber: "",
    ifscCode: "",
    remarks: "",
    utr: "",
  };

  const onSubmit = async () => {
    const values = getValues();
    let payload = {
      ...values,
      merchantId: id,
    };
    try {
      setIsButtonLoading(true);
      const res = await dispatch(add_Manual_Payout(payload)).unwrap();
      responseToaster(res);
      if (!!res) {
        onCloseModal();
        reset();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };
  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={addManualPayoutModal}
      toggle={onCloseModal}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Add Manual Withdraw</h3>
        <i
          onClick={() => {
            onCloseModal();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="withdrawalAmt"
                  label="Amount"
                  className="w-100"
                  type="number"
                  placeholder="Enter Amount"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="withdrawalFees"
                  label="Withdraw Fees"
                  className="w-100"
                  type="number"
                  placeholder="Enter Withdraw Fees"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="withdrawal_associate_fees"
                  label="Withdraw Associate Fees"
                  className="w-100"
                  type="number"
                  placeholder="Enter Withdraw Associate Fees"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="bankHolder"
                  label="Bank Holder Name"
                  className="w-100"
                  type="text"
                  placeholder="Enter Bank Holder Name"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="acNumber"
                  label="Account Number"
                  className="w-100"
                  type="text"
                  placeholder="Enter Account Number"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="ifscCode"
                  label="IFSC Code"
                  className="w-100"
                  type="text"
                  placeholder="Enter IFSC Code"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="utr"
                  label="Bank UTR"
                  className="w-100"
                  type="text"
                  placeholder="Enter Bank UTR"
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-div">
                <ControlledInput
                  name="remarks"
                  label="Remark"
                  className="w-100"
                  type="text"
                  placeholder="Enter Remark"
                  control={control}
                />
              </div>
            </Col>
          </Row>

        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onCloseModal()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddManualPayoutModal;
