import { GETOPTION_TRACKING, GET_PENDINGPAYMENT_DATA, MATCH_RESELLER, TRACKING } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Tracking = (payload) => {
  return apiInstance.post(TRACKING, payload);
};
export const GetOptionTracking = (payload) => {
    return apiInstance.post(GETOPTION_TRACKING, payload);
  };
export const GetPeningPaymenData = (payload) => {
    return apiInstance.post(GET_PENDINGPAYMENT_DATA, payload);
  };
export const MatchReseller = (payload) => {
  return apiInstance.post(MATCH_RESELLER, payload);
};
