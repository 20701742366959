import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Label,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useUser } from "../../../redux/slices/authSlice";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import {
  rolesAdd,
  rolesDetail,
  rolessUpdate,
} from "../../../redux/slices/rolesSlice";
import Loader from "../../../components/Custom/Loader";
import CheckBox from "../../../components/Custom/Elements/CheckBox";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

const AddRolesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForAdd, setIsLoadingForAdd] = useState(false);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const user = useUser();
  const { id } = useParams();

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Please Enter Role Name"),
    permission: Yup.array().min(1, "Please check a permission"),
  });

  const onGetDataById = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id,
      };
      const res = await dispatch(rolesDetail(payload)).unwrap();
      if (res) {
        setFormData(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const setFormData = (res) => {
    setValue("name", res?.role?.name);
    setCheckedPermissions(
      (res?.permission || [])?.filter((role) => { return role?.menu_name !== "Role" || role?.menu_name !== "Role Manager" }).map?.((permission) => {
        return permission?.name;
      })
    );
  };

  useEffect(() => {
    if (id) {
      onGetDataById();
    }
  }, [id]);

  const onSubmit = async (formData) => {
    try {
      setIsLoadingForAdd(true);
      const payload = {
        ...formData,
        permission: checkedPermissions,
      };
      let res;
      if (id) {
        res = await dispatch(rolessUpdate({ ...payload, id: id })).unwrap();
      } else {
        res = await dispatch(rolesAdd(payload)).unwrap();
      }
      if (res) {
        onClose();
      }
      responseToaster(res);
      setIsLoadingForAdd(false);
    } catch (error) {
      console.log("error", error);
      setIsLoadingForAdd(false);
    }
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("permission", checkedPermissions);
  }, [checkedPermissions, setValue]);

  const onClose = () => {
    setCheckedPermissions([]);
    reset();
    navigate(-1);
  };

  const handleCheckboxChange = (event, key) => {
    const { value, checked } = event.target;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions = getRelatedPermissions(value, key);
      if (checked) {
        clearErrors("permission");
        updatedPermissions = [
          ...new Set([...prev, value, ...relatedPermissions]),
        ];
      } else {
        if (
          hasRelatedPermissionsChecked(value, key)
        ) {
          return prev;
        }
        updatedPermissions = prev.filter((permission) => permission !== value);
      }
      return updatedPermissions;
    });
  };

  const hasRelatedPermissionsChecked = (value, key) => {
    const relatedPermissions = user?.user?.permission?.[key];

    if (key === "Bank Limit" && value === "deposit-bankLimit-list") {
      return relatedPermissions?.some((relatedPermission) =>
        checkedPermissions
          ?.filter((per) => {
            return per === "update-deposit-bank-limit";
          })
          ?.includes(relatedPermission)
      )
    }
    if (key === "Bank Limit" && value === "withdrawal-bankLimit-list") {
      return relatedPermissions?.some((relatedPermission) =>
        checkedPermissions
          ?.filter((per) => {
            return per === "update-withdrawal-bank-limit";
          })
          ?.includes(relatedPermission)
      )
    }
    return relatedPermissions?.some((relatedPermission) =>
      checkedPermissions
        ?.filter((per) => {
          return per !== user?.user?.permission?.[key]?.[0];
        })
        ?.includes(relatedPermission)
    ) &&
      value === user?.user?.permission?.[key]?.[0];
  };

  const getRelatedPermissions = (value, key) => {
    if (key === "Bank Limit" && value === "update-deposit-bank-limit") {
      return [user?.user?.permission?.[key]?.[0], "deposit-bankLimit-list"]
    }
    if (key === "Bank Limit" && value === "update-withdrawal-bank-limit") {
      return [user?.user?.permission?.[key]?.[0], "withdrawal-bankLimit-list"]
    }
    return [user?.user?.permission?.[key]?.[0]] || [];
  };

  const preparePermissions = () => {
    return Object.entries(user?.user?.permission).filter(
      ([key, _]) => key !== "Role" && key !== "Role Manager"
    );
  };

  const handleAllCheckboxChange = (event, key) => {
    const { checked } = event.target;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions = getAllPermissionByKey(key);
      if (checked) {
        clearErrors("permission");
        updatedPermissions = [...new Set([...prev, ...relatedPermissions])];
      } else {
        updatedPermissions = prev.filter(
          (permission) => !relatedPermissions.includes(permission)
        );
      }
      return updatedPermissions;
    });
  };

  const handleSuperAllCheckboxChange = (event) => {
    const { checked } = event.target;
    setCheckedPermissions(() => {
      if (checked) {
        clearErrors("permission");
        return getAllPermissions();
      } else {
        return [];
      }
    });
  };

  const getAllPermissions = () => {
    const arr = [];
    preparePermissions().forEach(([_, value]) => {
      value.forEach((item) => {
        arr.push(item);
      });
    });
    return arr;
  };

  const getAllPermissionByKey = (key) => {
    return user?.user?.permission?.[key];
  };

  const isAllChecked = (key) => {
    return (
      getAllPermissionByKey(key)?.filter((item) =>
        checkedPermissions?.includes(item)
      ).length === getAllPermissionByKey(key)?.length
    );
  };

  const isSuperAllChecked = () => {
    return (
      getAllPermissions()?.filter((item) => checkedPermissions?.includes(item))
        .length === getAllPermissions()?.length
    );
  };

  return (
    <>
      <Card className="position-relative">
        <CardHeader className="flex-column align-items-start">
          <div className="addrole_header w-100">
            <div className="d-flex align-items-start justify-content-between rolesub_sec pt-0">
              <h4 className="main-title">
                <b>{`${id ? "Edit" : "Add"} Roles`}</b>
              </h4>
              {!isLoading ? (
                <div className="addrole-footer">
                  <CustomeButton
                    className="submitbutton"
                    isButtonLoading={isLoadingForAdd}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {id ? "Update" : "Add"} Role
                  </CustomeButton>
                  <Button
                    type="button"
                    className="graybutton"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-start justify-content-between py-50">
              <div className="d-flex align-items-start gap-50 w-25">
                <div className="select-div input-field text-nowrap mt-25">
                  <Label>Role Name</Label>
                </div>
                <ControlledInput
                  name="name"
                  // label="Name"
                  placeholder="Enter Role Name"
                  errors={errors}
                  control={control}
                  className="w-100"
                />
              </div>
              {/* <i onClick={onClose} className="fa-solid fa-xmark"></i> */}
              <div className="d-flex py-50">
                <CheckBox
                  checked={isSuperAllChecked()}
                  onChange={(e) => handleSuperAllCheckboxChange(e)}
                />
                <div className="select-div input-field">
                  <Label>Select all permission</Label>
                </div>
                {/* <div>
                  <strong>Select All Permission</strong>
                </div> */}
              </div>
            </div>
          </div>

          <Form className="addRole-main">
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  <div>
                    {errors?.permission?.message ? (
                      <div style={{ color: "red", fontSize: 12 }}>
                        {errors?.permission?.message}
                      </div>
                    ) : null}
                    <div>
                      {preparePermissions()?.map?.(([key, value]) => (
                        <div className="row rolesub_sec" key={key}>
                          <div className="col-lg-3 col-sm-4 roles_name">
                            <div className="d-flex">
                              <CheckBox
                                value={key}
                                checked={isAllChecked(key)}
                                // label={key}
                                onChange={(e) =>
                                  handleAllCheckboxChange(e, key)
                                }
                              />
                              <div>
                                <strong>{key}</strong>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="row">
                              {value?.map((permission) => (
                                <div className="col-lg-4">
                                  <div key={permission}>
                                    <CheckBox
                                      value={permission}
                                      checked={checkedPermissions.includes(
                                        permission
                                      )}
                                      label={permission}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, key)
                                      }
                                      containerClassName="align-items-start"
                                      className="checkbox_margin"
                                    // labelClassName="mt-20"
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </CardHeader>
      </Card>
    </>
  );
};

export default AddRolesPage;
