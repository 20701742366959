import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Customer } from "../services/customerServices";

const initialState = {
  customerState: [],
  isLoading: true,
};
export const getCustomer = createAsyncThunk(
  "/support/GetCustomers",
  async (payload) => {
    return await Customer(payload);
  }
);

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    clearCustomer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerState = action.payload;
    });
    builder.addCase(getCustomer.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default customerSlice.reducer;
export const { clearCustomer } = customerSlice.actions;

export const selectCustomer = (state) => {
  return state.customer.customerState;
};

export const useCustomer = () => {
  const customerState = useSelector(selectCustomer);
  return useMemo(() => ({ customerState }), [customerState]);
};
