import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Available_Bank,
  Bank_BalSheet,
  Mark_As_Used,
  Merge_UTR,
  UpdateBankTransaction,
  Update_Payment,
  Update_BalSheet,
} from "../services/bankBalSheetServices";
import { UpdateBankTransactionMode } from "../services/bankBalSheetServices";

const initialState = {
  bankBalSheetState: [],
  availableBankState: [],
  bankBalSheetStateisLoading: true,
};
export const Bank_BalSheet_Get = createAsyncThunk(
  "/bankbalsheet",
  async (payload) => {
    return await Bank_BalSheet(payload);
  }
);


export const Bank_Available = createAsyncThunk(
  "/available_bank",
  async (payload) => {
    return await Available_Bank(payload);
  }
);

export const UTR_Merge = createAsyncThunk("/mergeutr", async (payload) => {
  return await Merge_UTR(payload);
});

export const Used_Mark = createAsyncThunk("/used-mark", async (payload) => {
  return await Mark_As_Used(payload);
});

export const Payment_Update = createAsyncThunk(
  "/update-payment",
  async (payload) => {
    return await Update_Payment(payload);
  }
);

export const BalSheet_Update = createAsyncThunk(
  "/update-balsheet",
  async (payload) => {
    return await Update_BalSheet(payload);
  }
);

export const BankTransactionUpdate = createAsyncThunk(
  "/update-bank-transaction",
  async (payload) => {
    return await UpdateBankTransaction(payload);
  }
);

export const BankTransactionModeUpdate = createAsyncThunk(
    "/update-bank-transaction",
    async (payload) => {
      return await UpdateBankTransactionMode(payload);
    }
  );

const bankBalSheetSlice = createSlice({
  name: "bankBalSheetSlice",
  initialState,
  reducers: {
    clearBankBalSheet: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(Bank_BalSheet_Get.fulfilled, (state, action) => {
      state.bankBalSheetStateisLoading = false;
      state.bankBalSheetState = action.payload;
    });
    builder.addCase(Bank_BalSheet_Get.pending, (state, action) => {
      state.bankBalSheetStateisLoading = true;
    });
    builder.addCase(Bank_BalSheet_Get.rejected, (state, action) => {
      state.bankBalSheetStateisLoading = false;
    });
    builder.addCase(Bank_Available.fulfilled, (state, action) => {
      state.availableBankState = action.payload;
    });
  },
});
export default bankBalSheetSlice.reducer;
export const { clearBankBalSheet } = bankBalSheetSlice.actions;

export const selectBalSheet = (state) => {
  return state.bankBalSheet.bankBalSheetState;
};

export const useBalSheet = () => {
  const bankBalSheetState = useSelector(selectBalSheet);
  return useMemo(() => ({ bankBalSheetState }), [bankBalSheetState]);
};
