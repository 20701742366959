import React, { useState } from "react";
import { Button, Card, CardHeader, Form } from "reactstrap";
import PayOutBankLimit from "./PayOutBankLimit";
import PayInBankLimit from "./PayInBankLimit";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { TRA_FILTER } from "../../../constant";
import { useForm } from "react-hook-form";
import { useRole } from "../../../redux/slices/authSlice";


export const statusDataOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const BankLimit = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(TRA_FILTER);
  const role = useRole("Bank Limit");
  const tabsData = [];

  if (role.includes("deposit-bankLimit-list")) {
    tabsData.push({ title: "Deposit", Component: PayInBankLimit });
  }

  if (role.includes("withdrawal-bankLimit-list")) {
    tabsData.push({ title: "Withdrawal", Component: PayOutBankLimit });
  }

  const Component = tabsData?.[currentTab]?.Component;

  const { control, getValues, handleSubmit, reset } = useForm();

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };
  if (!role.includes("bankLimit-list")) {
    return;
  }

  return (
    <div>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h4 className="main-title">
            <b>Bank Limit</b>
          </h4>
          <div className="d-flex button-space">
            {!currentTab ? (
              <Form
                className="transaction-main-div d-flex button-space"
                onSubmit={handleSubmit(onSubmit)}
              >
                <ControlledDropDown
                  name="isActive"
                  placeholder="Select status..."
                  options={statusDataOptions}
                  control={control}
                />
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    reset();
                    setFilter({ ...TRA_FILTER });
                  }}
                >
                  Clear
                </Button>
              </Form>
            ) : null}
            <span className=" aurorefresh-btn">
              <Button
                type="button"
                className={`${refresh ? "" : "filterbtn"} gap`}
                color={refresh ? "primary" : ""}
                onClick={() => setRefresh(!refresh)}
                title="Auto refresh"
              >
                {refresh ? (
                  <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                ) : (
                  <i className="fa-solid fa-arrows-rotate"></i>
                )}
                Auto Refresh
              </Button>
            </span>
            {tabsData?.map((tab, index) => {
              if (
                tab?.title === "Permission" &&
                payinData?.merchantPermission !== 1
              ) {
                return <></>;
              }
              return (
                <Button
                  className={currentTab === index ? "primary" : "filterbtn"}
                  color={currentTab === index ? "primary" : ""}
                  onClick={() => {
                    setCurrentTab(index);
                    reset();
                    setFilter({ ...TRA_FILTER });
                  }}
                >
                  {tab.title}
                </Button>
              );
            })}
          </div>
        </CardHeader>
      </Card>
      {Component ? (
        <Component
          refresh={refresh}
          setFilter={!currentTab ? setFilter : undefined}
          filter={!currentTab ? filter : undefined}
        />
      ) : null}
    </div>
  );
};

export default BankLimit;
