import React from "react";
import { Input, Label } from "reactstrap";
import "./CheckBox.scss";

const CheckBox = (props) => {
    const {
        inputClassName = "",
        className = "",
        name,
        value,
        label,
        onChange,
        ...rest
    } = props;

    return (

        <div className={`${className} form-check form-check-inline`}>
            <Input
                type="checkbox"
                className={`${inputClassName}`}
                checked={value}
                onChange={(e) => {
                    onChange?.(e.target.checked)
                }}
                {...rest}
            />
            {label ? <Label className="form-check-label">{label}</Label> : null}
        </div>
    );
};

export default CheckBox;
