import React from "react";
import { dateFormatter } from "../../../helperFunctions";

const Invoice = ({ refe,data }) => {
  // const data = refe?.current?.row;
  return (
    <>
      <div
        ref={refe}
        style={{
          zIndex: 1,
          margin: 0,
          padding: "5% 5% 0",
          backgroundColor: "#fff",
          position: "absolute",
          left: "-9999px", // Move the element off-screen
          top: 0,
          width: "210mm", // A4 size width
          height: "297mm", // A4 size width
          fontFamily: "cambria",
          // display:"flex",
          // alignItems:"center",
        }}
      >
        <div className="container amount_inr">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tbody>
              <tr>
                <td
                  colSpan="2"
                  style={{
                    borderBottom: "2px solid #4f81bd",
                    padding: "20px 0",
                    textAlign: "right",
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#17365d",
                    backgroundColor: "#fff",
                  }}
                >
                  Invoice
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{ padding: "20px 0px" }}>
                  <div>
                    <b>Invoice No : {data?.txnId}</b>
                    <div>
                      <b>Invoice Date : {dateFormatter(data?.createdAt)}</b>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{ padding: "15px 0px" }}>
                  <div>
                    <b>FROM</b>
                    {data?.pgBankUpiName ? (
                      <div>{data?.pgBankUpiName}</div>
                    ) : null}
                    {data?.emailId ? <div>Email : {data?.emailId}</div> : null}
                    {data?.mobileNumber ? (
                      <div>Phone : {data?.mobileNumber}</div>
                    ) : null}
                    {/* {data?.pgRefId ? <div>PG Ref : {data?.pgRefId}</div> : null} */}
                  </div>
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid rgb(173, 170, 170)" }}>
                <td colSpan="2" style={{ padding: "20px 0px" }}>
                  <div>
                    <b>TO</b>
                    {data?.custName ? (
                      <div>Customer Name : {data?.custName}</div>
                    ) : null}
                    {data?.custEmail ? (
                      <a
                        href={`mailto:${data?.custEmail}`}
                        style={{ cursor: "pointer" }}
                      >
                        Email : {data?.custEmail}
                      </a>
                    ) : null}
                    {data?.custMobile ? (
                      <div>Mobile : {data?.custMobile}</div>
                    ) : null}
                    {data?.custId ? <div>ID : {data?.custId}</div> : null}
                    {data?.txnId ? (
                      <div>Transaction ID : {data?.txnId}</div>
                    ) : null}
                    {data?.utr ? <div>Bank Ref : {data?.utr}</div> : null}
                  </div>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#a7a7a7", color: "#000" }}>
                <td style={{ padding: "10px 8px" }}>Description</td>
                <td style={{ padding: "10px 8px" }}>Amount</td>
              </tr>
              <tr style={{ borderBottom: "1px solid rgb(173, 170, 170)" }}>
                <td style={{ padding: "10px 8px" }}>Wallet Deposit</td>
                <td style={{ padding: "10px 8px" }}>₹ {data?.paymentAmt}</td>
              </tr>
              <tr style={{ height: "50px" }}>
                <td style={{ padding: "10px 8px" }}></td>
                <td style={{ padding: "10px 8px" }}></td>
              </tr>
              <tr
                style={{
                  borderTop: "1px solid rgb(173, 170, 170)",
                }}
              >
                <td
                  style={{
                    backgroundColor: "#fff",
                  }}
                ></td>
                <td
                  style={{
                    padding: "10px 0",
                    color: "#000000",
                    fontWeight: 700,
                    textAlign: "right",
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px -50px 7px -48px inset",
                    borderBottom: "1px solid rgb(173, 170, 170)",
                  }}
                >
                  Total Deposit: ₹ {data?.paymentAmt}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="2"
                  style={{ paddingTop: "20px", backgroundColor: "#fff" }}
                >
                  <div>
                    <div>
                      <b>Note:</b>
                    </div>
                    <div>
                      - Deposit amount has been successfully added to your
                      wallet.
                    </div>
                    <div>
                      - For further inquiries, please reach out to our support
                      team.
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <Button
        onClick={(e) => {
          e.stopPropagation();
          generatePDF();
        }}
        color="primary"
        className={"filterbtn btn-smallsize"}
      >
        Download Invoice
      </Button> */}
    </>
  );
};

export default Invoice;
