import React from "react";
import { Button, Card, CardBody, Form, Modal } from "reactstrap";

const MerchantGoogleAnalyticModal = ({
  isViewsendDataModal,
  onCloseModal,
  data,
}) => {
  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="modal-lg"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Merchant Google Analytic</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100">
          <div className="grid grid-cols-3 py-2 modal-container">
            <div>
              <p className="block-title">Merchant Id</p>
              <p className="block-data">
                {data?.merchant_details?.merchant_id || "-"}
              </p>
            </div>
            <div>
              <p className="block-title">Merchant Name</p>
              <p className="block-data">
                {data?.merchant_details?.merchant_name || "-"}
              </p>
            </div>
            <div>
              <p className="block-title">Status</p>
              <p className="block-data">
                {data?.status ? "Active" : "InActive" || "-"}
              </p>
            </div>
          </div>
          <Card>
            <CardBody>
              <h5>Code</h5>
              <div className="webevent-sec whitespace-webevent">
                <pre>{data?.code}</pre>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default MerchantGoogleAnalyticModal;
