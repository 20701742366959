import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import {
  getBankMonitoring,
  getBankMonitoringBanks,
  getBankMonitoringChart,
  getBankMonitoringDepositChart,
  getBankMonitoringPg,
} from "../services/bankMonitoringServices";

const initialState = {
  chart: {},
  depositChart: {},
  banks: {},
  data: {},
  pgData:{}
};

export const bankMonitoringBanksGet = createAsyncThunk(
  "/bankMonitoringBanksGet",
  async () => {
    return await getBankMonitoringBanks();
  }
);

export const bankMonitoringGet = createAsyncThunk(
  "/bankMonitoringGet",
  async () => {
    return await getBankMonitoring();
  }
);

export const bankMonitoringPgGet = createAsyncThunk(
  "/bankMonitoringPgGet",
  async (payload) => {
    return await getBankMonitoringPg(payload);
  }
);

export const bankMonitoringDepositChartGet = createAsyncThunk(
  "/bankMonitoringDepositChartGet",
  async (payload) => {
    return await getBankMonitoringDepositChart(payload);
  }
);

export const bankMonitoringChartGet = createAsyncThunk(
  "/bankMonitoringChartGet",
  async (payload) => {
    return await getBankMonitoringChart(payload);
  }
);

const bankMonitoringSlice = createSlice({
  name: "bankMonitoringSlice",
  initialState,
  reducers: {
    clearBankMonitoring: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(bankMonitoringBanksGet.fulfilled, (state, action) => {
      state.banks = action.payload;
    });
    builder.addCase(bankMonitoringGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(bankMonitoringPgGet.fulfilled, (state, action) => {
      state.pgData = action.payload;
    });
    builder.addCase(bankMonitoringChartGet.fulfilled, (state, action) => {
      state.chart = action.payload;
    });
    builder.addCase(
      bankMonitoringDepositChartGet.fulfilled,
      (state, action) => {
        state.depositChart = action.payload;
      }
    );
  },
});
export default bankMonitoringSlice.reducer;
export const { clearBankMonitoring } = bankMonitoringSlice.actions;

export const selectBankMonitoringBanksData = (state) => {
  return state.bankMonitoring.banks;
};
export const useBankMonitoringBanksData = () => {
  const banks = useSelector(selectBankMonitoringBanksData);
  return useMemo(() => banks, [banks]);
};

export const selectBankMonitoringData = (state) => {
  return state.bankMonitoring.data;
};
export const useBankMonitoringData = () => {
  const data = useSelector(selectBankMonitoringData);
  return useMemo(() => data, [data]);
};

export const selectBankMonitoringPgData = (state) => {
  return state.bankMonitoring.pgData;
};
export const useBankMonitoringPgData = () => {
  const pgData = useSelector(selectBankMonitoringPgData);
  return useMemo(() => pgData, [pgData]);
};

export const selectBankMonitoringChart = (state) => {
  return state.bankMonitoring.chart;
};
export const useBankMonitoringChart = () => {
  const data = useSelector(selectBankMonitoringChart);
  return useMemo(() => data, [data]);
};

export const selectBankMonitoringDepositChart = (state) => {
  return state.bankMonitoring.depositChart;
};
export const useBankMonitoringDepositChart = () => {
  const depositChart = useSelector(selectBankMonitoringDepositChart);
  return useMemo(() => depositChart, [depositChart]);
};
