import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { useUser } from "../../../redux/slices/authSlice";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import {
  rolesAdd,
  rolesDetail,
  rolessUpdate,
} from "../../../redux/slices/rolesSlice";
import Loader from "../../../components/Custom/Loader";
import CheckBox from "../../../components/Custom/Elements/CheckBox";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const AddRolesModal = (props) => {
  const { onHide, isOpen, onGet } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForAdd, setIsLoadingForAdd] = useState(false);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const user = useUser();

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Please Enter Role Name"),
    permission: Yup.array().min(1, "Please check a permission"),
  });

  const onGetDataById = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: isOpen?.id,
      };
      const res = await dispatch(rolesDetail(payload)).unwrap();
      if (res) {
        setFormData(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const setFormData = (res) => {
    setValue("name", res?.role?.name);
    setCheckedPermissions(
      (res?.permission || [])?.map?.((permission) => {
        return permission?.name;
      })
    );
  };

  useEffect(() => {
    if (isOpen?.id) {
      onGetDataById();
    }
  }, [isOpen]);

  const onSubmit = async (formData) => {
    try {
      setIsLoadingForAdd(true);
      const payload = {
        ...formData,
        permission: checkedPermissions,
      };
      let res;
      if (isOpen?.id) {
        res = await dispatch(
          rolessUpdate({ ...payload, id: isOpen?.id })
        ).unwrap();
      } else {
        res = await dispatch(rolesAdd(payload)).unwrap();
      }
      if (res) {
        onClose();
      }
      responseToaster(res);
      onGet();
      setIsLoadingForAdd(false);
    } catch (error) {
      console.log("error", error);
      setIsLoadingForAdd(false);
    }
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("permission", checkedPermissions);
  }, [checkedPermissions, setValue]);

  const onClose = () => {
    setCheckedPermissions([]);
    reset();
    onHide();
  };

  const handleCheckboxChange = (event, key) => {
    const { value, checked } = event.target;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions = getRelatedPermissions(value, key);
      if (checked) {
        clearErrors("permission");
        updatedPermissions = [...new Set([...prev, value, ...relatedPermissions])];
      } else {
        if (hasRelatedPermissionsChecked(value, key)
          && value === user?.user?.permission?.[key]?.[0]
        ) {
          return prev;
        }
        updatedPermissions = prev.filter((permission) => permission !== value);
      }
      return updatedPermissions;
    });
  };

  const hasRelatedPermissionsChecked = (_, key) => {
    const relatedPermissions = user?.user?.permission?.[key];
    return relatedPermissions?.some((relatedPermission) =>
      checkedPermissions?.filter((per) => { return per !== user?.user?.permission?.[key]?.[0] })?.includes(relatedPermission)
    );
  };

  const getRelatedPermissions = (_, key) => {
    return [user?.user?.permission?.[key]?.[0]] || [];
  };

  const preparePermissions = () => {
    return Object.entries(user?.user?.permission).filter(([key, _]) => key !== "Role" && key !== "Role Manager")
  }

  if (!isOpen) return null;

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">{`${isOpen?.id ? "Edit" : "Add"} Roles`}</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <ControlledInput
                name="name"
                label="Name"
                placeholder="Enter Name"
                errors={errors}
                control={control}
              />
              <div className="select-div input-field">
                <Label>Permission</Label>
              </div>
              {errors?.permission?.message ? (
                <div style={{ color: "red", fontSize: 12 }}>
                  {errors?.permission?.message}
                </div>
              ) : null}
              {preparePermissions()?.map?.(([key, value]) => (
                <React.Fragment key={key}>
                  <strong>{key}</strong>
                  {value?.map((permission) => (
                    <div key={permission}>
                      <CheckBox
                        value={permission}
                        checked={checkedPermissions.includes(permission)}
                        label={permission}
                        onChange={(e) => handleCheckboxChange(e, key)}
                      />
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        {!isLoading ? (
          <div className="modal-footer">
            <CustomeButton
              className="submitbutton"
              isButtonLoading={isLoadingForAdd}
              type="submit"
            >
              {isOpen?.id ? "Update" : "Add"} Role
            </CustomeButton>
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
          </div>
        ) : null}
      </Form>
    </Modal>
  );
};

export default AddRolesModal;
