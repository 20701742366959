import React, { useEffect, useRef, useState } from "react";
import UploadIcon from "../../../../assets/images/pages/upload-cloud.png";

import "react-datepicker/dist/react-datepicker.css";
import "./ImagePicker.scss";

const ImagePicker = (props) => {
  const {
    className = "",
    inputClassName = "",
    label = "Upload File",
    text = "Upload File",
    onChange,
    onClose,
    value,
    err,
    isLoading,
    ...rest
  } = props;

  const [isDropping, setIsDropping] = useState(false);

  const imagePickerRef = useRef();
  const drop = useRef();
  const drag = useRef();

  const handleDragover = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDropping(true);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { files } = e.dataTransfer;
    onLocalChange(files);
    setIsDropping(false);
  };

  const handleDragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // if (e.target !== drag.current) {
    setIsDropping(true);
    // }
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // if (e.target === drag.current) {
    setIsDropping(false);
    // }
  };

  const onLocalChange = (files) => {
    const file = files?.[0];
    onChange({
      file: file,
      liveImage: file ? URL.createObjectURL(file) : "",
    });
  };

  useEffect(() => {
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragover", handleDragover);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);
    // return () => {
    //   drop.current.removeEventListener("drop", handleDrop);
    //   drop.current.removeEventListener("dragover", handleDragover);
    //   drop.current.removeEventListener("dragenter", handleDragEnter);
    //   drop.current.removeEventListener("dragleave", handleDragLeave);
    // };
  }, []);

  return (
    <>
      <input
        ref={imagePickerRef}
        type="file"
        className={`d-none ${inputClassName}`}
        value=""
        onChange={(e) => {
          onLocalChange?.(e.target.files);
        }}
        {...rest}
      />
      <div className="image-picker">
        <div
          className={`${className} ${
            isDropping ? "main-drop" : ""
          } main position-relative cursor-pointer d-flex align-items-center justify-content-center`}
          onClick={() => {
            imagePickerRef.current.click();
          }}
          ref={drop}
        >
          {value?.liveImage ? (
            <div
              className="cross-mark cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (isLoading) {
                  return;
                }

                onChange?.({
                  file: "",
                  liveImage: "",
                });
                onClose?.();
              }}
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </div>
          ) : null}
          {value?.liveImage ? (
            <div className="">
              <img src={value?.liveImage} className="image" />
            </div>
          ) : (
            <div
              ref={drag}
              // className="d-flex flex-column align-items-center justify-content-center gap-50"
            >
              <div className="dragbox">
                <div className="drag-text">
                  <img src={UploadIcon} />
                  <p className="mb-0">Drag & Drop file here</p>
                </div>
                <div className="dragborder">
                  <span>or</span>
                </div>
                <div className="dragbutton">
                  <span>Upload File</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImagePicker;
