import React from "react";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import {
  merchantMinAmountUpdate,
  merchantMaxAmountUpdate,
} from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { seperator } from "../../../helperFunctions";
import { showToaster } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantMinMaxChange = (props) => {
  const {
    updateDetails,
    setUpdateDetails,
    data,
    onGetMerchantPayOut,
    keyInput,
    setIsLoading,
  } = props;

  const dispatch = useDispatch();
  const role = useRole("Merchant");
  const onUpdate = async () => {
    try {
      const payload = {
        merchantId: data?.merchantId,
        pgName: data?.pgName,
        id: data?.id,
        pk: data?.pgId,
        value: updateDetails?.value,
      };

      if (updateDetails?.value < 100) {
        showToaster("Please enter an amount greater than or equal to 100.", "Error");
        return;
      }
    //   if (
    //     keyInput === "maxAmt" &&
    //     updateDetails?.value < +data?.minAmt
    //   ) {
    //     showToaster("Please enter an amount greater than the minimum amount", "Error");
    //     return;
    //   }
    //   if (
    //     keyInput === "minAmt" &&
    //     updateDetails?.value > +data?.maxAmt
    //   ) {
    //     showToaster("Please enter an amount less than the maximum amount", "Error");
    //     return;
    //   }

      setIsLoading(true);
      const res =
        keyInput === "minAmt"
          ? await dispatch(merchantMinAmountUpdate(payload)).unwrap()
          : await dispatch(merchantMaxAmountUpdate(payload)).unwrap();

      setIsLoading(false);
      showToaster(res?.message);
      await onGetMerchantPayOut();
      setUpdateDetails({});
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onUpdate();
  };

  return (
    <>
      <div
        className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="order-singal-div">
          <div>
            {updateDetails.input === keyInput &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onSubmit}>
                <InputField
                  type="number"
                  autoFocus
                  value={updateDetails?.value}
                  placeholder={`Enter Amount`}
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button
                  type="submit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={onSubmit}
                >
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className={`cursor-pointer`}
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={(e) => {
                  if (!role.includes("merchant-payout-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: keyInput,
                    value: data?.[keyInput],
                  });
                }}
              >
                {`${seperator(data?.[keyInput])}`}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantMinMaxChange;
