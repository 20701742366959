import { useForm } from "react-hook-form";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BANK_STATEMENT_LOCAL,
  DATE_RANGE,
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import {
  getStatement,
  useStatement,
} from "../../../redux/slices/bankStatementSlice";
import { IoFilterOutline } from "react-icons/io5";
import { Box, Switch } from "@mui/material";
import "./Bank_Statement.scss";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import {
  dateFormatter,
  getLocalData,
  onFixed,
  responseToaster,
  seperator,
  setLocalData,
} from "../../../helperFunctions";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import BankStatementModal from "../../../components/Custom/Modals/BankStatementModal/BankStatementModal";
import BankStatementFileUplod from "../../../components/Custom/Modals/BankStatementFileUplodModal/BankStatementFileUplod";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ParseDataModal from "../../../components/Custom/Modals/ParseDataModal/ParseDataModal";
import { useRole } from "../../../redux/slices/authSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#76bdff" : "#308fe8",
  },
}));

const Bank_Statement = () => {
  const { isLoading } = useSelector((state) => state.statement);
  const bankstatementState = useStatement();
  const [isBankStatementUtrModal, setIsBankStatementUtrModal] = useState(false);
  const [parseData, setparseData] = useState(null);

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const role = useRole("Bank Statement");

  const IsGetOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const prepareBanklistOptions = () => {
    const options = bankstatementState?.bank
      ?.slice()
      ?.sort((a, b) => a?.label?.localeCompare(b?.label))
      ?.map((val) => ({
        value: val?.account_number,
        label: `${val?.label} - ${val?.account_number}`,
      }));
    return options;
  };

  const initialValues = {
    date: DATE_RANGE,
    acNo: DROPDOWN_ALL_VALUE,
    isget: DROPDOWN_ALL_VALUE,
  };

  const [filterColumns, setFilterColumns] = useState([]);
  const [BankStatementFileUplodModal, setBankStatementFileUplodModal] =
    useState(false);

  const [filter, setFilter] = useState(TRA_FILTER);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(BANK_STATEMENT_LOCAL, false)
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(BANK_STATEMENT_LOCAL, !isFilterOpen);
  };

  const onToggleBankStatementModal = (id) => {
    setId(id);
    setIsBankStatementUtrModal(!isBankStatementUtrModal);
  };

  const onCloseModalBankStatementFileUplod = (id) => {
    setBankStatementFileUplodModal(false);
  };

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "FILE INFO",
      name: "fileName",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span>Create : </span>
                  {data?.created_at_ist || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Update : </span>
                  {data?.updated_at_ist || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Name : </span>
                  {data?.fileName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Remark : </span> {data?.note || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Size : </span> {data?.fileSize || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACCOUNT Info",
      name: "acNumber",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <span>{data?.accountData?.acOwnerName || ""}</span>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Number : </span>
                  {data?.accountData?.acNumber || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>IFSC : </span> {data?.accountData?.IFSCcode || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>UPI : </span>
                  {data?.accountData?.upiId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "IS GET",
      name: "isGet",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>{data?.isGet ? " Yes" : "No"}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "UTR COUNT	",
      name: "totalCnt",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span>Total Count : </span>
                  {`${seperator(data?.totalCnt, false)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Added UTR : </span> {data?.totAddedUTR}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Running : </span>
                  {data?.isWorking}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Parse Data : </span>
                  {+data?.parsedData?.length || 0}
                </p>
              </div>

            </div>
          </>
        );
      },
    },
    {
      title: "PROGRESS",
      name: "Development",
      Cell: (data) => {
        const todata = (+data?.Development / +data?.totalCnt) * 100 || 0;
        const progressPer = todata > 100 ? 100 : todata;
        return (
          <>
            <div className="position-relative">
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progressPer}
                />
              </Box>
              <div className="progress-position text-center">
                <span>{onFixed(progressPer)}%</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      Cell: (data) => {
        return (
          <>
            <div className="d-flex flex-column gap-50">
              {!!data?.parsedData?.length ? (
                <Button
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => setparseData(data)}
                >
                  Show Parsed Record
                </Button>
              ) : null}
              {data?.totAddedUTR && role?.includes("show-added-utr") ? (
                <Button
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => onToggleBankStatementModal(data?.id)}
                >
                  Show Add UTR
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const GetBankstatement = async (loading = false) => {
    try {
      if (loading) {
        setIsTableLoading(true);
      }
      const { isFilter, page_no, limit, date, ...rest } = filter;

      const payload = isFilter
        ? {
          filter_data: {
            startDate: dateFormatter(date?.[0], "start"),
            endDate: dateFormatter(date?.[1], "end", date?.[0]),
            ...rest,
          },
          page_no,
          limit,
        }
        : {
          page_no,
          limit,
        };
      const res = await dispatch(getStatement(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsTableLoading(false);
    } catch (err) {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role?.includes("bankStatement-list")) {
      GetBankstatement(true);
    }
  }, [filter]);

  useEffect(() => {
    if (role?.includes("bankStatement-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          GetBankstatement(false);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  if (!role.includes("bankStatement-list")) {
    return;
  }

  return (
    <div
      className={`bank-statement-main-div  ${isLoading && refresh ? "opacity-25" : ""
        }`}
    >
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Bank Statement</b>
            </h4>
            <div className="d-flex" style={{ gap: "10px" }}>
              <Button
                type="button"
                className={`${refresh ? "" : "filterbtn"} gap`}
                color={refresh ? "primary" : ""}
                onClick={() => setRefresh(!refresh)}
                title="Auto refresh"
              >
                {refresh ? (
                  <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                ) : (
                  <i className="fa-solid fa-arrows-rotate"></i>
                )}
                Auto Refresh
              </Button>
              {role.includes("upload-bank-statement") ? <Button
                color="primary"
                title="Bank Statement"
                onClick={() => setBankStatementFileUplodModal(true)}
              >
                Bank Statement
              </Button> : null}
              <Button
                type="button"
                className="filterbtn"
                onClick={() => {
                  toggleIsFilterOpen();
                }}
                title="Apply Filter"
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                  title="Column Filter"
                >
                  <div style={{ color: "#7367F0" }}>
                    <IoFilterOutline fill="#7367F0" />
                  </div>
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {isFilterOpen && (
              <div
                className="d-flex my-xl-auto right-content align-items-end button-space"
                style={{ flexWrap: "wrap" }}
              >
                <ControlledDatePicker
                  name="date"
                  selectsRange
                  label="Date"
                  maxDate={new Date()}
                  placeholder="Select Date"
                  control={control}
                />

                <ControlledDropDown
                  label="Bank"
                  name="acNo"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={prepareBanklistOptions()}
                  control={control}
                />

                <ControlledDropDown
                  label="Is Get"
                  id="type"
                  name="isget"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={IsGetOptions}
                  control={control}
                />

                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    reset();
                    setFilter({ ...TRA_FILTER });
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          isLoading={isTableLoading && !refresh}
          data={bankstatementState?.data || []}
          isData={!!bankstatementState?.data?.length}
          filterColumns={filterColumns}
          count={bankstatementState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {bankstatementState?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <BankStatementModal
        isOpen={isBankStatementUtrModal}
        onClose={onToggleBankStatementModal}
        id={id}
      />
      <ParseDataModal data={parseData} onClose={() => setparseData(null)} />
      <BankStatementFileUplod
        BankStatementFileUplodModal={BankStatementFileUplodModal}
        onGet={() => GetBankstatement()}
        onCloseModalBankStatementFileUplod={onCloseModalBankStatementFileUplod}
      />
    </div>
  );
};

export default Bank_Statement;
