import { Logout, clearAuth, setIsLoading } from "../redux/slices/authSlice";
import { LOGOUT_EVENTS, LOGOUT_TIME } from "../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef } from "react";
import { clearBankStatement } from "../redux/slices/bankStatementSlice";
import { clearBankTransaction } from "../redux/slices/bankTransactionSlice";
import { clearCustomer } from "../redux/slices/customerSlice";
import { clearDashboard } from "../redux/slices/dashboardSlice";
import { clearMerchant } from "../redux/slices/merchantSlice";
import { clearPayinPayout } from "../redux/slices/payinPayoutSlice";
import { clearPaymentMethod } from "../redux/slices/paymentMethodSlice";
import { clearPayout } from "../redux/slices/payoutSlice";
import { clearReport } from "../redux/slices/ReportSlice";
import { clearSupport } from "../redux/slices/supportSlice";
import { clearTransaction } from "../redux/slices/transactionSlice";

const addListeners = (events, cb) => {
  events.forEach((event) => window.addEventListener(event, cb));

  return () => {
    events.forEach((event) => window.removeEventListener(event, cb));
  };
};

export const useLogoutInterval = (time = LOGOUT_TIME) => {
  const listenRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    addListeners(LOGOUT_EVENTS, async (e) => {
      clearTimeout(listenRef.current);
      listenRef.current = setTimeout(async () => {
        try {
          dispatch(setIsLoading(true));
          const res = await dispatch(Logout()).unwrap();
          navigate("/");
          dispatch(setIsLoading(false));
          return res;
        } catch (err) {
          dispatch(setIsLoading(false));
        }
      }, time);
    });

    return () => {
      clearTimeout(listenRef.current);
    };
    // eslint-disable-next-line
  }, []);
};

export const useClearData = (refresh = true) => {
  sessionStorage.clear();
  localStorage.clear();
  if (refresh) {
    window.location.reload();
  }
  //   const dispatch = useDispatch();
  //   dispatch(clearAuth());
  //   const navigate = useNavigate();
  //   navigate("/");
  //   dispatch(clearBankStatement());
  //   dispatch(clearBankTransaction());
  //   dispatch(clearCustomer());
  //   dispatch(clearDashboard());
  //   dispatch(clearMerchant());
  //   dispatch(clearPayinPayout());
  //   dispatch(clearPaymentMethod());
  //   dispatch(clearPayout());
  //   dispatch(clearReport());
  //   dispatch(clearSupport());
  //   dispatch(clearTransaction());
  //   dispatch(clearPg());
};

export const useAppSelector = useSelector
