import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  addReseller,
  addResellerSettlement,
  addResellerToMerchant,
  deletReseller,
  deleteResellerCommission,
  getReseller,
  getResellerComission,
  getResellerDetails,
  getResellerMerchantList,
  getResellerSettlement,
  getResellerSettlementBalance,
  resetResellerPassword,
  updateResellerCommission,
} from "../services/resellerServices";

const initialState = {
  data: {},
  depositDetails: {},
  withdrawalDetails: {},
  comission: [],
  merchantList: [],
  settlementData: {},
  settlementBalanceData: {},
};

export const resellerGet = createAsyncThunk("resellerGet", async (payload) => {
  return await getReseller(payload);
});
export const resellerDepositDetailsGet = createAsyncThunk(
  "resellerDepositDetailsGet",
  async (payload) => {
    return await getResellerDetails(payload);
  }
);
export const resellerWithdrawalDetailsGet = createAsyncThunk(
  "resellerWithdrawalDetailsGet",
  async (payload) => {
    return await getResellerDetails(payload);
  }
);
export const resellerComissionGet = createAsyncThunk(
  "resellerComissionGet",
  async (payload) => {
    return await getResellerComission(payload);
  }
);
export const resellerMerchantListGet = createAsyncThunk(
  "resellerMerchantListGet",
  async (payload) => {
    return await getResellerMerchantList(payload);
  }
);
export const resellerSettlementGet = createAsyncThunk(
  "resellerSettlementGet",
  async (payload) => {
    return await getResellerSettlement(payload);
  }
);
export const resellerSettlementBalanceGet = createAsyncThunk(
  "resellerSettlementBalanceGet",
  async (payload) => {
    return await getResellerSettlementBalance(payload);
  }
);
export const resellerAdd = createAsyncThunk("resellerAdd", async (payload) => {
  return await addReseller(payload);
});
export const resellerToMerchantAdd = createAsyncThunk(
  "resellerToMerchantAdd",
  async (payload) => {
    return await addResellerToMerchant(payload);
  }
);
export const resellerCommissionUpdate = createAsyncThunk(
  "resellerCommissionUpdate",
  async (payload) => {
    return await updateResellerCommission(payload);
  }
);
export const resellerCommissionDelete = createAsyncThunk(
  "resellerCommissionDelete",
  async (payload) => {
    return await deleteResellerCommission(payload);
  }
);
export const resellerSettlementAdd = createAsyncThunk(
  "addResellerSettlement",
  async (payload) => {
    return await addResellerSettlement(payload);
  }
);
export const resetPasswordReseller = createAsyncThunk(
  "resetResellerPassword",
  async (payload) => {
    return await resetResellerPassword(payload);
  }
);
export const resellerDeletId = createAsyncThunk(
  "deletReseller",
  async (payload) => {
    return await deletReseller(payload);
  }
);

const resellersSlice = createSlice({
  name: "resellersSlice",
  initialState,
  reducers: {
    clearReseller: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resellerGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(resellerDepositDetailsGet.fulfilled, (state, action) => {
      state.depositDetails = action.payload;
    });
    builder.addCase(resellerWithdrawalDetailsGet.fulfilled, (state, action) => {
      state.withdrawalDetails = action.payload;
    });
    builder.addCase(resellerComissionGet.fulfilled, (state, action) => {
      state.comission = action.payload?.merchantList || [];
    });
    builder.addCase(resellerMerchantListGet.fulfilled, (state, action) => {
      state.merchantList = action.payload;
    });
    builder.addCase(resellerSettlementGet.fulfilled, (state, action) => {
      state.settlementData = action.payload;
    });
    builder.addCase(resellerSettlementBalanceGet.fulfilled, (state, action) => {
      state.settlementBalanceData = action.payload;
    });
  },
});
export default resellersSlice.reducer;
export const { clearReseller } = resellersSlice.actions;

export const selectReseller = (state) => {
  return state.reseller.data;
};
export const useReseller = () => {
  const data = useSelector(selectReseller);
  return useMemo(() => data, [data]);
};

export const selectResellerDepositDetails = (state) => {
  return state.reseller.depositDetails;
};
export const useResellerDepositDetails = () => {
  const data = useSelector(selectResellerDepositDetails);
  return useMemo(() => data, [data]);
};

export const selectResellerWithdrawalDetails = (state) => {
  return state.reseller.withdrawalDetails;
};
export const useResellerWithdrawalDetails = () => {
  const data = useSelector(selectResellerWithdrawalDetails);
  return useMemo(() => data, [data]);
};

export const selectResellerComission = (state) => {
  return state.reseller.comission;
};
export const useResellerComission = () => {
  const data = useSelector(selectResellerComission);
  return useMemo(() => data, [data]);
};

export const selectResellerMerchantList = (state) => {
  return state.reseller.merchantList;
};
export const useResellerMerchantList = () => {
  const merchantList = useSelector(selectResellerMerchantList);
  return useMemo(() => merchantList, [merchantList]);
};

export const selectResellerSettlementData = (state) => {
  return state.reseller.settlementData;
};
export const useResellerSettlementData = () => {
  const settlementData = useSelector(selectResellerSettlementData);
  return useMemo(() => settlementData, [settlementData]);
};

export const selectResellerSettlementBalanceData = (state) => {
  return state.reseller.settlementBalanceData;
};
export const useResellerSettlementBalanceData = () => {
  const settlementBalanceData = useSelector(
    selectResellerSettlementBalanceData
  );
  return useMemo(() => settlementBalanceData, [settlementBalanceData]);
};
