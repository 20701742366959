import { Table, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { Button, Card, CardHeader, Form } from "reactstrap";
import React, { useEffect, useState } from "react";
import { DROPDOWN_ALL_VALUE, FILTER, showToaster } from "../../../constant";
import { useDispatch } from "react-redux";
import AddResellerModal from "./AddResellerModal";
import { resellerGet, useReseller } from "../../../redux/slices/resellerSlice";
import AddToMerchantModal from "./AddToMerchantModal";
import { useLocation, useNavigate } from "react-router-dom";
import ResellerDelet from "./ResellerDelet";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const initialValues = {
  date: [new Date(), new Date()],
  resellerId: DROPDOWN_ALL_VALUE,
  merchantId: "",
};
const Reseller = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState({ ...FILTER, ...initialValues });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [resellerData, setResellerData] = useState(false);
  const [resellerDelet, setResellerDelet] = useState(false);
  const role = useRole("Reseller User");

  const data = useReseller();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const columns = [
    {
      title: "ID",
      name: "reseller_id",
    },
    // {
    //   title: "Deposit Txn",
    //   name: "total_payin_txn",
    //   Cell:(item) => {
    //     return (<span>{item?.total_payin_txn || "0"}</span>)
    //   }
    // },
    {
      title: "Deposit Amount",
      name: "total_success_amount",
      Cell: (data) => {
        return <>{seperator(data?.total_success_amount)}</>;
      },
    },
    {
      title: "Deposit Commission",
      name: "total_payin_commission",
      Cell: (data) => {
        return <>{seperator(data?.total_payin_commission)}</>;
      },
    },
    // {
    //   title: "Withdrawal Txn",
    //   name: "total_payout_txn",
    //   Cell:(item) => {
    //     return (<span>{item?.total_payout_txn || "0"}</span>)
    //   }
    // },
    {
      title: "Withdrawal Amount",
      name: "total_success_payout",
      Cell: (data) => {
        return <>{seperator(data?.total_success_payout)}</>;
      },
    },
    {
      title: "Withdrawal Commission",
      name: "total_payout_commission",
      Cell: (data) => {
        return <>{seperator(data?.total_payout_commission)}</>;
      },
    },
    // {
    //   title: "Pending Commission",
    //   name: "pending_commission",
    //   Cell: (item) => {
    //     return <span>{seperator(item?.pending_commission)}</span>;
    //   },
    // },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <div className="d-flex gap">
            <div>
              {/* {role.includes("reseller-list") &&
              role.includes("addResellerToMerchant") ? ( */}
              <Button
                color="primary"
                className="btn-smallsize"
                onClick={(e) => {
                  e.stopPropagation();
                  setResellerData(data);
                }}
              >
                Add Merchant
              </Button>
              {/* ) : null} */}
            </div>
            {role.includes("delete-reseller") ? <div>
              <Button
                className="btn-smallsize"
                color="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  setResellerDelet(data);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </div> : null}
          </div>
        );
      },
    },
  ];

  const onSubmit = () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, handleSubmit, watch, reset, getValues } = useForm({
    defaultValues: initialValues,
  });

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, page_no, limit, date, ...rest } = filter;
      const payload = {
        filter_data: {
          ...rest,
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
        },
      };
      const res = await dispatch(resellerGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const prepareResellerOptions = () => {
    const options = data?.reseller?.map((item) => ({
      value: item?.reseller_id,
      label: item?.full_name || "-",
    }));
    return options;
  };
  const prepareMerchantOptions = () => {
    const options = Object.entries(data?.mercahntList || {})
      ?.find(([key, item]) => key === watch("resellerId"))?.[1]
      ?.map((item) => ({
        value: item?.merchant_id,
        label: item?.merchant_details?.merchant_name || "-",
      }));
    return options;
  };

  useEffect(() => {
    if (role?.includes("reseller-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("reseller-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>Reseller</b>
                </h4>
              </div>
              <div className="d-flex align-items-end button-space">
                <div
                  className="d-flex my-xl-auto right-content align-items-center button-space "
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="mb-0">Reseller ID</p>
                  <ControlledDropDown
                    name="resellerId"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareResellerOptions()}
                    control={control}
                  />
                  <span
                    onClick={() =>
                      watch("resellerId") === DROPDOWN_ALL_VALUE
                        ? showToaster("Please select reseller Id", "Error")
                        : ""
                    }
                  >
                    <ControlledDropDown
                      name="merchantId"
                      isDisabled={watch("resellerId") === DROPDOWN_ALL_VALUE}
                      options={prepareMerchantOptions()}
                      placeholder="Select Merchant"
                      control={control}
                    />
                  </span>
                  <div className="d-flex flex-column select-div">
                    <ControlledDatePicker
                      name="date"
                      placeholder="Select Date"
                      maxDate={new Date()}
                      selectsRange
                      control={control}
                    />
                  </div>

                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...FILTER });
                    }}
                  >
                    Clear
                  </Button>
                  {role?.includes("add-reseller") ? (
                    <Button
                      type="button"
                      title="Add Reseller"
                      color="primary"
                      onClick={() => setIsOpenModal(true)}
                    >
                      Add Reseller
                    </Button>
                  ) : null}
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      navigate(location?.pathname + "/settlement");
                    }}
                  >
                    Settlement
                  </Button>
                </div>
              </div>
            </div>
          </CardHeader>
        </Form>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
                onClick={() => {
                  navigate(`${location?.pathname}/${val?.reseller_id}`);
                }}
              />
            );
          })}
        </Table>
      </Card>
      <AddResellerModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
      />
      <AddToMerchantModal
        isOpen={resellerData}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setResellerData(false)}
      />
      <ResellerDelet
        isOpen={resellerDelet}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        onHide={() => setResellerDelet(false)}
      />
    </div>
  );
};

export default Reseller;
