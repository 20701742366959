import React, { useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { Central_Bank_Merchant_Add } from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";

const AddCentralBank = (props) => {
  const { isOpen, onCloseCentralBankModal, GetMerchantData } = props;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const onHide = () => {
    onCloseCentralBankModal();
    setIsButtonLoading(false);
  };

  const onCentralBankMerchantStatus = async () => {
    setIsButtonLoading(true);
    try {
      const payload = {
        isCentralBankMerchant: isOpen?.isCentralBankMerchant ? 0 : 1,
        merchantId: isOpen?.merchantId,
      };
      const res = await dispatch(Central_Bank_Merchant_Add(payload)).unwrap();
      onHide();
      setIsButtonLoading(false);
      await GetMerchantData();
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onHide} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Central Bank Change</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h3> Do you want to change central merchant ?</h3>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onHide()}>
            Cancel
          </Button>
          <CustomeButton
            onClick={() => onCentralBankMerchantStatus()}
            className="submitbutton"
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddCentralBank;
