import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import {
  addBankProxy,
  deleteBankProxy,
  getBankProxy,
  updateBankProxyStatus,
} from "../services/bankProxyServices";

const initialState = {
  isLoading: true,
  data: {},
};

export const bankProxyGet = createAsyncThunk(
  "/bankProxyGet",
  async (payload) => {
    return await getBankProxy(payload);
  }
);

export const bankProxyAdd = createAsyncThunk(
  "/bankProxyAdd",
  async (payload) => {
    return await addBankProxy(payload);
  }
);

export const bankProxyStatusUpdate = createAsyncThunk(
  "/bankProxyStatusUpdate",
  async (payload) => {
    return await updateBankProxyStatus(payload);
  }
);

export const bankProxyDelete = createAsyncThunk(
  "/bankProxyDelete",
  async (payload) => {
    return await deleteBankProxy(payload);
  }
);

const bankProxySlice = createSlice({
  name: "bankProxySlice",
  initialState,
  reducers: {
    clearBankProxy: () => initialState,
    setBankProxy: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(bankProxyGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default bankProxySlice.reducer;
export const { clearBankProxy, setBankProxy } = bankProxySlice.actions;

export const selectBankProxyData = (state) => {
  return state.bankProxy.data;
};
export const useBankProxyData = () => {
  const data = useSelector(selectBankProxyData);
  return useMemo(() => data, [data]);
};
