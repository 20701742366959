import React from "react";
import { createRoot } from "react-dom/client";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import "./scss/react/libs/react-hot-toasts/react-hot-toasts.scss";
// import "./iconfont.css";
import "./scss/core.scss";
import "./assets/scss/style.scss";
import "./index.scss";

// import * as serviceWorker from "./serviceWorker";
import * as serviceWorker from "./registerServiceWorker";
import App from "./App";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import { FINGER_KEY, FINGER_REGION_KEY, restricForFingerPrintUrls } from "./constant";

// Lazy load app
const container = document.getElementById("root");
const root = createRoot(container);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);
root.render(
  !restricForFingerPrintUrls?.find((item) => window.location.hostname?.includes(item)) ? (
    <FpjsProvider
      loadOptions={{
        apiKey: FINGER_KEY,
        region: FINGER_REGION_KEY,
      }}
    >
      {app}
    </FpjsProvider>
  ) : (
    app
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
