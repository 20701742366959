import MerchantPayInAddMetaModal from "../../../components/Custom/Modals/MerchantPayInAddMetaModal/MerchantPayInAddMetaModal";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router";
import {
  Merchant_Pay_In_Available_Payment_Get,
  Merchant_Pay_In_Get,
  useMerchantPayInAvailablePayment,
  useMerchantPayIn,
  Merchant_Pay_In_Meta_Add,
  Merchant_Pay_In_Status_Update,
  Merchant_Pay_In_Delete,
} from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Row,
  Dropdown as PopUp,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { FILTER } from "../../../constant";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import MerchantPayinAmount from "./MerchantPayinAmount";
import { responseToaster, sortByKey } from "../../../helperFunctions";
import MerchantPayinPaymentMethods from "./MerchantPayinPaymentMethods";
import MerchantPayinLevels from "./MerchantPayinLevels";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantPayIn = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenAddMeta, setIsOpenAddMeta] = useState(false);
  const [deleteData, setDeleteData] = useState(false);

  const merchantPayIn = useMerchantPayIn();
  const merchantAvailablePayment = useMerchantPayInAvailablePayment();

  const [objFilterColumns, setObjFilterColumns] = useState([]);
  const [autoFilterColumns, setAutoFilterColumns] = useState([]);
  const [manualFilterColumns, setManualFilterColumns] = useState([]);

  const [objFilterOpen, setObjFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});

  const [filter] = useState(FILTER);
  const [objFilter, setObjFilter] = useState("");
  const [refresh, setRefresh] = useState(false);

  const role = useRole("Merchant");

  const onGetMerchantPayIn = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const { isFilter, ...payload } = filter;
      await dispatch(
        Merchant_Pay_In_Get({ ...payload, merchantId: params?.id })
      ).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetMerchantAvailablePayment = async (loading = false) => {
    try {
      if (loading) {
        setIsBankLoading(true);
      }
      const payload = {
        merchantId: params?.id,
      };
      await dispatch(Merchant_Pay_In_Available_Payment_Get(payload)).unwrap();
      setIsBankLoading(false);
    } catch (err) {
      setIsBankLoading(true);
    }
  };

  const onAddMeta = async (values) => {
    try {
      setIsButtonLoading(true);
      let payload = {
        ...values,
      };
      delete payload["all"];
      const res = await dispatch(Merchant_Pay_In_Meta_Add(payload)).unwrap();
      setIsOpenAddMeta(false);
      onGetMerchantPayIn(true);
      onGetMerchantAvailablePayment(true);
      responseToaster(res);
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdateStatus = async (payload) => {
    try {
      const res = await dispatch(
        Merchant_Pay_In_Status_Update(payload)
      ).unwrap();
      responseToaster(res);
    } catch (err) {}
  };

  const onDelete = async (payload) => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Merchant_Pay_In_Delete(payload)).unwrap();
      responseToaster(res);
      onGetMerchantPayIn(true);
      onGetMerchantAvailablePayment(true);
      setDeleteData(false);
      setIsButtonLoading(false);
    } catch (err) {
      setDeleteData(false);
      setIsButtonLoading(false);
    }
  };

  const prepareData = (data) => {
    if (!objFilter) {
      let newData = [];
      Object.entries(data)?.forEach(([_, val]) => {
        newData = [...newData, ...(Array.isArray(val) ? val : [])];
      });
      return sortByKey(newData, "isActive", "DESC");
    }
    const newData = data?.[objFilter] ? data[objFilter] : [];
    return sortByKey(newData, "isActive", "DESC");
  };

  useEffect(() => {
    const newAutoColumns = autopaycolumns?.map((item) => item?.name);
    const newManualColumns = manualpaycolumns?.map((item) => item?.name);
    const newObjColumns = objColumns?.map((item) => item?.name);
    setAutoFilterColumns(newAutoColumns);
    setManualFilterColumns(newManualColumns);
    setObjFilterColumns(newObjColumns);
  }, []);

  useEffect(() => {
    if (role.includes("merchant-transaction")) {
      onGetMerchantPayIn(true);
      onGetMerchantAvailablePayment(true);
    }
  }, [filter]);

  const objColumns = [
    {
      title: "MMPID",
      name: "id",
    },
    {
      title: "PG",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  {data?.pgName} {data?.pgId ? `(${data?.pgId})` : ""}
                </p>
              </div>
              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span>Label : </span>
                    {data?.pgLabel || ""}
                  </p>
                </div>
              ) : null}
              {data?.acNumber ? (
                <div className="order-singal-div">
                  <p>
                    <span>A/C : </span>
                    {data?.acNumber || ""}
                  </p>
                </div>
              ) : null}
              {data?.ifscCode ? (
                <div className="order-singal-div">
                  <p>
                    <span>IFSC : </span>
                    {data?.ifscCode || ""}
                  </p>
                </div>
              ) : null}
              {data?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span>Bank : </span>
                    {data?.bankName || ""}
                  </p>
                </div>
              ) : null}
              {data?.upiId ? (
                <div className="order-singal-div">
                  <p>
                    <span>UPI: </span>
                    {data?.upiId || ""}
                  </p>
                </div>
              ) : null}
              {data?.accountFlow ? (
                <div className="order-singal-div">
                  <p>
                    <span>A/C Flow: </span>
                    {data?.accountFlow || ""}
                  </p>
                </div>
              ) : null}
              {data?.pgType ? (
                <div className="order-singal-div">
                  <p>
                    <span>Type: </span>
                    {data?.pgType || ""}
                  </p>
                </div>
              ) : null}
              <div className="order-singal-div">
                <p
                  style={{
                    color: "var(--chatlook-darkblue--)",
                  }}
                >
                  {data?.is_continuous ? "Seamless" : "Hosted"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYMENT METHOD",
      name: "payment_gateway",
      Cell: (data) => {
        return (
          <>
            <div className="order-singal-div">
              <p>{data?.payment_gateway}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYMENT APP",
      name: "payment_app",
      Cell: (data) => {
        return (
          <>
            <MerchantPayinPaymentMethods
              data={data}
              onGet={() => onGetMerchantPayIn(true)}
            />
          </>
        );
      },
    },
    {
      title: "LEVEL",
      Cell: (data) => {
        return (
          <>
            <MerchantPayinLevels
              data={data}
              onGet={() => onGetMerchantPayIn(true)}
            />
          </>
        );
      },
    },
    {
      title: "ACTIVE",
      name: "active",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              value={data?.isActive}
              disabled={!role.includes("merchant-deposit-update")}
              onChange={async (value) => {
                try {
                  setIsLoading(true);
                  const payload = {
                    value: value,
                    pk: data?.pgId,
                    pgName: data?.pgName,
                    merchantId: data?.merchantId,
                    id: data?.id,
                  };
                  const res = await onUpdateStatus(payload);
                  if (res) {
                    responseToaster(res);
                  }
                  await onGetMerchantPayIn();
                  setIsLoading(false);
                } catch (err) {
                  setIsLoading(false);
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "amount",
      Cell: (data) => {
        return (
          <>
            <MerchantPayinAmount
              updateDetails={updateDetails}
              setUpdateDetails={setUpdateDetails}
              data={data}
              onGetMerchantPayIn={() => {
                onGetMerchantPayIn(true);
              }}
              setIsLoading={setIsLoading}
            />
          </>
        );
      },
    },
    {
      title: "DATE",
      name: "createdAt",
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <>
            <h6 className="mb-0">
              <Button
                color="danger"
                className="btn-smallsize"
                disabled={!role.includes("merchant-deposit-delete")}
                onClick={() => {
                  setDeleteData({
                    merchantId: params?.id,
                    pgName: data?.pgName,
                    id: data?.id,
                    pgId: data?.pgId,
                    paymentMethod: data?.payment_gateway,
                  });
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </h6>
          </>
        );
      },
    },
  ];

  const autopaycolumns = [
    {
      title: "PG INFO",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>
                {data?.pgName || ""}
                {data?.accountId ? ` (${data?.accountId})` : ""}
              </span>

              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> Label : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "LABEL",
      name: "merchantId",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <div>
                  <span
                    style={{
                      color: "var(--chatlook-darkblue--)",
                    }}
                  >
                    {data?.is_continuous ? "Seamless" : "Hosted"}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <Fragment>
            <Button
              color="primary"
              disabled={!role.includes("merchant-deposit-add")}
              onClick={() => {
                setIsOpenAddMeta({
                  merchantId: params?.id,
                  pgName: data?.pgName,
                  account_id: data?.accountId,
                  methods: data?.meta_methods,
                });
              }}
            >
              Add Bank
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const manualpaycolumns = [
    {
      title: "PG INFO",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>{data?.upiId ? `${data?.upiId}` : ""}</span>
              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> Label : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Bank name : </span>
                    {data?.bankName}
                  </p>
                </div>
              ) : null}
              {data?.acNumber ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.acNumber}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "LABEL",
      name: "upiId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <span>
                  {data?.pgName || ""}
                  {data?.accountId ? ` (${data?.accountId})` : ""}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      Cell: (data) => {
        return (
          <Fragment>
            <Button
              color="primary"
              disabled={!role.includes("merchant-deposit-add")}
              onClick={() => {
                setIsOpenAddMeta({
                  merchantId: params?.id,
                  pgName: data?.pgName,
                  account_id: data?.accountId,
                  methods: data?.meta_methods,
                });
              }}
            >
              Add Bank
            </Button>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        onGetMerchantPayIn(true);
        onGetMerchantAvailablePayment(false);
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
  }, [refresh, filter]);

  if (!role.includes("merchant-transaction")) {
    return;
  }

  return (
    <div
      className={`merchant-payintable ${
        isLoading && refresh ? "opacity-25" : ""
      }`}
    >
      <Card>
        <div className="d-flex align-items-center px-2 py-1 justify-content-between">
          <div className="d-flex align-items-center">
            <h5 className="tab-table-heading me-1">Merchant</h5>
          </div>
          <div className="d-flex align-items-center justify-content-center tab-button button-space">
            <span className=" aurorefresh-btn">
              <Button
                type="button"
                className={`${refresh ? "" : "filterbtn"} gap btn-smallsize`}
                color={refresh ? "primary" : ""}
                onClick={() => setRefresh(!refresh)}
                title="Auto refresh"
              >
                {refresh ? (
                  <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                ) : (
                  <i className="fa-solid fa-arrows-rotate"></i>
                )}
                Auto Refresh
              </Button>
            </span>
            <Button
              color={!objFilter ? "primary btn-smallsize" : ""}
              className={!objFilter ? "" : "filterbtn btn-smallsize"}
              onClick={() => {
                setObjFilter();
              }}
            >
              All
            </Button>
            {merchantPayIn?.data
              ? (Object.entries(merchantPayIn?.data || {}) || [])?.map(
                  ([key]) => {
                    return (
                      <Button
                        color={objFilter === key ? "primary btn-smallsize" : ""}
                        className={
                          objFilter === key ? "" : "filterbtn btn-smallsize"
                        }
                        onClick={() => {
                          setObjFilter(key);
                        }}
                      >
                        {key}
                      </Button>
                    );
                  }
                )
              : null}
            <PopUp
              isOpen={objFilterOpen}
              toggle={() => setObjFilterOpen(!objFilterOpen)}
            >
              <DropdownToggle
                className=" header-item waves-effect border-0 bg-transparent table-filterbtn btn-smallsize"
                id="page-header-user-dropdown"
                tag="button"
              >
                <IoFilterOutline value={{ color: "#7367F0" }} fill="#7367F0" />
              </DropdownToggle>
              <DropdownMenu
                className="z-3"
                style={{
                  whiteSpace: "nowrap",
                  padding: 20,
                  transform: "translate(-20px, 22px)",
                }}
              >
                <li className="z-3 text-dark">
                  <span>
                    <i className="fa-solid fa-filter"></i> Filter Column
                  </span>
                </li>
                <div className="border-bottom border-light mt-2">
                  {objColumns?.map((column) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <p>{column?.title}</p>
                        <Switch
                          checked={objFilterColumns?.includes(column?.name)}
                          size="small"
                          onChange={(e) => {
                            const newData = [...objFilterColumns];
                            const index = newData?.indexOf(column?.name);
                            if (index > -1) {
                              newData.splice(index, 1);
                            } else {
                              newData.push(column?.name);
                            }
                            setObjFilterColumns(newData);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </DropdownMenu>
            </PopUp>
          </div>
        </div>
        <Table
          isLoading={isLoading && !refresh}
          columns={objColumns}
          isExpandable={false}
          isData={!!prepareData(merchantPayIn?.data || {})?.length}
          filterColumns={objFilterColumns}
          onColumnsChange={(columns) => {
            setObjFilterColumns(columns);
          }}
        >
          {prepareData(merchantPayIn?.data || {})?.map((item) => {
            return (
              <>
                <TableRow
                  columns={objColumns}
                  item={item}
                  isExpandable={false}
                  filterColumns={objFilterColumns}
                />
              </>
            );
          })}
        </Table>
      </Card>
      <Row>
        <Col lg="6" className="autometa-sec">
          <Card>
            <div className="d-flex align-items-center">
              <h5 className="tab-table-heading m-1">Auto Pay Bank</h5>
            </div>
            <Table
              columns={autopaycolumns}
              isLoading={isBankLoading && !refresh}
              data={merchantAvailablePayment?.data?.auto || []}
              isData={!!merchantAvailablePayment?.data?.auto?.length}
              filterColumns={autoFilterColumns}
              isExpandable={false}
              onColumnsChange={(columns) => {
                setAutoFilterColumns(columns);
              }}
            >
              {merchantAvailablePayment?.data?.auto?.map((item) => {
                return (
                  <TableRow
                    columns={autopaycolumns}
                    item={item}
                    filterColumns={autoFilterColumns}
                    isExpandable={false}
                  />
                );
              })}
            </Table>
          </Card>
        </Col>

        <Col lg="6" className="manualpadding">
          <Card>
            <div className="d-flex align-items-center">
              <h5 className="tab-table-heading m-1">Manual Deposit Bank</h5>
            </div>
            <Table
              columns={manualpaycolumns}
              isLoading={isBankLoading && !refresh}
              data={merchantAvailablePayment?.data?.manual || []}
              isData={!!merchantAvailablePayment?.data?.manual?.length}
              filterColumns={manualFilterColumns}
              isExpandable={false}
              onColumnsChange={(columns) => {
                setManualFilterColumns(columns);
              }}
            >
              {merchantAvailablePayment?.data?.manual?.map((item) => {
                return (
                  <TableRow
                    columns={manualpaycolumns}
                    item={item}
                    isExpandable={false}
                    filterColumns={manualFilterColumns}
                  />
                );
              })}
            </Table>
          </Card>
        </Col>
      </Row>

      <MerchantPayInAddMetaModal
        isButtonLoading={isButtonLoading}
        data={isOpenAddMeta}
        onCancelClick={() => setIsOpenAddMeta(false)}
        onDoneClick={async (payload) => {
          await onAddMeta(payload);
        }}
      />
      <DeleteModal
        isButtonLoading={isButtonLoading}
        title="Delete Bank"
        content="Are you sure you want to delete deposit bank ?"
        isOpen={!!deleteData}
        onCancelClick={() => {
          setDeleteData(false);
        }}
        onDoneClick={async () => {
          await onDelete({
            ...deleteData,
          });
        }}
      />
    </div>
  );
};

export default MerchantPayIn;
