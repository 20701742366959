import React, { useRef, useState } from "react";
import TableCell from "./TableCell";
import "./Table.scss";
import html2canvas from "html2canvas";

const TableRow = (props) => {
  const {
    className = "",
    tdClassName = "",
    tdStyle = {},
    style = {},
    item = {},
    columns = [],
    config,
    filterColumns = [],
    onClick,
    onContextMenu = () => {},
    iWantShare = false,
    isExpandable = true,
    children,
    onEyeClick,
    shareColumns = [],
    CustUI,
  } = props;
  const tableRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleShare = async (e) => {
    e.stopPropagation();
    if (tableRef.current) {
      tableRef.current.style.display = "table";
      const canvas = await html2canvas(tableRef.current);
      const imageData = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = imageData;
      a.download = "table_image.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      tableRef.current.style.display = "none";
    }
  };

  return (
    <>
      <tr
        className={`${className}  ${
          isOpen ? "activerow" : ""
        } table-row  tablecell-hoverx`}
        style={{ ...style }}
        onContextMenu={() => onContextMenu(item)}
        onClick={(e) => {
          onClick?.(item, e);
          if (isExpandable) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {isExpandable ? (
          <TableCell
            style={{ ...tdStyle }}
            className={`cursor-pointer`}
            onClick={(e) => {
              // e.stopPropagation();
              // setIsOpen(!isOpen);
              onEyeClick?.(item);
            }}
          >
            <div>
              <i className="fa-solid fa-eye"></i>
            </div>
            {iWantShare ? (
              <div onClick={(e) => handleShare(e)}>
                <i className="fa-solid fa-share"></i>
              </div>
            ) : null}
            {CustUI ? CustUI : null}
          </TableCell>
        ) : null}

        {columns?.map((column, index) => {
          const {
            Cell,
            name,
            className = "",
            type,
          } = column;
          if (
            !filterColumns?.includes(column?.name) ||
            (config && !config?.show_columns?.includes(column?.checkKey))
          ) {
            return <></>;
          }
          return  <TableCell
              className={`${className} ${tdClassName}`}
              key={index}
              type={type}
            >
              {Cell ? <Cell {...item} isOpen={isOpen} /> : item?.[name] || "-"}
            </TableCell>
          
        })}
      </tr>
      {isOpen && isExpandable ? (
        <tr className="open-tabledata">
          <TableCell colspan={+columns?.length + 1} className={`detailcell`}>
            <div className="collapse-sec">{children}</div>
          </TableCell>
        </tr>
      ) : null}

      <tr
        ref={tableRef}
        className={`${className}  ${
          isOpen ? "activerow" : ""
        } table-row  tablecell-hoverx`}
        style={{ ...style, display: "none" }}
        onClick={() => {
          onClick?.(item);
          if (isExpandable) {
            setIsOpen(!isOpen);
          }
        }}
        onContextMenu={() => onContextMenu(item)}
      >
        {isExpandable ? (
          <TableCell
            style={{ ...tdStyle }}
            className={`cursor-pointer`}
            onClick={(e) => {
              // e.stopPropagation();
              // setIsOpen(!isOpen);
              onEyeClick?.(item);
            }}
          >
            <div>
              <i className="fa-solid fa-eye"></i>
            </div>
          </TableCell>
        ) : null}
        {shareColumns?.map((column, index) => {
          const { Cell, name, className = "", type } = column;
          if (
            !filterColumns?.includes(column?.name) ||
            (config && !config?.show_columns?.includes(column?.checkKey))
          ) {
            return <></>;
          }
          return (
            <TableCell
              className={`${className} ${tdClassName}`}
              key={index}
              type={type}
            >
              {Cell ? <Cell {...item} isOpen={isOpen} /> : item?.[name] || "-"}
            </TableCell>
          );
        })}
      </tr>
    </>
  );
};

export default TableRow;
