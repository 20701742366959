import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { logoutSubUser } from "../../../redux/slices/usersSlice";
import { responseToaster } from "../../../helperFunctions";

const LogoutUser = (props) => {
  const { id, onCloseUser, onGet, user } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onLogoutUser = async () => {
    try {
      setIsButtonLoading(true);
      const payload = {
        id,
      };
      let response = await dispatch(logoutSubUser(payload)).unwrap();
      responseToaster(response);
      if (response && response?.status) {
        onCloseUser()
        onGet?.()
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    onCloseUser();
  };

  return (
    <Modal isOpen={user} toggle={onClose} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Logout User</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <p> Are you sure you want to log out this user ?</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onClose()}>
            Cancel
          </Button>
          <CustomeButton
            onClick={onLogoutUser}
            className="submitbutton"
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutUser;
