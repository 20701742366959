import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Table, TableRow } from "../../../components/Table";
import { Button, Card, CardHeader, Form } from "reactstrap";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { dateFormatter, responseToaster } from "../../../helperFunctions";
import { useFormik } from "formik";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import { idfcMailWebhookGet, useIdfcMailWebhook } from "../../../redux/slices/idfcMailWebhookSlice";

const searchOptions = [
  {
    label: "Account Number",
    value: "account_number",
  },
  {
    label: "Bank RRN",
    value: "bank_rrn",
  },
  {
    label: "Payout ID",
    value: "payout_id",
  },
];
const IdfcMailWebhook = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const role = useRole("IDFC Mail Webhook");
  const dispatch = useDispatch();

  const data = useIdfcMailWebhook();

  const columns = [
    {
      title: "PAYOUT ID",
      name: "payout_id",
    },
    {
      title: "PAYOUT AMOUNT",
      name: "payout_amount",
    },
    {
      title: "ACCOUNT NUMBER",
      name: "account_number",
    },
    {
      title: "BANK RRN",
      name: "bank_rrn",
    },
    {
      title: "PAYMENT FROM",
      name: "payment_from",
    },
    {
      title: "BANK DATE",
      name: "bank_date ",
    },
    {
      title: "IS GET",
      name: "is_get",
    },
    {
      title: "IS DATA SYNC",
      name: "is_data_sync ",
    },
    {
      title: "ERROR MESSAGE",
      name: "error_message ",
    },
    {
      title: "CREATED AT",
      name: "created_at_ist ",
    },
    {
      title: "UPDATED AT",
      name: "updated_at_ist ",
    },
  ];

  const initialValues = {
    filter: "",
    search: "",
    startDate: null,
    endDate: null,
  };

  const onSubmit = async (values) => {
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  const { values, handleSubmit, setFieldValue, setValues, resetForm } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const {
        isFilter,
        startDate,
        filter: dropdown,
        search,
        endDate,
        page_no,
        limit,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
          page_no,
          limit,
          filter_data: {
            [dropdown]: search,
            startDate: dateFormatter(startDate, "start"),
            endDate: dateFormatter(endDate, "end", startDate),
            ...rest,
          },
        }
        : {
          page_no,
          limit,
        };
      const res = await dispatch(idfcMailWebhookGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("mailWebhook-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("mailWebhook-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>IDFC Webhook</b>
            </h4>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Button
                type="button"
                className="filterbtn"
                onClick={() => {
                  setShowDiv(!showDiv); // Toggle the value
                }}
                title="Apply Filter"
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
            </div>
          </div>

          <Form
            className="d-flex my-xl-auto right-content align-items-end button-space flex-wrap py-1 "
            onSubmit={handleSubmit}
          >
            {showDiv && (
              <>
                <DropDown
                  label="Filter"
                  options={searchOptions}
                  value={values?.filter}
                  onChange={(selectedOption) =>
                    setFieldValue("filter", selectedOption.value)
                  }
                />
                <InputField
                  placeholder="Enter Search Value"
                  label="Search"
                  value={values?.search}
                  onChange={(e) => {
                    setFieldValue("search", e.target.value);
                  }}
                />
                <DatePicker
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  startDate={values?.startDate}
                  endDate={values?.endDate}
                  selectsRange
                  onChange={(selectedDate) =>
                    setValues({
                      ...values,
                      startDate: selectedDate?.[0],
                      endDate: selectedDate?.[1],
                    })
                  }
                />
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger "
                  onClick={() => {
                    resetForm();
                    setFilter({ ...FILTER });
                  }}
                >
                  Clear
                </Button>
              </>
            )}
          </Form>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>
    </div>
  );
};

export default IdfcMailWebhook;
