import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useDispatch } from "react-redux";
import { Merchant_Pay_In_Payment_Methods_Update } from "../../../redux/slices/merchantSlice";
import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantPayinPaymentMethods = (props) => {
  const { data, onGet } = props;
  const dispatch = useDispatch();
  const role = useRole("Merchant");
  const [isLoading, setIsLoading] = useState(false);

  const staticDataTwo = [
    { value: "is_phonepe", label: "PhonePe" },
    { value: "is_paytm", label: "Paytm" },
    { value: "is_googlepay", label: "GooglePay" },
    { value: "all", label: "All" },
  ];

  const [checked, setChecked] = useState({});
  const onUpdateStatus = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...checked,
        merchantId: data?.merchantId,
        pgName: data?.pgName,
        id: data?.id,
        pgId: data?.pgId,
      };
      const res = await dispatch(
        Merchant_Pay_In_Payment_Methods_Update(payload)
      ).unwrap();
      responseToaster(res);

      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setChecked({
      ...checked,
      is_phonepe: data?.is_phonepe || 0,
      is_paytm: data?.is_paytm || 0,
      is_googlepay: data?.is_googlepay || 0,
      all:
        data?.is_phonepe == 1 && data?.is_paytm == 1 && data?.is_googlepay == 1
          ? 1
          : 0,
    });
  }, [data]);

  const prepareChecked = (value) => {
    if (value === "all") {
      return (
        checked?.is_phonepe === 1 &&
        checked?.is_paytm === 1 &&
        checked?.is_googlepay === 1
      );
    }
    return checked?.[value] === 1;
  };

  const handleChange = (value) => {
    if (value === "all") {
      const allChecked = checked.all === 1 ? 0 : 1;
      setChecked({
        is_phonepe: allChecked,
        is_paytm: allChecked,
        is_googlepay: allChecked,
        all: allChecked,
      });
    } else {
      const newChecked = {
        ...checked,
        [value]: checked[value] === 1 ? 0 : 1,
      };
      newChecked.all =
        newChecked?.is_phonepe === 1 &&
        newChecked?.is_paytm === 1 &&
        newChecked?.is_googlepay === 1
          ? 1
          : 0;
      setChecked(newChecked);
    }
  };

  return (
    <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
      <div className="order-singal-div">
        <div className="button-space checkbox-icon">
          {staticDataTwo?.map?.((level) => (
            <div
              className="d-flex align-items-center gap mb-50"
              key={level?.value}
            >
              <Input
                type="checkbox"
                disabled={!role.includes("merchant-deposit-update")}
                checked={prepareChecked(level?.value)}
                onChange={() => handleChange(level?.value)}
              />
              {level?.label}
            </div>
          ))}
        </div>
      </div>

      <CustomeButton
        className="btn-smallsize mt-50"
        isButtonLoading={isLoading}
        disabled={!role.includes("merchant-deposit-update")}
        onClick={onUpdateStatus}
      >
        Update
      </CustomeButton>
    </div>
  );
};

export default MerchantPayinPaymentMethods;
