import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Form, Row } from "reactstrap";
import "./Reconciliation.scss";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";

// Formik validation
import { reconciliationGet } from "../../../redux/slices/reconciliationSlice";
import {
  isParsable,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const Reconciliation = () => {
  const typeOptions = [
    { value: "DEPOSIT", label: "Deposit" },
    { value: "WITHDRAWAL", label: "Withdraw" },
  ];

  const initialValues = {
    id: "",
    type: typeOptions?.[0]?.value,
  };
  const role = useRole("Reconciliation");
  const dispatch = useDispatch();

  const [parsedData1, setParsedData1] = useState(null);
  const [roconData, setReconData] = useState({});

  useEffect(() => {
    const jsonData = new URLSearchParams(roconData?.data);
    const json = {};
    jsonData?.forEach((value, key) => {
      json[key] = value;
    });
    setParsedData1(json);

    try {
      const parsedSendNotifyContent = isParsable(roconData?.data?.response_body)
        ? JSON.parse(roconData?.data?.response_body || null)
        : null;
      setParsedData1(parsedSendNotifyContent);
    } catch (error) {}
  }, [roconData]);

  const onSubmit = async (values) => {
    try {
      const res = await dispatch(reconciliationGet(values)).unwrap();
      if (res) {
        setReconData(res);
      }
      responseToaster(res);
    } catch (err) {}
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  if (!role.includes("reconciliation-list")) {
    return;
  }

  return (
    <div className="reconciliation-main-div">
      <Card>
        <CardHeader className="align-items-md-center align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h3>Recon System</h3>
          </div>
          <Form
            className="d-flex my-xl-auto right-content align-items-center"
            style={{ flexWrap: "wrap" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledDropDown
              name="type"
              label="Filter"
              isDefaultOption={false}
              options={typeOptions}
              control={control}
              className="me-1"
            />
            <ControlledInput
              name="id"
              label="Search"
              placeholder="Enter Search Value"
              control={control}
            />
            <div
              className="d-flex "
              style={{ flexWrap: "wrap", paddingLeft: "10px" }}
            >
              <div className="pe-1 mb-xl-0 mt-2 d-flex align-items-center">
                <Button
                  type="submit"
                  color="primary"
                  className="me-1 mb-0 ms-auto"
                >
                  Apply
                </Button>
                <Button
                  color="danger"
                  className="mb-0"
                  onClick={() => {
                    reset();
                    setReconData({});
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Form>
        </CardHeader>
      </Card>

      {Object.keys(roconData?.data || {})?.length ? (
        <Card className="w-100">
          <div className="row">
            <div className="col-lg-8">
              {roconData?.data && (
                <>
                  <Row className="p-1 data-row fw-bold">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"INDEX"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {"VALUES"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Amount"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.Amount
                        ? seperator(roconData?.data?.Amount)
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"bankUtr"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Bank RRN"]
                        ? roconData?.data?.["Bank RRN"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Card Mask"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Card Mask"]
                        ? roconData?.data?.["Card Mask"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Currency Code"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Currency Code"]
                        ? roconData?.data?.["Currency Code"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Cust Email"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Cust Email"]
                        ? roconData?.data?.["Cust Email"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Customer Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Customer Id"]
                        ? roconData?.data?.["Customer Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Deposit Date"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Deposit Date"]
                        ? roconData?.data?.["Deposit Date"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Deposit Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Deposit Id"]
                        ? roconData?.data?.["Deposit Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Deposit Status"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Deposit Status"]
                        ? roconData?.data?.["Deposit Status"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Hash"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.Hash
                        ? roconData?.data?.Hash
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Is Status Fail"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Is Status Fail"]
                        ? roconData?.data?.["Is Status Fail"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Mop Type"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Mop Type"]
                        ? roconData?.data?.["Mop Type"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Order Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Order Id"]
                        ? roconData?.data?.["Order Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Orig Txn Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Orig Txn Id"]
                        ? roconData?.data?.["Orig Txn Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Pay Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Pay Id"]
                        ? roconData?.data?.["Pay Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Payment Method"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Payment Method"]
                        ? roconData?.data?.["Payment Method"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Payment Type"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Payment Type"]
                        ? roconData?.data?.["Payment Type"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Pg Order Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Pg Order Id"]
                        ? roconData?.data?.["Pg Order Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Pg Ref Num"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Pg Ref Num"]
                        ? roconData?.data?.["Pg Ref Num"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Pg Res Code"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Pg Res Code"]
                        ? roconData?.data?.["Pg Res Code"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Pg Txn Message"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Pg Txn Message"]
                        ? roconData?.data?.["Pg Txn Message"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Response Code"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Response Code"]
                        ? roconData?.data?.["Response Code"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Response Date & Time"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Response Date & Time"]
                        ? roconData?.data?.["Response Date & Time"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Response Message"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Response Message"]
                        ? roconData?.data?.["Response Message"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Status"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Status"]
                        ? roconData?.data?.["Status"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Surcharge Flag"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Surcharge Flag"]
                        ? roconData?.data?.["Surcharge Flag"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Total Amount"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Total Amount"]
                        ? seperator(roconData?.data?.["Total Amount"])
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Txn Id"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Txn Id"]
                        ? roconData?.data?.["Txn Id"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"Txn Type"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["Txn Type"]
                        ? roconData?.data?.["Txn Type"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"amount"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.amount
                        ? seperator(roconData?.data?.amount)
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"pg Org Response"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["pg Org Response"]
                        ? roconData?.data?.["pg Org Response"]
                        : "No data found"}
                    </Col>
                  </Row>
                  <Row className="px-1 py-50 data-row">
                    <Col lg={6} md={6} sm={6} className="left-part">
                      {"pg Ref Number"}
                    </Col>
                    <Col lg={6} md={6} sm={6} className="right-part">
                      {roconData?.data?.["pg Ref Number"]
                        ? roconData?.data?.["pg Ref Number"]
                        : "No data found"}
                    </Col>
                  </Row>
                </>
              )}
            </div>
            <div className="col-lg-4 p-1">
              <h5>Response Body</h5>
              <div className="webevent-sec mb-1 p-1">
                <pre>{JSON.stringify(parsedData1, null, 1)}</pre>
              </div>
            </div>
          </div>
        </Card>
      ) : null}
    </div>
  );
};

export default Reconciliation;
