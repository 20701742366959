import { Button, Form, Modal } from "reactstrap";
import { responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { pgUpdateUser } from "../../redux/slices/pgSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import EyeOff from "../../assets/images/pages/eye-off.svg";
import EyeOn from "../../assets/images/pages/eye-on.svg";
import { useForm } from "react-hook-form";
import ControlledInput from "../../components/Custom/Forms/Controller/ControlledInput";

const PgUpdateUserModal = (props) => {
  const { onHide, isShow, setIsShow, isOpen, onGet, params } = props;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    userName: "",
    password: "",
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = await dispatch(
        pgUpdateUser({
          ...values,
          accountId: isOpen?.account_id,
          pgType: params?.pgType,
          pgName: params?.pgName
        })
      ).unwrap();
      responseToaster(res);
      if (!!res) {
        onGet();
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: initialValues,
  });

  const onClose = () => {
    reset();
    onHide();
  };
  useEffect(() => {
    if (isOpen) {
      setValue("userName", isOpen?.username);
      setValue("password", "");
    }
  }, [isOpen]);

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={isOpen}
      toggle={onHide}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <div className="d-flex justify-content-between">
            <h4 className="modal-title mt-0">
              Update User Account Credentials
            </h4>
          </div>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>
        <div className="modal-body">
          <ControlledInput
            name="userName"
            label="User Name"
            placeholder="Enter User Name"
            autoComplete="off"
            control={control}
          />
          <ControlledInput
            name="password"
            type={isShow ? "text" : "password"}
            label="Password"
            placeholder="Enter Password"
            autoComplete="new-password"
            rightIcon={
              <img
                src={isShow ? EyeOn : EyeOff}
                alt="Eye"
                onClick={() => setIsShow(!isShow)}
              />
            }
            control={control}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PgUpdateUserModal;
