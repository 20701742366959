import React from "react";

// THIRD - PARTY IMPORT
import { CSVLink } from "react-csv";

const Export = (props) => {
  const {
    className = "",
    children,
    filename = "File.csv",
    data = [],
    headers = [],
    onClick,
    refe,
    ...rest
  } = props;
  return (
    <CSVLink
      ref={refe}
      filename={filename}
      className={`${className} appl-btn rounded-lg h-10 flex items-center justify-center`}
      data={data}
      onClick={onClick}
      headers={headers}
      {...rest}
    >
      {children}
    </CSVLink>
  );
};

export default Export;
