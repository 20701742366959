// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import InputField from "../InputField/InputField";

const ControlledInput = (props) => {
    const { control, name, ...rest } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...restt } }) => {
                return <InputField {...restt} {...rest} />;
            }}
        />
    );
};

export default ControlledInput;
