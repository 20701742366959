import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import {
  isPayinDownUpdate,
  pgRouterGet,
  setPgRouter,
  usePgRouterData,
} from "../../../redux/slices/pgRouterSlice";
import { Table, TableRow } from "../../../components/Table";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const PgRouter = () => {
  const dispatch = useDispatch();
  const role = useRole("PG Routers");

  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const data = usePgRouterData();

  const statusOption = [
    { value: 1, label: "Up", activeClassName: "green" },
    { value: 0, label: "Down", activeClassName: "red" },
  ];

  const columns = [
    {
      title: "PG",
      name: "created_at",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> PG : </span>
                  {data?.pg || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> PG Type : </span>
                  {data?.pg_type || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYIN",
      name: "id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Module : </span>
                  {data?.payin_router || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Meta : </span>
                  {data?.payin_meta_router || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYOUT",
      name: "label_name",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Module : </span>
                  {data?.payout_router || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Meta : </span>
                  {data?.payout_meta_router || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYIN DOWN",
      name: "is_payin_down",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusLoading === data?.pg}
              value={data?.is_payin_down ? 1 : 0}
              className={`${statusLoading ? "disabled" : ""}`}
              reverse
              options={statusOption}
              onChange={async (value) => {
                if (role.includes("update-is-payin-down")) {
                  try {
                    const payload = {
                      pg: data?.pg,
                      isPayinDown: value,
                    };
                    await onUpdateDownStatus(payload);
                  } catch (err) { }
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: "DATE",
      name: "updated_at",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.created_at_ist || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updated_at_ist || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const onUpdateDownStatus = async (payload) => {
    try {
      setStatusLoading(payload?.pg);
      const res = await dispatch(isPayinDownUpdate(payload)).unwrap();
      const index = data?.data?.findIndex((item) => item?.pg === payload?.pg);
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = {
          ...newData[index],
          is_payin_down: payload?.isPayinDown,
        };
        dispatch(setPgRouter({ ...data, data: newData }));
      }
      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const onGetPgRouter = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(pgRouterGet()).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("pgRouter-list")) {
      onGetPgRouter();
    }
  }, []);

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("pgRouter-list")) {
    return;
  }

  return (
    <div className="support-log-main-div">
      <Card>
        <CardHeader className="flex-column  align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <h4 className="main-title">
                <b>Developer PG Router</b>
              </h4>
            </div>
          </div>
        </CardHeader>
        <Table
          columns={columns}
          isLoading={isLoading}
          data={data?.data || []}
          isData={!!data?.data?.length}
          filterColumns={filterColumns}
          isExpandable={false}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
    </div>
  );
};

export default PgRouter;
