import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  merchantGoogleAnalyticAdd,
  merchantGoogleAnalyticUpdate,
} from "../../../redux/slices/merchantGoogleAnalyticSlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import { get_merchant_list } from "../../../redux/slices/payinPayoutSlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const AddMerchantGoogleAnalyticModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getMerchantListState } = useSelector((state) => state?.payinPayout);


  const initialValues = {
    code: "",
    merchantId:"",
  };

  const isEdit = !!isOpen?.merchant_id;
  const onSubmit = async () => {
    try {
      const values = getValuesForm();
      setIsLoading(true);
      const payload = {
        ...values
      };
      let res;
      if (isEdit) {
        res = await dispatch(
          merchantGoogleAnalyticUpdate({ ...payload, id: isOpen?.id })
        ).unwrap();
        onClose();
      } else {
        res = await dispatch(merchantGoogleAnalyticAdd(payload)).unwrap();
        onClose();
      }
      responseToaster(res);
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const schema = Yup.object({
    merchantId: Yup.string().required("Please Select Merchant"),
    code: Yup.string().required("Code Is Required"),
  });
  const {
    control,
    setValue,
    getValues: getValuesForm,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onGetMerchantList = async () => {
    try {
      await dispatch(get_merchant_list()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if(isOpen){
      onGetMerchantList();
    }
  }, [isOpen]);

  const onClose = () => {
    reset();
    onHide();
  };

  useEffect(() => {
    if (isEdit && isOpen) {
      setValue(
        "code",
        isOpen?.code
      );
      setValue(
        "merchantId",
        isOpen?.merchant_id
      );
    }
  }, [isEdit, isOpen]);

  const getMerchantDataOptions = () => {
    const options = getMerchantListState?.data
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchant_id,
          label: val?.merchant_name,
        };
        return clientDataOption;
      });
    return options;
  };

  return (
    <Modal
      className="add-manual-payout-modal modal-lg"
      isOpen={!!isOpen}
      centered={true}
      toggle={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">{`${
            isEdit ? "Edit" : "Add"
          } Merchant Google Analytic`}</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <div className="selectitems">
            <ControlledDropDown
              name="merchantId"
              label="Merchant"
              options={getMerchantDataOptions()}
              control={control}
              isDisabled={isEdit}
              errors={errors}
            />
            <ControlledInput
              name="code"
              label="Code"
              className="w-100"
              type="textarea"
              placeholder="Enter Code"
              control={control}
              rows="8"
              errors={errors}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              {isEdit ? "Update" : "Submit"}
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMerchantGoogleAnalyticModal;
