import React from "react";

const Loader = ({ style = {}, iconProps = {}, iconStyle = {}, ...rest }) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: "transparent", ...style }}
      {...rest}
    >
      <i
        className="fa fa-spinner fa-spin"
        style={{ backgroundColor: "transparent", ...iconStyle }}
        {...iconProps}
      ></i>
    </div>
  );
};

export default Loader;
