import { Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal } from "reactstrap";
import {
  Bank_Status_Fetch,
  Bank_Status_Update,
} from "../../../../redux/slices/merchantSlice";
import { useState } from "react";
import Loader from "../../Loader";
import { responseToaster } from "../../../../helperFunctions";
import { useRole } from "../../../../redux/slices/authSlice";

const BankConfig = (props) => {
  const { onCloseConfig, bankCofingModal } = props;
  const [isLoading, setIsLoading] = useState({
    loading: false,
    isFilter: false,
  });
  const { bankStateFetchState } = useSelector((state) => state.merchant);
  const dispatch = useDispatch();
  const role = useRole("Merchant");

  const GetFetchBankStatus = async () => {
    try {
      setIsLoading({ ...isLoading, loading: true, isFilter: true });

      const data = await dispatch(Bank_Status_Fetch()).unwrap();
      if (data) {
        setIsLoading({ ...isLoading, loading: false });
        if (!isLoading?.loading && isLoading?.isFilter) {
          responseToaster(data);
          setIsLoading({ ...isLoading, loading: false });
        }
      }
    } catch (err) {
      setIsLoading({ ...isLoading, loading: false });
    }
  };

  const BankStatusUpdate = async (payload) => {
    try {
      setIsLoading({ ...isLoading, loading: true });
      const data = await dispatch(Bank_Status_Update(payload)).unwrap();
      responseToaster(data);
      setIsLoading({ ...isLoading, isFilter: true });
      GetFetchBankStatus();
    } catch (err) {
      setIsLoading({ ...isLoading, loading: false });
    }
  };
  useEffect(() => {
    if (role.includes("merchant-bank-config")) {
      GetFetchBankStatus();
    }
  }, []);

  const dd = ["FEDERAL", "HDFC", "ICICI", "RBL"];
  return (
    <Modal isOpen={bankCofingModal} toggle={onCloseConfig} centered={true}>
      <Form>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Bank Config</h3>
          <i
            onClick={() => {
              onCloseConfig();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>

        <div className="modal-body w-100">
          {dd?.map((val) => {
            return (
              <div className="d-flex px-2">
                <Switch
                  name="HDFC"
                  checked={
                    bankStateFetchState?.data?.length &&
                    bankStateFetchState?.data?.findIndex(
                      (item) => item?.bank_name === val
                    ) > -1
                  }
                  disabled={
                    isLoading?.loading ||
                    !role.includes("merchant-bank-config-update")
                  }
                  size="small"
                  onChange={(e) =>
                    BankStatusUpdate({
                      BankName: val,
                      Value: e?.target?.checked ? 1 : 0,
                    })
                  }
                />
                <p>{val} IS DOWN</p>
              </div>
            );
          })}
          {isLoading?.loading && (
            <div className="loader-div">
              <Loader />
            </div>
          )}
        </div>
        <div className="modal-footer">
          Note : When UTR does not come into the bank then down those banks.
        </div>
      </Form>
    </Modal>
  );
};

export default BankConfig;
