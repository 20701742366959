import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SsTransaction, getCurrentSsTransaction, updateSsTransaction } from "../services/ssTransactionServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { Transaction_Filter_Data } from "../services/transactionServices";

const getCurrentSs = () =>
  JSON.parse(sessionStorage.getItem("currentSs") || "{}");
const initialState = {
  filter: false,
  currentSs: getCurrentSs(),
  ssTransactionState: [],
  ssTransactionFilterState: [],
  ssTransactionStateisLoading: true,
};

export const getSsTransaction = createAsyncThunk(
  "/ss-transaction",
  async (payload) => {
    return await SsTransaction(payload);
  }
);
export const Filter_Data_Transaction = createAsyncThunk(
  "/filtertransaction",
  async (payload) => {
    return await Transaction_Filter_Data(payload);
  }
);

export const currentTransactionGet = createAsyncThunk(
  "/currentTransactionGet",
  async (payload) => {
    return await getCurrentSsTransaction(payload);
  }
);

export const ssTransactionUpdate = createAsyncThunk(
  "/ssTransactionUpdate",
  async (payload) => {
    return await updateSsTransaction(payload);
  }
);

const ssTransactionSlice = createSlice({
  name: "ssTransactionSlice",
  initialState,
  reducers: {
    setSsTransactionFilter: (state, { payload }) => {
      state.filter = payload;
    },
    clearSsTransaction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSsTransaction.pending, (state, action) => {
      state.ssTransactionStateisLoading = true;
    });
    builder.addCase(getSsTransaction.fulfilled, (state, action) => {
      state.ssTransactionStateisLoading = false;
      state.ssTransactionState = action.payload;
    });
    builder.addCase(getSsTransaction.rejected, (state, action) => {
      state.ssTransactionStateisLoading = false;
    });
    builder.addCase(Filter_Data_Transaction.fulfilled, (state, action) => {
      state.ssTransactionFilterState = action.payload;
    });
  },
});

export default ssTransactionSlice.reducer;

export const { setSsTransactionFilter, setCurrentSs, clearSsTransaction } =
  ssTransactionSlice.actions;

export const selectSsTransaction = (state) => {
  return state.ssTransaction.ssTransactionState;
};
export const useSsTransaction = () => {
  const ssTransactionState = useSelector(selectSsTransaction);
  return useMemo(() => ssTransactionState , [ssTransactionState]);
};

export const selectCurrentSs = (state) => {
  return state.ssTransaction.currentSs;
};
