import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addProxy, getProxy } from "../services/proxyServices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  isLoading: true,
  data: [],
};

export const proxyGet = createAsyncThunk("/proxyGet", async (payload) => {
  return await getProxy(payload);
});

export const proxyAdd = createAsyncThunk("/proxyAdd", async (payload) => {
  return await addProxy(payload);
});

const proxySlice = createSlice({
  name: "proxySlice",
  initialState,
  reducers: {
    clearProxy: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(proxyGet.fulfilled, (state, action) => {
      state.data = action.payload?.data || []
    });
    
  },
});
export default proxySlice.reducer;
export const { clearProxy } = proxySlice.actions;

export const selectProxyData = (state) => {
  return state.proxy.data;
};
export const useProxyData = () => {
  const data = useSelector(selectProxyData);
  return useMemo(() => data, [data]);
};
