import React, { useState } from "react";
import Uploadicon from "../../../../assets/images/pages/upload-cloud.png";
import { Button, Form, Label, Modal } from "reactstrap";
import "./UploadStatusFileModal.scss";
import { Upload_Status_File } from "../../../../redux/slices/payinPayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { useRole } from "../../../../redux/slices/authSlice";

const UploadStatusFileModal = (props) => {
  const { onCloseUploadStatusFileModal, uploadStatusFileModal } = props;
  const role = useRole("Withdrawal Manual");
  const dispatch = useDispatch();

  const onClose = () => {
    reset();
    onCloseUploadStatusFileModal();
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const initialValues = {
    bankId: "",
    bankFile: "",
  };

  const { manualpayoutBankState } = useSelector((state) => state.payinPayout);

  const bankData = () => {
    const bankDataOption = manualpayoutBankState?.data
      ?.slice()
      ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
      ?.map((val) => ({
        value: `${val?.accountId}`,
        label: `${val?.bankName} - ${val?.note} -${val?.accountId} `,
      }));
    return bankDataOption;
  };

  const onSubmit = async () => {
    const values = getValues();
    const fileUpload = new FormData();
    fileUpload.append("bankId", values?.bankId);
    fileUpload.append("bankFile", values?.bankFile?.[0]);
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Upload_Status_File(fileUpload)).unwrap();
      responseToaster(res);
      setIsButtonLoading(false);
      if (!!res) {
        onClose();
      }
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, getValues, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={uploadStatusFileModal}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Upload Status File</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body w-100">
          <ControlledDropDown
            id="filter"
            name="bankId"
            placeholder="Select Bank Name"
            label="Bank Name"
            disabled={!role?.includes("add-withdrawal-manual")}
            errors
            options={bankData()}
            control={control}
          />

          <div className="select-div mt-1">
            <Label>Enter Withdraw Fees</Label>
            <div className="proof-sec text-center">
              <label className="label">
                <input
                  type="file"
                  name="bankFile"
                  onChange={(e) => setValue("bankFile", e?.target?.files)}
                />
                <p>
                  <img src={Uploadicon} className="me-2 img-fluid" />
                  <span
                    className={`${
                      watch("bankFile")?.[0]?.name ? "themecolor" : ""
                    }`}
                  >
                    {watch("bankFile")?.[0]?.name || "Upload File (xlsx)"}
                  </span>
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadStatusFileModal;
