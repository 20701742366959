import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Resend_Webhook_Transaction } from "../../../../redux/slices/transactionSlice";
import { Button, Modal } from "reactstrap";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const ResendWebhookModal = (props) => {
  const { isMarkOpen, resendWebhook, onCloseResendModal, onGet } = props;

  const dispatch = useDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const Resend_webhook_Transaction = async () => {
    try {
      setIsButtonLoading(true);
      let payload = { txnId: isMarkOpen };
      let response = await dispatch(
        Resend_Webhook_Transaction(payload)
      ).unwrap();
      responseToaster(response);
      if (!!response) {
        onCloseResendModal();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={resendWebhook} toggle={onCloseResendModal} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Resend Webhook</h3>
        <i onClick={onCloseResendModal} className="fa-solid fa-xmark"></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to resend webhook?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onCloseResendModal()}>
            Cancel
          </Button>
          <CustomeButton
          className="submitbutton"
            onClick={Resend_webhook_Transaction}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default ResendWebhookModal;
