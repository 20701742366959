import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resellerDepositDetailsGet,
  resellerWithdrawalDetailsGet,
  useResellerDepositDetails,
  useResellerWithdrawalDetails,
} from "../../../redux/slices/resellerSlice";
import { useParams } from "react-router-dom";
import { Button, Card, CardHeader, Form } from "reactstrap";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { useFormik } from "formik";
import { Table, TableCell, TableRow } from "../../../components/Table";
import SettingModal from "./SettingModal";
import ResellerResetPassword from "./ResellerResetPassword";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";
import Export from "../../../components/Custom/Elements/Export/Export";
import { DROPDOWN_ALL_VALUE, showToaster } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const depositColumns = [
  {
    title: "Date",
    name: "date",
  },
  {
    title: "Total Txn",
    name: "total_txn",
    Cell: (item) => {
      return <span>{seperator(item?.total_txn, false)}</span>;
    },
  },
  {
    title: "Total Deposit Amount",
    name: "total_payin_amount",
    type: "₹ ",
    Cell: (item) => {
      return <span>{seperator(item?.total_payin_amount)}</span>;
    },
  },
  {
    title: "Success Deposit Amount",
    name: "success_payin_amount",
    Cell: (item) => {
      return <span>{seperator(item?.success_payin_amount)}</span>;
    },
  },
  {
    title: "Commission Amount",
    name: "commission",
    Cell: (item) => {
      return <span>{seperator(item?.commission)}</span>;
    },
  },
];

const withdrawalColumns = [
  {
    title: "Date",
    name: "date",
  },
  {
    title: "Total Txn",
    name: "total_txn",
    Cell: (item) => {
      return <span>{seperator(item?.total_txn, false)}</span>;
    },
  },
  {
    title: "Total Withdrawal Amount",
    name: "total_payout_amount",
    type: "₹ ",
    Cell: (item) => {
      return <span>{seperator(item?.total_payout_amount)}</span>;
    },
  },
  {
    title: "Success Withdrawal Amount",
    name: "success_payout_amount",
    Cell: (item) => {
      return <span>{seperator(item?.success_payout_amount)}</span>;
    },
  },
  {
    title: "Commission Amount",
    name: "commission",
    Cell: (item) => {
      return <span>{seperator(item?.commission)}</span>;
    },
  },
];

const depositColumns2 = [
  {
    title: "Name",
    name: "merchant_name",
  },
  {
    title: "Total Txn",
    name: "total_txn",
    Cell: (item) => {
      return <span>{seperator(item?.total_txn, false)}</span>;
    },
  },
  {
    title: "Total Deposit Amount",
    name: "total_payin_amount",
    type: "₹ ",
    Cell: (item) => {
      return <span>{seperator(item?.total_payin_amount)}</span>;
    },
  },
  {
    title: "Success Deposit Amount",
    name: "success_payin_amount",
    Cell: (item) => {
      return <span>{seperator(item?.success_payin_amount)}</span>;
    },
  },
  {
    title: "Commission Amount",
    name: "commission",
    Cell: (item) => {
      return <span>{seperator(item?.commission)}</span>;
    },
  },
  {
    title: "Commission Per",
    name: "commission_per",
    Cell: (item) => {
      return <span>{onFixed(item?.commission_per)}%</span>;
    },
  },
];

const withdrawalColumns2 = [
  {
    title: "Name",
    name: "merchant_name",
  },
  {
    title: "Total Txn",
    name: "total_txn",
  },
  {
    title: "Total Withdrawal Amount",
    name: "total_payout_amount",
    type: "₹ ",
    Cell: (item) => {
      return <span>{seperator(item?.total_payout_amount)}</span>;
    },
  },
  {
    title: "Success Withdrawal Amount",
    name: "success_payout_amount",
    Cell: (item) => {
      return <span>{seperator(item?.success_payout_amount)}</span>;
    },
  },
  {
    title: "Commission Amount",
    name: "commission",
    Cell: (item) => {
      return <span>{seperator(item?.commission)}</span>;
    },
  },
  {
    title: "Commission Per",
    name: "commission_per",
    Cell: (item) => {
      return <span>{onFixed(item?.commission_per)}%</span>;
    },
  },
];

const getDefaultDates = () => {
  const date = new Date();
  return {
    startDate: new Date(date.setDate(date.getDate() - 7)),
    endDate: new Date(),
  };
};

const initialValues = {
  merchantId: DROPDOWN_ALL_VALUE,
  ...getDefaultDates(),
};
const ResellerDetails = () => {
  const [depositFilterColumns, setDepositFilterColumns] = useState([]);
  const [depositFilterColumns2, setDepositFilterColumns2] = useState([]);
  const [withdrawalFilterColumns, setWithdrawalFilterColumns] = useState([]);
  const [withdrawalFilterColumns2, setWithdrawalFilterColumns2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeposit, setIsDeposit] = useState(true);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [resellerResetPassword, setResellerResetPassword] = useState(false);
  const [lastFilter, setLastFilter] = useState(initialValues);
  const role = useRole("Reseller User");

  const dispatch = useDispatch();
  const params = useParams();
  const depositDetails = useResellerDepositDetails();
  const withdrawalDetails = useResellerWithdrawalDetails();

  const onSubmit = (values) => {
    setLastFilter({ ...values, isToast: true });
    onGetData();
  };

  const { handleSubmit, values, setValues, setFieldValue, resetForm } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const prepareMerchantOptions = () => {
    const data = isDeposit ? depositDetails : withdrawalDetails;
    const options = data?.merchantList
      ?.slice()
      ?.sort((a, b) =>
        a?.merchant_details?.merchant_name?.localeCompare(
          b?.merchant_details?.merchant_name
        )
      )
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchant_id,
          label: val?.merchant_details?.merchant_name,
        };
        return clientDataOption;
      });
    return options;
  };

  const onGetResellerDepositDetails = async () => {
    try {
      setIsLoading(true);
      const payload = {
        resellerId: params?.id,
        type: "payin",
        filter_data: {
          ...values,
          startDate: dateFormatter(values?.startDate, "start"),
          endDate: dateFormatter(values?.endDate, "end", values?.startDate),
        },
      };
      const res = await dispatch(resellerDepositDetailsGet(payload)).unwrap();
      if (lastFilter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetResellerWithdrawalDetails = async () => {
    try {
      setIsLoading(true);
      const payload = {
        resellerId: params?.id,
        type: "payout",
        filter_data: {
          ...values,
          startDate: dateFormatter(values?.startDate, "start"),
          endDate: dateFormatter(values?.endDate, "end", values?.startDate),
        },
      };
      const res = await dispatch(
        resellerWithdrawalDetailsGet(payload)
      ).unwrap();
      if (lastFilter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetData = () => {
    if (role.includes("reseller-list")) {
      if (isDeposit) {
        onGetResellerDepositDetails();
      } else {
        onGetResellerWithdrawalDetails();
      }
    }
  };

  const prepareDepositTotal = () => {
    const data = depositDetails?.data || [];

    let totalTxn = 0;
    let totalDeposit = 0;
    let totalSuccess = 0;
    let totalCommission = 0;

    Object.entries(data)?.forEach(([key, item]) => {
      totalTxn += item?.total_txn || 0;
      totalDeposit += item?.total_payin_amount || 0;
      totalSuccess += item?.success_payin_amount || 0;
      totalCommission += item?.commission || 0;
    });
    return (
      <>
        <tr className="lasttablerow">
          <TableCell>Total</TableCell>
          <TableCell>-</TableCell>
          <TableCell>{seperator(totalTxn, false)}</TableCell>
          <TableCell>{seperator(totalDeposit)}</TableCell>
          <TableCell>{seperator(totalSuccess)}</TableCell>
          <TableCell>{seperator(totalCommission)}</TableCell>
        </tr>
      </>
    );
  };
  const prepareWithdrawalTotal = () => {
    const data = withdrawalDetails?.data || [];

    let totalTxn = 0;
    let totalDeposit = 0;
    let totalSuccess = 0;
    let totalCommission = 0;

    Object.entries(data)?.forEach(([key, item]) => {
      totalTxn += item?.total_txn || 0;
      totalDeposit += item?.total_payout_amount || 0;
      totalSuccess += item?.success_payout_amount || 0;
      totalCommission += item?.commission || 0;
    });
    return (
      <>
        <tr className="lasttablerow">
          <TableCell>Total</TableCell>
          <TableCell>-</TableCell>
          <TableCell>{seperator(totalTxn, false)}</TableCell>
          <TableCell>{seperator(totalDeposit)}</TableCell>
          <TableCell>{seperator(totalSuccess)}</TableCell>
          <TableCell>{seperator(totalCommission)}</TableCell>
        </tr>
      </>
    );
  };

  const prepareCsvProps = () => {
    if (isDeposit) {
      const filename = "Reseller Deposit.csv";
      const data =
        Object.entries(depositDetails?.data || {})?.map(([key, item]) => {
          let inner = {};
          Object.entries(item?.data || {})?.forEach(([_, subItem]) => {
            Object.entries(subItem || {})?.forEach(([_, subItem2]) => {
              inner = { ...subItem2 };
            });
          });
          return {
            ...item,
            date: key,
            ...inner,
          };
        }) || [];
      const headers =
        depositColumns2?.map((val) => ({
          label: val?.title,
          key: val?.name,
        })) || [];
      headers.unshift({
        label: "Date",
        key: "date",
      });
      return {
        filename,
        data,
        headers,
      };
    } else {
      const filename = "Reseller Withdrawal.csv";
      const data =
        Object.entries(withdrawalDetails?.data || {})?.map(([key, item]) => {
          let inner = {};
          Object.entries(item?.data || {})?.forEach(([_, subItem]) => {
            Object.entries(subItem || {})?.forEach(([_, subItem2]) => {
              inner = { ...subItem2 };
            });
          });
          return {
            ...item,
            date: key,
            ...inner,
          };
        }) || [];
      const headers =
        withdrawalColumns2?.map((val) => ({
          label: val?.title,
          key: val?.name,
        })) || [];
      headers.unshift({
        label: "Date",
        key: "date",
      });
      return {
        filename,
        data,
        headers,
      };
    }
  };

  const isExportable = () =>
    values?.merchantId &&
    values?.merchantId !== DROPDOWN_ALL_VALUE &&
    lastFilter?.merchantId === values?.merchantId;

  useEffect(() => {
    onGetData();
  }, [isDeposit]);

  useEffect(() => {
    const newDepositColumns = depositColumns?.map((item) => item?.name);
    setDepositFilterColumns(newDepositColumns);
    const newDepositColumns2 = depositColumns2?.map((item) => item?.name);
    setDepositFilterColumns2(newDepositColumns2);

    const newWithdrawalColumns = withdrawalColumns?.map((item) => item?.name);
    setWithdrawalFilterColumns(newWithdrawalColumns);
    const newWithdrawalColumns2 = withdrawalColumns2?.map((item) => item?.name);
    setWithdrawalFilterColumns2(newWithdrawalColumns2);
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="gap">
            <BackIcon />
            {role.includes("reseller-deposit-list") ? <Button
              className={isDeposit ? "primary" : "filterbtn"}
              color={isDeposit ? "primary" : ""}
              onClick={() => setIsDeposit(true)}
            >
              Deposit
            </Button> : null}
            {role.includes("reseller-withdrawal-list") ? <Button
              className={!isDeposit ? "primary" : "filterbtn"}
              color={!isDeposit ? "primary" : ""}
              onClick={() => setIsDeposit(false)}
            >
              Withdrawal
            </Button> : null}
            {role.includes("reseller-setting") ? <Button
              className="filterbtn"
              color="primary"
              onClick={() => setIsSettingOpen(!isSettingOpen)}
            >
              Setting
            </Button> : null}
          </div>
          {role.includes("reset-reseller-password") ? <div>
            <Button
              className="filterbtn"
              color="primary"
              onClick={() => setResellerResetPassword(true)}
            >
              Reset Password
            </Button>
          </div> : null}
        </CardHeader>
        <Form onSubmit={handleSubmit}>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>Reseller {isDeposit ? "Deposit" : "Withdrawal"} Details</b>
                </h4>
              </div>
              <div className="d-flex align-items-end button-space">
                <div
                  className="d-flex my-xl-auto right-content align-items-center button-space "
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="mb-0">Merchant ID</p>
                  <DropDown
                    name="merchantId"
                    options={prepareMerchantOptions()}
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    value={values?.merchantId}
                    onChange={(e) => setFieldValue("merchantId", e.value)}
                  />
                  <div className="d-flex flex-column select-div">
                    <DatePicker
                      placeholder="Select Date"
                      maxDate={new Date()}
                      selectsRange
                      startDate={values?.startDate}
                      endDate={values?.endDate}
                      onChange={(selectedDate) =>
                        setValues({
                          ...values,
                          startDate: selectedDate?.[0],
                          endDate: selectedDate?.[1],
                        })
                      }
                    />
                  </div>
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  >
                    Clear
                  </Button>
                  <span
                    // onClick={() => showToaster("Please select client", "Error")}
                  >
                    <Export
                      {...prepareCsvProps()}
                      className={`btn btn-primary rounded ${isExportable() ? "" : "disabled opacity-50"
                        }`}
                    >
                      Export
                    </Export>
                  </span>
                </div>
              </div>
            </div>
          </CardHeader>
        </Form>
        {isDeposit ? (
          <Table
            columns={depositColumns}
            filterColumns={depositFilterColumns}
            isLoading={isLoading}
            isData={!!Object.entries(depositDetails?.data || {})?.length}
          >
            {Object.entries(depositDetails?.data || {})?.map(([key, item]) => {
              return (
                <TableRow
                  columns={depositColumns}
                  filterColumns={depositFilterColumns}
                  item={{ ...item, date: key }}
                >
                  <Table
                    columns={depositColumns2}
                    filterColumns={depositFilterColumns2}
                    isData={!!Object.entries(item?.data || {})?.length}
                    minHeight={50}
                    isExpandable={false}
                  >
                    {Object.entries(item?.data || {})?.map(
                      ([subKey, subItem]) => {
                        return (
                          <>
                            {Object.entries(subItem || {})?.map(
                              ([subKey2, subItem2]) => {
                                return (
                                  <>
                                    <TableRow
                                      columns={depositColumns2}
                                      filterColumns={depositFilterColumns2}
                                      item={{ ...subItem2, merchantId: subKey }}
                                      isExpandable={false}
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        );
                      }
                    )}
                  </Table>
                </TableRow>
              );
            })}
            {prepareDepositTotal()}
          </Table>
        ) : (
          <Table
            columns={withdrawalColumns}
            filterColumns={withdrawalFilterColumns}
            isLoading={isLoading}
            isData={!!Object.entries(withdrawalDetails?.data || {})?.length}
          >
            {Object.entries(withdrawalDetails?.data || {})?.map(
              ([key, item]) => {
                return (
                  <TableRow
                    columns={withdrawalColumns}
                    filterColumns={withdrawalFilterColumns}
                    item={{ ...item, date: key }}
                  >
                    <Table
                      columns={withdrawalColumns2}
                      filterColumns={withdrawalFilterColumns2}
                      isLoading={isLoading}
                      isData={!!Object.entries(item?.data || {})?.length}
                      minHeight={100}
                      isExpandable={false}
                    >
                      {Object.entries(item?.data || {})?.map(
                        ([subKey, subItem]) => {
                          return (
                            <>
                              {Object.entries(subItem || {})?.map(
                                ([subKe2y, subItem2]) => {
                                  return (
                                    <>
                                      <TableRow
                                        columns={withdrawalColumns2}
                                        filterColumns={withdrawalFilterColumns2}
                                        item={{
                                          ...subItem2,
                                          merchantId: subKey,
                                        }}
                                        isExpandable={false}
                                      />
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        }
                      )}
                    </Table>
                  </TableRow>
                );
              }
            )}
            {prepareWithdrawalTotal()}
          </Table>
        )}
      </Card>
      <SettingModal
        isOpen={isSettingOpen}
        onHide={() => setIsSettingOpen(!isSettingOpen)}
      />
      <ResellerResetPassword
        resellerResetPassword={resellerResetPassword}
        onCloseResellerResetPassword={() =>
          setResellerResetPassword(!resellerResetPassword)
        }
        id={params?.id}
      />
    </>
  );
};

export default ResellerDetails;
