import { ADD_BANK_TRANSACTION, AVAILABLE_BANK, BANK_TRANSACTION, BANK_TRANSACTION_DETAILS, MARK_AS_USED, MERGEUTR, UPDATE_MOBILE_NUMBER, UPDATE_PAYMENT, UPDATE_TRANSACTION } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Bank_Transaction = (payload) => {
    return apiInstance.post(BANK_TRANSACTION, payload);
  };
  
  export const Available_Bank = (payload) => {
    return apiInstance.post(AVAILABLE_BANK, payload);
  };
  export const Add_Bank_Transaction = (payload) => {
    return apiInstance.post(ADD_BANK_TRANSACTION, payload);
  };
  export const Merge_UTR = (payload) => {
    return apiInstance.post(MERGEUTR, payload);
  };
  
  export const Mark_As_Used = (payload) => {
    return apiInstance.post(MARK_AS_USED, payload);
  };

  export const Update_Payment = (payload) => {
    return apiInstance.post(UPDATE_PAYMENT, payload);
  };

  export const Update_Transaction = (payload) => {
    return apiInstance.post(UPDATE_TRANSACTION, payload);
  };  

  export const Update_Mobile_Number = (payload) => {
    return apiInstance.post(UPDATE_MOBILE_NUMBER, payload);
  };  

  export const Bank_Transaction_Details = (payload) => {
    return apiInstance.post(BANK_TRANSACTION_DETAILS, payload);
  };  