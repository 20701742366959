import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Upi_Bank_Success } from "../services/upiBankSuccessServices";

const initialState = {
  upiBankSuccessGetState: [],
  upiBankSuccessGetLoading: true,
};

export const Upi_Bank_Success_Get = createAsyncThunk(
  "GetPgPayinSummary",
  async (payload) => {
    return await Upi_Bank_Success(payload);
  }
);

const upiBankSuccessSlice = createSlice({
  name: "upiBankSuccessSlice",
  initialState,
  reducers: {
    clearBouncer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(Upi_Bank_Success_Get.pending, (state, action) => {
      state.upiBankSuccessGetLoading = true;
    });
    builder.addCase(Upi_Bank_Success_Get.fulfilled, (state, action) => {
      state.upiBankSuccessGetLoading = false;
      state.upiBankSuccessGetState = action.payload;
    });
    builder.addCase(Upi_Bank_Success_Get.rejected, (state, action) => {
      state.upiBankSuccessGetLoading = false;
    });
  },
});
export default upiBankSuccessSlice.reducer;

export const selectUpiBankSuccessGetState = (state) => {
  return state.upiBankSuccess.upiBankSuccessGetState;
};

export const useUpiBankSuccessGetState = () => {
  const upiBankSuccessGetState = useSelector(selectUpiBankSuccessGetState);
  return useMemo(() => upiBankSuccessGetState, [upiBankSuccessGetState]);
};
