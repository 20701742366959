import { BANKSATEMENT, BANK_UPLOAD, SHOW_ADD_UTR } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Statement = (payload) => {
  return apiInstance.post(BANKSATEMENT, payload);
};
export const Show_added_Utr = (payload) => {
  return apiInstance.post(SHOW_ADD_UTR, payload);
};

export const Merchant_upload_File = (payload) => {
  return apiInstance.post(BANK_UPLOAD, payload);
};
