import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PayoutReqResLogs } from "../services/payoutReqResLogsService";

const initialState = {
  payoutReqResLogsState: [],
  isLoading: true,
};
export const getPayoutReqResLogs = createAsyncThunk(
  "/payoutRequestResponseLogs",
  async (payload) => {
    return await PayoutReqResLogs(payload);
  }
);

const payoutReqResLogsSlice = createSlice({
  name: "payoutReqResLogsSlice",
  initialState,
  reducers: {
    clearPayoutReqResLogs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPayoutReqResLogs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPayoutReqResLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payoutReqResLogsState = action.payload;
    });
    builder.addCase(getPayoutReqResLogs.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default payoutReqResLogsSlice.reducer;
export const { clearPayoutReqResLogs } = payoutReqResLogsSlice.actions;

export const selectPayoutReqResLogs = (state) => {
  return state.payoutReqResLogs.payoutReqResLogsState;
};

export const usePayoutReqResLogs = () => {
  const payoutReqResLogsState = useSelector(selectPayoutReqResLogs);
  return useMemo(() => ({ payoutReqResLogsState }), [payoutReqResLogsState]);
};
