import React, { useEffect, useState } from "react";

const ScrollToTop = () => {
  const [scroll, setScroll] = useState(window.scrollY);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
      setHide(true);
      clearTimeout(timer);
      timer = setTimeout(() => setHide(false), 3000);
    };
    let timer = null;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {scroll > 0 && hide ? (
        <div className="scroll-to-top" onClick={handleScrollToTop}>
          <div className="scrollbtn">
            <i class="fa-solid fa-angle-up"></i>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ScrollToTop;
