import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PayinReqResLogs } from "../services/payinReqResLogsService";

const initialState = {
  payinReqResLogsState: [],
  isLoading: true,
};
export const getPayinReqResLogs = createAsyncThunk(
  "/payinRequestResponseLogs",
  async (payload) => {
    return await PayinReqResLogs(payload);
  }
);

const payinReqResLogsSlice = createSlice({
  name: "payinReqResLogsSlice",
  initialState,
  reducers: {
    clearPayinReqResLogs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPayinReqResLogs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPayinReqResLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payinReqResLogsState = action.payload;
    });
    builder.addCase(getPayinReqResLogs.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default payinReqResLogsSlice.reducer;
export const { clearPayinReqResLogs } = payinReqResLogsSlice.actions;

export const selectPayinReqResLogs = (state) => {
  return state.payinReqResLogs.payinReqResLogsState;
};

export const usePayinReqResLogs = () => {
  const payinReqResLogsState = useSelector(selectPayinReqResLogs);
  return useMemo(() => ({ payinReqResLogsState }), [payinReqResLogsState]);
};
