// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const ControlledToggleSwitch = (props) => {
    const { control, name, ...rest } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...restt } }) => {
                return <ToggleSwitch {...restt} {...rest} />;
            }}
        />
    );
};

export default ControlledToggleSwitch;
