import React from "react";
import { Button, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const MarkAsUsedModal = (props) => {
  const { id, onCancelClick, onDoneClick, isButtonLoading } = props;

  return (
    <Modal isOpen={!!id} toggle={onCancelClick} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Set As Used</h3>
        <i
          onClick={() => {
            onCancelClick();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to set used UTR ({id}) ?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onCancelClick}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={onDoneClick}
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default MarkAsUsedModal;
