import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getMailReader,
  updateMailReaderStatus,
} from "../services/mailReaderSrevices";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const initialState = {
  data: {},
};

export const mailReaderGet = createAsyncThunk(
  "mailReaderGet",
  async (payload) => {
    return await getMailReader(payload);
  }
);

export const mailReaderStatusUpdate = createAsyncThunk(
  "updateMailReaderStatus",
  async (payload) => {
    return await updateMailReaderStatus(payload);
  }
);

const mailReaderSlice = createSlice({
  name: "mailReaderSlice",
  initialState,
  reducers: {
    clearMailReader: () => initialState,
    setMailReader: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mailReaderGet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default mailReaderSlice.reducer;
export const { clearMailReader, setMailReader } = mailReaderSlice.actions;

export const selectMailReaderData = (state) => {
  return state.mailReader.data;
};
export const useMailReaderData = () => {
  const data = useSelector(selectMailReaderData);
  return useMemo(() => data, [data]);
};
