import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Label, Modal } from "reactstrap";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import Uploadicon from "../../../../assets/images/pages/upload-cloud.png";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { UploadExcel } from "../../../../redux/slices/bankParseSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";

const emailidOptions = [
  { value: "pluspe", label: "RBL BANK" },
  { value: "tmbank", label: "TM BANK" },
  { value: "sbi", label: "SBI BANK" },
  { value: "idfc", label: "IDFC BANK" },
];

const BankParseFileUpload = (props) => {
  const { isModalOpen, onCloseModal, isButtonLoading, setIsButtonLoading } =
    props;
  const dispatch = useDispatch();

  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm(
    {}
  );

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const onSubmit = async () => {
    const values = getValues();
    setIsButtonLoading(true);
    try {
      const fileUpload = new FormData();
      if (values?.excelFile) {
        fileUpload.append("excelFile", values?.excelFile);
      }
      fileUpload.append("selectedBank", values?.selectedBank);
      const res = await dispatch(UploadExcel(fileUpload)).unwrap();
      if (res) {
        responseToaster(res);
        onClose();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Bank Parse</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <ControlledDropDown
            name="selectedBank"
            label="Selected Bank"
            isDefaultOption={false}
            placeholder="Select Selected Bank"
            options={emailidOptions}
            control={control}
          />
          <div className="select-div mt-1">
            <Label>Upload File</Label>
            <div className="proof-sec text-center text-break">
              <label className="label">
                <input
                  id="excelFile"
                  type="file"
                  onChange={(e) => setValue("excelFile", e?.target?.files[0])}
                />
                <p>
                  <img src={Uploadicon} className="me-1 img-fluid" />
                  <span
                    className={`${
                      watch("excelFile")?.name ? "themecolor" : ""
                    }`}
                  >
                    {watch("excelFile")?.name || "Upload File (xlsx)"}
                  </span>
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isButtonLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default BankParseFileUpload;
