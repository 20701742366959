import MerchantPayInBankModal from "../../../components/Custom/Modals/MerchantPayInBankModal/MerchantPayInBankModal";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import MerchantPayOut from "./MerchantPayOut";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Merchant_Pay_In_Available_Payment_Get,
  Merchant_Pay_In_Bank_Update,
  Merchant_Pay_In_Get,
  useMerchantPayIn,
} from "../../../redux/slices/merchantSlice";
import MerchantPayIn from "./MerchantPayIn";
import MerchantForm from "./MerchantForm";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { responseToaster } from "../../../helperFunctions";
import MerchantPermission from "./MerchantPermission";
import Avatar from "../../../components/Custom/Elements/Avatar/Avatar";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";
import { prepareMerchantFormData } from "./merchantFuntions";
import { Merchant_Get } from "../../../redux/services/merchantServices";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const tabParam = new URLSearchParams(location.search).get("tab");
  const payinData = useMerchantPayIn();

  const [merchantData, setMerchantData] = useState();
  const [menu, setMenu] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpenBank, setIsOpenBank] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const role = useRole("Merchant");

  const tabsData = [
    ...(role.includes("merchant-transaction")
      ? [{ title: "Deposit", Component: MerchantPayIn }]
      : []),

    ...(role.includes("merchant-payout")
      ? [{ title: "Withdrawal", Component: MerchantPayOut }]
      : []),

    {
      title: "Setting",
      Component: MerchantForm,
    },
    ...(role.includes("merchant-permission")
      ? [
          {
            title: "Permission",
            Component: MerchantPermission,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setCurrentTab(Number(tabParam));
    // navigate(`/merchant/${params?.id}?tab=${tabParam || 0}`);
  }, [tabParam]);

  useEffect(() => {
    const handleEvent = () => {
      navigate("/merchant");
    };
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  }, []);

  const onGetMerchant = async () => {
    try {
      const payload = {
        filter_data: {
          merchantId: params?.id,
        },
        page_no: 1,
        limit: 1,
      };
      setIsLoading(true);
      const res = await Merchant_Get(payload);
      setIsLoading(false);
      setMerchantData(prepareMerchantFormData(res?.data?.[0]));
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetMerchant();
  }, []);

  useEffect(() => {
    document.title = merchantData?.merchantName
      ? `${merchantData?.merchantName} - ${
          tabsData?.find((item, index) => index === currentTab)?.title
        } ${currentTab === 0 || currentTab === 1 ? "Bank" : ""}`
      : "Merchant";
  }, [merchantData, currentTab]);

  const onGetMerchantPayIn = async (payload) => {
    try {
      await dispatch(
        Merchant_Pay_In_Get({ ...payload, merchantId: params?.id })
      ).unwrap();
    } catch (err) {}
  };

  const onGetMerchantAvailablePayment = async () => {
    try {
      const payload = {
        merchantId: params?.id,
      };
      await dispatch(Merchant_Pay_In_Available_Payment_Get(payload)).unwrap();
    } catch (err) {}
  };

  const onUpdateBank = async (payload) => {
    try {
      const res = await dispatch(Merchant_Pay_In_Bank_Update(payload)).unwrap();
      responseToaster(res);
    } catch (err) {}
  };

  return (
    <div>
      <Card>
        <CardHeader style={{ display: "unset" }}>
          <div className="d-flex  justify-content-between flex-wrap">
            <div className="d-flex flex-wrap button-space my-25">
              <BackIcon
                onClick={() => {
                  navigate("/merchant");
                }}
              />
              {tabsData?.map((tab, index) => (
                <>
                  <NavLink
                    key={index}
                    to={`/merchant/${params?.id}?tab=${index}`}
                  >
                    <Button
                      className={currentTab === index ? "primary" : "filterbtn"}
                      color={currentTab === index ? "primary" : ""}
                      onClick={(e) => setCurrentTab(index)}
                    >
                      {tab.title}
                    </Button>
                  </NavLink>
                </>
              ))}
            </div>

            <div className="allbank my-25">
              {/* {currentTab === 0 ? (
                <>
                  <Button
                    color="success"
                    onClick={async () => {
                      try {
                        await onUpdateBank({
                          merchantId: params?.id,
                          action: "ACTIVE",
                          bankName: "FEDERAL",
                        });
                        onGetMerchantPayIn();
                        onGetMerchantAvailablePayment();
                      } catch (err) { }
                    }}
                  >
                    Start All Fedral Bank
                  </Button>
                  <Button
                    color="danger"
                    onClick={async () => {
                      setIsOpenBank(true);
                    }}
                  >
                    Stop All Fedral Bank
                  </Button>
                </>
              ) : null} */}
              <div>
                <Dropdown
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="d-flex align-items-center"
                >
                  <DropdownToggle
                    className="btn p-0 header-item waves-effect d-flex align-items-center gap-50 avtrabtn"
                    id="page-header-user-dropdown "
                    tag="button"
                  >
                    <Avatar
                      style={{
                        color: " white",
                        backgroundColor: "var(--chatlook-darkblue--)",
                      }}
                      name={merchantData?.merchantName}
                      sx={{ width: 34, height: 34, fontSize: 14 }}
                    />
                    <b className="d-flex align-items-center gap-50">
                      {merchantData?.merchantName}{" "}
                      <span
                        className={`${
                          merchantData?.acStatus == "Approved"
                            ? "mostatus-green-light"
                            : "mostatus-orange-light"
                        }`}
                      ></span>
                      <i class="fa-solid fa-angle-down"></i>
                    </b>
                  </DropdownToggle>
                  <DropdownMenu className="z-3  merchnt-drop text-nowrap">
                    <li>
                      <b>Name :</b> {merchantData?.merchantName}
                    </li>
                    <li>
                      <b>Email :</b> {merchantData?.merchantEmail}
                    </li>
                    <li>
                      <b>ID :</b> {merchantData?.merchantId}
                    </li>
                    <li>
                      <b>Status :</b>{" "}
                      <span
                        className={`${
                          merchantData?.acStatus == "Approved"
                            ? "bg-textsuccess"
                            : "bg-textinitialized"
                        }`}
                      >
                        {merchantData?.acStatus}
                      </span>
                    </li>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <></>
            </div>
          </div>
        </CardHeader>
      </Card>

      {tabsData?.map((tab, index) => {
        if (currentTab === index) {
          const Component = tab.Component;
          return (
            <Component
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              merchantData={merchantData}
              setMerchantData={setMerchantData}
            />
          );
        }
      })}
      {/* {currentTab === 0 ? (
        <MerchantPayIn merchantData={merchantData} />
      ) : currentTab === 1 ? (
        <MerchantPayOut merchantData={merchantData} />
      ) : currentTab === 2 ? (
        <MerchantForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          merchantData={merchantData}
          setMerchantData={setMerchantData}
          permission={permission}
        />
      ) : currentTab === 3 ? (
        <MerchantPermission merchantData={merchantData} />
      ) : null} */}

      <MerchantPayInBankModal
        isOpen={isOpenBank}
        isButtonLoading={isButtonLoading}
        onCancelClick={() => setIsOpenBank(false)}
        onDoneClick={async () => {
          try {
            setIsButtonLoading(true);
            await onUpdateBank({
              merchantId: params?.id,
              action: "DEACTIVE",
              bankName: "FEDERAL",
            });
            setIsOpenBank(false);
            onGetMerchantPayIn();
            onGetMerchantAvailablePayment();
            setIsButtonLoading(false);
          } catch (err) {
            setIsOpenBank(false);
            setIsButtonLoading(false);
          }
        }}
      />
    </div>
  );
};

export default MerchantDetails;
