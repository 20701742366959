import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { addAddPg } from "../services/addPgServices";


const initialState = {
  isLoading: true,
  data: {},
};

export const addPgAdd = createAsyncThunk(
  "/addPgAdd",
  async (payload) => {
    return await addAddPg(payload);
  }
);

const addPgSlice = createSlice({
  name: "addPgSlice",
  initialState,
  reducers: {
    clearAddPg: () => initialState,
    setAddPg: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
  },
});
export default addPgSlice.reducer;
export const { clearAddPg, setAddPg } = addPgSlice.actions;

export const selectAddPgData = (state) => {
  return state.addPg.data;
};
export const useAddPgData = () => {
  const data = useSelector(selectAddPgData);
  return useMemo(() => data, [data]);
};
