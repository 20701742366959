import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import "./AddMerchantWhitelistIp.scss";
import { GetaddMerchantWhitelistIp } from "../../../../redux/slices/merchantSlice";
import { useParams } from "react-router-dom";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { responseToaster } from "../../../../helperFunctions";

const AddMerchantWhitelistIp = (props) => {
  const {
    onCloseAddMerchantWhitelistIp,
    merchant_White_list_Ips,
    addMerchantWhitelistIp,
  } = props;
  const [merchantIp, setMerchantIp] = useState("");
  const onClose = () => {
    onCloseAddMerchantWhitelistIp();
    reset();
    setMerchantIp("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  const initialValues = { merchantIp: null };

  const statusDataOptions = [{ value: "PAYOUT", label: "Payout" }];

  const onSubmit = async () => {
    const values = getValues();
    let payload = {
      ...values,
      merchantId: id,
    };
    try {
      setIsButtonLoading(true);
      const res = await dispatch(GetaddMerchantWhitelistIp(payload)).unwrap();
      responseToaster(res);
      if (!!res) {
        onClose();
        merchant_White_list_Ips();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const { control, setValue, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={addMerchantWhitelistIp}
      toggle={onClose}
      centered={true}
    >
      <div className="modal-header d-flex justify-content-between">
        <h3 className="modal-title mt-0">Add Merchant Whitelist</h3>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="select-div">
            <ControlledInput
              name="merchantIp"
              label="Merchant IP"
              className="w-100"
              type="text"
              value={merchantIp}
              placeholder="Enter Merchant IP"
              control={control}
              onChange={(e) => {
                if (/^[0-9.]*$/.test(e.target.value)) {
                  setMerchantIp(e.target?.value);
                  setValue("merchantIp", e.target?.value);
                }
              }}
            />
          </div>
          <div className="select-div">
            <ControlledDropDown
              name="type"
              label="Type"
              id="type"
              placeholder="Select Type"
              options={statusDataOptions}
              control={control}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button className="graybutton" onClick={() => onClose()}>
            Cancel
          </Button>
          <CustomeButton
            type="submit"
            className="submitbutton"
            isButtonLoading={isButtonLoading}
          >
            Submit
          </CustomeButton>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMerchantWhitelistIp;
