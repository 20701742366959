import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { Table, TableRow } from "../../../components/Table";
import { useForm } from "react-hook-form";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import {
  dateFormatter,
  onFixed,
  responseToaster,
} from "../../../helperFunctions";
import {
  getOptionTracking,
  getTracking,
  useTracking,
} from "../../../redux/slices/trackingSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { DROPDOWN_ALL_VALUE } from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const initialDate = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  return {
    date: [startDate, new Date()],
  };
};

const initialValues = {
  merchantId: DROPDOWN_ALL_VALUE,
  isFilter: false,
  ...initialDate(),
};

const Tracking = (props) => {
  const { bankOptions } = props;

  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(initialValues);
  const dispatch = useDispatch();
  const trackingState = useTracking();
  const { trackingOptionState, trackingIsLoading } = useSelector(
    (state) => state.tracking
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const role = useRole("Tracking");

  const columns = [
    {
      title: "DATE",
      name: "txn_date",
      Cell: (data) => {
        const year = data?.txn_date?.slice(0, 4);
        const month = data?.txn_date?.slice(5, 7);
        const date = data?.txn_date?.slice(8, 10);
        return (
          <>
            <span>{`${date}-${month}-${year}`}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS",
      name: "success",
      Cell: (data) => {
        return (
          <>
            {data?.success_sum != 0 ? (
              <div className="d-flex align-items-center">
                <b>{data?.success_sum}</b>~
                <span>{`(${onFixed(data?.success_percentage)}%)`}</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">-</div>
            )}
          </>
        );
      },
    },
    {
      title: "PROCESSING",
      name: "processing",
      Cell: (data) => {
        return (
          <>
            {data?.processing_sum != 0 ? (
              <div className="d-flex align-items-center">
                <b>{data?.processing_sum}</b>~
                <span>{`(${onFixed(data?.processing_percentage)}%)`}</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">-</div>
            )}
          </>
        );
      },
    },
    {
      title: "PENDING",
      name: "pending",
      Cell: (data) => {
        return (
          <>
            {data?.pending_sum != 0 ? (
              <div className="d-flex align-items-center">
                <b>{data?.pending_sum}</b>~
                <span>{`(${onFixed(data?.pending_percentage)}%)`}</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">-</div>
            )}
          </>
        );
      },
    },
    {
      title: "INTIALIZED",
      name: "Initialized",
      Cell: (data) => {
        return (
          <>
            {data?.initialized_sum != 0 ? (
              <div className="d-flex align-items-center">
                <b>{data?.initialized_sum}</b>~
                <span>{`(${onFixed(data?.initialized_percentage)}%)`}</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">-</div>
            )}
          </>
        );
      },
    },
    {
      title: "FAIL",
      name: "Failed",
      Cell: (data) => {
        return (
          <>
            {data?.failed_sum != 0 ? (
              <div className="d-flex align-items-center">
                <b>{data?.failed_sum}</b>~
                <span>{`(${onFixed(data?.failed_percentage)}%)`}</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">-</div>
            )}
          </>
        );
      },
    },
  ];

  const onGetTracking = async () => {
    try {
      const { isFilter, date, merchantId, ...rest } = filter;
      const payload = {
        startDate: dateFormatter(date?.[0], "start"),
        endDate: dateFormatter(date?.[1], "end", date?.[0]),
        ...{ metaId: merchantId },
        ...rest,
      };
      const res = await dispatch(getTracking(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const getClientOptions = () => {
    const options = trackingOptionState?.merchantList
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.merchant_id,
        label: val?.merchant_name,
      }));
    return bankOptions ? bankOptions : options;
  };

  const onOptionTracking = async () => {
    try {
      await dispatch(getOptionTracking()).unwrap();
    } catch (err) {}
  };

  const onSubmit = () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };
  useEffect(() => {
    if (role.includes("summary-list")) {
      onOptionTracking();
    }
  }, []);

  useEffect(() => {
    onGetTracking();
  }, [filter]);

  const maxDate = () => {
    if (!filter?.startDate || (filter?.startDate && filter?.endDate)) {
      return new Date();
    }
    const d = new Date(filter?.startDate);
    d.setDate(d.getDate() + 30);
    return d > new Date() ? new Date() : d;
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("summary-list") ? (
        <>
          <div className="payoutmanual-main-div">
            <Card>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CardHeader>
                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div>
                      <h4 className="main-title">
                        <b>Tracking</b>
                      </h4>
                    </div>
                    <div className="d-flex align-items-end button-space">
                      <div
                        className="d-flex my-xl-auto right-content align-items-center button-space "
                        style={{ flexWrap: "wrap" }}
                      >
                        <p className="mb-0">
                          {bankOptions ? "Bank" : "Merchant ID"}
                        </p>
                        <ControlledDropDown
                          id="merchant_id"
                          name="merchantId"
                          isDefaultOption={DROPDOWN_ALL_VALUE}
                          options={getClientOptions()}
                          control={control}
                        />
                        <div className="d-flex flex-column select-div">
                          <ControlledDatePicker
                            name="date"
                            placeholder="Select Date"
                            maxDate={maxDate()}
                            selectsRange
                            control={control}
                          />
                        </div>
                        <Button type="submit" color="primary">
                          Apply
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            setFilter({
                              ...initialValues,
                            });
                            reset();
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                        <DropdownToggle
                          className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                          id="page-header-user-dropdown"
                          tag="button"
                          title="Column Filter"
                          type="button"
                        >
                          <IoFilterOutline fill="#7367F0" />
                        </DropdownToggle>
                        <DropdownMenu
                          className="z-3"
                          style={{
                            whiteSpace: "nowrap",
                            padding: 20,
                            transform: "translate(-20px, 22px)",
                          }}
                        >
                          <li className="z-3 text-dark">
                            <span>
                              <i className="fa-solid fa-filter"></i> Filter
                              Column
                            </span>
                          </li>
                          <div className="border-bottom border-light mt-2">
                            {columns?.map((column) => {
                              return (
                                <div className="d-flex justify-content-between">
                                  <p>{column?.title}</p>
                                  <Switch
                                    checked={filterColumns?.includes(
                                      column?.name
                                    )}
                                    size="small"
                                    onChange={(e) => {
                                      const newData = [...filterColumns];
                                      const index = newData?.indexOf(
                                        column?.name
                                      );
                                      if (index > -1) {
                                        newData.splice(index, 1);
                                      } else {
                                        newData.push(column?.name);
                                      }
                                      setFilterColumns(newData);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </CardHeader>
              </Form>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={trackingIsLoading}
                data={trackingState?.data || []}
                isData={!!trackingState?.data?.length}
                isExpandable={false}
              >
                {trackingState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      filterColumns={filterColumns}
                      item={item}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Tracking;
