import {
  PG,
  PG_ADD,
  PG_UPDATE_MAX_LIMIT,
  PG_UPDATE_MIN_LIMIT,
  PG_UPDATE_PAYMENT_MAX_COUNT_LIMIT,
  PG_UPDATE_PAYMENT_METHOD,
  PG_UPDATE_PAYMENT_TURNOVER,
  PG_UPDATE_PRODUCT_INFO,
  PG_UPDATE_STATUS,
  PG_TEST_PAYMENT,
  PG_EMPTY_BANK,
  PG_UPDATE_UPI,
  PG_UPDATE_AUTO_LOGIN,
  PG_UPDATE_BANK,
  PG_UPDATE_USER,
  PG_UPDATE_BALANCE,
  PG_UPDATE_REMAIN_LIMIT,
  PG_UPDATE_PROXY_INFO,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(`${PG}/${pgType}/${pgName}`, payload);
};

export const updateStatusPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(`${PG_UPDATE_STATUS}/${pgType}/${pgName}`, payload);
};
export const updateMinLimitPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_MIN_LIMIT}/${pgType}/${pgName}`,
    payload
  );
};
export const updateMaxLimitPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_MAX_LIMIT}/${pgType}/${pgName}`,
    payload
  );
};
export const updateProductInfoPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_PRODUCT_INFO}/${pgType}/${pgName}`,
    payload
  );
};
export const updateBalancePg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_BALANCE}/${pgType}/${pgName}`,
    payload
  );
};
export const updatePaymentMethodPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_PAYMENT_METHOD}/${pgType}/${pgName}`,
    payload
  );
};
export const updateUpdateProxyInfo = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_PROXY_INFO}/${pgType}/${pgName}`,
    payload
  );
};
export const updatePaymentTurnOverPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_PAYMENT_TURNOVER}/${pgType}/${pgName}`,
    payload
  );
};
export const updateRemainLimitPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_REMAIN_LIMIT}/${pgType}/${pgName}`,
    payload
  );
};
export const updatePaymentMaxCountLimitPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_PAYMENT_MAX_COUNT_LIMIT}/${pgType}/${pgName}`,
    payload
  );
};

export const updateUpiPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(`${PG_UPDATE_UPI}/${pgType}/${pgName}`, payload);
};

export const updateAutoLoginPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(
    `${PG_UPDATE_AUTO_LOGIN}/${pgType}/${pgName}`,
    payload
  );
};

export const addPg = ( payload) => {
  return apiInstance.post(`${PG_ADD}`, payload);
};

export const testPaymentPg = ({ pgName, pgType }, payload) => {
  return apiInstance.post(`${PG_TEST_PAYMENT}/${pgType}/${pgName}`, payload);
};

export const emptyBankPg = (payload) => {
  return apiInstance.post(`${PG_EMPTY_BANK}`, payload);
};

export const updateBankPg = ( payload) => {
  return apiInstance.post(`${PG_UPDATE_BANK}`, payload);
};
export const updateBankUser = ( payload) => {
  return apiInstance.post(`${PG_UPDATE_USER}`, payload);
};