import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import {
  GetCustomersDetails,
  blockCustomerByHId,
} from "../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import { Table, TableRow } from "../../../components/Table";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const CustomerDetails = (props) => {
  const { onHide, isOpen, payload } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [blockCusLoading, setBlockCusLoading] = useState(false);
  const [cusData, setCusData] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const role = useRole("Deposit");
  const dispatch = useDispatch();

  const onClose = () => {
    onHide();
  };
  const columns = [
    {
      title: "ORDER ID",
      name: "merchant_order_id",
    },
    {
      title: "CUSTOMER ID",
      name: "customer_id",
    },
    {
      title: "MERCHANT NAME",
      name: "merchant_name",
      Cell: (data) => {
        return <div>{data?.merchant_details?.merchant_name}</div>;
      },
    },
    /*  {
       title: "CUSTOMER EMAIL",
       name: "customer_email",
     },
     {
       title: "CUSTOMER MOBILE",
       name: "acNumbercustomer_mobile",
     }, */
    {
      title: "DATE",
      name: "created_at_ist",
    },
    {
      title: "DEPOSIT STATUS",
      name: "payment_status",
    },
  ];
  const onNewColums = () => {
    const newColumns = columns?.map((item) => item?.name);
    if (newColumns) {
      setFilterColumns(newColumns);
    }
  };

  useEffect(() => {
    onNewColums();
  }, []);
  const onGetCustomersDetails = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(
        GetCustomersDetails({ browserId: payload })
      ).unwrap();
      if (res) {
        setCusData(res?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const blockByHID = async () => {
    try {
      setBlockCusLoading(true);
      const res = await dispatch(
        blockCustomerByHId({ browserId: payload })
      ).unwrap();
      setBlockCusLoading(false);
      responseToaster(res);
    } catch (error) {
      setBlockCusLoading(false);
    }
  };
  useEffect(() => {
    if (isOpen && role.includes("deposit-list")) {
      onGetCustomersDetails();
    }
  }, [isOpen]);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
    >
      <div className="max-h-modal">
        <div className="modal-header">
          <h3 className="modal-title mt-0">Customer Details</h3>
          <div className="d-flex gap-1 align-items-center">
            {role?.includes("deposit-block-customer") ? <CustomeButton
              color="danger"
              onClick={() => {
                blockByHID();
              }}
              isButtonLoading={blockCusLoading}
            >
              Block All Details
            </CustomeButton> : null}
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
        </div>
        <div className="modal-body">
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading}
            data={cusData || []}
            isData={cusData?.length > 0}
            count={cusData?.length || 0}
            isExpandable={false}
            className="mb-0 p-0"
          >
            {cusData?.map((item) => {
              return (
                <TableRow
                  columns={columns}
                  filterColumns={filterColumns}
                  item={item}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerDetails;
