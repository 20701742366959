import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { Payout_Daily_Limit_Update } from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import React from "react";
import { Button } from "reactstrap";
import { onFixed, responseToaster, seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const MerchantPayOutAmount = ({
  updateDetails,
  setUpdateDetails,
  data,
  onGetMerchantPayOut,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const role = useRole("Merchant");
  // const [isLoading, setIsLoading] = useState(false);

  const getPercentage = () => {
    const per =
      ((data?.present_revenue || 0) / (data?.daily_threshold || 0)) * 100 || 0;
    return per !== Infinity ? onFixed(per) : 0;
  };

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const payload = {
        merchantId: data?.merchantId,
        pgName: data?.pgName,
        id: data?.id,
        pk: data?.pgId,
        value: updateDetails?.value,
      };
      const res = await dispatch(Payout_Daily_Limit_Update(payload)).unwrap();
      setIsLoading(false);
      responseToaster(res);
      await onGetMerchantPayOut();
      setUpdateDetails({});
    } catch (err) {
      setIsLoading(false);
    }
  };
  const perTurnOver =
    (+data?.present_revenue / +data?.daily_threshold) * 100 || 0;

  return (
    <>
      <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
        <div className="order-singal-div">
          <div>
            <span>Current Bal : </span>
            {`${seperator(data?.present_balance)}`}
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Current TurnOver : </span>
            <span className={perTurnOver > 79 ? "text-red" : ""}>
              {`${seperator(data?.present_revenue)}`}
            </span>
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Daily Limit : </span>
            {updateDetails.input === "daily" &&
            updateDetails?.id === data?.id ? (
              <form className="d-flex tableinput" onSubmit={onUpdate}>
                <InputField
                  autoFocus
                  value={updateDetails?.value}
                  placeholder="Enter Daily Limit"
                  onChange={(e) =>
                    setUpdateDetails({
                      ...updateDetails,
                      value: e.target.value,
                    })
                  }
                />
                <Button type="submit" color="primary" className="btn-smallsize">
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setUpdateDetails({})}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </form>
            ) : (
              <span
                className={`cursor-pointer`}
                style={{ color: "var(--chatlook-darkblue--)" }}
                onClick={() => {
                  if (!role.includes("merchant-payout-update")) {
                    return;
                  }
                  setUpdateDetails({
                    id: data?.id,
                    input: "daily",
                    value: data?.daily_threshold,
                  });
                }}
              >
                {`${seperator(data?.daily_threshold)}`}
              </span>
            )}
          </div>
        </div>
        <div className="order-singal-div">
          <div>
            <span>Use Limit Per: </span>
            <span className="cursor-pointer">{getPercentage()}%</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantPayOutAmount;
