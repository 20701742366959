import {
  ADD_PAYOUT_WHITELIST_CLIENT,
  EDIT_PAYOUT_WHITELISTCLIENT_LIMIT,
  GET_PAYOUT_WHITELIST_CLIENT,
  UPDATE_PAYOUT_WHITELISTCLIENT_STATUS,
  UPDATE_PAYOUT_WHITELISTCLIENT_STATUS_MANUAL,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Get_Payout_Whitelist_Client = () => {
  return apiInstance.post(GET_PAYOUT_WHITELIST_CLIENT);
};
export const Update_Payout_Whitelist_Status = (payload) => {
  return apiInstance.post(UPDATE_PAYOUT_WHITELISTCLIENT_STATUS, payload);
};

export const Update_Payout_Whitelist_Status_Manual = (payload) => {
  return apiInstance.post(UPDATE_PAYOUT_WHITELISTCLIENT_STATUS_MANUAL, payload);
};

export const Edit_Payout_WhiteList_Limit = (payload) => {
  return apiInstance.post(EDIT_PAYOUT_WHITELISTCLIENT_LIMIT, payload);
};
export const Add_Payout_Whitelist_Client = (payload) => {
  return apiInstance.post(ADD_PAYOUT_WHITELIST_CLIENT, payload);
};
