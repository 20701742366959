import React, { Fragment, useEffect } from "react";

// ** Router Import
import { prepareBlobUrl, setLocalData } from "./helperFunctions";
import AllRoutes from "./routes/allRoutes";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { restricForFingerPrintUrls } from "./constant";

const App = () => {
  const logo = process.env.REACT_APP_FAVICON;

  const { data } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  useEffect(() => {
    if (
      !restricForFingerPrintUrls?.find((item) =>
        window.location.hostname?.includes(item)
      )
    ) {
      if (data?.visitorId) {
        setLocalData("b", { visitorId: data?.visitorId }, {});
      }
    }
  }, [data]);

  return (
    <Fragment>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={prepareBlobUrl(logo)} />
      </Helmet>
      <AllRoutes /> 
      <Toaster />
    </Fragment>
  );
};

export default App;
