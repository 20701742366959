import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getFedConfigBanks, fedConfigBankStatusUpdate, fedConfigBankClaimCheckUpdate } from "../services/fedConfigBankService";

const initialState = {
  data: {},
};

export const fedConfigBankGet = createAsyncThunk("/fedConfigBank", async (payload) => {
  return await getFedConfigBanks(payload);
});
export const updateFedConfigBankStatus = createAsyncThunk(
  "/updateFedConfigBankStatus", 
  async (payload) => {
    return await fedConfigBankStatusUpdate(payload);
  }
);

export const updateFedConfigBankClaimCheck = createAsyncThunk(
  "/updateFedConfigBankClaimCheck", 
  async (payload) => {
    return await fedConfigBankClaimCheckUpdate(payload);
  }
);

const fedConfigBankSlice = createSlice({
  name: "fedConfigBankSlice",
  initialState,
  reducers: {
    clearFedConfigBank: () => initialState,
    setFedConfigBank: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fedConfigBankGet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
});
export default fedConfigBankSlice.reducer;
export const { clearFedConfigBank, setFedConfigBank } = fedConfigBankSlice.actions;

export const selectFedConfigBankData = (state) => {
  return state.fedConfigBank.data;
};
export const useFedConfigBankData = () => {
  const data = useSelector(selectFedConfigBankData);
  return useMemo(() => data, [data]);
};
