import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Add_Manual_Payin,
  Add_Manual_Payout,
  Get_Merchant_Pay_In_Available_Payment,
  Get_Merchant_Pay_In,
  Merchant_Get,
  Reset_Merchant_Account_Password,
  View_Dashboard_Logs,
  View_Merchant_White_list_Ips,
  View_Statement,
  Get_Merchant_Pay_Out,
  Get_Merchant_Pay_Out_Available_Payment,
  Update_Merchant_Pay_Out_Status,
  Add_Merchant_Pay_Out_Meta,
  Add_Merchant_Pay_In_Meta,
  Update_Merchant_Pay_In_Level,
  Update_Merchant_Pay_In_Bank,
  Update_Merchant_Pay_In_Status,
  Delete_Merchant_Pay_In,
  Update_Merchant_Details,
  Merchant_Add,
  Fetch_Bank_Status,
  Update_Bank_Status,
  Delete_Merchant_Pay_Out,
  Update_Merchant_Pay_In_Min_Limit,
  Update_Merchant_Pay_In_Max_Limit,
  Update_Merchant_Pay_In_Daily_Limit,
  Update_Merchant_Pay_In_Per_Limit,
  Merchant_UpdatePermission,
  Get_Merchant_Permission,
  Get_Pending_Settlement,
  Add_Merchant_Settlement,
  Update_Merchant_Status,
  Get_Merchant_Module,
  Add_Merchant_WhitelistIp,
  Add_Merchant_Central_Bank,
  Get_Merchant_Central_Bank,
  Add_Merchant_Pay_In_Central_Bank,
  Add_Merchant_Pay_Out_Central_Bank,
  Add_Merchant_Pay_In_Pay_Out_Central_Bank,
  Add_Merchant_Table_Central_Bank,
  Update_Payout_Daily_Limit,
  Update_Merchant_Pay_In_Payment_Methods,
  UpdateMerchantPayInPaymentLevel,
  Update_Merchant_White_ListIps_Status,
  Add_Central_Bank_Merchant,
  MerchantCheckoutGet,
  MerchantCheckoutUpdate,
  updateMerchantMinMaxLimit,
  Update_Merchant_IsFlow,
  updatemerchantMinAmount,
  updatemerchantMaxAmount,
  Update_Merchant_IsDesktopEnable,
  Update_Merchant_Pay_Out_InUse_Status,
} from "../services/merchantServices";

const initialState = {
  merchantState: [],
  merchantStateisLoading: true,
  merchantCheckoutStateisLoading: true,
  bankStateFetchState: [],
  viewStatementState: [],
  viewStatementStateLoading: true,
  viewDashboardLogs: [],
  viewDashboardLogsLoading: true,
  viewMerchantWhitelistIpsState: [],
  viewMerchantWhitelistIpsStateLoading: true,
  merchantPayInState: {},
  merchantPayInStateLoading: true,
  merchantPayInAvailablePaymentState: [],
  merchantPayInAvailablePaymentStateLoading: true,
  merchantPayOutState: {},
  merchantPayOutStateLoading: true,
  merchantPayOutAvailablePaymentState: [],
  merchantPayOutAvailablePaymentStateLoading: true,
  merchantPermissionStateLoading: true,
  merchantPermissionState: [],
  merchantPendingSettlementState: [],
  merchantPendingSettlementIsLoading: [],
  merchantModuleState: [],
  merchantModuleLoading: false,
  addMerchantWhitelistIpState: [],
  addMerchantWhitelistIpLoading: false,
  // merchantCentralBankState:[]
};

export const GetMerchant = createAsyncThunk("/getmerchant", async (payload) => {
  return await Merchant_Get(payload);
});

export const GetMerchantCheckout = createAsyncThunk(
  "/getmerchantcheckout",
  async (payload) => {
    return await MerchantCheckoutGet(payload);
  }
);

export const UpdateMerchantCheckout = createAsyncThunk(
  "/updatemerchantcheckout",
  async (payload) => {
    return await MerchantCheckoutUpdate(payload);
  }
);

export const add_Manual_Payout = createAsyncThunk(
  "/Add_Manual_Payout",
  async (payload) => {
    return await Add_Manual_Payout(payload);
  }
);
export const add_Manual_Payin = createAsyncThunk(
  "/Add_Manual_Payin",
  async (payload) => {
    return await Add_Manual_Payin(payload);
  }
);
export const view_Statement = createAsyncThunk(
  "/View_Statement",
  async (payload) => {
    return await View_Statement(payload);
  }
);

export const view_Dashboard_Logs = createAsyncThunk(
  "/View_Dashboard_Logs",
  async (payload) => {
    return await View_Dashboard_Logs(payload);
  }
);
export const view_Merchant_White_list_Ips = createAsyncThunk(
  "/View_Merchant_White_list_Ips",
  async (payload) => {
    return await View_Merchant_White_list_Ips(payload);
  }
);
export const reset_Merchant_Account_Password = createAsyncThunk(
  "/Reset_Merchant_Account_Password",
  async (payload) => {
    return await Reset_Merchant_Account_Password(payload);
  }
);

export const Merchant_Details_Update = createAsyncThunk(
  "/Merchant_Details_Update",
  async (payload) => {
    return await Update_Merchant_Details(payload);
  }
);

export const Merchant_IsFlow_Update = createAsyncThunk(
  "/Merchant_Details_Update",
  async (payload) => {
    return await Update_Merchant_IsFlow(payload);
  }
);

export const Merchant_IsDesktopEnable_Update = createAsyncThunk(
  "/Merchant_Details_Update",
  async (payload) => {
    return await Update_Merchant_IsDesktopEnable(payload);
  }
);

export const Add_Merchant = createAsyncThunk(
  "/merchant_add",
  async (payload) => {
    return await Merchant_Add(payload);
  }
);

export const Bank_Status_Fetch = createAsyncThunk(
  "/Fetch_Bank_Status",
  async (payload) => {
    return await Fetch_Bank_Status(payload);
  }
);
export const Bank_Status_Update = createAsyncThunk(
  "/Update_Bank_Status",
  async (payload) => {
    return await Update_Bank_Status(payload);
  }
);
export const Merchant_Permission_Get = createAsyncThunk(
  "/Get_Merchant_Permission",
  async (payload) => {
    return await Get_Merchant_Permission(payload);
  }
);
export const Update_Merchant_Permission = createAsyncThunk(
  "/Update_Merchant_Permission",
  async (payload) => {
    return await Merchant_UpdatePermission(payload);
  }
);
export const pending_Settlement_Get = createAsyncThunk(
  "/Get_Pending_Settlement",
  async (payload) => {
    return await Get_Pending_Settlement(payload);
  }
);
export const merchant_Settlement_Add = createAsyncThunk(
  "/Update_Merchant_Permission",
  async (payload) => {
    return await Add_Merchant_Settlement(payload);
  }
);

export const merchant_Status_Update = createAsyncThunk(
  "/merchantStatusUpdate",
  async (payload) => {
    return await Update_Merchant_Status(payload);
  }
);

export const Merchant_Module_Get = createAsyncThunk(
  "/Get_Merchant_Module",
  async (payload) => {
    return await Get_Merchant_Module(payload);
  }
);
export const GetaddMerchantWhitelistIp = createAsyncThunk(
  "/GetaddMerchantWhitelistIp",
  async (payload) => {
    return await Add_Merchant_WhitelistIp(payload);
  }
);

export const Merchant_Central_Bank_Add = createAsyncThunk(
  "/Merchant_Central_Bank_Add",
  async (payload) => {
    return await Add_Merchant_Central_Bank(payload);
  }
);
export const Merchant_Central_Bank_Get = createAsyncThunk(
  "/Merchant_Central_Bank_Get",
  async (payload) => {
    return await Get_Merchant_Central_Bank(payload);
  }
);
export const Merchant_Pay_In_Pay_Out_Central_Bank_Add = createAsyncThunk(
  "/Merchant_Pay_In_Pay_Out_Central_Bank_Add",
  async (payload) => {
    return await Add_Merchant_Pay_In_Pay_Out_Central_Bank(payload);
  }
);
export const Merchant_Table_Central_Bank_Add = createAsyncThunk(
  "/Merchant_Table_Central_Bank_Add",
  async (payload) => {
    return await Add_Merchant_Table_Central_Bank(payload);
  }
);
export const Payout_Daily_Limit_Update = createAsyncThunk(
  "/Update_Payout_Daily_Limit",
  async (payload) => {
    return await Update_Payout_Daily_Limit(payload);
  }
);
export const Central_Bank_Merchant_Add = createAsyncThunk(
  "/Add_Central_Bank_Merchant",
  async (payload) => {
    return await Add_Central_Bank_Merchant(payload);
  }
);
export const merchantMinMaxLimitUpdate = createAsyncThunk(
  "/merchantMinMaxLimitUpdate",
  async (payload) => {
    return await updateMerchantMinMaxLimit(payload);
  }
);

export const merchantMinAmountUpdate = createAsyncThunk(
  "/merchantMinAmountUpdate",
  async (payload) => {
    return await updatemerchantMinAmount(payload);
  }
);
export const merchantMaxAmountUpdate = createAsyncThunk(
  "/merchantMaxAmountUpdate",
  async (payload) => {
    return await updatemerchantMaxAmount(payload);
  }
);

// PAY IN
export const Merchant_Pay_In_Get = createAsyncThunk(
  "/Merchant_Pay_In_Get",
  async (payload) => {
    return await Get_Merchant_Pay_In(payload);
  }
);

export const Merchant_Pay_In_Available_Payment_Get = createAsyncThunk(
  "/Merchant_Pay_In_Available_Payment_Get",
  async (payload) => {
    return await Get_Merchant_Pay_In_Available_Payment(payload);
  }
);

export const Merchant_Pay_In_Meta_Add = createAsyncThunk(
  "/Merchant_Pay_In_Meta_Add",
  async (payload) => {
    return await Add_Merchant_Pay_In_Meta(payload);
  }
);

export const Merchant_Pay_In_Level_Update = createAsyncThunk(
  "/Merchant_Pay_In_Level_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Level(payload);
  }
);

export const Merchant_Pay_In_Bank_Update = createAsyncThunk(
  "/Merchant_Pay_In_Bank_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Bank(payload);
  }
);

export const Merchant_Pay_In_Status_Update = createAsyncThunk(
  "/Merchant_Pay_In_Status_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Status(payload);
  }
);

export const Merchant_Pay_In_Delete = createAsyncThunk(
  "/Merchant_Pay_In_Delete",
  async (payload) => {
    return await Delete_Merchant_Pay_In(payload);
  }
);

export const Merchant_Pay_Out_Delete = createAsyncThunk(
  "/Merchant_Pay_Out_Delete",
  async (payload) => {
    return await Delete_Merchant_Pay_Out(payload);
  }
);
export const Merchant_Pay_In_Min_Limit_Update = createAsyncThunk(
  "/Merchant_Pay_In_Min_Limit_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Min_Limit(payload);
  }
);
export const Merchant_Pay_In_Max_Limit_Update = createAsyncThunk(
  "/Merchant_Pay_In_Max_Limit_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Max_Limit(payload);
  }
);
export const Merchant_Pay_In_Daily_Limit_Update = createAsyncThunk(
  "/Merchant_Pay_In_Daily_Limit_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Daily_Limit(payload);
  }
);
export const Merchant_Pay_In_Per_Limit_Update = createAsyncThunk(
  "/Merchant_Pay_In_Per_Limit_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Per_Limit(payload);
  }
);
export const Merchant_Pay_In_Central_Bank_Add = createAsyncThunk(
  "/Merchant_Pay_In_Central_Bank_Add",
  async (payload) => {
    return await Add_Merchant_Pay_In_Central_Bank(payload);
  }
);
export const Merchant_Pay_In_Payment_Methods_Update = createAsyncThunk(
  "/Merchant_Pay_In_Payment_Methods_Update",
  async (payload) => {
    return await Update_Merchant_Pay_In_Payment_Methods(payload);
  }
);

export const MerchantPayInPaymentLevelUpdate = createAsyncThunk(
  "/MerchantPayInPaymentLevelUpdate",
  async (payload) => {
    return await UpdateMerchantPayInPaymentLevel(payload);
  }
);

// PAY OUT
export const Merchant_Pay_Out_Get = createAsyncThunk(
  "/Merchant_Pay_Out_Get",
  async (payload) => {
    return await Get_Merchant_Pay_Out(payload);
  }
);

export const Merchant_Pay_Out_Available_Payment_Get = createAsyncThunk(
  "/Merchant_Pay_Out_Available_Payment_Get",
  async (payload) => {
    return await Get_Merchant_Pay_Out_Available_Payment(payload);
  }
);

export const Merchant_Pay_Out_Status_Update = createAsyncThunk(
  "/Merchant_Pay_Out_Status_Update",
  async (payload) => {
    return await Update_Merchant_Pay_Out_Status(payload);
  }
);
export const Merchant_Pay_Out_InUse_Status_Update = createAsyncThunk(
  "/Merchant_Pay_Out_InUse_Status_Update",
  async (payload) => {
    return await Update_Merchant_Pay_Out_InUse_Status(payload);
  }
);

export const Merchant_Pay_Out_Meta_Add = createAsyncThunk(
  "/Merchant_Pay_Out_Meta_Add",
  async (payload) => {
    return await Add_Merchant_Pay_Out_Meta(payload);
  }
);
export const Merchant_Pay_Out_Central_Bank_Add = createAsyncThunk(
  "/Merchant_Pay_Out_Central_Bank_Add",
  async (payload) => {
    return await Add_Merchant_Pay_Out_Central_Bank(payload);
  }
);

export const merchant_white_listIps_status_update = createAsyncThunk(
  "/Merchant_Pay_In_Status_Update",
  async (payload) => {
    return await Update_Merchant_White_ListIps_Status(payload);
  }
);

const merchantSlice = createSlice({
  name: "merchantSlice",
  initialState,
  reducers: {
    setMerchant: (state, { payload }) => {
      state.merchantState = payload;
    },
    setMerchantCentralBank: (state, { payload }) => {
      state.merchantState = payload;
    },
    setAddMerchantCentralBank: (state, { payload }) => {
      state.merchantState = payload;
    },
    setMerchantPayin: (state, { payload }) => {
      state.merchantPayInState = payload;
    },
    setMerchantPayout: (state, { payload }) => {
      state.merchantPayOutState = payload;
    },
    setWhitelistIps: (state, { payload }) => {
      state.viewMerchantWhitelistIpsState = payload;
    },
    clearMerchant: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetMerchant.fulfilled, (state, action) => {
      state.merchantStateisLoading = false;
      state.merchantState = action.payload;
    });
    builder.addCase(GetMerchant.pending, (state, action) => {
      state.merchantStateisLoading = true;
    });
    builder.addCase(GetMerchant.rejected, (state, action) => {
      state.merchantStateisLoading = false;
    });
    builder.addCase(Bank_Status_Fetch.fulfilled, (state, action) => {
      state.merchantStateisLoading = false;
      state.bankStateFetchState = action.payload;
    });
    builder.addCase(view_Statement.fulfilled, (state, action) => {
      state.viewStatementState = action.payload;
      state.viewStatementStateLoading = false;
    });
    builder.addCase(view_Statement.pending, (state, action) => {
      state.viewStatementStateLoading = true;
    });
    builder.addCase(view_Statement.rejected, (state, action) => {
      state.viewStatementStateLoading = false;
    });
    builder.addCase(view_Dashboard_Logs.fulfilled, (state, action) => {
      state.viewDashboardLogs = action.payload;
      state.viewDashboardLogsLoading = false;
    });
    builder.addCase(view_Dashboard_Logs.pending, (state, action) => {
      state.viewDashboardLogsLoading = true;
    });
    builder.addCase(view_Dashboard_Logs.rejected, (state, action) => {
      state.viewDashboardLogsLoading = false;
    });
    builder.addCase(view_Merchant_White_list_Ips.fulfilled, (state, action) => {
      state.viewMerchantWhitelistIpsStateLoading = false;
      state.viewMerchantWhitelistIpsState = action.payload;
    });
    builder.addCase(view_Merchant_White_list_Ips.pending, (state, action) => {
      state.viewMerchantWhitelistIpsStateLoading = true;
    });
    builder.addCase(view_Merchant_White_list_Ips.rejected, (state, action) => {
      state.viewMerchantWhitelistIpsStateLoading = false;
    });

    builder.addCase(Merchant_Pay_In_Get.fulfilled, (state, action) => {
      state.merchantPayInStateLoading = false;
      state.merchantPayInState = action.payload || {};
    });
    builder.addCase(Merchant_Pay_In_Get.pending, (state, action) => {
      state.merchantPayInStateLoading = true;
    });
    builder.addCase(Merchant_Pay_In_Get.rejected, (state, action) => {
      state.merchantPayInStateLoading = false;
    });

    builder.addCase(
      Merchant_Pay_In_Available_Payment_Get.fulfilled,
      (state, action) => {
        state.merchantPayInAvailablePaymentStateLoading = false;
        state.merchantPayInAvailablePaymentState = action.payload;
      }
    );
    builder.addCase(
      Merchant_Pay_In_Available_Payment_Get.pending,
      (state, action) => {
        state.merchantPayInAvailablePaymentStateLoading = true;
      }
    );
    builder.addCase(
      Merchant_Pay_In_Available_Payment_Get.rejected,
      (state, action) => {
        state.merchantPayInAvailablePaymentStateLoading = false;
      }
    );
    builder.addCase(Merchant_Pay_Out_Get.fulfilled, (state, action) => {
      state.merchantPayOutStateLoading = false;
      state.merchantPayOutState = action.payload;
    });
    builder.addCase(Merchant_Pay_Out_Get.pending, (state, action) => {
      state.merchantPayOutStateLoading = true;
    });
    builder.addCase(Merchant_Pay_Out_Get.rejected, (state, action) => {
      state.merchantPayOutStateLoading = false;
    });

    builder.addCase(
      Merchant_Pay_Out_Available_Payment_Get.fulfilled,
      (state, action) => {
        state.merchantPayOutAvailablePaymentStateLoading = false;
        state.merchantPayOutAvailablePaymentState = action.payload;
      }
    );
    builder.addCase(
      Merchant_Pay_Out_Available_Payment_Get.pending,
      (state, action) => {
        state.merchantPayOutAvailablePaymentStateLoading = true;
      }
    );
    builder.addCase(
      Merchant_Pay_Out_Available_Payment_Get.rejected,
      (state, action) => {
        state.merchantPayOutAvailablePaymentStateLoading = false;
      }
    );
    builder.addCase(Merchant_Permission_Get.fulfilled, (state, action) => {
      state.merchantPermissionStateLoading = false;
      state.merchantPermissionState = JSON.parse(
        action.payload?.data?.permission || "[]"
      );
    });
    builder.addCase(Merchant_Permission_Get.pending, (state, action) => {
      state.merchantPermissionStateLoading = true;
    });
    builder.addCase(Merchant_Permission_Get.rejected, (state, action) => {
      state.merchantPermissionStateLoading = false;
    });
    builder.addCase(pending_Settlement_Get.fulfilled, (state, action) => {
      state.merchantPendingSettlementIsLoading = false;
      state.merchantPendingSettlementState = action.payload;
    });
    builder.addCase(pending_Settlement_Get.pending, (state, action) => {
      state.merchantPendingSettlementIsLoading = true;
    });
    builder.addCase(pending_Settlement_Get.rejected, (state, action) => {
      state.merchantPendingSettlementIsLoading = false;
    });
    builder.addCase(Merchant_Module_Get.fulfilled, (state, action) => {
      state.merchantModuleLoading = false;
      state.merchantModuleState = action.payload;
    });
    builder.addCase(Merchant_Module_Get.pending, (state, action) => {
      state.merchantModuleLoading = true;
    });
    builder.addCase(Merchant_Module_Get.rejected, (state, action) => {
      state.merchantModuleLoading = false;
    });
    builder.addCase(GetaddMerchantWhitelistIp.fulfilled, (state, action) => {
      state.addMerchantWhitelistIpLoading = false;
      state.addMerchantWhitelistIpState = action.payload;
    });
    builder.addCase(GetaddMerchantWhitelistIp.pending, (state, action) => {
      state.addMerchantWhitelistIpLoading = true;
    });
    builder.addCase(GetaddMerchantWhitelistIp.rejected, (state, action) => {
      state.addMerchantWhitelistIpLoading = false;
    });
  },
});

export default merchantSlice.reducer;
export const {
  clearMerchant,
  setMerchant,
  setMerchantPayin,
  setMerchantPayout,
  setWhitelistIps,
  setMerchantCentralBank,
  setAddMerchantCentralBank,
} = merchantSlice.actions;

export const selectMerchantData = (state) => state.merchant.merchantState;
export const useMerchantData = () => {
  const merchantData = useSelector(selectMerchantData);
  return useMemo(() => merchantData, [merchantData]);
};

export const selectViewMerchantWhitelistIpsState = (state) =>
  state.merchant.viewMerchantWhitelistIpsState;

export const useViewMerchantWhitelistIpsState = () => {
  const viewMerchantWhitelistIpsState = useSelector(
    selectViewMerchantWhitelistIpsState
  );
  return useMemo(
    () => viewMerchantWhitelistIpsState,
    [viewMerchantWhitelistIpsState]
  );
};

export const selectMerchantStateisLoading = (state) =>
  state.merchant.merchantStateisLoading;
export const useMerchantStateisLoading = () => {
  const merchantStateisLoading = useSelector(selectMerchantStateisLoading);
  return useMemo(() => merchantStateisLoading, [merchantStateisLoading]);
};

export const selectMerchantOptions = (state) => state.merchant.merchantState;
export const useMerchantOptions = () => {
  const options = useSelector(selectMerchantOptions);
  return useMemo(() => options, [options]);
};

export const selectMerchantPayInLoading = (state) =>
  state.merchant.merchantPayInStateLoading;
export const useMerchantPayInLoading = () => {
  const isLoading = useSelector(selectMerchantPayInLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectMerchantPayInAvailablePaymentLoading = (state) =>
  state.merchant.merchantPayInAvailablePaymentStateLoading;
export const useMerchantPayInAvailablePaymentLoading = () => {
  const isLoading = useSelector(selectMerchantPayInAvailablePaymentLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectMerchantPayIn = (state) => state.merchant.merchantPayInState;
export const useMerchantPayIn = () => {
  const data = useSelector(selectMerchantPayIn);
  return useMemo(() => data, [data]);
};

export const selectMerchantPayInAvailablePayment = (state) =>
  state.merchant.merchantPayInAvailablePaymentState;
export const useMerchantPayInAvailablePayment = () => {
  const data = useSelector(selectMerchantPayInAvailablePayment);
  return useMemo(() => data, [data]);
};

export const selectMerchantPayOutLoading = (state) =>
  state.merchant.merchantPayOutStateLoading;
export const useMerchantPayOutLoading = () => {
  const isLoading = useSelector(selectMerchantPayOutLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectMerchantPayOutAvailablePaymentLoading = (state) =>
  state.merchant.merchantPayOutAvailablePaymentStateLoading;
export const useMerchantPayOutAvailablePaymentLoading = () => {
  const isLoading = useSelector(selectMerchantPayOutAvailablePaymentLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const selectMerchantPayOut = (state) =>
  state.merchant.merchantPayOutState;
export const useMerchantPayOut = () => {
  const data = useSelector(selectMerchantPayOut);
  return useMemo(() => data, [data]);
};

export const selectMerchantPayOutAvailablePayment = (state) =>
  state.merchant.merchantPayOutAvailablePaymentState;
export const useMerchantPayOutAvailablePayment = () => {
  const data = useSelector(selectMerchantPayOutAvailablePayment);
  return useMemo(() => data, [data]);
};

export const selectMerchantPermissionState = (state) =>
  state.merchant.merchantPermissionState;

export const useMerchantPermission = () => {
  const merchantPermissionState = useSelector(selectMerchantPermissionState);
  return useMemo(() => merchantPermissionState, [merchantPermissionState]);
};

export const selectMerchantPendingSettlementState = (state) =>
  state.merchant.merchantPendingSettlementState;

export const useMerchantPendingSettlementState = () => {
  const merchantPendingSettlementState = useSelector(
    selectMerchantPendingSettlementState
  );
  return useMemo(
    () => merchantPendingSettlementState,
    [merchantPendingSettlementState]
  );
};

export const selectMerchantModuleState = (state) =>
  state.merchant.merchantModuleState;

export const useMerchantModuleState = () => {
  const merchantModuleState = useSelector(selectMerchantModuleState);
  return useMemo(() => merchantModuleState, [merchantModuleState]);
};
