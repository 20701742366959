import React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Form,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  TRA_FILTER,
  PG_LOCAL,
  TRA_PAGINATION_OPTIONS,
  DROPDOWN_SEARCH_VALUE,
} from "../../constant";
import Table from "../../components/Table/Table";
import TableRow from "../../components/Table/TableRow";
import { useLocation } from "react-router-dom";
import {
  capitalCase,
  getLocalData,
  responseToaster,
  seperator,
  setLocalData,
} from "../../helperFunctions";
import PgLimit from "./PgLimit";
import PgAvailableMethod from "./PgAvailableMethod";
import AddPgModal from "./AddPgModal";
import PgTestModal from "./PgTestModal";
import PgEmptyBankModal from "./PgEmptyBankModal";
import PgMerchantId from "./PgMerchantId";
import PgAccount from "./PgAccount";
import PgStatus from "./PgStatus";
import { pgGet } from "../../redux/slices/pgSlice";
import PgUpdateBankModal from "./PgUpdateBankModal";
import "./Pg.scss";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../components/Custom/Forms/Controller/ControlledInput";
import PgUpdateUserModal from "./PgUpdateUserModal";
import { useRole, useUser } from "../../redux/slices/authSlice";
import PgProxyId from "./PgProxyId";

const nameChanges = {
  payout: "withdrawal",
  payin: "deposit",
};

export const getPgIdFromPgName = (pg, pgName) => {
  const pgId = pg?.find((pg) => {
    return pg?.name === pgName
  })

  return pgId?.pg_id
}

const FincarePaymeta = () => {
  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [addPgModalOpen, setAddPgModalOpen] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(TRA_FILTER);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [bankStatusIsLoading, setBankStatusIsLoading] = useState(false);
  const [updateStatusIsLoading, setupdateStatusIsLoading] = useState(false);
  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isPayout = params?.pgType === "payout";
  const isUpiPay = params?.pgName === "UPIPAY" && params?.pgType === "payin";
  const [pgTestModal, setPgTestModal] = useState(false);
  const [pgEmptyBankModal, setPgEmptyBankModal] = useState(false);
  const [pgUpdateBankModal, setPgUpdateBankModal] = useState(false);
  const [pgUpdateStarPaisaModal, setPgUpdateStarPaisaModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [updateBalanceDetails, setUpdateBalanceDetails] = useState({});
  const { user } = useUser();

  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(PG_LOCAL, false)
  );
  const payinRole = useRole("PG Deposit Bank");
  const payoutRole = useRole("PG Withdrawal Bank");

  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(PG_LOCAL, !isFilterOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const upiPayOptions =
    params?.pgName === "UPIPAY"
      ? [
        { value: "upiId", label: "UPI ID" },
        { value: "acNumber", label: "Account Number" },
      ]
      : [];

  const filterOptions = [
    { value: "merchant_id", label: "Merchant Id" },
    { value: "account_id", label: "Account Id" },
    { value: "label", label: "Label" },
    ...upiPayOptions,
  ];

  const initialValues = {
    filter: isUpiPay ? DROPDOWN_SEARCH_VALUE : filterOptions?.[0]?.value,
    search: "",
  };

  const statusOptions = [
    { value: 1, label: "Enabled" },
    { value: 0, label: "Disabled" },
  ];

  const columns = [
    {
      title: "BANK ID",
      name: "bank_id",
      checkKey: "BANK ID",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {isUpiPay ? (
                <div className="order-singal-div">
                  <p>
                    <span>Bank : </span>
                    {data?.account_id || ""}
                  </p>
                </div>
              ) : null}
              {isUpiPay ? (
                <div className="order-singal-div">
                  <p>
                    <span>MID : </span>
                    {data?.merchant_id || ""}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ID",
      name: "id",
      checkKey: "ID",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {isPayin || isPayout ? (
                <div className="order-singal-div">
                  <p>{data?.id}</p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "VENDOR",
      name: "vendor_id",
      checkKey: "VENDOR",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {isUpiPay ? (
                <div className="order-singal-div">
                  <p>
                    <span>ID : </span>
                    {data?.vendor_id || ""}
                  </p>
                </div>
              ) : null}
              {isUpiPay ? (
                <div className="order-singal-div">
                  <p>
                    <span>Vendor : </span>
                    {data?.vendor_name || ""}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ACCOUNT",
      name: "account_id",
      checkKey: "ACCOUNT",
      Cell: (item) => {
        return (
          <>
            <PgAccount
              data={item}
              onGet={onGet}
              columns={data?.pgConfig?.editable_columns || []}
              setIsLoading={setIsLoading}
            />
          </>
        );
      },
    },
    {
      title: "MERCHANT ID",
      name: "merchant_id",
      checkKey: "MERCHANT ID",
      Cell: (item) => {
        return (
          <>
            {(isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
              (isPayout && payoutRole?.includes("pg-withdrawal-bank-update")) ? (
              <PgMerchantId
                setUpdateBalanceDetails={setUpdateBalanceDetails}
                updateBalanceDetails={updateBalanceDetails}
                data={item}
                onGet={onGet}
                columns={data?.pgConfig?.editable_columns || []}
                setIsLoading={setIsLoading}
              />
            ) : (
              <div
                className={"cell-order"}
                onClick={(e) => e.stopPropagation()}
              >
                {isPayin || isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Max Count Limit : </span>
                      {`${seperator(item?.max_count_limit, false)}`}
                    </p>
                  </div>
                ) : null}
                {isPayout ? (
                  <div className="order-singal-div">
                    <span>Balance : </span>
                    {`${seperator(item?.available_balance)}`}
                  </div>
                ) : null}
                {item?.last_check_balance_at_ist && isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Last Check : </span>
                      {item?.last_check_balance_at_ist || "-"}
                    </p>
                  </div>
                ) : null}
                {item?.product_info && isPayin ? (
                  <div className="order-singal-div">
                    <span>Product Info : </span>
                    {item?.product_info || "Not Set"}
                  </div>
                ) : null}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "LABEL/EMAIL	",
      name: "label",
      checkKey: "LABEL/EMAIL",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {isPayin || isPayout ? (
                <div className="order-singal-div">
                  <p>
                    <span>{data?.label || ""}</span>
                  </p>
                </div>
              ) : null}
              {isPayin || (data?.email_id && isPayout) ? (
                <div className="order-singal-div">
                  <p>
                    <span>Email : </span>
                    {data?.email_id || ""}
                  </p>
                </div>
              ) : null}
              {isPayout && data?.debit_account ? (
                <div className="order-singal-div">
                  <p>
                    <span>A/C : </span>
                    {data?.debit_account}
                  </p>
                </div>
              ) : null}
              {isPayout && data?.ifsc_code ? (
                <div className="order-singal-div">
                  <p>
                    <span>IFSC : </span>
                    {data?.ifsc_code}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "AVAILABLE METHOD",
      name: "available_method",
      checkKey: "AVAILABLE METHOD",
      Cell: (item) => {
        const words = item?.available_method?.split(",");
        return (
          <>
            {(isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
              (isPayout && payoutRole?.includes("pg-withdrawal-bank-update")) ? (
              <PgAvailableMethod
                item={item}
                columns={data?.pgConfig?.editable_columns || []}
                onGet={onGet}
                setIsLoading={setIsLoading}
              />
            ) : (
              <div
                className={`${data?.isOpen ? "cell-order-white" : "cell-order"
                  }`}
                onClick={(e) => e.stopPropagation()}
              >
                {isPayin || isPayout ? (
                  <div className="order-singal-div">
                    <span>
                      {" "}
                      {words?.map((val, index) => {
                        return (
                          <>
                            <div key={index}>{val}</div>
                          </>
                        );
                      }) || "Not Set"}
                    </span>
                  </div>
                ) : null}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "BOUNCER",
      name: "createdAtIst",
      checkKey: "BOUNCER",
      Cell: (item) => {
        return (
          <>
            <div
              className={`${item?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="order-singal-div">
                <p>
                  <span>Name : </span>
                  {item?.proxy_list?.label_name || "-"}
                </p>
              </div>
              {(isPayin || isPayout) ? (
                <>
                  {(isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
                    (isPayout && payoutRole?.includes("pg-withdrawal-bank-update")) ? (
                    <PgProxyId
                      item={item}
                      columns={data?.pgConfig?.editable_columns || []}
                      onGet={onGet}
                      setIsLoading={setIsLoading}
                      options={data?.pgConfig?.add_meta_columns || []}
                    />
                  ) : (
                    <div
                      className={`${item?.isOpen ? "cell-order-white" : "cell-order"
                        }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isPayin || isPayout ? (
                        <div className="order-singal-div">
                          <p>
                            <span>Proxy ID : </span>
                            {item?.proxy_id || "-"}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : null}
              {item?.proxy_id && (isPayin || isPayout) ? (
                <div className="order-singal-div">
                  <p>
                    <span>Proxy IP : </span>
                    {item?.proxy_list ? item?.proxy_list?.ip_proxy : ""}
                  </p>
                </div>
              ) : null}
              {item?.bouncer_sub_domain_url && isPayin ? (
                <div className="order-singal-div">
                  <p>
                    <span>Bouncer : </span>
                    {item?.bouncer_sub_domain_url || "-"}
                  </p>
                </div>
              ) : null}
              {item?.callback_sub_domain_url && isPayin ? (
                <div className="order-singal-div">
                  <p>
                    <span>CallBack : </span>
                    {item?.callback_sub_domain_url || "-"}
                  </p>
                </div>
              ) : null}
              {item?.white_listed_ip && isPayin ? (
                <div className="order-singal-div">
                  <p>
                    <span>Whitelist IP : </span>
                    {item?.white_listed_ip || "-"}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "status",
      checkKey: "STATUS",
      Cell: (item) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <PgStatus
              data={item}
              pgData={data}
              setPgData={setData}
              columns={data?.pgConfig?.editable_columns || []}
              onGet={onGet}
              setupdateStatusIsLoading={setupdateStatusIsLoading}
              updateStatusIsLoading={updateStatusIsLoading}
              setBankStatusIsLoading={setBankStatusIsLoading}
              bankStatusIsLoading={bankStatusIsLoading}
            />
          </div>
        );
      },
    },
    {
      title: "LIMIT/TURNOVER",
      checkKey: "LIMIT/TURN OVER",
      name: "Limit/Turn Over",
      Cell: (item) => {
        return (
          <>
            {(isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
              (isPayout && payoutRole?.includes("pg-withdrawal-bank-update")) ? (
              <PgLimit
                updateDetails={updateDetails}
                setUpdateDetails={setUpdateDetails}
                data={item}
                onGet={onGet}
                columns={data?.pgConfig?.editable_columns || []}
                setIsLoading={setIsLoading}
              />
            ) : (
              <div
                className={"cell-order"}
                onClick={(e) => e.stopPropagation()}
              >
                {" "}
                {isUpiPay || isPayin || isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Min Limit : </span>
                      {`${seperator(item?.min_limit)}`}
                    </p>
                  </div>
                ) : null}
                {isUpiPay || isPayin || isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Max Limit : </span>
                      {`${seperator(item?.max_limit)}`}
                    </p>
                  </div>
                ) : null}
                {/*  {isUpiPay || isPayin || isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Max Count Limit : </span>
                      {`${seperator(item?.max_count_limit, false)}`}
                    </p>
                  </div>
                ) : null} */}
                {isUpiPay || isPayin ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Turnover : </span>
                      {`${seperator(item?.turn_over)}`}
                    </p>
                  </div>
                ) : null}
                {isUpiPay || isPayin ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Current Turnover : </span>
                      {`${seperator(item?.current_turn_over)}`}
                    </p>
                  </div>
                ) : null}
                {isUpiPay ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Live Balance : </span>
                      {`${seperator(item?.live_bank_balance)}`}
                    </p>
                  </div>
                ) : null}
                {isPayout ? (
                  <div className="order-singal-div">
                    <p>
                      <span>Remain Limit : </span>
                      {`${seperator(item?.remain_limit)}`}
                    </p>
                  </div>
                ) : null}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "DATE",
      name: "Date",
      checkKey: "DATE",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {isUpiPay || isPayin || isPayout ? (
                <div className="order-singal-div">
                  <p>
                    <span>Create : </span>
                    {data?.created_at_ist || ""}
                  </p>
                </div>
              ) : null}
              {isUpiPay || isPayin || isPayout ? (
                <div className="order-singal-div">
                  <p>
                    <span>Update : </span>
                    {data?.updated_at_ist || ""}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      name: "action",
      checkKey: "ACTION",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* {isUpiPay || isPayin ? (
                <Button color="primary" onClick={() => setPgTestModal(data)}>
                  Test
                </Button>
              ) : null} */}
              {/*  {isPayout &&
              params?.pgName === "BULKPE" &&
              ((isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
                (isPayout &&
                  payoutRole?.includes("pg-withdrawal-bank-update"))) ? (
                <Button
                  color="primary"
                  onClick={() => setPgEmptyBankModal(data)}
                >
                  Empty Bank
                </Button>
              ) : null}
              {isUpiPay && payinRole?.includes("pg-deposit-bank-update") ? (
                <Button
                  color="primary"
                  // className="ms-1"
                  onClick={(e) => {
                    setPgUpdateBankModal(data);
                    e.stopPropagation();
                  }}
                >
                  Update Bank Credential
                </Button>
              ) : null}
              {isStarPaisa &&
              ((isPayin && payinRole?.includes("pg-deposit-bank-update")) ||
                (isPayout &&
                  payoutRole?.includes("pg-withdrawal-bank-update"))) ? (
                <Button
                  color="primary"
                  onClick={(e) => {
                    setPgUpdateStarPaisaModal(data);
                    e.stopPropagation();
                  }}
                >
                  Update User Credential
                </Button>
              ) : null} */}
              {isPayin && payinRole?.includes("pg-deposit-bank-update") || (isPayout &&
                payoutRole?.includes("pg-withdrawal-bank-update")) ? <Button
                  color="primary"
                  onClick={(e) => {
                    setPgUpdateBankModal(data);
                    e.stopPropagation();
                  }}
                >
                Update
              </Button> : null}
            </div>
          </>
        );
      },
    },
  ];

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const onGetPg = async (loading) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        search,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
          filter_data: {
            [dropdown]: search,
            ...rest,
          },
          page_no,
          limit,
          pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName)
        }
        : { page_no, limit, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) };
      const res = await dispatch(pgGet({ payload, params })).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setData(res || {});
      setIsLoading(false);
    } catch (err) {
      setData({});
      setIsLoading(false);
    }
  };

  const onGet = () => {
    setFilter({ ...filter });
  };

  useEffect(() => {
    if (
      payinRole?.includes("pg-deposit-bank-list") ||
      payoutRole?.includes("pg-withdrawal-bank-list")
    ) {
      onGetPg(true);
    }
  }, [filter]);

  useEffect(() => {
    if (
      payinRole?.includes("pg-deposit-bank-list") ||
      payoutRole?.includes("pg-withdrawal-bank-list")
    ) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetPg(true);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  useEffect(() => {
    const newColumns = columns
      ?.filter(
        (item) =>
          item?.isOpen !== false &&
          data?.pgConfig?.show_columns?.includes(item?.checkKey)
      )
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, [data]);

  const getName = () =>
    `${capitalCase(params?.pgName)} ${capitalCase(
      nameChanges?.[params?.pgType?.toLowerCase()]
    )} Bank`;

  if (
    !payinRole?.includes("pg-deposit-bank-list") &&
    !payoutRole?.includes("pg-withdrawal-bank-list")
  ) {
    return;
  }

  return (
    <>
      <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
        <Card>
          {!pgUpdateBankModal ? <> <CardHeader className="flex-column align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>{getName()}</b>
                </h4>
              </div>

              <div
                className="merchant-management-card"
                style={{ display: "flex", justifyContent: "end", gap: "10px" }}
              >
                <div className="mb-xl-0 d-flex align-items-center">
                  <Button
                    type="button"
                    className={`${refresh ? "" : "filterbtn"} gap`}
                    color={refresh ? "primary" : ""}
                    onClick={() => setRefresh(!refresh)}
                    title="Auto refresh"
                  >
                    {refresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </div>
                {data?.pgConfig?.add_meta_columns?.columns?.length > 0 &&
                  ((isPayin && payinRole?.includes("pg-deposit-bank-list")) ||
                    (isPayout &&
                      payoutRole?.includes("pg-withdrawal-bank-list"))) ? (
                  <Button
                    type="button"
                    color="primary"
                    title={`Add ${getName()}`}
                    onClick={() => {
                      setPgUpdateBankModal(true);
                    }}
                  >
                    Add {getName()}
                  </Button>
                ) : null}
                <Button
                  type="button"
                  color="filterbtn "
                  className="filterbtn"
                  onClick={toggleIsFilterOpen}
                  title="Apply Filter"
                >
                  <i className="fa-solid fa-filter"></i> Apply Filter
                </Button>

                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                    title="Column Filter"
                  >
                    <IoFilterOutline
                      value={{ color: "#7367F0" }}
                      fill="#7367F0"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        if (
                          !data?.pgConfig?.show_columns?.includes(
                            column?.checkKey
                          )
                        ) {
                          return <></>;
                        }
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <Form
              className="transaction-main-div"
              onSubmit={handleSubmit(onSubmit)}
            >
              {isFilterOpen && (
                <>
                  <div className="d-flex align-items-end button-space mt-1  flex-wrap">
                    <ControlledDropDown
                      name="filter"
                      label="Filter"
                      isDefaultOption={isUpiPay ? DROPDOWN_SEARCH_VALUE : ""}
                      options={filterOptions}
                      control={control}
                    />
                    <ControlledInput
                      placeholder="Enter Search Value"
                      name="search"
                      label="Search"
                      control={control}
                    />
                    <ControlledDropDown
                      name="status"
                      label="Status"
                      isDefaultOption={false}
                      options={statusOptions}
                      control={control}
                    />
                    <Button type="submit" color="primary">
                      Apply
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => {
                        reset();
                        setFilter({ ...TRA_FILTER });
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </CardHeader>
            <Table
              columns={columns}
              filterColumns={filterColumns}
              isLoading={isLoading && !refresh}
              isExpandable={true}
              data={data?.data || []}
              isData={!!data?.data?.length}
              config={data?.pgConfig}
              count={data?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                });
              }}
              paginationOptions={TRA_PAGINATION_OPTIONS}
            >
              {data?.data?.map((item) => {
                return (
                  <TableRow
                    tdClassName="position-relative"
                    columns={columns}
                    config={data?.pgConfig}
                    item={item}
                    filterColumns={filterColumns}
                    isExpandable={true}
                  >
                    <div className="gap">
                      <span className="bold">Bank Merchant : </span>
                      {item?.bankmerchantlist?.map((val, index, array) => {
                        return (
                          <>
                            <span className="w-700">
                              {val} {index !== array?.length - 1 ? "," : ""}
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </TableRow>
                );
              })}
            </Table> </> : <PgUpdateBankModal
            isOpen={pgUpdateBankModal}
            onHide={() => {
              setPgUpdateBankModal(false);
              setIsShow(false);
            }}
            config={data?.pgConfig?.add_meta_columns}
            onGet={() => onGetPg(true)}
            setIsShow={setIsShow}
            isShow={isShow}
            params={params}
          />}
        </Card>
      </div>
      <AddPgModal
        isOpen={addPgModalOpen}
        onHide={() => setAddPgModalOpen(false)}
        config={data?.pgConfig?.add_meta_columns}
        onGet={() =>
          setFilter({
            ...filter,
            ...TRA_FILTER,
          })
        }
      />
      <PgTestModal isOpen={pgTestModal} onHide={() => setPgTestModal(false)} />
      <PgEmptyBankModal
        isOpen={pgEmptyBankModal}
        onHide={() => setPgEmptyBankModal(false)}
      />

      <PgUpdateUserModal
        isOpen={pgUpdateStarPaisaModal}
        onHide={() => {
          setPgUpdateStarPaisaModal(false);
          setIsShow(false);
        }}
        onGet={() => onGetPg(true)}
        setIsShow={setIsShow}
        isShow={isShow}
        params={params}
      />
    </>
  );
};

export default FincarePaymeta;
